import axios, { getHeaders } from "./SipMonClient";
import { ISipApiResponse,  IAdlsCapacityTrend } from "../models";
import { sliceName } from "../reducers/AdlsCapacityTrend";
import { StateActionType } from "../types";



export const getCapacityMetrics = async (): Promise<ISipApiResponse<IAdlsCapacityTrend[]>> => {
    const { data, status } = await axios.get<IAdlsCapacityTrend[]>(
        `api/adlsCapacityTrend`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return {
        data,
        message: "",
        status
    };
};

export const getSizingMetricsByPlatform = async (platform: string[]): Promise<ISipApiResponse<IAdlsCapacityTrend[]>> => {
   
    const { data, status } = await axios.post<IAdlsCapacityTrend[]>(
        `api/adlsCapacityTrend`,
        platform,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

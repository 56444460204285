import RbacRoute from "./components/common/RbacRoute";
import { PMUser } from "./constants";
import { AccessRequestEdit } from "./components/AccessPackages/AccessRequestEdit";
import AddAccessPackage from "./components/AccessPackages/AddAccessPackage";
import AccessPackageList from "./pages/AccessPackageList";
import AccessRequestList from "./pages/AccessRequestList";
import UpdateAccessPackage from "./components/AccessPackages/UpdateAccessPackage";
import MigrateAccessPackage from "./components/AccessPackages/MigrateAccessPackage";
import AssignmentsList from "./pages/AssignmentsList";
import CloneAssignment from "./components/Assignments/CloneAssignment";
import AssignmentClones from "./components/Assignments/AssignmentClones";
import AssignmentCloneView from "./components/Assignments/AssignmentCloneView";

const ElmRoutes = () => {
    return (
        <>
            <RbacRoute role={PMUser} path={"/accessPackages"} exact component={AccessPackageList} />
            <RbacRoute role={PMUser} path={"/accessRequests"} exact component={AccessRequestList} />
            <RbacRoute role={PMUser} path={"/accessRequests/:id"} exact component={AccessRequestEdit} />
            <RbacRoute role={PMUser} path={"/accessPackages/new"} exact component={AddAccessPackage} />
            <RbacRoute role={PMUser} path={"/accessPackage/:id"} exact component={UpdateAccessPackage} />
            <RbacRoute role={PMUser} path={"/migrateAccessPackage/:id"} exact component={MigrateAccessPackage} />
            <RbacRoute role={PMUser} path={"/assignments"} exact component={AssignmentsList} />
            <RbacRoute
                role={PMUser}
                path={"/assignmentclone/:id/:catalogId/:policy"}
                exact
                component={CloneAssignment}
            />
            <RbacRoute role={PMUser} path={"/assignmentClones"} exact component={AssignmentClones} />
            <RbacRoute
                role={PMUser}
                path={"/cloneStatusView/:targetId/:originalId/:catalogId"}
                exact
                component={AssignmentCloneView}
            />
        </>
    );
};

export default ElmRoutes;

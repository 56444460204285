import { ComboBox, IComboBoxProps, Label, Shimmer } from "@fluentui/react";
import { getShimmerStyles } from "../../../columns/utils";
type ShimmeredProps = {
    loading: boolean;
    cbxLabel: string;
    width?: string;
    isStackItem?: boolean;
    showLabel?: boolean;
} & IComboBoxProps;

function ShimmeredComboBox(props: ShimmeredProps) {
    return (
        <div className={props.isStackItem ? "" : "col"}>
            {(props.showLabel ?? true) && <Label className="label"> {props.cbxLabel} </Label>}
            <Shimmer isDataLoaded={!props.loading} ariaLabel={`Loading ${props.cbxLabel}`} styles={getShimmerStyles()}>
                <ComboBox ariaLabel={props.cbxLabel} {...props} />
            </Shimmer>
        </div>
    );
}

export { ShimmeredComboBox };

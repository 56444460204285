import { ButtonType, Dialog, DialogType, IButtonProps, IDialogContentProps, IDialogStyles } from "@fluentui/react";

import DialogContainerProps from "../../models/dialogProps/DialogContainerProps";
import * as React from "react";
import { useState } from "react";

export const DialgContainer: React.FC<DialogContainerProps> = ({
    showDialog,
    onClose,
    onDismiss,
    dialogProps,
    children,
    title
}) => {
    const [dialogState, setDialogState] = useState({ isMaximized: false, show: true });

    let MaximizableDialogProps: IDialogContentProps = {
        topButtonsProps: [getResizeButtonProps()],
        title,
        titleProps: { style: { margin: "10px" } },
        type: DialogType.close
    };

    let dialogStyle = getDialogStyle();

    function toggleDialogSize(): void {
        setDialogState({
            ...dialogState,
            isMaximized: !dialogState.isMaximized
        });
        updateDialogPropsAndStyles();
    }

    function updateDialogPropsAndStyles(): void {
        MaximizableDialogProps = {
            topButtonsProps: [getResizeButtonProps()]
        };
        dialogStyle = getDialogStyle();
    }

    function getResizeButtonProps(): IButtonProps {
        return dialogState.isMaximized
            ? {
                  buttonType: ButtonType.icon,
                  iconProps: { iconName: "BackToWindow" },
                  ariaLabel: "BackToWindow",
                  onClick: toggleDialogSize
              }
            : {
                  buttonType: ButtonType.icon,
                  iconProps: { iconName: "FullScreen" },
                  ariaLabel: "FullScreen",
                  onClick: toggleDialogSize
              };
    }

    function getDialogStyle(): IDialogStyles {
        return dialogState.isMaximized
            ? {
                  root: {},
                  main: {
                      width: "100% !important",
                      height: "100%",
                      maxWidth: "100% !important",
                      maxHeight: "100% !important",
                      overflowY: "hidden"
                  }
              }
            : {
                  root: {},
                  main: {
                      maxHeight: "100% !important",
                      maxWidth: "100% !important",
                      minHeight: "172px !important",
                      minWidth: "288px !important",
                      borderRadius: "4px",
                      overflowY: "hidden"
                  }
              };
    }

    const onDismissed = (): void => {
        setDialogState({ ...dialogState, isMaximized: false });
    };

    return (
        <Dialog
            hidden={!showDialog}
            onDismiss={onDismiss ? onDismiss : onClose}
            dialogContentProps={MaximizableDialogProps}
            styles={dialogStyle}
            modalProps={{ isBlocking: true, onDismissed }}
            {...dialogProps}
        >
            {children}
        </Dialog>
    );
};

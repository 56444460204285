import React, { useEffect } from "react";
import { Stack, Text, mergeStyles } from "@fluentui/react";
import Background from "../images/Hero.png";

const heroStyle = mergeStyles({
    backgroundImage: `url(${Background})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "white",
    height: "300px",
    marginBottom: "20px",
    selectors: {
        "@media(min-width: 576px)": {
            padding: "4rem 2rem"
        },
        "@media(max-width:576px)": {
            background: "none",
            backgroundColor: "#002060",
            padding: "2rem 2rem"
        }
    }
});

function HomePage() {
    useEffect(() => {
        document.title = "Home - SIP Portal";
    });
    return (
        <Stack className={`${heroStyle} jumbotron`}>
            <Stack.Item styles={{ root: { width: "60%" } }}>
                <Text block variant={"xxLarge"}>
                    <h1 style={{ color: "white" }}>Welcome to SIP Admin Portal</h1>
                </Text>
            </Stack.Item>
        </Stack>
    );
}

export default HomePage;

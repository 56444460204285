import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInitialState, onComplete, onLoading, StateActionType } from "../types";

import { IServerAdministrationHistory } from "../models";
import { getDefaultCases } from "./DefaultCases";
import { getServerAdministrationHistoriesById } from "../api/ServerAdministrationApi";

const sliceName = "ServerAdministrations";

const getServerAdministrationHistoryById = createAsyncThunk(`${sliceName}/get_by_id`, async (id: number, _thunkAPI) => {
    const response = await getServerAdministrationHistoriesById(id);
    return response.data;
});

const initialState = getInitialState<IServerAdministrationHistory[], IServerAdministrationHistory>();

// Then, handle actions in your reducers:
const servAdministrationsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IServerAdministrationHistory>(),
    extraReducers: (builder) => {
        builder.addCase(getServerAdministrationHistoryById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getServerAdministrationHistoryById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
    }
});

export const { reset, error, setCurrent } = servAdministrationsSlice.actions;
export { getServerAdministrationHistoryById, sliceName };
export const { reducer } = servAdministrationsSlice;

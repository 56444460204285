import axios, { getHeaders } from "./SipMonClient";
import { ISipApiResponse } from "../models/SipApiResponse";
import { sliceName } from "../reducers/DataSource";
import {
    IAdlsDataset,
    IDataset,
    IDatasetTag,
    IKustoCluster,
    IKustoDatabase,
    IKustoDataset,
    ISDWDataset,
    SDWSProcParamResponse,
    SDWSProcSchemaResponse,
    SProcSchemaRequest,
    StorageAccount,
    AdlsAutoDiscoveryEntry
} from "../models/Dataset";
import sipmonClient from "./SipMonClient";
import { StateActionType } from "../types";
import { IFeedPartition } from "../models";

export const getStorageAccounts = async (subscriptionId: string): Promise<ISipApiResponse<StorageAccount[]>> => {
    const { data, status } = await sipmonClient.get<StorageAccount[]>(
        `api/Dataset/getStorageAccountsBySubscription/${subscriptionId}`
    );
    return {
        data,
        message: "",
        status
    };
};

export const getPartitions = async (): Promise<ISipApiResponse<IFeedPartition[]>> => {
    const { data, status } = await sipmonClient.get<IFeedPartition[]>(`api/Dataset/getPartitions`);
    return {
        data,
        message: "",
        status
    };
};

export const getAdlsAutoDiscoveryEntries = async (
    subscriptionID: string,
    storageID: number
): Promise<ISipApiResponse<AdlsAutoDiscoveryEntry[]>> => {
    const { data, status } = await sipmonClient.get<AdlsAutoDiscoveryEntry[]>(
        `api/Dataset/getAdlsAutoDiscoveryEntries/${subscriptionID}/${storageID}`
    );
    return {
        data,
        message: "",
        status
    };
};

// Dataset

export const createDataset = async (formData: IDataset): Promise<ISipApiResponse<IDataset>> => {
    const { data, status } = await axios.post(
        `api/Dataset/AddDataset`,
        formData,
        getHeaders(sliceName, StateActionType.Add)
    );
    return { data, message: "", status };
};
// Update Dataset
export const putDataset = async (formData: IDataset): Promise<ISipApiResponse<IDataset>> => {
    const { data, status } = await axios.put(
        `api/Dataset/UpdateDataset`,
        formData,
        getHeaders(sliceName, StateActionType.Update)
    );
    return { data, message: "", status };
};

export const getDatasets = async (): Promise<ISipApiResponse<IDataset[]>> => {
    const { data, status } = await sipmonClient.get<IDataset[]>(`api/Dataset/getDatasets`);
    return {
        data,
        message: "",
        status
    };
};
// Get Dataset by Id
export const getDataset = async (dsID: number): Promise<ISipApiResponse<IDataset>> => {
    const { data, status } = await sipmonClient.get<IDataset>(`api/Dataset/getDataset/${dsID}`);
    return {
        data,
        message: "",
        status
    };
};

// Get Dataset Tags
export const getDatasetTags = async (): Promise<ISipApiResponse<IDatasetTag[]>> => {
    const { data, status } = await sipmonClient.get<IDatasetTag[]>(`api/Dataset/getTags`);
    return {
        data,
        message: "",
        status
    };
};

// ADLS Dataset

export const createAdlsDataset = async (formData: IAdlsDataset): Promise<ISipApiResponse<IAdlsDataset>> => {
    const { data, status } = await axios.post(
        `api/Dataset/AddAdlsDataset`,
        formData,
        getHeaders(sliceName, StateActionType.Add)
    );
    return { data, message: "", status };
};

// Get ADLS Dataset by dataset id
export const getADLSDataset = async (dsID: number): Promise<ISipApiResponse<IAdlsDataset>> => {
    const { data, status } = await sipmonClient.get<IAdlsDataset>(`api/Dataset/getADLSDataset/${dsID}`);
    return {
        data,
        message: "",
        status
    };
};

// Delete ADLS
export const deleteAdlsDatasetApi = async (id: number): Promise<ISipApiResponse<IAdlsDataset>> => {
    const { data, status } = await axios.delete(
        `api/Dataset/Adls/${id}`,
        getHeaders(sliceName, StateActionType.Delete)
    );

    return { data, message: "", status };
};

// SDW Dataset

export const createSDWDataset = async (formData: ISDWDataset): Promise<ISipApiResponse<ISDWDataset>> => {
    const { data, status } = await axios.post(
        `api/Dataset/AddSDWDataset`,
        formData,
        getHeaders(sliceName, StateActionType.Add)
    );
    return { data, message: "", status };
};

export const getSProcParamsApi = async (sprocName: string): Promise<ISipApiResponse<SDWSProcParamResponse>> => {
    const { data, status } = await axios.get(`api/Dataset/getSProcParams/${sprocName}`);
    return { data, message: "", status };
};

export const getSProcSchemaApi = async (
    sProcReq: SProcSchemaRequest
): Promise<ISipApiResponse<SDWSProcSchemaResponse>> => {
    const { data, status } = await axios.post(
        `api/Dataset/getSProcSchema`,
        sProcReq,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

export const getServerDetails = async (): Promise<ISipApiResponse<string[]>> => {
    const { data, status } = await sipmonClient.get<string[]>(`api/Dataset/getServers`);
    return {
        data,
        message: "",
        status
    };
};

export const getSDWDatabases = async (serverName: string): Promise<ISipApiResponse<string[]>> => {
    const { data, status } = await sipmonClient.get<string[]>(`api/Dataset/getDatabaseInfo/${serverName}`);
    return {
        data,
        message: "",
        status
    };
};

export const getSDWResourceList = async (dbName: string, type: number): Promise<ISipApiResponse<string[]>> => {
    const { data, status } = await sipmonClient.get<string[]>(`api/Dataset/getSDWResources/${dbName}/${type}`);
    return {
        data,
        message: "",
        status
    };
};

// Get SDW Dataset by Dataset id
export const getSDWDataset = async (dsID: number): Promise<ISipApiResponse<ISDWDataset>> => {
    const { data, status } = await sipmonClient.get<ISDWDataset>(`api/Dataset/getSDWDataset/${dsID}`);
    return {
        data,
        message: "",
        status
    };
};
// Delete SDW Dataset
export const deleteSDWDatasetApi = async (id: number): Promise<ISipApiResponse<ISDWDataset>> => {
    const { data, status } = await axios.delete(`api/Dataset/sdw/${id}`, getHeaders(sliceName, StateActionType.Delete));

    return { data, message: "", status };
};

export const getDateTimeFieldsByEntityApi = async (sdwDSId: number): Promise<ISipApiResponse<string[]>> => {
    const { data, status } = await sipmonClient.get<string[]>(`api/Dataset/getDateTimeFieldsByEntity/${sdwDSId}`);
    return {
        data,
        message: "",
        status
    };
}
// Kusto Dataset

export const createKustoDataset = async (formData: IKustoDataset): Promise<ISipApiResponse<IKustoDataset>> => {
    const { data, status } = await axios.post(
        `api/Dataset/AddKustoDataset`,
        formData,
        getHeaders(sliceName, StateActionType.Add)
    );
    return { data, message: "", status };
};

// Get Kusto Dataset by Dataset id
export const getKustoDataset = async (dsID: number): Promise<ISipApiResponse<IKustoDataset>> => {
    const { data, status } = await sipmonClient.get<IKustoDataset>(`api/Dataset/getKustoDataset/${dsID}`);
    return {
        data,
        message: "",
        status
    };
};

export const getKustoClusters = async (): Promise<ISipApiResponse<IKustoCluster[]>> => {
    const { data, status } = await sipmonClient.get<IKustoCluster[]>(`api/Dataset/getKustoClusters`);
    return {
        data,
        message: "",
        status
    };
};

export const getKustoDatabases = async (clusterID: number): Promise<ISipApiResponse<IKustoDatabase[]>> => {
    const { data, status } = await sipmonClient.get<IKustoDatabase[]>(`api/Dataset/getKustoDatabases/${clusterID}`);
    return {
        data,
        message: "",
        status
    };
};

export const getKustoTables = async (dbId: number): Promise<ISipApiResponse<string[]>> => {
    const { data, status } = await sipmonClient.get<string[]>(`api/Dataset/getKustoTables/${dbId}`);
    return {
        data,
        message: "",
        status
    };
};

// Delete Kusto Dataset
export const deleteKustoDatasetApi = async (id: number): Promise<ISipApiResponse<IKustoDataset>> => {
    const { data, status } = await axios.delete(
        `api/Dataset/Kusto/${id}`,
        getHeaders(sliceName, StateActionType.Delete)
    );

    return { data, message: "", status };
};

// Check dataset duplicates by name
export const checkDatasetDuplicate = async (id: number, name: string): Promise<boolean> => {
    try {
        await sipmonClient.post(`api/Dataset/checkDatasetDuplicate`, { id, name });
        return false;
    } catch (e) {
        if (axios.isAxiosError(e) && e.response && e.response.status === 400) return true;
        else throw e;
    }
};
export const checkAdlsDuplicate = async (id: number, storageId: number, path: string): Promise<boolean> => {
    try {
        await sipmonClient.post(`api/Dataset/checkAdlsDuplicate`, { id, storageId, path });
        return false;
    } catch (e) {
        if (axios.isAxiosError(e) && e.response && e.response.status === 400) return true;
        else throw e;
    }
};

export const checkKustoDuplicate = async (
    id: number,
    cluster: string,
    database: string,
    name: string
): Promise<boolean> => {
    try {
        await sipmonClient.post(`api/Dataset/checkKustoDuplicate`, { id, cluster, database, name });
        return false;
    } catch (e) {
        if (axios.isAxiosError(e) && e.response && e.response.status === 400) return true;
        else throw e;
    }
};
export const checkSdwDuplicate = async (
    id: number,
    server: string,
    database: string,
    type: number,
    name: string
): Promise<boolean> => {
    try {
        await sipmonClient.post(`api/Dataset/checkSdwDuplicate`, { id, server, database, type, name });
        return false;
    } catch (e) {
        if (axios.isAxiosError(e) && e.response && e.response.status === 400) return true;
        else throw e;
    }
};

export interface IAdlsCapacityTrend {
    Id: number,
    Name: string;
    platformName: string;
    UsedCapacityInBytes: number;
    MetricsDate: Date;
    lastUpdated?: Date;
    SubscriptionId: string,
    PipelineCount: number,
    JobsCount: number,
    FeedsCount: number,
    CompressedUsedCapacity: number,
    UnCompressedUsedCapacity: number,
    SuccessRate: number,
}

export const EmptyAdlsCapacityTrend: IAdlsCapacityTrend = {
    Id: 0,
    Name: "",
    platformName: "",
    UsedCapacityInBytes: 0,
    MetricsDate: new Date(),
    SubscriptionId: "",
    PipelineCount: 0,
    JobsCount: 0,
    FeedsCount: 0,
    CompressedUsedCapacity: 0,
    UnCompressedUsedCapacity: 0,
    SuccessRate: 0,
};

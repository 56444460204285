import React, { useEffect } from "react";
import "../../styles/App.css";
import Errorbar from "../common/ErrorBar";
import { IComboBox, IComboBoxOption, Stack } from "@fluentui/react";
import { adlsCapacityTrendSelector, subscriptionSelector } from "../../pages/Selectors";
import { StateActionType } from "../../types";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { getAllSubscriptions } from "../../reducers/Subscription";
import { getAllCapacityMetrics, getAllSizingMetricsByPlatform } from "../../reducers/AdlsCapacityTrend";
import { round, toNumber } from "lodash";
import { ShimmeredComboBox, ShimmeredText } from "../common/Shimmered";

const dropdownControlStyles = {
    root: {
        minWidth: "150px",
        margin: "0 30px 20px 0",
        maxWidth: "450px"
    }
};

function AdlsSummary() {
    const [pageState, setPageState] = React.useState<boolean>(false);
    const [selectedKeysCBForPlatform, setSelectedKeysCBForPlatform] = React.useState<string[]>([]);
    const dispatch = useAppDispatch();

    let vPipelineCount = 0,
        vFeedsCount = 0,
        vJobsCount = 0,
        vSuccessRate = 0,
        vUsedCapacity = 0,
        vTotalUsedCapacity = 0;
    //display used capacity of storage accounts on the dashboard
    const {
        entities: adlsCapacityTrend,
        state: adlsCapacityTrendState,
        error: adlsCapacityTrendError
    } = useAppSelector(adlsCapacityTrendSelector);
    if (adlsCapacityTrend != null) {
        adlsCapacityTrend.map((ele) => {
            vPipelineCount = ele["pipelineCount"];
            vFeedsCount = ele["feedsCount"];
            vJobsCount = ele["jobsCount"];
            vSuccessRate = ele["successRate"];
            vUsedCapacity = ele["compressedUsedCapacity"];
            vTotalUsedCapacity = ele["unCompressedUsedCapacity"];
        });
    }

    useEffect(() => {
        if (selectedKeysCBForPlatform.length) {
            setPageState(true);
            dispatch(getAllSizingMetricsByPlatform(selectedKeysCBForPlatform));
            setPageState(false);
        } else if (selectedKeysCBForPlatform.length === 0) {
            setPageState(true);
            dispatch(getAllCapacityMetrics());
            setPageState(false);
        }
    }, [selectedKeysCBForPlatform]);

    //fetch data for subscriptions dropdown
    const {
        entities: subscriptionEntity,
        state: subscriptionState,
        error: subscriptionError
    } = useAppSelector(subscriptionSelector);

    useEffect(() => {
        if (subscriptionState[StateActionType.LoadAll] === "INIT") {
            dispatch(getAllSubscriptions());
        }
    }, []);

    //handle on change for platform
    const onChangeCBForPlatform = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number
    ): void => {
        if (option) {
            setSelectedKeysCBForPlatform(
                option.selected
                    ? [...selectedKeysCBForPlatform, option.key as string]
                    : selectedKeysCBForPlatform.filter((key) => key !== option.key)
            );
        }
    };

    return (
        <>
            {subscriptionError && <Errorbar msg={subscriptionError} />}
            {adlsCapacityTrendError && <Errorbar msg={adlsCapacityTrendError} />}

            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 5, padding: "0 10px" }}>
                <Stack tokens={{ childrenGap: 1, padding: 10 }}>
                    <Stack.Item align="start">
                        <ShimmeredComboBox
                            loading={subscriptionState[StateActionType.LoadAll] === "LOADING_ALL"}
                            cbxLabel="Select Platforms"
                            styles={dropdownControlStyles}
                            selectedKey={selectedKeysCBForPlatform}
                            placeholder="Select All"
                            options={
                                subscriptionEntity
                                    ? subscriptionEntity.map((b) => ({
                                          key: b["name"] as unknown as string,
                                          text: b["name"]
                                      }))
                                    : []
                            }
                            allowFreeform
                            autoComplete="on"
                            onChange={onChangeCBForPlatform}
                            disabled={false}
                            multiSelect
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
            <br />
            <Stack.Item style={{ color: "green" }}>{<h2>{pageState && "Loading ..."}</h2>}</Stack.Item>
            <Stack>
                <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 3, padding: "0 5px" }}>
                    <Stack.Item
                        style={{
                            color: "white",
                            backgroundColor: "rgb(0, 120, 212)",
                            display: "flex",
                            padding: "0 10px",
                            width: "500px"
                        }}
                    >
                        <h4>
                            <ShimmeredText
                                lblStyle={{ root: { color: "white" } }}
                                loading={adlsCapacityTrendState[StateActionType.LoadAll] === "LOADING_ALL"}
                                txtLabel={`Total Pipelines Published : ${vPipelineCount}`}
                                className="label"
                            ></ShimmeredText>{" "}
                        </h4>
                    </Stack.Item>
                    <Stack.Item
                        style={{
                            color: "white",
                            backgroundColor: "rgb(0, 120, 212)",
                            display: "flex",
                            padding: "0 10px",
                            width: "500px"
                        }}
                    >
                        <h4>
                            <ShimmeredText
                                lblStyle={{ root: { color: "white" } }}
                                loading={adlsCapacityTrendState[StateActionType.LoadAll] === "LOADING_ALL"}
                                txtLabel={`Total Jobs : ${vJobsCount}`}
                                className="label"
                            ></ShimmeredText>{" "}
                        </h4>
                    </Stack.Item>
                    <Stack.Item
                        style={{
                            color: "white",
                            backgroundColor: "rgb(0, 120, 212)",
                            display: "flex",
                            padding: "0 10px",
                            width: "500px"
                        }}
                    >
                        <h4>
                            <ShimmeredText
                                lblStyle={{ root: { color: "white" } }}
                                loading={adlsCapacityTrendState[StateActionType.LoadAll] === "LOADING_ALL"}
                                txtLabel={`Jobs Execution Success Rate : ${vSuccessRate}%`}
                                className="label"
                            ></ShimmeredText>{" "}
                        </h4>
                    </Stack.Item>
                </Stack>
            </Stack>
            <br />
            <br />
            <Stack>
                <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 3, padding: "0 5px" }}>
                    <Stack.Item
                        style={{
                            color: "white",
                            backgroundColor: "rgb(0, 120, 212)",
                            display: "flex",
                            padding: "0 10px",
                            width: "500px"
                        }}
                    >
                        <h4>
                            <ShimmeredText
                                lblStyle={{ root: { color: "white" } }}
                                loading={adlsCapacityTrendState[StateActionType.LoadAll] === "LOADING_ALL"}
                                txtLabel={`Total Used Capacity(TB) : ${round(vUsedCapacity * 0.001, 2)}`}
                                className="label"
                            ></ShimmeredText>{" "}
                        </h4>
                    </Stack.Item>
                    <Stack.Item
                        style={{
                            color: "white",
                            backgroundColor: "rgb(0, 120, 212)",
                            display: "flex",
                            padding: "0 10px",
                            width: "500px"
                        }}
                    >
                        <h4>
                            <ShimmeredText
                                lblStyle={{ root: { color: "white" } }}
                                loading={adlsCapacityTrendState[StateActionType.LoadAll] === "LOADING_ALL"}
                                txtLabel={`Feeds Count : ${vFeedsCount}`}
                                className="label"
                            ></ShimmeredText>{" "}
                        </h4>
                    </Stack.Item>
                    <Stack.Item
                        style={{
                            color: "white",
                            backgroundColor: "rgb(0, 120, 212)",
                            display: "flex",
                            padding: "0 10px",
                            width: "500px"
                        }}
                    >
                        <h4>
                            <ShimmeredText
                                lblStyle={{ root: { color: "white" } }}
                                loading={adlsCapacityTrendState[StateActionType.LoadAll] === "LOADING_ALL"}
                                txtLabel=""
                                className="label"
                            ></ShimmeredText>{" "}
                        </h4>
                    </Stack.Item>
                </Stack>
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 1, padding: "0 10px" }}>
                <Stack.Item style={{ color: "black", display: "flex", padding: "0 10px", width: "500px" }}>
                    <h4>
                        <a href="https://msit.powerbi.com/groups/7f1ceb41-55c9-4338-8563-374fc94d5734/reports/5e333fea-70ac-4285-80fe-0bb9cd5be661?ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&pbi_source=linkShare">
                            Navigate to Platform Metrics Trend charts
                        </a>
                    </h4>
                </Stack.Item>
            </Stack>
        </>
    );
}
export default AdlsSummary;

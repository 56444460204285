import React, { useCallback } from "react";
import { IIconProps, IconButton, Shimmer, Stack } from "@fluentui/react";
import { telemetryConfig, TelemetryService } from "../../service/telemetry";
import { getUserDisplayName, getUserName } from "../../service/authProvider";
import { IEventTelemetry } from "@microsoft/applicationinsights-web";
import { useLocation } from "react-router";
import { useGetFeedbackDetailsQuery } from "../../api/SIPFeatureApi";
import { toNumber } from "lodash";
import CrudStatusDialog from "./CrudStatusDialog";
import { getShimmerStyles } from "../../columns/utils";

function Feedback() {
    const { data, error, isLoading, isSuccess, isError } = useGetFeedbackDetailsQuery("feedbacksAPI");
    const [totalLikes, setTotalLikes] = React.useState<number>(0);
    const [totalDislikes, setTotalDislikes] = React.useState<number>(0);

    const [curUserTotalLikes, setCurUserTotalLikes] = React.useState<number>(0);
    const [curUserTotalDislikes, setCurUserTotalDislikes] = React.useState<number>(0);

    const [showAnnouncment, setShowAnnouncement] = React.useState(false);
    const loc = useLocation();
    let count = 0;
    const strLike = "Like";
    const strDislike = "DisLike";

    function _onClickFeedbackButton(type: string): void {
        TelemetryService.initialize(telemetryConfig);

        if (type == strLike) {
            setCurUserTotalLikes(toNumber(curUserTotalLikes) + 1);
        } else {
            setCurUserTotalDislikes(toNumber(curUserTotalDislikes) + 1);
        }
        const feedbackEvent: IEventTelemetry = {
            name: "Feedback Type: " + type
        };
        TelemetryService.trackEvent(feedbackEvent, {
            url: window.location.href.toLowerCase(),
            user_Id: getUserName(),
            user_DisplayName: getUserDisplayName(),
            action: type,
            feature: document ? document.title : ""
        });
        setShowAnnouncement(true);
    }

    // Method to retrive page visit details
    const getPageFeedbackInfo = useCallback(async () => {
        setTotalLikes(0);
        setTotalDislikes(0);
        let response;
        if (isSuccess && data) {
            // Get all the data for the current page
            response = data.filter((d) => d.pageURL == window.location.href.toLowerCase());
            if (response) {
                // Page's Total Like and Dislikes
                let res = response.filter((d) => d.action == "Like");
                setTotalLikes(toNumber(res.length));
                res = response.filter((d) => d.action == "DisLike");
                setTotalDislikes(toNumber(res.length));
            }
            // Get all the data for the current user
            response = response.filter((d) => d.userId == getUserName());
            if (response) {
                // Page's Total Like and Dislikes for the current user
                let res = response.filter((d) => d.action == "Like");
                setCurUserTotalLikes(toNumber(res.length));
                res = response.filter((d) => d.action == "DisLike");
                setCurUserTotalDislikes(toNumber(res.length));
            }
        }
    }, [isSuccess, loc.pathname]);

    React.useEffect(() => {
        getPageFeedbackInfo();
    }, [isSuccess, loc.pathname]);

    const likeIcon: IIconProps = { iconName: "Like", style: { fontSize: 20 } };
    const likeSolidIcon: IIconProps = { iconName: "LikeSolid", style: { fontSize: 20 } };
    const disLikeIcon: IIconProps = { iconName: "Dislike", style: { fontSize: 20 } };
    const disLikeSolidIcon: IIconProps = { iconName: "DislikeSolid", style: { fontSize: 20 } };

    type FeedBackButtonsProps = {
        likeIcon: IIconProps;
        disLikeIcon: IIconProps;
        totalLikes: number;
        totalDislikes: number;
    };

    function FeedBackButtons({ likeIcon, disLikeIcon, totalLikes, totalDislikes }: FeedBackButtonsProps) {
        return (
            <Stack horizontal>
                <Stack horizontal>
                    <IconButton
                        name="Like"
                        aria-label="Like"
                        role="button"
                        iconProps={likeIcon}
                        allowDisabledFocus
                        onClick={() => _onClickFeedbackButton(strLike)}
                    />
                    <Stack.Item align="center" styles={{ root: { fontWeight: "bold" } }}>
                        {totalLikes}
                    </Stack.Item>
                </Stack>

                <Stack horizontal>
                    <IconButton
                        name="Dislike"
                        aria-label="Dislike"
                        role="button"
                        iconProps={disLikeIcon}
                        allowDisabledFocus
                        onClick={() => _onClickFeedbackButton(strDislike)}
                    />
                    <Stack.Item align="center" styles={{ root: { fontWeight: "bold" } }}>
                        {totalDislikes}
                    </Stack.Item>
                </Stack>
            </Stack>
        );
    }

    return (
        <>
            <CrudStatusDialog
                showDialog={showAnnouncment}
                title={`Your feedback has been successfully recorded!`}
                onClose={() => setShowAnnouncement(false)}
            />
            <Shimmer
                isDataLoaded={!isLoading}
                ariaLabel={`Loading Likes and Dislikes details`}
                width={"15%"}
                styles={getShimmerStyles()}
                style={{ marginTop: 2 }}
            >
                <FeedBackButtons
                    likeIcon={getLikeIcon()}
                    disLikeIcon={getDislikeIcon()}
                    totalLikes={getTotalLikes()}
                    totalDislikes={getTotalDislikes()}
                />
            </Shimmer>
        </>
    );

    function getTotalDislikes(): number {
        return totalDislikes >= curUserTotalDislikes ? totalDislikes : curUserTotalDislikes;
    }

    function getTotalLikes(): number {
        return totalLikes >= curUserTotalLikes ? totalLikes : curUserTotalLikes;
    }

    function getDislikeIcon(): IIconProps {
        if (curUserTotalLikes == 0 && curUserTotalDislikes == 0) {
            return disLikeIcon;
        }
        return curUserTotalLikes < curUserTotalDislikes ? disLikeSolidIcon : disLikeIcon;
    }

    function getLikeIcon(): IIconProps {
        if (curUserTotalLikes == 0 && curUserTotalDislikes == 0) {
            return likeIcon;
        }
        return curUserTotalLikes >= curUserTotalDislikes ? likeSolidIcon : likeIcon;
    }
}
export default Feedback;

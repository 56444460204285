import { ISipApiResponse } from "../models/SipApiResponse";
import { ISourceOwner } from "../models/SourceOwner";
import { IContactMethod } from "../models/ContactMethod";
import { StateActionType } from "../types";
import axios, { getHeaders } from "./SipMonClient";


import { sliceName } from "../reducers/SourceOwners";


// Get Source Owner names
export const getSourceOwnerNames = async (): Promise<ISipApiResponse<ISourceOwner[]>> => {
    const { data, status } = await axios.get<ISourceOwner[]>(`api/GetSourceOwners`);
    return { data, message: "", status };
};

// Get Contact Method Options
export const getContactMethods = async (): Promise<ISipApiResponse<IContactMethod[]>> => {
    const { data, status } = await axios.get<IContactMethod[]>(`api/GetContactMethods`);
    return { data, message: "", status };
};

export const createSourceOwner = async (
    sourceOwnerID: number,
    sourceOwnerName: string,
    contactMethodID: number,
    sourceOwnerValue: string
): Promise<ISipApiResponse<ISourceOwner>> => {
    const { data, status } = await axios.post<ISourceOwner>(
        `api/CreateSourceOwner`,
        { Id: sourceOwnerID, Name: sourceOwnerName, DriContactMethodTypeId: contactMethodID, Value: sourceOwnerValue },
        getHeaders(sliceName, StateActionType.Add)
    );
    return { data, message: "", status };
};


export const lsTypeOptions = [
    { key: 'AzureBlobFSLinkedService', text: 'AzureBlobFS Linked Service' },
    { key: 'AzureBlobStorageLinkedService', text: 'AzureBlobStorage Linked Service' },
    { key: 'AzureDataExplorerLinkedService', text: 'AzureDataExplorer Linked Service'},
    { key: 'AzureDataLakeStoreLinkedService', text: 'AzureDataLakeStore Linked Service' },
    { key: 'AzureSqlDatabaseLinkedService', text: 'AzureSqlDatabase Linked Service' },
    { key: 'AzureSqlDWLinkedService', text: 'AzureSqlDW Linked Service' },
    { key: 'FileServerLinkedService', text: 'FileServer Linked Service' },
    { key: 'LinkedService', text: 'Linked Service' },
    { key: 'RestServiceLinkedService', text: 'RestService Linked Service' },
    { key: 'SqlServerLinkedService', text: 'SqlServer Linked Service' }
];

export const propOptions = [
    { key: 'AccountName', text: 'Account Name' },
    { key: 'Data Source', text: 'Data Source' },
    { key: 'Initial Catalog', text: 'Initial Catalog' },
    { key: 'Host', text: 'Host' },
    { key: 'Endpoint', text: 'Endpoint' },
    { key: 'Database', text: 'Database' },
    { key: 'URI', text: 'URI' },
    { key: 'ServiceEndpoint', text: 'Service Endpoint' },
    { key: 'DataLakeStoreUri', text: 'Data Lake Store Uri' },
    { key: 'ResourceGroupName', text: 'Resource Group Name' }
];



import { useEffect, useState } from "react";
import { Stack, IStackTokens, IconButton, ProgressIndicator } from "@fluentui/react";
import Errorbar from "../common/ErrorBar";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { useHistory, useParams } from "react-router-dom";
import AccessPackageDialog from "./AccessPackageDialog";
import { CrudProps } from "./Types";
import AccessPackageForm from "./AccessPackageForm";
import { AccessPackageFormMode } from "../../models/Elm/AccessPackageFormMode";
import { getAllFeeds } from "../../reducers/Feeds";
import { feedSelector } from "../../pages/Selectors";
import { IFeed, AccessPackageDto } from "../../models";
import CompactFeedList from "../common/CompactFeedList";
import { useGetMigrationAccessPackageQuery, useMigrateAccessPackageMutation } from "../../reducers/AccessPackageApi";
import { handleError } from "./utils";
import { useTempError } from "../../hooks/UseTempError";

export function MigrateAccessPackage() {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [migrateAccessPackage, migrationResult] = useMigrateAccessPackageMutation();

    const { id } = useParams<{ id: string }>();
    const {
        data: accessPackageDto,
        error: accessPackageDtoError,
        isLoading: accessPackageDtoLoading
    } = useGetMigrationAccessPackageQuery(id);
    const emptyCrudProps: CrudProps = { title: "", children: <></> };

    const [error, setError] = useState("");
    const [crudProps, setCrudProps] = useState<CrudProps>(emptyCrudProps);
    const [associatedFeeds, setAssociatedFeeds] = useState<IFeed[]>([]);
    const { entities: feeds } = useAppSelector(feedSelector);

    useTempError(error, setError);

    useEffect(() => {
        if (accessPackageDtoError)
            handleError(
                new Error("There was an error getting the migration Access Package"),
                setError,
                accessPackageDtoError
            );
    }, [accessPackageDtoError]);
    useEffect(() => {
        if (!feeds || feeds.length === 0) {
            dispatch(getAllFeeds());
        }
    }, []);

    useEffect(() => {
        if (feeds && feeds.length > 0 && accessPackageDto) {
            setAssociatedFeeds(
                feeds.filter(
                    (f) =>
                        accessPackageDto.securityGroupName && f.feedAADGroupName === accessPackageDto.securityGroupName
                )
            );
        }
    }, [feeds, accessPackageDto]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (migrationResult.error) {
            setCrudProps({ ...crudProps, title: "" });
            handleError(new Error("There was an error migrating the Access Package"), setError, migrationResult.error);
        } else if (migrationResult.data) {
            setCrudProps((p) => ({ ...p, title: "Access Package migrated successfully" }));
            timeout = setTimeout(() => {
                setCrudProps((p) => ({ ...p, title: "" }));
                history.push(`/accessPackages/?catalogId=${migrationResult.data?.catalogId}`);
            }, 2000);
        }
        return () => clearTimeout(timeout);
    }, [migrationResult]);

    async function processAccessPackage(accessPackageDto: AccessPackageDto) {
        if (accessPackageDto) {
            setCrudProps((p) => ({ ...p, title: "Migrating Access Package" }));

            await migrateAccessPackage({ accessPackageDto, feedId: id });
        }
    }

    const containerStackTokens: IStackTokens = { childrenGap: 10 };

    return (
        <>
            <Stack styles={{ root: { marginLeft: 10, marginBottom: 100 } }} tokens={containerStackTokens}>
                {error && <Errorbar msg={error} />}

                <AccessPackageDialog
                    showDialog={crudProps.title !== ""}
                    title={crudProps.title}
                    onClose={() =>
                        crudProps.title === "Created Access Package Successfully"
                            ? history.push(
                                  `/accessPackages/?catalogId=${accessPackageDto ? accessPackageDto.catalogId : ""}`
                              )
                            : setCrudProps(emptyCrudProps)
                    }
                >
                    {crudProps.children}
                    <ProgressIndicator
                        progressHidden={crudProps.title === "Created Access Package Successfully" || error !== ""}
                    />
                </AccessPackageDialog>

                <Stack>
                    <Stack.Item align="end">
                        <IconButton
                            title="Close"
                            className="routing"
                            onClick={() => history.goBack()}
                            iconProps={{ iconName: "Cancel" }}
                            allowDisabledFocus
                        />
                    </Stack.Item>
                </Stack>

                <AccessPackageForm
                    mode={AccessPackageFormMode.Migrate}
                    accessPackage={accessPackageDto}
                    onSave={processAccessPackage}
                    setError={setError}
                    loading={accessPackageDtoLoading}
                />
                <CompactFeedList title="Associated Feeds" feeds={associatedFeeds} />
            </Stack>
        </>
    );
}

export default MigrateAccessPackage;

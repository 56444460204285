import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useGetAssignmentClonesQuery } from "../../reducers/AssignmentsApi";
import { CloneEntry } from "../../models/Elm/CloneEntry";
import StandardForm from "../common/StandardForm";
import { StackRow } from "../common/StackComponents";
import { toPacificTimeString } from "../common/utils";
import { FontIcon, IconButton, Stack, Text, getTheme } from "@fluentui/react";
import { LoadingOverLay } from "../common/LoadingOverLay";
import { getCloneStatus } from "./utils";
import { getErrorMessage } from "../AccessPackages/utils";

const AssignmentCloneView = () => {
    const { targetId, originalId, catalogId } = useParams<{
        targetId: string;
        originalId: string;
        catalogId: string;
    }>();
    const theme = getTheme();
    const history = useHistory();
    const [error, setError] = React.useState<string>("");
    const { data: clones, error: clonesError } = useGetAssignmentClonesQuery(catalogId, { skip: !catalogId });

    const [cloneEntry, setCloneEntry] = React.useState<CloneEntry>();

    React.useEffect(() => {
        if (clones) {
            const clone = clones.find((x) => x.target === targetId && x.original === originalId);

            if (clone) {
                setCloneEntry(clone);
            }
        }
    }, [clones]);

    React.useEffect(() => {
        if (clonesError) {
            const errorMessage = getErrorMessage(clonesError);
            setError(errorMessage);
        } else {
            setError("");
        }
    }, [clonesError]);

    return (
        <StandardForm widthPercent={90} mediumWidthPercent={90} smallWidthPercent={90}>
            <Stack.Item align="end" styles={{ root: { marginBottom: "-50px" } }}>
                <IconButton
                    title="Close"
                    onClick={() => history.replace("/assignmentClones")}
                    iconProps={{ iconName: "Cancel" }}
                    allowDisabledFocus
                />
            </Stack.Item>
            <LoadingOverLay isOverlayVisible={!cloneEntry} />
            {cloneEntry && (
                <>
                    <StackRow columnWidth={80}>
                        <div style={{ textAlign: "center" }}>
                            <Text variant="large">
                                Access clone from {cloneEntry.original} ({cloneEntry.originalType})
                                <FontIcon style={{ margin: "0 20px", transform: "scaleX(-1)" }} iconName="SkypeArrow" />
                                {cloneEntry.target} ({cloneEntry.targetType})
                            </Text>
                        </div>
                    </StackRow>
                    <StackRow horizontalAlign="space-between">
                        <Text variant="mediumPlus">Total: {cloneEntry.status.length}</Text>

                        <Text variant="mediumPlus">Status: {getCloneStatus(cloneEntry)}</Text>
                    </StackRow>
                    <div
                        style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            height: "30px",
                            borderBottom: `1px solid ${theme.palette.neutralLight}`,
                            backgroundColor: theme.palette.white
                        }}
                    >
                        <StackRow>
                            <div>AccessPackage</div>
                            <div>Status</div>
                            <div>ActionTime (PST)</div>
                        </StackRow>
                    </div>

                    {cloneEntry.status.map((x) => (
                        <StackRow key={x.accessPackageName}>
                            <div>{x.accessPackageName}</div>
                            <div>{x.status === "Clone Request Conflict" ? "Existing Access" : x.status}</div>
                            <div>{toPacificTimeString(x.lastModified)}</div>
                        </StackRow>
                    ))}
                </>
            )}
        </StandardForm>
    );
};

export default AssignmentCloneView;

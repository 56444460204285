import React, { useState } from "react";
import { DialgContainer } from "../common/DialogContainer";
import SuppressDialogProps from "../../models/dialogProps/SuppressionDialogProps";
import { DatePicker, DefaultButton, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { FormTextField } from "../common/FormTextField";

export const SuppressionDialog: React.FC<SuppressDialogProps> = (props) => {
    function _setDate(endTime: Date | null | undefined) {
        endTime = endTime ? endTime : new Date();
        endTime.setHours(23, 59, 0, 0);
        setSuppressionEndTime(endTime);
    }

    function _setJustification(_: any, justification: string | undefined) {
        justification = justification ? justification : "";
        setJustification(justification);
    }
    const [justification, setJustification] = useState<string>(props.state.justification);
    const [endTime, setSuppressionEndTime] = useState<Date>(props.state.endTime);

    function onSave() {
        props.setDate({ ...props.state, startTime: new Date(), endTime, justification, changed: true });
        props.onClose();
    }
    const tokens = { childrenGap: 10 };
    return (
        <DialgContainer {...props}>
            <Stack tokens={tokens}>
                <DatePicker
                    label="Suppress Notifications Date Picker"
                    ariaLabel="Suppress Notifications Date Picker"
                    showGoToToday={false}
                    maxDate={props.maxSuppressionTime}
                    minDate={props.state.startTime}
                    onSelectDate={_setDate}
                    value={endTime}
                />
                {props.state.suppressedBy && <FormTextField label="Suppressed By" txt={props.state.suppressedBy} />}
                <TextField
                    label="Justification"
                    multiline
                    rows={3}
                    maxLength={500}
                    value={justification}
                    onChange={_setJustification}
                />
                <Stack horizontalAlign="end" horizontal tokens={tokens}>
                    <PrimaryButton onClick={onSave} disabled={!justification} text="Save" />
                    <DefaultButton onClick={props.onClose} text="Cancel" />
                </Stack>
            </Stack>
        </DialgContainer>
    );
};

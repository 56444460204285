import { Label, ActivityItem, Stack, Shimmer } from "@fluentui/react";
import { ShimmeredPersona, getShimmerStyles } from "../../columns/utils";
import { GitHistory } from "./EditPipeline";

export function AdoHistory({ gitHistory, loading }: { gitHistory: GitHistory[]; loading: boolean }) {
    return (
        <Stack styles={{ root: { height: "248px" } }}>
            <Label className="label">Ado History</Label>
            <Shimmer
                customElementsGroup={
                    <Stack>
                        <ShimmeredPersona />
                        <div style={{ height: "10px", background: "white" }} />
                        <ShimmeredPersona />
                        <div style={{ height: "10px", background: "white" }} />
                        <ShimmeredPersona />
                    </Stack>
                }
                isDataLoaded={!loading}
                ariaLabel="Loading Ado history for pipeline"
                width="100%"
                styles={getShimmerStyles()}
            >
                {gitHistory && (
                    <div>
                        {gitHistory.map((item) => (
                            <ActivityItem style={{ padding: "2px" }} {...item} key={item.key} />
                        ))}
                    </div>
                )}
            </Shimmer>
        </Stack>
    );
}

import { useEffect, useState } from "react";
import { Stack, IStackTokens, IconButton, ProgressIndicator } from "@fluentui/react";
import Errorbar from "../common/ErrorBar";
import { useHistory, useParams } from "react-router-dom";
import AccessPackageDialog from "./AccessPackageDialog";
import { CrudProps } from "./Types";
import AccessPackageForm from "./AccessPackageForm";
import { AccessPackageFormMode } from "../../models/Elm/AccessPackageFormMode";
import { AccessPackageDto } from "../../models";
import { useGetAccessPackageQuery, useUpdateAccessPackageMutation } from "../../reducers/AccessPackageApi";
import { handleError } from "./utils";
import { useTempError } from "../../hooks/UseTempError";

export function UpdateAccessPackage() {
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    const {
        data: accessPackageDto,
        isLoading: accessPackageDtoLoading,
        error: accessPackageDtoError
    } = useGetAccessPackageQuery(id);
    const emptyCrudProps: CrudProps = { title: "", children: <></> };
    const [updateAccessPackage, updateResult] = useUpdateAccessPackageMutation();
    const [error, setError] = useState("");
    const [crudProps, setCrudProps] = useState<CrudProps>(emptyCrudProps);

    useTempError(error, setError);

    useEffect(() => {
        if (accessPackageDtoError)
            handleError(new Error("There was an error getting the Access Package"), setError, accessPackageDtoError);
    }, [accessPackageDtoError]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (updateResult.error) {
            setCrudProps({ ...crudProps, title: "" });
            handleError(new Error("There was an error updating the Access Package"), setError, updateResult.error);
        } else if (updateResult.data) {
            setCrudProps((p) => ({ ...p, title: "Access Package updated Successfully" }));

            timeout = setTimeout(() => {
                setCrudProps((p) => ({ ...p, title: "" }));
                history.push(`/accessPackages/?catalogId=${updateResult.data?.catalogId}`);
            }, 2000);
        }
        return () => clearTimeout(timeout);
    }, [updateResult]);

    async function processAccessPackage(accessPackageDto: AccessPackageDto) {
        if (accessPackageDto) {
            setCrudProps({ ...crudProps, title: "Updating Access Package" });
            await updateAccessPackage(accessPackageDto);
        }
    }

    const containerStackTokens: IStackTokens = { childrenGap: 10 };

    return (
        <>
            <Stack styles={{ root: { marginLeft: 10 } }} tokens={containerStackTokens}>
                {error && <Errorbar msg={error} />}

                <AccessPackageDialog
                    showDialog={crudProps.title !== ""}
                    title={crudProps.title}
                    onClose={() =>
                        crudProps.title === "Created Access Package Successfully"
                            ? history.push(
                                  `/accessPackages/?catalogId=${accessPackageDto ? accessPackageDto.catalogId : ""}`
                              )
                            : setCrudProps(emptyCrudProps)
                    }
                >
                    {crudProps.children}
                    <ProgressIndicator
                        progressHidden={crudProps.title === "Created Access Package Successfully" || error !== ""}
                    />
                </AccessPackageDialog>

                <Stack>
                    <Stack.Item align="end">
                        <IconButton
                            title="Close"
                            className="routing"
                            onClick={() => history.goBack()}
                            iconProps={{ iconName: "Cancel" }}
                            allowDisabledFocus
                        />
                    </Stack.Item>
                </Stack>

                <AccessPackageForm
                    mode={AccessPackageFormMode.Update}
                    accessPackage={accessPackageDto}
                    onSave={processAccessPackage}
                    setError={setError}
                    loading={accessPackageDtoLoading}
                />
            </Stack>
        </>
    );
}

export default UpdateAccessPackage;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import {
    getFeedCategories,
    getFeedCategory,
    deleteFeedCategory as deleteFeedCategoryApi,
    editFeedCategory,
    editFeedCategoryContacts,
    EditFeedCategoryContactsRequest
} from "../api/FeedCategoryApi";

import { getDefaultCases } from "./DefaultCases";
import { IFeedCategory } from "../models";

const sliceName = "feedCategories";

const initialState = getInitialState<IFeedCategory, IFeedCategory>();

const getAllFeedCategories = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getFeedCategories();
    return response.data;
});

const getFeedCategoryById = createAsyncThunk(`${sliceName}/get_by_id`, async (id: number, _thunkAPI) => {
    const response = await getFeedCategory(id);
    return response.data;
});
const upsertFeedCategory = createAsyncThunk(`${sliceName}/update`, async (feedCategory: IFeedCategory, _thunkAPI) => {
    const response = await editFeedCategory(feedCategory);
    return response.data;
});

const updateFeedCategoryContacts = createAsyncThunk(
    `${sliceName}/update_contacts`,
    async (request: IFeedCategory, _thunkAPI) => {
        const response = await editFeedCategoryContacts(request);
        return response.data;
    }
);

const deleteFeedCategory = createAsyncThunk(`${sliceName}/delete`, async (feedCategory: IFeedCategory, _thunkAPI) => {
    const response = await deleteFeedCategoryApi(feedCategory);

    return response.data;
});

const feedCategoriesSlice = createSlice({
    name: sliceName,
    initialState,

    reducers: getDefaultCases<IFeedCategory>(),
    extraReducers: (builder) => {
        builder.addCase(getAllFeedCategories.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllFeedCategories.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getFeedCategoryById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(getFeedCategoryById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadSingle);
        });
        builder.addCase(upsertFeedCategory.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(upsertFeedCategory.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Update);
        });
        builder.addCase(updateFeedCategoryContacts.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(updateFeedCategoryContacts.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Update);
        });
        builder.addCase(deleteFeedCategory.pending, (state, _action) => {
            onLoading(state, StateActionType.Delete);
        });
        builder.addCase(deleteFeedCategory.fulfilled, (state, action) => {
            onComplete(state, StateActionType.Delete);
        });
    }
});

export const { reset, error, setCurrent } = feedCategoriesSlice.actions;
export {
    getAllFeedCategories,
    getFeedCategoryById,
    upsertFeedCategory,
    updateFeedCategoryContacts,
    deleteFeedCategory,
    sliceName
};
export const { reducer } = feedCategoriesSlice;

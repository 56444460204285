import { History } from "history";
import { IServerAdministrationHistory } from "../models";
import { IStandardColumn } from "../models/StandardColumn";
import { ActionType } from "../types";
import { formatDate } from "./RemoteServerActionColumns";
import { addSortLabels} from "./utils";

export function ServerAdministrationHistoryColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "actionTypeID",
            name: "Action Type",
            fieldName: "actionTypeID",
            filterBy: true,
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            isSorted: true,
            onRender: (item: IServerAdministrationHistory) => renderActionType(item)
        },
        {
            key: "actionInitiatedBy",
            name: "Action Initiated By",
            fieldName: "actionInitiatedBy",
            filterBy: true,
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
            isSorted: true
        },
        {
            key: "sysCreatedTime",
            name: "Action Requested Time",
            fieldName: "sysCreatedTime",
            filterBy: true,
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
            isSorted: true,
            onRender: (item: IServerAdministrationHistory) => formatDate(item.sysCreatedTime)
        },
        {
            key: "actionCompletedTime",
            name: "Action Completed Time",
            fieldName: "actionCompletedTime",
            filterBy: true,
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            isSorted: true,
            onRender: (item: IServerAdministrationHistory) => formatDate(item.actionCompletedTime)
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            filterBy: true,
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isSorted: true
        },
        {
            key: "note",
            name: "Comments",
            fieldName: "note",
            filterBy: true,
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            isSorted: true,
            onRender: (item: IServerAdministrationHistory) => renderComments(item)
        }
    ];

    function renderActionType(item: IServerAdministrationHistory) {
        switch (item["remoteServerAdministrationActionId"]) {
            case ActionType.Reboot:
                return ActionType[item["remoteServerAdministrationActionId"] as ActionType]
            case ActionType["Service Restart"]:
                return ActionType[item["remoteServerAdministrationActionId"] as ActionType]
            default:
                return <></>
        }
    }

    function renderComments(item: IServerAdministrationHistory) {
        if (item["note"]) {
            let result = item["note"].replace(new RegExp('\n', 'g'), '<br/>');
            return <div dangerouslySetInnerHTML={{ __html: result }} />;
        }
        return item.note
    }

    addSortLabels(cols);
    return cols;
}


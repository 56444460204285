import { Label, Shimmer } from "@fluentui/react";
import { getShimmerStyles } from "../../../columns/utils";
import { GraphLookupProps, GraphLookup } from "../GraphLookup";
type ShimmeredProps = {
    loading: boolean;
    txtLabel: string | React.ReactNode;
    width?: string;
    className?: string;
} & GraphLookupProps;

function ShimmeredGraphLookup(props: ShimmeredProps) {
    return (
        <div className={props.className}>
            <Label className="label"> {props.txtLabel} </Label>
            <Shimmer isDataLoaded={!props.loading} ariaLabel={`Loading ${props.txtLabel}`} styles={getShimmerStyles()}>
                <GraphLookup {...props} />
            </Shimmer>
        </div>
    );
}

export { ShimmeredGraphLookup };

import { IContact } from "../models/Contact";
import { BaseAction, StateValues } from ".";
export enum ContactActionTypes {
    GETALL = "CONTACT/GETALL",
    GETSINGLE = "CONTACT/GETSINGLE",
    INIIALIZE = "CONTACT/INIT",
    ADDCONTACT = "CONTACT/ADD",
    SETADDSTATE = "CONTACT/SETADDSTATE",
    EDITCONTACT = "CONTACT/EDIT",
    SEARCHCONTACT = "CONTACT/SEARCH",
    LOADINGCONTACT = "CONTACT/LOADING",
    DELETECONTACT = "FFEDCATEGORYOWNER/DELETE",
    LOADINGERROR = "CONTACT/ERROR",
    TESTCONTACT = "TEST/CONTACT"
}

interface IContactTest extends BaseAction {
    type: typeof ContactActionTypes.TESTCONTACT;
}

export interface IContactError extends BaseAction<string> {
    type: ContactActionTypes.LOADINGERROR;
}

export interface IContactSearchAction extends BaseAction<string> {
    type: ContactActionTypes.SEARCHCONTACT;
}
export interface IContactGetAllAction extends BaseAction<IContact[]> {
    type: ContactActionTypes.GETALL;
}

export interface IContactLoading extends BaseAction {
    type: ContactActionTypes.LOADINGCONTACT;
}

export interface IContactInit extends BaseAction {
    type: ContactActionTypes.INIIALIZE;
}

export interface IContactGetSingleAction extends BaseAction<IContact> {
    type: ContactActionTypes.GETSINGLE;
}

export interface IContactAddAction extends BaseAction<IContact> {
    type: ContactActionTypes.ADDCONTACT;
}

export interface IContactSetAddStateAction extends BaseAction<string> {
    type: ContactActionTypes.SETADDSTATE;
}

export interface IContactEditAction extends BaseAction<IContact> {
    type: ContactActionTypes.EDITCONTACT;
}

export interface IContactDeleteAction extends BaseAction<IContact> {
    type: ContactActionTypes.DELETECONTACT;
}

export type ContactActions =
    | IContactTest
    | IContactGetAllAction
    | IContactGetSingleAction
    | IContactAddAction
    | IContactSetAddStateAction
    | IContactEditAction
    | IContactDeleteAction
    | IContactInit
    | IContactSearchAction
    | IContactLoading
    | IContactError;

export interface IContactState {
    readonly currentContact: IContact | null;
    readonly contacts: IContact[];
    readonly contactLoading: boolean;
    readonly contactState: StateValues;
    readonly contactSearch: string;
    readonly contactError: string;
    readonly contactAddState: string;
}

import axios, { getHeaders } from "./SipMonClient";
import { ISipApiResponse } from "../models/SipApiResponse";
import { sliceName } from "../reducers/DataSource";
import { StateActionType } from "../types";
import { IRemoteServerAdministration, IRemoteServerConfig, IRemoteServerServiceConfig } from "../models";

// Method to get Configured Server details from DB
export const getAllRemoteServerConfigs = async (): Promise<ISipApiResponse<IRemoteServerConfig[]>> => {
    const { data, status } = await axios.get<IRemoteServerConfig[]>(
        `api/remoteserverconfigs`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

// Method to get Configured Services from DB
export const getAllRemoteServerServiceConfigs = async (): Promise<ISipApiResponse<IRemoteServerServiceConfig[]>> => {
    const { data, status } = await axios.get<IRemoteServerServiceConfig[]>(
        `api/remoteserverconfigs/getallservices`,

        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

// Method to post message to Storage Queue
export const postAdministrationCommand = async (props: IRemoteServerAdministration[]): Promise<ISipApiResponse<IRemoteServerConfig>> => {

    const { data, status } = await axios.put(
        `api/remoteserveradministration/postMessage`,
        props,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};





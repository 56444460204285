import React from "react";
import { Fabric } from "@fluentui/react/lib/Fabric";
import FeedFreshnessTable from "./FeedFreshnessTable";
import { IFeedFreshness } from "../../models/FeedFreshness";
import { ITolerance } from "../../pages/FeedFreshnessList";

interface IFeedFreshnessProps {
    feedFreshnesses: IFeedFreshness[];
    enableRouting: boolean;
    tolerance: ITolerance;
}

function FeedFreshnessTableContainer(props: IFeedFreshnessProps) {
    return (
        <Fabric>
            <FeedFreshnessTable
                feedFreshnesses={props.feedFreshnesses}
                tableTitle="Feed Freshness"
                enableRouting={props.enableRouting}
                tolerance={props.tolerance}
            />
        </Fabric>
    );
}

export default FeedFreshnessTableContainer;

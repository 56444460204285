import { useEffect } from "react";
import "../styles/App.css";
import { directoryAuditSelector } from "./Selectors";
import StandardList from "../components/common/StandardList";
import { generateDirectoryAuditColumns } from "../columns";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { StateActionType } from "../types";
import { getAllDirectoryAudits } from "../reducers/DirectoryAudit";

function DirectoryAuditList() {
    const dispatch = useAppDispatch();
    const {
        state: directoryAuditState,
        entities: directoryAudit,
        error: directoryAuditError
    } = useAppSelector(directoryAuditSelector);

    const history = useHistory();
    useEffect(() => {
        document.title = "Directory Audit - SIP Portal";
        if (directoryAuditState[StateActionType.LoadAll] === "INIT") {
            dispatch(getAllDirectoryAudits());
        }
    }, []);

    return (
        <StandardList
            entities={directoryAudit}
            title={"Directory Audit"}
            columns={generateDirectoryAuditColumns(history)}
            entityState={directoryAuditState[StateActionType.LoadAll]}
            error={directoryAuditError}
        />
    );
}
export default DirectoryAuditList;

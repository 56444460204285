import React from "react";
import { DialgContainer } from "../common/DialogContainer";
import DialogContainerProps from "../../models/dialogProps/DialogContainerProps";

type AccessPackageDialogProps = {
    title: string;
    children?: React.ReactNode;
} & DialogContainerProps;

function AccessPackageDialog(props: AccessPackageDialogProps) {
    return <DialgContainer {...{ ...props }}>{props.children && props.children}</DialgContainer>;
}

export default AccessPackageDialog;

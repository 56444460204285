import { useState, useEffect, useCallback } from "react";
import { CoherenceHeader } from "@cseo/controls/lib/header";
import { authProvider } from "../../service/authProvider";
import { getPhoto } from "../../api/GraphApi";
import Help from "./Help";
import { useAccount } from "@azure/msal-react";
import { Image } from "@fluentui/react";
import logo from "../../images/logo.png";
import { NotificationListItem, ItemStatus, ItemDisplayStatus } from "@cseo/controls";
function Header({ notificationItems }: { notificationItems?: NotificationListItem[] }) {
    const logout = authProvider.logout;
    const [imgUrl, setImgurl] = useState<string>("");
    const [_notificationItems, setNotificationItems] = useState<NotificationListItem[] | undefined>(notificationItems);
    const [newNotifications, setNewNotifications] = useState<number>(0);
    const account = useAccount();
    const getUserPhotoCallback = useCallback(async () => {
        const photo = await getPhoto();
        if (photo) {
            setImgurl(photo);
        }
    }, [getPhoto]);

    useEffect(() => {
        if (account) {
            getUserPhotoCallback();
        }
    }, [account, getUserPhotoCallback]);
    useEffect(() => {
        if (notificationItems) {
            setNotificationItems([...notificationItems]);
            const cnt = notificationItems.filter((x) => x.status === "unread").length;

            setNewNotifications(cnt);
        } else {
            setNotificationItems([]);
            setNewNotifications(0);
        }
    }, [notificationItems]);

    const updateStatus = () => {
        if (_notificationItems && _notificationItems.length > 0) {
            const list = _notificationItems.map((x): NotificationListItem => ({ ...x, displayStatus: "old" }));

            setNotificationItems(list);
        }
    };
    const updateItem = (itemKey: string, dStatus: ItemDisplayStatus, rStatus?: ItemStatus) => {
        if (_notificationItems) {
            const list = _notificationItems.map((item) => ({ ...item }));
            list.map((item) => {
                if (item.itemKey === itemKey) {
                    if (item.status === "unread") {
                        setNewNotifications((prevState) => prevState - 1);
                    }

                    item.displayStatus = dStatus;
                    if (rStatus) {
                        item.status = rStatus;
                    }
                }
            });
            setNotificationItems(list);
        }
    };
    return (
        <span id="sip-navbar">
            <CoherenceHeader
                headerLabel={"header"}
                appNameSettings={{
                    label: <Image src={logo} height={40} alt="Security Intelligence Platform Portal" />
                }}
                farRightSettings={{
                    notificationsSettings: {
                        panelSettings: {
                            titleText: "Notifications",
                            items: _notificationItems,
                            onPanelOpened: updateStatus,
                            newNotifications: newNotifications,
                            updateItem: updateItem
                        }
                    },
                    helpSettings: {
                        panelSettings: {
                            body: Help()
                        }
                    },
                    profileSettings: {
                        panelSettings: {
                            fullName: account?.name,
                            emailAddress: account?.username,
                            imageUrl: imgUrl,
                            logOutLink: "#",
                            onLogOut: () => {
                                if (logout) {
                                    logout();
                                }
                            }
                        }
                    }
                }}
            ></CoherenceHeader>
        </span>
    );
}

export default Header;

import {
    IBasePickerSuggestionsProps,
    IPickerItemProps,
    IStyleSet,
    ITag,
    Label,
    TagItem,
    TagPicker,
    keyframes,
    mergeStyleSets,
    mergeStyles
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useEffect, useState } from "react";
import { FieldErrorMessage } from "./FieldErrorMessage";

export interface DataTag extends ITag {
    data?: any;
}

export type AutoCompleteProps<T extends DataTag | DataTag[]> = {
    itemName: string;
    allowFreeform?: boolean;
    data: DataTag[];
    placeHolderText?: string;
    multiSelect?: boolean;
    disabled?: boolean;
    val: T | undefined;
    errorMessage?: string;
    sendSelectedValue: (val: T | undefined) => void;
};

export function AutoCompleteTextField<T extends DataTag | DataTag[]>({
    itemName,
    allowFreeform,
    data,
    placeHolderText,
    disabled,
    val,
    multiSelect,
    sendSelectedValue,
    errorMessage
}: AutoCompleteProps<T>) {
    const [suggestions, setSuggestions] = useState<DataTag[]>([]);
    useEffect(() => {
        setSuggestions(data);
    }, [data]);

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: `Suggested ${itemName}`,
        noResultsFoundText: `No ${itemName} found`
    };

    const pickerId = useId(`${itemName}-picker`);
    const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }

        return tagList.some((compareTag) => compareTag.name === tag.name);
    };

    const filterSuggestedTags = (filterText: string, tagList?: ITag[]): ITag[] => {
        return suggestions.filter(
            (tag) =>
                filterText &&
                tag.name.toLowerCase().includes(filterText.toLowerCase()) &&
                !listContainsTagList(tag, tagList)
        );
    };
    function onItemSelected(item?: ITag[]) {
        if (item && item.length > 0) {
            if (val && Array.isArray(val)) {
                sendSelectedValue(item as T);
            } else {
                sendSelectedValue(item[0] as T);
            }
        } else {
            sendSelectedValue(undefined);
        }
    }

    function onRenderItem(props: IPickerItemProps<ITag>) {
        return (
            <TagItem
                key={props.item.key}
                styles={{ root: { maxWidth: "unset" }, text: { width: "100%" } }}
                item={props.item}
                index={props.index}
                onRemoveItem={props.onRemoveItem}
                removeButtonIconProps={{ iconName: "Delete" }}
            >
                {props.item.name}
            </TagItem>
        );
    }

    return (
        <>
            <TagPicker
                onInputChange={(str) => {
                    if (
                        str &&
                        allowFreeform &&
                        !suggestions.some((x) => x.name.toLocaleLowerCase() === str.toLocaleLowerCase())
                    ) {
                        // clear out freeform suggestions
                        setSuggestions(data);
                        setSuggestions((suggestions) => [...suggestions, { key: str, name: str }]);
                    }

                    return str;
                }}
                onRenderItem={onRenderItem}
                selectionAriaLabel={`Selected ${itemName}`}
                disabled={disabled}
                onResolveSuggestions={filterSuggestedTags}
                getTextFromItem={(item: ITag) => item.name}
                pickerSuggestionsProps={pickerSuggestionsProps}
                onChange={onItemSelected}
                itemLimit={multiSelect ? undefined : 1}
                selectedItems={(val && (Array.isArray(val) ? val : [val])) || []}
                onEmptyResolveSuggestions={(selected) =>
                    (selected && data.filter((x) => !selected.map((x) => x.name).includes(x.name))) || []
                }
                inputProps={{
                    id: pickerId,
                    placeholder: val ? undefined : placeHolderText
                }}
            />

            <FieldErrorMessage errorMessage={errorMessage} />
        </>
    );
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Catalog, OdataResponse } from "../models";
import { authProvider, authenticationParameters } from "../service/authProvider";

export const graphElmApi = createApi({
    reducerPath: "GraphElmApi",
    tagTypes: ["Catalogs"],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_GRAPH_ENDPOINT}:443/v1.0/identityGovernance/entitlementManagement`,
        prepareHeaders: async (headers) => {
            const token = await authProvider.acquireTokenSilent({
                scopes: ["https://graph.microsoft.com/EntitlementManagement.ReadWrite.All"],
                account: authProvider.getActiveAccount() || undefined
            });

            if (token) {
                headers.set("authorization", `Bearer ${token.accessToken}`);
            }
        }
    }),
    endpoints: (builder) => ({
        getCatalogs: builder.query<Catalog[], undefined>({
            query: () => ({
                url: `/catalogs`,
                method: "GET"
            }),
            transformResponse: (response: OdataResponse<Catalog>) => response.value,
            providesTags: (result) => (result ? result.map(({ id }) => ({ type: "Catalogs", id })) : ["Catalogs"]),
            keepUnusedDataFor: 120
        })
    })
});

export const { useGetCatalogsQuery, usePrefetch } = graphElmApi;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AccessPackageReference, AccessPackageView, AccessRequest, IContact, ProcessSipAccessRequest } from "../models";
import {
    getAccessRequests,
    getAccessRequestById,
    processSipAccessRequest,
    getAccessPackageReferences,
    getPrePopulatedSecondaryApprovers,
    validateOwnership
} from "../api/AccessPackageApi";
import { Contact } from "@sipmon/reactcomponents";

const sliceName = "accessPackages";

export enum AccessPackageComponents {
    subscription,
    catalog,
    aad,
    accessPackage,
    accessPackageReferences,
    accessPackageVw,
    resource,
    accessRequests,
    approvedRequestStatus,
    currentAccessRequest,
    sipApproval,
    prePopulatedSecondaryApprovers,
    validateOwnership
}

export type AccessPackageState = {
    subscriptionId?: string;
    catalogId?: string;
    resourceCreated?: boolean;
    accessPackageId?: string;
    aadGroupId?: string;
    addGroupName?: string;
    accessRequests?: AccessRequest[];
    accessPackageReferences?: AccessPackageReference[];
    sipApproval?: string;
    currentAccessRequest?: AccessRequest;
    accessPackageView?: AccessPackageView[];
    errors: Record<AccessPackageComponents, string>;
    loading: Record<AccessPackageComponents, boolean>;
    prePopulatedSecondaryApprovers?: IContact[];
    isOwnershipValid?: boolean;
};

export type CreateAccessPackgeProps = Pick<AccessPackageState, "catalogId" | "aadGroupId">;
const initialState: AccessPackageState = {
    errors: {
        [AccessPackageComponents.subscription]: "",
        [AccessPackageComponents.catalog]: "",
        [AccessPackageComponents.aad]: "",
        [AccessPackageComponents.accessPackage]: "",
        [AccessPackageComponents.accessPackageVw]: "",
        [AccessPackageComponents.resource]: "",
        [AccessPackageComponents.accessRequests]: "",
        [AccessPackageComponents.accessPackageReferences]: "",
        [AccessPackageComponents.approvedRequestStatus]: "",
        [AccessPackageComponents.currentAccessRequest]: "",
        [AccessPackageComponents.sipApproval]: "",
        [AccessPackageComponents.prePopulatedSecondaryApprovers]: "",
        [AccessPackageComponents.validateOwnership]: ""
    },
    loading: {
        [AccessPackageComponents.subscription]: false,
        [AccessPackageComponents.catalog]: false,
        [AccessPackageComponents.aad]: false,
        [AccessPackageComponents.accessPackage]: false,
        [AccessPackageComponents.accessPackageVw]: false,
        [AccessPackageComponents.resource]: false,
        [AccessPackageComponents.accessRequests]: false,
        [AccessPackageComponents.accessPackageReferences]: false,
        [AccessPackageComponents.approvedRequestStatus]: false,
        [AccessPackageComponents.currentAccessRequest]: false,
        [AccessPackageComponents.sipApproval]: false,
        [AccessPackageComponents.prePopulatedSecondaryApprovers]: false,
        [AccessPackageComponents.validateOwnership]: false
    }
};

const getAccessPackageReferenceAsync = createAsyncThunk(
    `${sliceName}/get_access_package_reference`,

    async () => {
        const response = await getAccessPackageReferences();
        return response.data;
    }
);

const getAccessRequestsAsync = createAsyncThunk(
    `${sliceName}/get_access_requests`,

    async () => {
        const response = await getAccessRequests();
        return response.data;
    }
);

const getAccessRequestByIdAsync = createAsyncThunk<AccessRequest, number, { rejectValue: any }>(
    `${sliceName}/get_access_request_by_id`,

    async (id: number, { rejectWithValue }) => {
        try {
            const response = await getAccessRequestById(id);
            return response.data;
        } catch (error) {
            return handleError(error, rejectWithValue);
        }
    }
);

const validateOwnershipAsync = createAsyncThunk<
    void,
    { groupId: string; subscriptionId: string },
    { rejectValue: any }
>(
    `${sliceName}/validate_ownership`,

    async ({ groupId, subscriptionId }, { rejectWithValue }) => {
        try {
            const response = await validateOwnership(groupId, subscriptionId);
            return response.data;
        } catch (error) {
            return handleError(error, rejectWithValue);
        }
    }
);

const getPrePopulatedSecondaryApproversAsync = createAsyncThunk<Contact[], string, { rejectValue: any }>(
    `${sliceName}/get_prepopulated_secondary_approvers`,

    async (catalogId: string, { rejectWithValue }) => {
        try {
            const response = await getPrePopulatedSecondaryApprovers(catalogId);
            return response.data;
        } catch (error) {
            return handleError(error, rejectWithValue);
        }
    }
);

// this is to avoid getting the serialized error message from the server, which will always be something like
// Server Returned a 400 Bad Request, this will get the actual error message from the server
const handleError = (error: any, rejectWithValue) => {
    if (error && axios.isAxiosError(error) && error.response) {
        if (error.response.data.error) {
            return rejectWithValue(error.response.data.error);
        } else {
            return rejectWithValue(error.response.data);
        }
    } else {
        return rejectWithValue(error as any);
    }
};
const submitSipRequestAsync = createAsyncThunk<string, ProcessSipAccessRequest, { rejectValue: any }>(
    `${sliceName}/sip_request`,
    async (request: ProcessSipAccessRequest, { rejectWithValue }) => {
        try {
            const response = await processSipAccessRequest(request);
            return response.data;
        } catch (error) {
            return handleError(error, rejectWithValue);
        }
    }
);

const accessPackageSlice = createSlice({
    name: sliceName,
    initialState,

    reducers: {
        resetAccessRequestState: (state) => {
            state.sipApproval = undefined;
            state.currentAccessRequest = undefined;
            state.errors[AccessPackageComponents.currentAccessRequest] = "";
            state.errors[AccessPackageComponents.sipApproval] = "";
            state.errors[AccessPackageComponents.approvedRequestStatus] = "";
        },
        clearSecondaryApprovers: (state) => {
            state.prePopulatedSecondaryApprovers = undefined;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAccessRequestsAsync.pending, (state, _action) => {
            state.loading[AccessPackageComponents.accessRequests] = true;
            state.errors[AccessPackageComponents.accessRequests] = "";
        });
        builder.addCase(getAccessRequestsAsync.fulfilled, (state, action) => {
            state.loading[AccessPackageComponents.accessRequests] = false;
            state.accessRequests = action.payload;
        });
        builder.addCase(getAccessRequestsAsync.rejected, (state, action) => {
            state.loading[AccessPackageComponents.accessRequests] = false;
            state.errors[AccessPackageComponents.accessRequests] =
                "The following error occured while trying to fetch the access requests: " +
                (action?.error?.message || "An unknown error occurred attempting to fetch Access Requests");
        });

        builder.addCase(getAccessPackageReferenceAsync.pending, (state, _action) => {
            state.loading[AccessPackageComponents.accessPackageReferences] = true;
            state.errors[AccessPackageComponents.accessPackageReferences] = "";
        });
        builder.addCase(getAccessPackageReferenceAsync.fulfilled, (state, action) => {
            state.loading[AccessPackageComponents.accessPackageReferences] = false;
            state.accessPackageReferences = action.payload;
        });
        builder.addCase(getAccessPackageReferenceAsync.rejected, (state, action) => {
            state.loading[AccessPackageComponents.accessPackageReferences] = false;
            state.errors[AccessPackageComponents.accessPackageReferences] =
                "The following error occured while trying to fetch the access package reference: " +
                (action?.error?.message || "An unknown error occurred attempting to fetch Access Package References");
        });

        builder.addCase(getAccessRequestByIdAsync.pending, (state, _action) => {
            state.loading[AccessPackageComponents.currentAccessRequest] = true;
            state.errors[AccessPackageComponents.currentAccessRequest] = "";
        });
        builder.addCase(getAccessRequestByIdAsync.fulfilled, (state, action) => {
            state.loading[AccessPackageComponents.currentAccessRequest] = false;
            state.currentAccessRequest = action.payload;
        });
        builder.addCase(getAccessRequestByIdAsync.rejected, (state, action) => {
            state.loading[AccessPackageComponents.currentAccessRequest] = false;
            state.errors[AccessPackageComponents.currentAccessRequest] =
                "The following error occured while trying to fetch the access request: " +
                (action.payload?.message ||
                    action.payload?.title ||
                    "An unknown error occurred attempting to fetch Access Request");
        });

        builder.addCase(submitSipRequestAsync.pending, (state, _action) => {
            state.loading[AccessPackageComponents.sipApproval] = true;
            state.errors[AccessPackageComponents.sipApproval] = "";
        });
        builder.addCase(submitSipRequestAsync.fulfilled, (state, action) => {
            state.loading[AccessPackageComponents.sipApproval] = false;
            state.sipApproval = action.payload;
        });
        builder.addCase(submitSipRequestAsync.rejected, (state, action) => {
            state.loading[AccessPackageComponents.sipApproval] = false;
            state.errors[AccessPackageComponents.sipApproval] =
                "The following error occured while trying to process the approval/rejection: " +
                (action.payload?.message ||
                    action.payload?.title ||
                    "An unknown error occurred attempting to fetch Access Request");
        });

        builder.addCase(getPrePopulatedSecondaryApproversAsync.pending, (state, _action) => {
            state.loading[AccessPackageComponents.prePopulatedSecondaryApprovers] = true;
            state.errors[AccessPackageComponents.prePopulatedSecondaryApprovers] = "";
        });
        builder.addCase(getPrePopulatedSecondaryApproversAsync.fulfilled, (state, action) => {
            state.loading[AccessPackageComponents.prePopulatedSecondaryApprovers] = false;
            state.prePopulatedSecondaryApprovers = action.payload;
        });
        builder.addCase(getPrePopulatedSecondaryApproversAsync.rejected, (state, action) => {
            state.loading[AccessPackageComponents.prePopulatedSecondaryApprovers] = false;
            state.errors[AccessPackageComponents.prePopulatedSecondaryApprovers] =
                "The following error occured while trying to get secondary approvers list: " +
                (action.payload?.message || action.payload?.title);
            ("An unknown error occurred attempting to fetch secondary approvers list");
        });
        builder.addCase(validateOwnershipAsync.pending, (state, _action) => {
            state.isOwnershipValid = undefined;
            state.loading[AccessPackageComponents.validateOwnership] = true;
            state.errors[AccessPackageComponents.validateOwnership] = "";
        });
        builder.addCase(validateOwnershipAsync.fulfilled, (state, _action) => {
            state.loading[AccessPackageComponents.validateOwnership] = false;
            state.isOwnershipValid = true;
        });
        builder.addCase(validateOwnershipAsync.rejected, (state, action) => {
            state.loading[AccessPackageComponents.validateOwnership] = false;
            if (action.payload?.message && action.payload.message === "Group is not owned by ELM Function MSI") {
                state.isOwnershipValid = false;
            } else {
                state.errors[AccessPackageComponents.validateOwnership] =
                    "The following error occured while trying to validate ownership: " +
                    (action.payload?.message || action.payload?.title);
            }
        });
    }
});
export const { resetAccessRequestState, clearSecondaryApprovers } = accessPackageSlice.actions;

export {
    sliceName,
    getAccessRequestsAsync,
    getAccessPackageReferenceAsync,
    getAccessRequestByIdAsync,
    submitSipRequestAsync,
    getPrePopulatedSecondaryApproversAsync,
    validateOwnershipAsync
};
export const { reducer } = accessPackageSlice;

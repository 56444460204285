import {
    DefaultButton,
    DetailsList,
    IconButton,
    IContextualMenuProps,
    IIconProps,
    Label,
    MessageBar,
    MessageBarType,
    Pivot,
    PivotItem,
    PrimaryButton,
    SelectionMode,
    Stack,
    TextField
} from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import {
    EmptyAdlsDataset,
    EmptyKustoDataset,
    EmptySDWDataset,
    EmptyDataset,
    IDataset,
    IDatasetAssociation
} from "../../models/Dataset";
import { accessPackageSelector, dataSetSelector } from "../../pages/Selectors";
import { getAccessPackageReferenceAsync } from "../../reducers/AccessPackages";
import {
    DatasetComponents,
    getDatasetById,
    setCurrentAdlsDataset,
    setCurrentKustoDataset,
    setCurrentSDWDataset,
    setCurrentDataset,
    setDatasetAssociation,
    setDatasetAlert,
    updateDataset,
    addDataset,
    clearResponse,
    setSelectedTab
} from "../../reducers/Dataset";

import {
    checkDatasetDuplicate,
    deleteAdlsDatasetApi,
    deleteKustoDatasetApi,
    deleteSDWDatasetApi
} from "../../api/DatasetApi";
import { generateDatasetAssociationColumns } from "../../columns/DatasetAssociationColumns";
import { ShimmeredAutoComplete } from "../common/Shimmered";
import StandardForm from "../common/StandardForm";
import { useGetActionAndId } from "./useGetActionAndId";
import { StackRow } from "../common/StackComponents";
import { DisplayInCatalogToggle } from "./DisplayInCatalogToggle";
import { nameof } from "../common/utils";
import SaveButtonContainer from "./SaveButtonContainer";
import {
    anyFieldErrors,
    FieldState,
    getAdlsDatasetAssociation,
    getITag,
    getKustoDatasetAssociation,
    getSdwDatasetAssociation
} from "./utils";
import { LoadingOverLay } from "../common/LoadingOverLay";
import { DataTag } from "../common/AutoCompleteTextField";
import SaveModal from "./SaveModal";
import CrudStatusDialog from "../common/CrudStatusDialog";
import Alerts from "../DatasetAlert/Alerts";

interface IParentprops {
    id: string;
}
interface IProps extends RouteComponentProps<IParentprops> {}

export function DatasetForm(props: IProps) {
    const { dsId, isDatasetAdd, isAdd } = useGetActionAndId();

    const { accessPackageReferences, loading } = useAppSelector(accessPackageSelector);
    const { currentDataset, dsErrors, updateDatasetResponse, addDatasetResponse, datasetAssociation, datasetAlerts, dsLoading, selectedTab } =
        useAppSelector(dataSetSelector);
    const [menuProps, setMenuProps] = useState<IContextualMenuProps>({ items: [] });
    const [showDeleteAnnouncment, setShowDeleteAnnouncement] = useState(false);
    const [showAnnouncment, setShowAnnouncement] = useState(false);
    const [fieldState, setFieldState] = useState<FieldState>({
        message: "",
        errors: {}
    });

    const dispatch = useAppDispatch();

    async function checkDuplicate() {
        if (!currentDataset) return;
        try {
            const isDup = await checkDatasetDuplicate(currentDataset.id, currentDataset.name);
            if (isDup) {
                setFieldState({ ...fieldState, errors: { name: "Dataset name already exists" } });
            } else {
                setFieldState({ ...fieldState, errors: { name: "" } });
            }
        } catch (error) {
            setFieldState({ ...fieldState, errors: { name: "" } });
            console.error("An error occurred checking duplicates");
            console.error(JSON.stringify(error));
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        if (params.get('tab')) {
            dispatch(setSelectedTab(params.get('tab') as string))
        }
        if (currentDataset) {
            const tempMenuProps: IContextualMenuProps = { items: [] };
            if (!currentDataset.adlsDataset) {
                tempMenuProps.items.push({
                    key: "adls",
                    text: "ADLS",
                    onClick: () => {
                        dispatch(
                            setCurrentAdlsDataset({
                                ...EmptyAdlsDataset,
                                datasetId: currentDataset.id,
                                datasetName: currentDataset.name
                            })
                        );

                        props.history.replace(`/AdlsDataset/New${dsId ? "/" + dsId : ""}`);
                    }
                });
            }
            if (!currentDataset.kustoDataset) {
                tempMenuProps.items.push({
                    key: "kusto",
                    text: "Kusto",
                    onClick: () => {
                        dispatch(
                            setCurrentKustoDataset({
                                ...EmptyKustoDataset,
                                datasetId: currentDataset.id,
                                datasetName: currentDataset.name
                            })
                        );
                        props.history.replace(`/KustoDataset/New${dsId ? "/" + dsId : ""}`);
                    }
                });
            }
            if (!currentDataset.sdwDataset) {
                tempMenuProps.items.push({
                    key: "sdw",
                    text: "SDW",
                    onClick: () => {
                        dispatch(
                            setCurrentSDWDataset({
                                ...EmptySDWDataset,
                                datasetId: currentDataset.id,
                                datasetName: currentDataset.name
                            })
                        );
                        props.history.replace(`/SDWDataset/New${dsId ? "/" + dsId : ""}`);
                    }
                });
            }
            setMenuProps(tempMenuProps);
            const tempDatasetAssociation: IDatasetAssociation[] = [];
            if (currentDataset.adlsDataset) {
                tempDatasetAssociation.push(
                    getAdlsDatasetAssociation(currentDataset.adlsDataset, currentDataset.id, currentDataset.datasetTags)
                );
            }
            if (currentDataset.kustoDataset) {
                tempDatasetAssociation.push(
                    getKustoDatasetAssociation(
                        currentDataset.kustoDataset,
                        currentDataset.id,
                        currentDataset.datasetTags
                    )
                );
            }
            if (currentDataset.sdwDataset) {
                tempDatasetAssociation.push(
                    getSdwDatasetAssociation(currentDataset.sdwDataset, currentDataset.id, currentDataset.datasetTags)
                );
            }
            if (tempDatasetAssociation.length > 0) {
                dispatch(setDatasetAssociation(tempDatasetAssociation));
            }
            // Get alerts for this dataset
            if (currentDataset.datasetAlerts) {
                dispatch(setDatasetAlert(currentDataset.datasetAlerts));
            }
        } else {
            dispatch(setCurrentDataset(EmptyDataset));
        }
    }, [currentDataset]);

    useEffect(() => {
        if (accessPackageReferences) {
            const subId = accessPackageReferences.find(
                (x) => x.id === currentDataset?.accessPackageReferenceId
            )?.subscriptionId;

            if (subId) {
                onChange(subId, "subscriptionId");
            }
        }
    }, [accessPackageReferences, currentDataset?.accessPackageReferenceId]);

    useEffect(() => {
        if (!isAdd && dsId) {
            dispatch(getDatasetById(dsId));
        }
    }, [location.href]);

    useEffect(() => {
        if (currentDataset?.name) {
            checkDuplicate();
        }
    }, [currentDataset?.name]);

    useEffect(() => {
        dispatch(getAccessPackageReferenceAsync());
    }, [getAccessPackageReferenceAsync]);

    useEffect(() => {
        if (addDatasetResponse !== undefined && addDatasetResponse !== -1) {
            history.push(`/datasets/Edit/${addDatasetResponse}`);

            setTimeout(() => {
                resetForm();
            }, 5000);
        }
    }, [addDatasetResponse]);
    useEffect(() => {
        if (updateDatasetResponse === "Success" && !isAdd && dsId) {
            dispatch(getDatasetById(dsId));

            setTimeout(() => {
                resetForm();
            }, 5000);
        }
    }, [updateDatasetResponse]);

    async function onChange(value: any, stateKey: string): Promise<void> {
        setShowAnnouncement(false);
        if (!currentDataset) return;
        const updatedDataset = { ...currentDataset, [stateKey]: value };
        dispatch(setCurrentDataset(updatedDataset));
    }

    function isFormValid() {
        if (!currentDataset) return false;

        if (anyFieldErrors(fieldState)) return false;

        if (currentDataset.name?.trim().length === 0) return false;
        if (currentDataset.accessPackageReferenceId === 0) return false;
        // enforce at least one dataset association
        if (!(currentDataset.adlsDataset || currentDataset.kustoDataset || currentDataset.sdwDataset)) return false;

        return true;
    }

    function setAccessPackageReferenceId(tag: DataTag | undefined) {
        if (!currentDataset) return;
        const updatedDataset = { ...currentDataset };

        if (!tag) {
            updatedDataset.accessPackageReferenceId = 0;
            updatedDataset.accessPackageName = "";
            dispatch(setCurrentDataset(updatedDataset));
            return;
        }

        if (!accessPackageReferences) return;

        updatedDataset.accessPackageReferenceId = (tag?.key as number) || 0;
        updatedDataset.accessPackageName = tag.name;
        dispatch(setCurrentDataset(updatedDataset));
        return;
    }
    async function submitDatasetDetails() {
        if (!currentDataset) return;

        const updatedDataset = { ...currentDataset };
        setShowAnnouncement(true);
        if (isDatasetAdd) {
            dispatch(addDataset(updatedDataset));
        } else {
            dispatch(updateDataset(updatedDataset));
        }
    }

    const history = useHistory();
    const linkIcon: IIconProps = { iconName: "AddLink" };
    const addButtonRef = useRef<HTMLDivElement>(null);

    const invokeDelete = async (id: number, type: string) => {
        if (confirm(`Are you sure you want to delete this association?`)) {
            if (!dsId) {
                if (type == "Adls") {
                    const tempDatasetAssociation = datasetAssociation.filter((x) => x.dsID !== id);

                    dispatch(setDatasetAssociation(tempDatasetAssociation));
                }

                return;
            }
            // call api to delete
            if (type == "Adls") {
                await deleteAdlsDatasetApi(id);
            } else if (type == "Kusto") {
                await deleteKustoDatasetApi(id);
            } else if (type == "SDW") {
                await deleteSDWDatasetApi(id);
            }

            dispatch(getDatasetById(dsId));

            setShowDeleteAnnouncement(true);
        }
    };

    function clearAllDatasets() {
        dispatch(setDatasetAssociation([]));
        dispatch(setDatasetAlert([]));
        dispatch(setCurrentAdlsDataset(EmptyAdlsDataset));
        dispatch(setCurrentKustoDataset(EmptyKustoDataset));
        dispatch(setCurrentSDWDataset(EmptySDWDataset));
        dispatch(setCurrentDataset(EmptyDataset));
    }

    function resetForm() {
        dispatch(clearResponse("updateDatasetResponse"));
        dispatch(clearResponse("addDatasetResponse"));
        setShowAnnouncement(false);
    }

    useEffect(() => {
        return () => { dispatch(setSelectedTab("")); }
    }, []);

    const _handleLinkClick = (item: PivotItem): void => {

        if (item.props && item.props.itemKey) {
            dispatch(setSelectedTab(item.props.itemKey))
        }
    };
    

    return (
        <StandardForm widthPercent={70} mediumWidthPercent={90} smallWidthPercent={90}>
            <LoadingOverLay isOverlayVisible={dsLoading[DatasetComponents.currentDataset]} />
            {dsErrors[DatasetComponents.Dataset] && (
                <MessageBar messageBarType={MessageBarType.error} title=" Dataset Creation Error">
                    {dsErrors[DatasetComponents.Dataset]}
                </MessageBar>
            )}

            <SaveModal
                hidden={!showAnnouncment}
                onClose={resetForm}
                successMsg="Dataset has been saved successfully"
                error={dsErrors[DatasetComponents.Dataset]}
                loading={dsLoading[DatasetComponents.Dataset]}
            />
            <CrudStatusDialog
                showDialog={showDeleteAnnouncment}
                title={"Association has been deleted successfully"}
                onClose={() => setShowDeleteAnnouncement(false)}
            />
            <Stack.Item align="end" style={{ textAlign: "right" }}>
                <IconButton
                    title="Close"
                    className="routing"
                    onClick={() => {
                        clearAllDatasets();
                        props.history.replace("/datasets");
                    }}
                    iconProps={{ iconName: "Cancel" }}
                    allowDisabledFocus
                />
            </Stack.Item>
            <DisplayInCatalogToggle
                enabled
                checked={currentDataset?.displayInCatalog || false}
                onChange={(e, newValue) => onChange(newValue, nameof<IDataset>("displayInCatalog"))}
            />
            <StackRow columnWidth={40}>
                <Stack>
                    <Label className="label" required>
                        Name
                    </Label>
                    <TextField
                        placeholder="Max length: 30 characters"
                        onChange={(_e, newValue) => {
                            if (newValue !== undefined) onChange(newValue, "name");
                        }}
                        required
                        value={currentDataset?.name || ""}
                        maxLength={30}
                        errorMessage={fieldState.errors.name}
                    ></TextField>
                </Stack>
                {currentDataset && currentDataset.isDescriptionManuallyOverwritten !== null && (
                    <Stack>
                        <Label className="label">Description</Label>

                        <TextField
                            multiline
                            autoAdjustHeight={true}
                            placeholder="Max length: 1000 characters"
                            onChange={(_e, newValue) => {
                                if (newValue !== undefined && newValue.length <= 1000)
                                    onChange(newValue, "description");
                            }}
                            value={currentDataset?.description || ""}
                        ></TextField>
                        {currentDataset && currentDataset.isDescriptionManuallyOverwritten === false && (
                            <label>
                                <b>Note:</b> This is an AI-generated description.
                            </label>
                        )}
                        {currentDataset && currentDataset.isDescriptionManuallyOverwritten === true && (
                            <label>
                                <b>Note:</b> The AI-generated dataset description has been manually edited by the SIP PM
                                Team.{" "}
                            </label>
                        )}
                    </Stack>
                )}
            </StackRow>
            <StackRow columnWidth={40}>
                <ShimmeredAutoComplete<DataTag>
                    label="Access Package"
                    loading={!accessPackageReferences}
                    itemName="access packages"
                    disabled={!accessPackageReferences || accessPackageReferences.length === 0}
                    sendSelectedValue={setAccessPackageReferenceId}
                    data={
                        accessPackageReferences
                            ?.map((entry) => ({ key: entry.id, name: entry.name }))
                            .sort((a, b) => a.name.localeCompare(b.name)) || []
                    }
                    val={getITag(currentDataset?.accessPackageName)}
                    placeHolderText={`Start typing for suggestions`}
                />
            </StackRow>

            <SaveButtonContainer>
                <PrimaryButton
                    text="Save"
                    type="button"
                    disabled={!isFormValid()}
                    onClick={() => {
                        submitDatasetDetails();
                    }}
                ></PrimaryButton>
                <PrimaryButton
                    text="Close"
                    type="button"
                    onClick={() => props.history.replace("/datasets")}
                ></PrimaryButton>
            </SaveButtonContainer>

            <Pivot
                aria-label="Dataset Details"
                linkSize="large"
                selectedKey={selectedTab ? selectedTab : "Associations" }
                onLinkClick={(item) => { item && _handleLinkClick(item) }}
            >
                <PivotItem headerText="Associations" itemKey="Associations" >
                    <Stack style={{ paddingTop: 20, width: "100%" }}>
                        <div
                            ref={addButtonRef}
                            onFocus={() => {
                                addButtonRef.current &&
                                    addButtonRef.current.scrollIntoView({
                                        behavior: "smooth",
                                        block: "nearest",
                                        inline: "start"
                                    });
                            }}
                        >
                            <Stack>
                                <Stack.Item grow align="end">
                                    <DefaultButton
                                        text="Associate"
                                        split
                                        iconProps={linkIcon}
                                        splitButtonAriaLabel="Associate with"
                                        aria-roledescription="split button"
                                        menuProps={menuProps}
                                        onClick={() => { }}
                                        disabled={!currentDataset?.accessPackageReferenceId || menuProps.items.length == 0}
                                    ></DefaultButton>
                                </Stack.Item>
                            </Stack>
                        </div>
                        <DetailsList
                            items={datasetAssociation}
                            selectionMode={SelectionMode.none}
                            // override standard behavior because when new the ids will all be 0
                            getKey={(item: IDatasetAssociation) => item.endpoint}
                            columns={generateDatasetAssociationColumns(history, invokeDelete, datasetAssociation.length > 1)}
                        />
                    </Stack>
                </PivotItem>
                {dsId && <PivotItem headerText="Alerts" itemKey="Alerts">
                    {/*Show only if dataset is already saved*/}
                    <Stack style={{ paddingTop: 20, width: "100%" }}>
                        <Alerts datasetID={dsId ?? 0} datasetAlerts={datasetAlerts} disabled={false} />
                    </Stack>
                </PivotItem>}

            </Pivot>
        </StandardForm>
    );
}
export default DatasetForm;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../service/authProvider";

export const feedbacksAPI = createApi({
    reducerPath: "feedbacksAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, {}) => {
            await getAccessToken().then((token) => {
                if (token) {
                    // If we have a token set in state, let's assume that we should be passing it.
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getFeedbackDetails: builder.query({
            query: () => "/api/SIPFeature/GetFeedbackInfo",
            keepUnusedDataFor: 60 // invalidate after 60 seconds
        })
    })
});

export const pageViewsAPI = createApi({
    reducerPath: "pageViewsAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, {}) => {
            await getAccessToken().then((token) => {
                if (token) {
                    // If we have a token set in state, let's assume that we should be passing it.
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getPageViewsDetails: builder.query({
            query: () => `/api/SIPFeature/GetPageViewsInfo`,
            keepUnusedDataFor: 60 // invalidate after 60 seconds
        })
    })
});

export const { useGetFeedbackDetailsQuery } = feedbacksAPI;
export const { useGetPageViewsDetailsQuery } = pageViewsAPI;

import {
    ComboBox,
    DefaultButton,
    DetailsRow,
    DialogFooter,
    DirectionalHint,
    IComboBox,
    IComboBoxOption,
    IDetailsRowProps,
    IDropdownStyles,
    IObjectWithKey,
    PrimaryButton,
    SelectionMode,
    Shimmer,
    ShimmeredDetailsList
} from "@fluentui/react";
import { IDropdownOption } from "@fluentui/react";
import {
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    Dropdown,
    IColumn,
    Selection,
    Stack,
    Sticky,
    StickyPositionType,
    TextField
} from "@fluentui/react";

import { uniqueId } from "lodash";
import React, { FormEvent, useEffect } from "react";
import { useState } from "react";

import AssociateFeedsDialogProps from "../../models/dialogProps/AssociateFeedsDialogProps";
import { IFeed } from "../../models/Feeds";
import { EmptyPipelineActivity, IPipelineActivity } from "../../models/PipelineActivtiy";

import { DialgContainer } from "../common/DialogContainer";
import Errorbar from "../common/ErrorBar";

import { renderStickyHeader, StickyWrapper } from "../common/StickyHeader";
import { useAppDispatch } from "../../hooks/ReduxHooks";
import { updateActivities } from "../../reducers/Activities";

interface IFeedActivitySelection extends IObjectWithKey {
    key: string;
    feedId: number;
    feedName: string;
    feedPath: string;
    selectedActivity: string;
    activities: string[];
}

interface ISelectionLookup {
    [storageAccount: string]: IFeedActivitySelection[];
}

const AssociateFeedsDialog: React.FC<AssociateFeedsDialogProps> = (props) => {
    const dispatch = useAppDispatch();

    const _getInitOptions = (name: string): IDropdownOption[] => [{ key: -1, text: `Select a ${name}` }];

    const [selectionTrigger, setSelectionTrigger] = useState<string>("");

    const [feedActivities, setFeeds] = useState<IFeedActivitySelection[]>([]);

    const [allSelections, setAllSelections] = useState<ISelectionLookup>({});
    const [storageAccount, setStorageAccount] = useState<string>("");

    const [selection] = useState<Selection>(
        new Selection({
            onSelectionChanged: () => setSelectionTrigger(uniqueId())
        })
    );

    const [feedSearchText, setFeedSearchText] = useState<string | undefined>("");
    const [storageAccountOptions, setStorageAccountOptions] = useState<IDropdownOption[]>(
        _getInitOptions("Storage Account")
    );

    const [filteredStorageAccountOptions, setFilteredStorageAccountOptions] =
        useState<IDropdownOption[]>(storageAccountOptions);
    const [errorState, setErrorState] = useState<string>("");

    const _mapFeedActivities = (feed: IFeed): IFeedActivitySelection => {
        const activities = props.activities.map((a) => a.name);

        let activityName = "";
        // make sure the feed activity are actually part of the selected pipeline... this only needs to happen on first go around after that
        // we can just rely on allSelections to get the selections otherwise deselects won't work correctly
        if (
            !allSelections[storageAccount] &&
            feed.activity &&
            activities.findIndex((x) => x === feed.activity!.name) > -1
        ) {
            activityName = feed.activity.name;
        }

        // preserves selected activities when changing storage accounts

        if (!activityName && allSelections[storageAccount]) {
            const s = allSelections[storageAccount].find((x) => x.feedId == feed.id);

            if (s) {
                activityName = s.selectedActivity;
            }
        }

        return {
            key: feed.name,
            feedId: feed.id,
            feedName: feed.name,
            feedPath: feed.path,
            selectedActivity: activityName,
            activities: activities
        };
    };

    useEffect(() => {
        if (storageAccount && props.showDialog) {
            const newIndices = selection
                .getSelection()
                .filter(
                    (index) =>
                        allSelections[storageAccount].findIndex((f) => f.key === index.key && f.selectedActivity) === -1
                )
                .map((index) => index as IFeedActivitySelection);

            const unselectedIndices = selection
                .getItems()
                .filter((item) => selection.isKeySelected(item.key as string) === false)
                .map((index) => index as IFeedActivitySelection);

            selection
                .getItems()
                .filter((item) => selection.isKeySelected(item.key as string) === false)
                .forEach((x) => ((x as IFeedActivitySelection).selectedActivity = ""));

            if (allSelections[storageAccount]) {
                allSelections[storageAccount] = allSelections[storageAccount].filter(
                    (index) => unselectedIndices.findIndex((f) => f.key === index.key) === -1
                );
                allSelections[storageAccount] = [...allSelections[storageAccount], ...newIndices];
            } else {
                allSelections[storageAccount] = newIndices;
            }

            setAllSelections({
                ...allSelections,
                [storageAccount]: allSelections[storageAccount]
            });
        }
    }, [selectionTrigger]);

    useEffect(() => {
        interface StoragePlatform {
            Name: string;
            Platform: string;
        }

        if (props.showDialog) {
            // get all storage accounts for dropdown
            setStorageOptions();

            // just get the first account that have feeds associated
            if (props.currentFeeds.length > 0) {
                const currentFeedStorageAccounts = props.currentFeeds
                    .map((x) => x.storageName)
                    .reduce((acc: string[], val) => {
                        if (!acc.includes(val)) acc.push(val);
                        return acc;
                    }, []);

                setStorageAccount(currentFeedStorageAccounts[0]);
            } else {
                setStorageAccount("");
            }
        }

        function setStorageOptions() {
            const storageAccs = props.feeds
                .map((f) => ({ Name: f.storageName, Platform: f.platformName }))
                .reduce((acc: StoragePlatform[], val) => {
                    if (acc.findIndex((c) => c.Name === val.Name) == -1) acc.push(val);
                    return acc;
                }, []);

            const tempOptions: IDropdownOption[] = storageAccs.map((sa) => ({
                key: sa.Name,
                text: `(${sa.Platform}) - ${sa.Name}`
            }));

            setStorageAccountOptions((state) => [state[0], ...tempOptions]);
        }
    }, [props.showDialog]);

    useEffect(() => {
        setFilteredStorageAccountOptions(storageAccountOptions);
    }, [storageAccountOptions]);

    useEffect(() => {
        if (storageAccount && props.showDialog && props.activities && props.activities.length > 0) {
            const items = props.feeds
                .filter((f) => f.storageName === storageAccount)
                .map(_mapFeedActivities)
                .sort((a, b) => b.selectedActivity.localeCompare(a.selectedActivity));

            // on the very first go around allSelections will be empty
            // we need to populate it will all current associations (as in persisted to the db)
            // this just ensures the entire state of selections is set from the beginning

            if (Object.keys(allSelections).length === 0) {
                const dict: ISelectionLookup = {};
                props.currentFeeds
                    .map((f) => ({ fas: _mapFeedActivities(f), feed: f })) // this is the same mapping logic used else where only not filtering on storage account
                    .forEach((obj, idx) => {
                        if (idx > -1) {
                            if (!dict[obj.feed.storageName]) {
                                dict[obj.feed.storageName] = [];
                            }

                            dict[obj.feed.storageName].push(obj.fas);
                        }
                    });

                setAllSelections(dict);
            }

            setFeeds(items);
        } // there is a race condition where activities might not be loaded
        // by the time to storage account is set... this will make sure the
        // activities are set in time
    }, [storageAccount, props.feeds, props.activities]);

    useEffect(() => {
        if (props.showDialog) {
            if (storageAccount) {
                if (feedActivities.length > 0) {
                    selection.setItems(feedActivities);

                    if (allSelections[storageAccount] && allSelections[storageAccount]) {
                        allSelections[storageAccount].forEach((i) => selection.setKeySelected(i.key, true, true));
                    }
                }
            }
        }
    }, [feedActivities]);

    useEffect(() => {
        if (props.showDialog) setErrorState(props.activitiesError);
    }, [props.activitiesError]);

    useEffect(() => {
        if (props.showDialog) {
            if (feedSearchText) {
                const items = props.feeds
                    .filter((f) => f.storageName === storageAccount)
                    .filter(
                        (f) =>
                            f.name.toLowerCase().indexOf(feedSearchText) > -1 ||
                            (f.path && f.path.toLowerCase().indexOf(feedSearchText) > -1)
                    )
                    .map(_mapFeedActivities)
                    .sort((a, b) => b.selectedActivity.localeCompare(a.selectedActivity));

                setFeeds(items);
            } else {
                const items = props.feeds
                    .filter((f) => f.storageName === storageAccount)
                    .map(_mapFeedActivities)
                    .sort((a, b) => b.selectedActivity.localeCompare(a.selectedActivity));
                setFeeds(items);
            }
        }
    }, [feedSearchText]);

    const _columns: IColumn[] = [
        { key: "Name", name: "Name", fieldName: "feedName", minWidth: 200, maxWidth: 200, isResizable: true },
        { key: "Path", name: "Path", fieldName: "feedPath", minWidth: 200, maxWidth: 200, isResizable: true },
        {
            key: "Activities",
            name: "Activities",
            fieldName: "activities",
            minWidth: 300,
            maxWidth: 500,
            isResizable: true
        }
    ];

    const _updateSelection = React.useCallback(
        (_event: FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) => {
            if (option) {
                // deep copy to make sure it's immutable and will fire the effects
                const updatedList = feedActivities.map((a) => ({ ...a }));

                const idx = updatedList.findIndex((x) => x.feedName === option.data);

                const updatedFa = { ...updatedList[idx], selectedActivity: option.text };

                updatedList.splice(idx, 1, updatedFa);

                setFeeds(updatedList);
                selection.setIndexSelected(idx, true, true);
            }
        },
        [feedActivities, setFeeds, selection]
    );

    const _onRenderRow = React.useCallback(
        (props: IDetailsRowProps | undefined): JSX.Element => {
            if (props) {
                return (
                    <div data-selection-toggle="true">
                        <DetailsRow {...props} />
                    </div>
                );
            } else {
                return <div></div>;
            }
        },
        [props, selection]
    );
    const _renderItemColumn = React.useCallback(
        (item: IFeedActivitySelection, _index: number | undefined, column: IColumn | undefined) => {
            if (!column) return;

            const fieldContent = item[column.fieldName || ""];

            switch (column.fieldName) {
                case "activities":
                    let options = _getInitOptions("Activity");
                    options = [
                        options[0],
                        ...item.activities.map((x): IDropdownOption => ({ key: x, text: x, data: item.feedName }))
                    ];

                    const selectedActivity = feedActivities.filter((f) => f.feedId === item.feedId)[0]!
                        .selectedActivity;

                    const key = selectedActivity ? selectedActivity : -1;

                    return (
                        <Dropdown
                            onClick={(e) => e.stopPropagation()}
                            options={options}
                            key={item.feedId}
                            defaultSelectedKey={key}
                            onChange={(e, o, i) => _updateSelection(e, o)}
                            dropdownWidth={"auto"}
                        />
                    );

                default:
                    return <span>{fieldContent}</span>;
            }
        },
        [feedActivities, selection, _updateSelection, _getInitOptions]
    );

    const _onSubmit = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>): void => {
            event.preventDefault();
            setErrorState("");

            const everyselectionHasActivity = selection.getSelection().every((x) => x["selectedActivity"] !== "");

            const activities: IPipelineActivity[] = [];
            if (!everyselectionHasActivity) {
                setErrorState("Every feed requires an Activity to be selected");
                return;
            }
            for (const key in allSelections) {
                allSelections[key].forEach((selectionItem) => {
                    if (selectionItem) {
                        const temp = props.activities.find((x) => x.name === selectionItem["selectedActivity"]);
                        // need to make a copy of the activity to avoid mutating the original
                        const activity = temp ? { ...temp } : undefined;

                        if (activity) {
                            const existingActivity = activities.find((x) => x.name === activity.name);

                            if (existingActivity) {
                                if (existingActivity.feedIds.findIndex((x) => x === selectionItem["feedId"]) == -1)
                                    existingActivity.feedIds.push(selectionItem["feedId"]);
                            } else {
                                activity.feedIds = [];
                                // clear out feedid's on the first go around to pickup changes

                                if (activity.feedIds.findIndex((x) => x === selectionItem["feedId"]) == -1)
                                    activity.feedIds.push(selectionItem["feedId"]);

                                activities.push(activity);
                            }
                        }
                    }
                });
            }

            // get any feeds that were deselected
            const removedFeedsActivities = props.currentFeeds
                .filter((f) => f.activity && activities.findIndex((x) => x.name === f.activity!.name) === -1)
                .map((x) => {
                    const temp = { ...x };
                    if (temp.activity) {
                        temp.activity = {
                            ...temp.activity,
                            feedIds: temp.activity.feedIds.filter((fid) => fid !== x.id)
                        };
                    }

                    return temp.activity as IPipelineActivity; //typescript can't figure out I am already filtering out undefined
                });

            const allActivities = activities.concat(removedFeedsActivities);

            dispatch(updateActivities(allActivities));
        },
        [allSelections, props.activities, setErrorState, updateActivities]
    );

    const _onStorageDropDownChange = (e: React.FormEvent<IComboBox>, i: IComboBoxOption): void => {
        //@ts-ignore
        if (e.type === "click" || (e.type === "keydown" && e.code === "Enter")) {
            setStorageAccount(i.key as string);
        }
    };

    const onInputValueChange = (e: string): void => {
        if (e) {
            const filteredOptions = storageAccountOptions.filter((x) => x.text.toLowerCase().includes(e.toLowerCase()));

            setFilteredStorageAccountOptions(filteredOptions);
        } else {
            setFilteredStorageAccountOptions(storageAccountOptions);
        }
    };
    // const dropDownStyles: Partial<IDropdownStyles> = { root: { maxWidth: "500px" } };

    return (
        <DialgContainer {...props}>
            <div style={{ height: "500px", width: "70vw" }}>
                <StickyWrapper>
                    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                        <Stack verticalFill verticalAlign={"space-evenly"} style={{ padding: "0 20px" }}>
                            <Stack.Item align="auto">
                                <ComboBox
                                    label="Storage Account"
                                    placeholder="Select a Storage Account"
                                    selectedKey={storageAccount}
                                    options={filteredStorageAccountOptions}
                                    allowFreeform={true}
                                    autoComplete="off"
                                    openOnKeyboardFocus={true}
                                    shouldRestoreFocus={false}
                                    onInputValueChange={(e) => onInputValueChange(e)}
                                    // filter based on the keydown event
                                    onChange={(e, i) => _onStorageDropDownChange(e, i as IComboBoxOption)}
                                    // styles={dropDownStyles}
                                />
                            </Stack.Item>
                            <Stack.Item align="auto">
                                <TextField label="Filter by name:" onChange={(ev, text) => setFeedSearchText(text)} />
                            </Stack.Item>
                        </Stack>
                    </Sticky>

                    <ShimmeredDetailsList
                        items={feedActivities}
                        columns={_columns}
                        setKey="key"
                        selectionPreservedOnEmptyClick={true}
                        selectionMode={SelectionMode.multiple}
                        constrainMode={ConstrainMode.unconstrained}
                        selection={selection}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                        onRenderDetailsHeader={renderStickyHeader}
                        onRenderItemColumn={_renderItemColumn}
                        onRenderRow={_onRenderRow}
                    />
                </StickyWrapper>
            </div>
            <DialogFooter>
                <PrimaryButton onClick={_onSubmit} text="Update" />
                <DefaultButton onClick={props.onClose} text="Cancel" />
            </DialogFooter>
            {errorState && <Errorbar msg={errorState} />}
        </DialgContainer>
    );
};

export default AssociateFeedsDialog;

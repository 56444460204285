import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogFooter,
    DialogType,
    FontWeights,
    PrimaryButton,
    Separator,
    Stack,
    Text,
    useTheme
} from "@fluentui/react";

interface SaveModalProps {
    hidden: boolean;
    successMsg?: string;
    loading?: boolean;
    error?: string;
    onClose: () => void;
}

const SaveModal: React.FC<SaveModalProps> = ({ hidden, successMsg, loading, onClose, error }) => {
    const [message, setMessage] = useState("Saving...");
    const theme = useTheme();
    useEffect(() => {
        if (loading) {
            setMessage("Saving ");
        } else {
            setMessage(successMsg || "Saved ✅");
        }
    }, [loading, successMsg]);

    const isSaving = loading && !error;

    useEffect(() => {
        if (error) {
            setMessage(error);
        }
    }, [error]);

    return (
        <Dialog
            hidden={hidden}
            dialogContentProps={{
                type: loading ? DialogType.normal : DialogType.close,
                title: "Save Progress",
                showCloseButton: false
            }}
            styles={{ main: { borderTop: `4px solid ${theme.palette.themePrimary}` } }}
            onDismiss={onClose}
            modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 450 } }
            }}
        >
            <Separator />
            <Stack horizontalAlign="center" horizontal style={{ width: "100%" }}>
                <Text styles={{ root: { fontWeight: FontWeights.bold } }} variant="mediumPlus">
                    {message}
                </Text>

                <Text
                    styles={{ root: { fontWeight: FontWeights.bold } }}
                    className={isSaving ? "typewriter" : ""}
                    variant="mediumPlus"
                    style={{ width: "20px" }}
                >
                    {" "}
                </Text>
            </Stack>
            <DialogFooter>
                {!loading && (
                    <Stack horizontalAlign="end">
                        <PrimaryButton text="Close" onClick={onClose} />
                    </Stack>
                )}
            </DialogFooter>
        </Dialog>
    );
};

export default SaveModal;

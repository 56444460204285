import React, { useState, useEffect } from "react";
import {
    ComboBox,
    Stack,
    Label,
    TextField,
    PrimaryButton,
    Separator,
    Toggle,
    FontIcon,
    IconButton,
    Text
} from "@fluentui/react";
import { AccessPackageDto, AccessPackagePolicyDetails, IContact } from "../../models";
import { GraphLookupList } from "./GraphLookupList";
import { GraphLookup } from "../common/GraphLookup";
import { AADState } from "./Types";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { accessPackageSelector, subscriptionSelector } from "../../pages/Selectors";
import { getAllSubscriptions } from "../../reducers/Subscription";
import { StateActionType } from "../../types";

import { LoadingOverLay } from "../../components/common/LoadingOverLay";
import {
    AccessPackageComponents,
    clearSecondaryApprovers,
    getPrePopulatedSecondaryApproversAsync,
    validateOwnershipAsync
} from "../../reducers/AccessPackages";
import { PolicyDetails } from "./PolicyDetails";
import { AccessPackageFormMode } from "../../models/Elm/AccessPackageFormMode";
import ToolTippedIconLink from "../common/ToolTippedIconLink";
import { useGetCatalogsQuery } from "../../reducers/GraphElmApi";
import PreferredSelect from "../common/PreferredSelect";
import {
    useGetAccessPackagePolicyDetailsQuery,
    useGetAllowedAccountTypesQuery,
    useGetGroupByIdQuery
} from "../../reducers/AccessPackageApi";
import { handleError } from "./utils";
import StandardForm from "../common/StandardForm";
import { FieldErrorMessage } from "../common/FieldErrorMessage";
import { ShimmeredGraphLookup } from "../common/Shimmered";
import SaveButtonContainer from "../Dataset/SaveButtonContainer";

interface Props {
    mode: AccessPackageFormMode;
    accessPackage?: AccessPackageDto;
    loading?: boolean;
    onSave: (accessPackage: AccessPackageDto) => Promise<void>;
    setError: (error: string) => void;
}

const AccessPackageForm: React.FC<Props> = (props) => {
    const emptyAadState = {
        AADObjectId: "",
        AADEmail: "",
        AADName: "",
        AADType: ""
    };

    const { mode } = props;

    const dispatch = useAppDispatch();
    const { accessPackage, onSave, setError, loading } = props;
    const { data: aadData, isLoading } = useGetGroupByIdQuery(accessPackage?.roleGroupOwnerGroupId, {
        skip: !accessPackage?.roleGroupOwnerGroupId
    });
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string>("");
    const [roleGroupAAD, setRoleGroupAAD] = useState<AADState>(emptyAadState);

    const [selectedCatalogId, setSelectedCatalogId] = useState<string>("");
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [aadState, setAadState] = useState<AADState>(emptyAadState);
    const [firstApproversList, setFirstApproversList] = useState<AADState[]>([]);
    const [secondApproversList, setSecondApproversList] = useState<AADState[]>([]);
    const [displayName, setDisplayName] = useState<string>("");
    const [isRoleGroup, setIsRoleGroup] = useState<boolean>(false);
    const [allowedAccountType, setAllowedAccountType] = useState<string>("");

    const {
        data: allowedAccountTypes,
        error: allowedAccountTypesError,
        isLoading: isAllowedAccountTypesLoading
    } = useGetAllowedAccountTypesQuery();
    const [isMultiDataset, setIsMultiDataset] = useState<boolean>(false);
    const {
        data: defaultPolicyDetails,
        isLoading: policyDetailsLoading,
        error: policyDetailsError
    } = useGetAccessPackagePolicyDetailsQuery(
        {
            catalogId: selectedCatalogId,
            accessPackageId: accessPackage?.accessPackageId ?? "",
            isMigration: mode === AccessPackageFormMode.Migrate
        },
        { skip: shouldSkipPolicyDetails() }
    );

    const [policyDetails, setPolicyDetails] = useState<AccessPackagePolicyDetails | undefined>();
    const [RoleGroupOwnerGroupId, setRoleGroupOwnerGroupId] = useState<string>(
        accessPackage?.roleGroupOwnerGroupId ?? ""
    );
    const { data: catalogs } = useGetCatalogsQuery(undefined, {
        pollingInterval: 3600000
    });
    const {
        entities: subscriptions,
        state: subscriptionState,
        error: subscriptionError
    } = useAppSelector(subscriptionSelector);

    const {
        prePopulatedSecondaryApprovers,
        isOwnershipValid,
        errors: accessPackageErrors,
        loading: accessPackageLoading
    } = useAppSelector(accessPackageSelector);

    useEffect(() => {
        setPolicyDetails(defaultPolicyDetails);
    }, [defaultPolicyDetails]);

    useEffect(() => {
        if (aadData) {
            setRoleGroupAAD({
                AADObjectId: aadData.objectId,
                AADEmail: aadData.email || "",
                AADName: aadData.name || "",
                AADType: aadData.type || ""
            });
        }
    }, [aadData]);
    useEffect(() => {
        if (!isLoading) {
            if (roleGroupAAD.AADObjectId) {
                setRoleGroupOwnerGroupId(roleGroupAAD.AADObjectId);
            } else if (RoleGroupOwnerGroupId && !isLoading) {
                setRoleGroupAAD(emptyAadState);
                setRoleGroupOwnerGroupId("");
            }
        }
    }, [roleGroupAAD.AADObjectId, RoleGroupOwnerGroupId]);

    useEffect(() => {
        if (subscriptionState[StateActionType.LoadAll] === "INIT") {
            dispatch(getAllSubscriptions());
        }

        return () => {
            dispatch(clearSecondaryApprovers());
        };
    }, []);

    useEffect(() => {
        if (accessPackageErrors[AccessPackageComponents.validateOwnership]) {
            setError(accessPackageErrors[AccessPackageComponents.validateOwnership]);
        }
    }, [accessPackageErrors[AccessPackageComponents.validateOwnership]]);

    useEffect(() => {
        if (policyDetailsError) {
            handleError(
                new Error("There was an error getting the Access Package Policy Details"),
                setError,
                policyDetailsError
            );
        }
    }, [policyDetailsError]);
    useEffect(() => {
        if (selectedCatalogId && mode === AccessPackageFormMode.Add) {
            dispatch(getPrePopulatedSecondaryApproversAsync(selectedCatalogId));
        }
    }, [selectedCatalogId]);

    function shouldSkipPolicyDetails(): boolean {
        if (selectedCatalogId && mode === AccessPackageFormMode.Update) {
            if (accessPackage?.accessPackageId) {
                return false;
            }
        } else if (selectedCatalogId) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (prePopulatedSecondaryApprovers) {
            setSecondApproversList(
                prePopulatedSecondaryApprovers.map((x) => ({
                    AADName: x.name,
                    AADObjectId: x.objectId,
                    AADEmail: x.email,
                    AADType: x.type
                }))
            );
        }
    }, [prePopulatedSecondaryApprovers]);

    useEffect(() => {
        if (subscriptionError) {
            setError(subscriptionError);
        } else {
            setError("");
        }
    }, [subscriptionError]);

    useEffect(() => {
        setValidationErrors(getValidationErrors());
    }, [
        selectedCatalogId,
        selectedSubscriptionId,
        aadState.AADObjectId,
        firstApproversList,
        policyDetails,
        validateTeamsGroup(),
        isOwnershipValid
    ]);

    useEffect(() => {
        if (accessPackage) {
            setIsRoleGroup(accessPackage.isRoleGroup);
            setAllowedAccountType(accessPackage.allowedAccountType);
            setIsMultiDataset(accessPackage.isMultiDataset);
            setRoleGroupOwnerGroupId(accessPackage.roleGroupOwnerGroupId ?? "");
            if (accessPackage.subscriptionId) setSelectedSubscriptionId(accessPackage.subscriptionId);
            if (accessPackage.catalogId) setSelectedCatalogId(accessPackage.catalogId);

            if (accessPackage.securityGroupId) {
                setAadState({
                    AADEmail: "",
                    AADName: accessPackage.securityGroupName,
                    AADObjectId: accessPackage.securityGroupId,
                    AADType: "Group"
                });
            }

            setFirstApproversList(
                accessPackage.firstStageApprovers?.map((x) => ({
                    AADName: x.name,
                    AADObjectId: x.objectId,
                    AADEmail: x.email,
                    AADType: x.type
                }))
            );
            setSecondApproversList(
                accessPackage.secondStageApprovers?.map((x) => ({
                    AADName: x.name,
                    AADObjectId: x.objectId,
                    AADEmail: x.email,
                    AADType: x.type
                })) ?? []
            );

            if (accessPackage.name) setDisplayName(accessPackage.name);
        }
    }, [accessPackage]);

    useEffect(() => {
        if (aadState.AADName) {
            const name = aadState.AADName.replace("SIE.", "").replace(".AAD", "");
            setDisplayName(name);
        }
    }, [aadState.AADName]);

    useEffect(() => {
        if (aadState.AADObjectId && selectedSubscriptionId) {
            dispatch(validateOwnershipAsync({ groupId: aadState.AADObjectId, subscriptionId: selectedSubscriptionId }));
        }
    }, [aadState.AADObjectId, selectedSubscriptionId]);

    function validateTeamsGroup() {
        if (!isRoleGroup) return true;

        if (!RoleGroupOwnerGroupId) return false;

        return true;
    }
    function convertAADStateToContact(aad: AADState): IContact {
        return {
            name: aad.AADName,
            email: aad.AADEmail,
            objectId: aad.AADObjectId,
            type: aad.AADType,
            id: 0
        };
    }

    const handleSave = async () => {
        const dto: AccessPackageDto = {
            subscriptionId: selectedSubscriptionId,
            catalogId: selectedCatalogId,
            securityGroupId: aadState.AADObjectId,
            securityGroupName: aadState.AADName,
            firstStageApprovers: firstApproversList.map((x) => convertAADStateToContact(x)),
            secondStageApprovers: secondApproversList.map((x) => convertAADStateToContact(x)),
            name: displayName,
            accessPackageId: accessPackage?.accessPackageId ?? "",
            policies: policyDetails?.accessPackagePolicies ?? [],
            id: accessPackage?.id ?? 0,
            isRoleGroup,
            allowedAccountType,
            roleGroupOwnerGroupId: RoleGroupOwnerGroupId,
            isMultiDataset
        };

        await onSave(dto);
    };
    function getValidationErrors(): string[] {
        let errors: string[] = [];

        if (!selectedCatalogId) {
            errors.push("Catalog is required");
        }
        if (!selectedSubscriptionId) {
            errors.push("Subscription is required");
        }
        if (!aadState.AADObjectId) {
            errors.push("AAD Security Group is required");
        }
        if (!firstApproversList || (firstApproversList && firstApproversList.length === 0)) {
            errors.push("First Approvers is required");
        }
        if (!policyDetails) {
            errors.push("Policy Details are required");
        }
        if (!validateTeamsGroup()) {
            errors.push("Role Group owner is required");
        }
        if (!isOwnershipValid) {
            errors.push("AAD Group is not owned by SipMon MSI");
        }

        return errors;
    }

    return (
        <>
            <LoadingOverLay isOverlayVisible={!!loading || !!isAllowedAccountTypesLoading} />
            <StandardForm>
                {accessPackage && accessPackage.link && (
                    <Stack.Item align="end">
                        <Stack>
                            <Stack.Item align="center">
                                <ToolTippedIconLink
                                    tooltipContent="View in Azure Portal"
                                    iconName="ELM"
                                    link={accessPackage.link}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                )}
                <Stack.Item>
                    <ComboBox
                        label="Subscription"
                        selectedKey={selectedSubscriptionId}
                        placeholder="Select a Subscription"
                        styles={{ root: { width: "100%" } }}
                        options={
                            subscriptions
                                ? subscriptions.map((subscription) => ({
                                      key: subscription.id,
                                      text: subscription.name
                                  }))
                                : []
                        }
                        allowFreeform
                        autoComplete="on"
                        onChange={(_ev, option) => option && setSelectedSubscriptionId(option.key as string)}
                        disabled={!subscriptions}
                    />
                </Stack.Item>
                <Stack.Item>
                    <PreferredSelect
                        entries={catalogs}
                        title="Catalog"
                        selectedEntryId={selectedCatalogId}
                        showLabel
                        urlParam="catalogId"
                        setEntryId={setSelectedCatalogId}
                        disabled={!catalogs || !selectedSubscriptionId || mode === AccessPackageFormMode.Update}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Toggle
                        label="Multi-Dataset"
                        inlineLabel
                        checked={isMultiDataset}
                        onChange={(_ev, checked) => setIsMultiDataset(checked ?? false)}
                    />
                </Stack.Item>
                <Stack.Item>
                    <ComboBox
                        label="Account Type"
                        selectedKey={allowedAccountType}
                        placeholder="Select a Account Type"
                        styles={{ root: { width: "100%" } }}
                        options={
                            allowedAccountTypes
                                ? allowedAccountTypes.map((allowedAccountType) => ({
                                      key: allowedAccountType,
                                      text: allowedAccountType
                                  }))
                                : []
                        }
                        autoComplete="on"
                        onChange={(_ev, option) => option && setAllowedAccountType(option.key as string)}
                        disabled={!allowedAccountTypes}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Toggle
                        label="Role Group"
                        inlineLabel
                        checked={isRoleGroup}
                        onChange={(_ev, checked) => setIsRoleGroup(checked ?? false)}
                    />
                    <FieldErrorMessage
                        errorMessage={
                            validateTeamsGroup() ? undefined : "Comms alias and Access Package Association required"
                        }
                    />
                </Stack.Item>
                {isRoleGroup && (
                    <Stack.Item>
                        <ShimmeredGraphLookup
                            loading={isLoading}
                            txtLabel="Owner Group"
                            formState={roleGroupAAD}
                            setState={setRoleGroupAAD}
                            stateKey="AAD"
                            title="Owner Group"
                            editDisabled={false}
                            type="AllMailEnabledGroups"
                        />
                    </Stack.Item>
                )}

                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        <Label>AAD Security Group</Label>
                        {aadState.AADObjectId && (
                            <Stack.Item>
                                View in Azure
                                <IconButton
                                    role="link"
                                    iconProps={{ iconName: "AAD" }}
                                    target="_blank"
                                    href={`https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Overview/groupId/${aadState?.AADObjectId}`}
                                    title="View AAD Security Group"
                                    ariaLabel="AAD Info"
                                    value="AAD Info"
                                    text="AAD Info"
                                />
                            </Stack.Item>
                        )}
                    </Stack>

                    <GraphLookup
                        formState={aadState}
                        setState={setAadState}
                        stateKey="AAD"
                        title="AAD Security Group"
                        editDisabled={!selectedCatalogId || mode === AccessPackageFormMode.Update}
                        type="AADGroups"
                    />
                </Stack.Item>
                <Stack.Item>
                    <Label required>First Approvers</Label>
                    <GraphLookupList
                        aadState={firstApproversList}
                        setState={setFirstApproversList}
                        title="First Approvers"
                        editDisabled={!displayName}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Label>Second Approvers</Label>
                    <GraphLookupList
                        aadState={secondApproversList}
                        setState={setSecondApproversList}
                        title="Second Approvers"
                        editDisabled={!displayName}
                    />
                </Stack.Item>
                <Stack.Item style={{ marginBottom: "20px" }}>
                    <Label>Name</Label>
                    <TextField
                        disabled={!aadState.AADObjectId}
                        value={displayName}
                        required
                        onChange={(_ev, v) => setDisplayName(v || "")}
                    />
                </Stack.Item>
                <Separator />
                <Stack.Item>
                    <PolicyDetails
                        policyDetails={policyDetails}
                        setPolicyDetails={setPolicyDetails}
                        loading={policyDetailsLoading}
                    />
                </Stack.Item>
                <SaveButtonContainer>
                    <PrimaryButton
                        style={{ marginTop: 10 }}
                        onClick={handleSave}
                        marginHeight={10}
                        text="Save"
                        disabled={validationErrors.length > 0}
                    />
                </SaveButtonContainer>

                {!loading &&
                    !isLoading &&
                    !policyDetailsLoading &&
                    !accessPackageLoading[AccessPackageComponents.validateOwnership] &&
                    validationErrors.length > 0 && (
                        <FieldErrorMessage
                            errorMessage={`Please fix the following errors before saving: ${validationErrors.join(
                                ", "
                            )}`}
                        />
                    )}
            </StandardForm>
        </>
    );
};

export default AccessPackageForm;

import { useState } from "react";

type AssociatePipelineProps = {
    showDialog: boolean;
    minHeight: string;
    width: string;
    onClose: () => void;
};

const initialAssociatePipelineProps = {
    showDialog: false,
    minHeight: "0",
    width: "500px",
    onClose: () => {}
};
const useAssociatePipeline = () => {
    const [associatePipelineProps, setShowDialog] = useState<AssociatePipelineProps>({
        ...initialAssociatePipelineProps,
        onClose: () => {
            setShowDialog((p) => ({ ...p, showDialog: false }));
        }
    });

    return [associatePipelineProps, setShowDialog] as const;
};

export { useAssociatePipeline };

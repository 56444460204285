import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import { getDirAudit, updateDirAudit, getDirAuditById } from "../api/DirectoryAuditApi";

import { getDefaultCases } from "./DefaultCases";
import { IDirectoryAudit } from "../models";

const sliceName = "directoryAudit";

const initialState = getInitialState<IDirectoryAudit, IDirectoryAudit>();

const getAllDirectoryAudits = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getDirAudit();
    return response.data;
});

const getDirectoryAuditById = createAsyncThunk(`${sliceName}/get_by_id`, async (id: string, _thunkAPI) => {
    const response = await getDirAuditById(id);
    return response.data;
});

const updateDirectoryAudit = createAsyncThunk(`${sliceName}/update`, async (dirAudit: IDirectoryAudit, _thunkAPI) => {
    const response = await updateDirAudit(dirAudit);
    return response.data;
});

const directoryAuditSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IDirectoryAudit>(),
    extraReducers: (builder) => {
        builder.addCase(getAllDirectoryAudits.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllDirectoryAudits.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getDirectoryAuditById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(getDirectoryAuditById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadSingle);
        });
        builder.addCase(updateDirectoryAudit.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(updateDirectoryAudit.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Update);
        });
    }
});

export const { reset, error, setCurrent } = directoryAuditSlice.actions;
export { getAllDirectoryAudits, updateDirectoryAudit, sliceName, getDirectoryAuditById };
export const { reducer } = directoryAuditSlice;

import React, { ReactElement, useRef } from "react";
import { Card } from "@fluentui/react-card";
import { IButtonStyles, IconButton, Label, TooltipHost } from "@fluentui/react";

export interface IDashboardItemProps {
    child?: ReactElement;
    tooltip: string;
    label: string;
    width?: string;
}

function DashboardItem(props: IDashboardItemProps) {
    const buttonStyles: IButtonStyles = {
        rootDisabled: { background: "transparent", color: "rgb(0, 120, 212)" },
        rootHovered: { background: "transparent", color: "rgb(0, 120, 212)", cursor: "default" }
    };
    const ref = useRef<HTMLDivElement>(null);
    return (
        <Card
            onFocus={() => ref.current && ref.current.scrollIntoView()}
            ref={ref}
            style={{ backgroundColor: "white", width: props.width || "500px" }}
        >
            <TooltipHost id={"refreshInverval"} content={props.tooltip}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <Label className="ml-2">{props.label}</Label>
                    <IconButton
                        onClick={(e) => e.preventDefault()}
                        styles={buttonStyles}
                        aria-labelledby={"refreshInverval"}
                        iconProps={{ iconName: "info" }}
                    />
                </div>
            </TooltipHost>
            {props.child && props.child}
        </Card>
    );
}

export default DashboardItem;

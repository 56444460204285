import {
    DefaultButton,
    DialogFooter,
    Stack,
    Sticky,
    StickyPositionType,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Toggle,
    TooltipHost,
    ITooltipHostStyles,
    IconButton,
    TextField,
    IComboBox,
    IComboBoxOption,
    StackItem
} from "@fluentui/react";

import React from "react";
import { useState } from "react";
import { EmptyPowerBIReports, IPowerBIReports } from "../../models";
import { pipelineSelector } from "../../pages/Selectors";
import DialogContainerProps from "../../models/dialogProps/DialogContainerProps";
import { ShimmeredComboBox } from "../common/Shimmered/ShimmeredComboBox";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { AddNewReport } from "../../reducers/PowerBIReports";
import CrudStatusDialog from "../common/CrudStatusDialog";
import { useHistory } from "react-router-dom";
interface IFormState extends IPowerBIReports {
    message: string;
}
const dropdownControlStyles = {
    root: {
        minWidth: "150px",
        margin: "0 30px 20px 0",
        maxWidth: "450px"
    }
};

function AddPowerBIReports(props: DialogContainerProps) {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [powerBIReport, setPowerBIReport] = useState<IPowerBIReports>(EmptyPowerBIReports);
    const [selectedKeysForCategory, setSelectedKeysForCategory] = React.useState<string>("");
    const [reportName, setReportName] = React.useState<string>("");
    const [linkName, setLinkName] = React.useState<string>("");
    const [tag, setTag] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [contacts, setContacts] = React.useState<string>("");
    const [repoLink, setRepoLink] = React.useState<string>("");
    const [showAnnouncment, setShowAnnouncement] = React.useState(false);

    const [FormState, setFormState] = useState<IFormState>({
        id: 0,
        name: powerBIReport.name,
        description: powerBIReport.description,
        message: "",
        endpoint: powerBIReport.endpoint,
        link: "",
        tags: "",
        repoLink: "",
        contacts: "",
        category: "",
        sysLastModifiedTime: new Date()
    });

    //handle on change for platform
    const onChangeCBForCategory = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number
    ): void => {
        if (option) {
            setSelectedKeysForCategory(option.key as string);
        }
    };
    const options: IComboBoxOption[] = [
        { key: 'DRI Related', text: 'DRI Related' },
        { key: 'SIP Usage', text: 'SIP Usage' },
        { key: 'SIP AzureDevOps', text: 'SIP AzureDevOps' },
        { key: 'Others', text: 'Others' }       
    ];
    //capture value entered for Report Name
    function handleReportName(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setReportName(newText);
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            } else setReportName("");
        }
    }
    function handleLinkName(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setLinkName(newText);
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            } else setLinkName("");
        }
    }

    function handletag(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setTag(newText);
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            } else setTag("");
        }
    }
    function handleDescription(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setDescription(newText);
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            } else setDescription("");
        }
    }

    function handleContacts(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setContacts(newText);
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            } else setContacts("");
        }
    }

    function handleRepoLink(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setRepoLink(newText);
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            } else setRepoLink("");
        }
    }
    function resetAnnouncement() {
        setShowAnnouncement(false);
        history.push("/powerBIReports");
    }
    return (
        <form id={"create-post-form"} noValidate={true}>
            <span style={{ float: "right" }}>
                <IconButton
                    title="Close"
                    className="routing"
                    onClick={() => history.push("/powerBIReports")}
                    iconProps={{ iconName: "Cancel" }}
                    allowDisabledFocus
                />
            </span>
            <CrudStatusDialog
                showDialog={showAnnouncment}
                title={"Report has been saved Successfully"}
                onClose={() => resetAnnouncement()}
            />

            <Stack>
                <Stack.Item style={{ width: "700px", height: "30px", marginRight: "10px" }}>
                    <TextField
                        label="Report Name:"
                        styles={{ root: { width: 500, paddingBottom: 20 } }}
                        onChange={handleReportName}
                        required
                        value={reportName}
                    ></TextField>
                    <TextField
                        label="Link:"
                        styles={{ root: { width: 500, paddingBottom: 20 } }}
                        onChange={handleLinkName}
                        required
                        value={linkName}
                    ></TextField>
                    <TextField
                        label="Tags:"
                        styles={{ root: { width: 500, paddingBottom: 20 } }}
                        onChange={handletag}
                        required
                        value={tag}
                    ></TextField>
                    <TextField
                        label="Description:"
                        styles={{ root: { width: 500, paddingBottom: 20 } }}
                        onChange={handleDescription}
                        required
                        value={description}
                    ></TextField>
                    <>
                        <TextField
                            label="Contact Details:"
                            styles={{ root: { width: 500, paddingBottom: 20 } }}
                            onChange={handleContacts}
                            required
                            value={contacts}
                        ></TextField>
                        <label>Note: Add contacts separated with semi-colon ';'</label>
                    </>

                    <TextField
                        label="Repo Link:"
                        styles={{ root: { width: 500, paddingBottom: 20 } }}
                        onChange={handleRepoLink}
                        required
                        value={repoLink}
                    ></TextField>
                    <ShimmeredComboBox
                        cbxLabel="Select Category"
                        loading={false}
                        styles={dropdownControlStyles}
                        selectedKey={selectedKeysForCategory}
                        placeholder="Select All"
                        options={options}
                        allowFreeform
                        autoComplete="on"
                        onChange={onChangeCBForCategory}
                        disabled={false}
                    />

                    <PrimaryButton
                        text="Add"
                        type="Button"
                        disabled={!reportName || !linkName}
                        onClick={() => {
                            if (reportName && linkName)
                                dispatch(
                                    AddNewReport({
                                        name: reportName,
                                        link: linkName,
                                        description: description ? description : "NA",
                                        tags: tag ? tag : "NA",
                                        repoLink: repoLink ? repoLink : "NA",
                                        contacts: contacts ? contacts : "NA",
                                        category: selectedKeysForCategory ? selectedKeysForCategory : "NA"
                                    })
                                );
                            setShowAnnouncement(true);
                        }}
                    ></PrimaryButton>
                    <DefaultButton text="Cancel" onClick={() => history.push("/powerBIReports")} />
                </Stack.Item>
            </Stack>
        </form>
    );
}
export default AddPowerBIReports;

import { History } from "history";
import { AdminUser } from "../constants";
import { AzureResource } from "../models";
import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { addSortLabels, renderViewColumn } from "./utils";

export function generateAzureResourceColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            filterBy: true,
            minWidth: 75,
            maxWidth: 250,
            isResizable: true,
            isSorted: true
        },
        {
            key: "type",
            name: "Type",
            fieldName: "type",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "platformName",
            name: "Platform Name",
            fieldName: "platformName",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "resourceGroup",
            name: "Resource Group",
            fieldName: "resourceGroup",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "monitoringStatus",
            name: "Monitoring Status",
            fieldName: "monitoringStatus",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true
        }
    ];
    if (isInRole(AdminUser)) {
        cols.push({
            key: "id",
            name: "View/Edit",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: AzureResource) => renderViewColumn(item, "azureResources", history)
        });
    }

    addSortLabels(cols);
    return cols;
}

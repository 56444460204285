import { FontIcon } from "@fluentui/react";
import React from "react";

export function FlexIcon(props: { iconName: string; title: string; color?: string }) {
    return (
        <span
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                height: "100%",
                flexWrap: "nowrap"
            }}
        >
            <FontIcon
                style={{
                    fontSize: "16px",
                    margin: "0px 6px",
                    color: props.color
                }}
                aria-label={props.title}
                title={props.title}
                iconName={props.iconName}
            />
        </span>
    );
}

import React from "react";
import { Text, Link, mergeStyleSets, IStyle, mergeStyles } from "@fluentui/react";
import { ExtendedTypography } from "@cseo/styles";

function Help() {
    const _Styles = mergeStyleSets({
        helpSampleLink: [
            mergeStyles(ExtendedTypography.size14Semibold),
            {
                margin: "8px 0",
                display: "block"
            }
        ] as IStyle
    });

    const year = new Date().getFullYear();
    return (
        <div>
            <Link href="mailto:ISRMDataPFTeam@microsoft.com" className={_Styles.helpSampleLink}>
                Contact
            </Link>
            <Text>By using DSRE SIP Portal you consent to the collection of Usage Data.</Text>
            <Link
                href="https://privacy.microsoft.com/en-us/privacystatement"
                target="_blank"
                className={_Styles.helpSampleLink}
            >
                Microsoft Privacy Statement
            </Link>
            <Text block>© Microsoft Corporation.</Text>
            <Text block> All rights reserved. {year}</Text>
        </div>
    );
}

export default Help;

import React, { useState } from "react";
import {
    IBasePickerSuggestionsProps,
    NormalPeoplePicker,
    IPersonaProps,
    Announced,
    PeoplePickerItemSuggestion
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { searchAAD, SearchType } from "../../api/ContactApi";
import { listContainsTagList } from "./utils";

export type GraphLookupProps = {
    stateKey: string;
    formState: Record<string, any>;
    setState: React.Dispatch<React.SetStateAction<any>>;
    editDisabled: boolean;
    title: string;
    type?: SearchType;
};
export function GraphLookup(props: GraphLookupProps) {
    const { formState: state, setState, stateKey, editDisabled, title, type } = props;

    const [msg, setMsg] = useState<string>("");
    const pickerId = useId(`${title}-picker`);
    const stateEmailKey = stateKey + "Email";
    const stateNameKey = stateKey + "Name";
    const stateObjectIdKey = stateKey + "ObjectId";
    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: `Suggested ${title}`,
        noResultsFoundText: `No ${title} found`,
        suggestionsContainerAriaLabel: `Suggested ${title}`,
        suggestionsAvailableAlertText: "Suggestions Available",
        loadingText: "Suggestions loading"
    };
    const seachType = type ? type : "All";

    const filterSuggestedTags = async (
        filterText: string,
        searchType: SearchType,
        tagList?: IPersonaProps[]
    ): Promise<IPersonaProps[]> => {
        if (filterText) {
            const resp = await searchAAD(filterText, searchType);
            if (!resp.error) {
                const tags: IPersonaProps[] = resp.data.map((x) => ({
                    secondaryText: x.email || "No Email",
                    text: x.name,
                    itemID: x.objectId
                }));
                const filteredTags = tags.filter((tag) => !listContainsTagList(tag, tagList));
                setMsg(`Loaded ${filteredTags.length} contacts`);
                return filteredTags;
            }
        }
        return [];
    };
    const onRenderSuggestionItem = (personaProps: IPersonaProps, suggestionsProps: IBasePickerSuggestionsProps) => {
        return (
            <PeoplePickerItemSuggestion
                styles={{ personaWrapper: { width: "100%" } }}
                personaProps={personaProps}
                suggestionsProps={suggestionsProps}
            />
        );
    };
    return (
        <>
            <Announced id={`${props.title}_annoucement`} aria-live="assertive" message={msg} />
            <NormalPeoplePicker
                styles={{ root: { background: "#FFFFFF" } }}
                aria-label={title}
                enableSelectedSuggestionAlert={true}
                removeButtonAriaLabel={`Remove ${title}`}
                onChange={(item) => {
                    if (!item || item.length === 0) {
                        setMsg(`Removed ${state[stateNameKey]}`);
                        setState({ ...state, [stateNameKey]: "", [stateEmailKey]: "", [stateObjectIdKey]: "" });
                    } else {
                        setState({
                            ...state,
                            [stateEmailKey]: item[0].secondaryText !== "No Email" ? item[0].secondaryText : "",
                            [stateNameKey]: item[0].text,
                            [stateObjectIdKey]: item[0].itemID
                        });
                    }
                }}
                selectedItems={
                    state[stateNameKey]
                        ? [
                              {
                                  text: state[stateNameKey],
                                  secondaryText: state[stateEmailKey] || "No Email",
                                  itemID: state[stateObjectIdKey],
                                  showSecondaryText: true
                              }
                          ]
                        : []
                }
                resolveDelay={700}
                onResolveSuggestions={(filter, selectedItems) => filterSuggestedTags(filter, seachType, selectedItems)}
                disabled={editDisabled}
                pickerSuggestionsProps={pickerSuggestionsProps}
                itemLimit={1}
                onRenderSuggestionsItem={onRenderSuggestionItem}
                inputProps={{
                    id: pickerId,
                    "aria-label": title
                }}
            />
        </>
    );
}

import { useEffect, useState } from "react";
import StandardList from "../common/StandardList";
import { useAppDispatch } from "../../hooks/ReduxHooks";
import { getAccessRequestsAsync } from "../../reducers/AccessPackages";
import { StateValues } from "../../types";
import { useGetValidationResultsQuery } from "./api";
import { getErrorMessage } from "../AccessPackages/utils";
import { generateDataValidationColumns } from "../../columns/DataValidationColumns";
import { useHistory } from "react-router-dom";
import MenuButton from "./MenuButton";

export function DataValidationView() {
    const dispatch = useAppDispatch();

    const [stateValues, setStateValues] = useState<StateValues>("INIT");
    const [errorMsg, setErrorMsg] = useState<string>();
    const { data, isLoading, error } = useGetValidationResultsQuery();
    const [filteredData, setFilteredData] = useState(data);
    const history = useHistory();
    const [filterLabel, setFilterLabel] = useState("Filter By");
    useEffect(() => {
        document.title = "Summary of ADLS Validation Results - SIP Portal";

        dispatch(getAccessRequestsAsync());
    }, []);

    useEffect(() => {
        if (error) {
            const strError = getErrorMessage(error);
            setErrorMsg(strError);
        } else {
            setErrorMsg(undefined);
        }
    }, [error]);

    useEffect(() => {
        // need to manual set the state since this is a non standard implementation
        if (isLoading) {
            setStateValues("LOADING_ALL");
        }

        if (data && !isLoading) {
            setFilteredData(data);
            setStateValues("LOADED_ALL");
        }
    }, [data, isLoading]);

    return (
        <StandardList
            entities={filteredData}
            title={"ADLS Validation Results"}
            columns={generateDataValidationColumns(history)}
            error={errorMsg}
            entityState={stateValues}
            extraHeaderItems={
                <MenuButton
                    filterLabel={filterLabel}
                    setFilterLabel={setFilterLabel}
                    setFilteredData={setFilteredData}
                    data={data}
                />
            }
        />
    );
}

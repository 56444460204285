import React, { useEffect, useState } from "react";
import StandardForm from "../common/StandardForm";
import { StackRow } from "../common/StackComponents";
import { useHistory, useParams } from "react-router-dom";
import { useGetAssignmentsByUserQuery, useGetAssignmentsQuery, useCloneMutation } from "../../reducers/AssignmentsApi";
import { AccessPackageAssignment } from "../../models";
import {
    ITheme,
    IconButton,
    Label,
    List,
    PrimaryButton,
    Stack,
    TextField,
    getFocusStyle,
    mergeStyleSets,
    useTheme
} from "@fluentui/react";
import { GraphLookup } from "../common/GraphLookup";
import { AADState } from "../AccessPackages/Types";
import SaveButtonContainer from "../Dataset/SaveButtonContainer";
import { CloneRequest } from "../../models/Elm/CloneRequest";
import SaveModal from "../Dataset/SaveModal";
import { getErrorMessage } from "../AccessPackages/utils";

const generateStyles = (theme: ITheme) => {
    const { palette, semanticColors, fonts } = theme;
    return mergeStyleSets({
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 30,
                padding: 10,
                boxSizing: "border-box",
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: "flex",
                selectors: {
                    "&:hover": { background: palette.neutralLight }
                }
            }
        ],

        itemContent: {
            marginLeft: 10,
            overflow: "hidden",
            flexGrow: 1
        },
        itemName: [
            fonts.medium,
            {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
            }
        ]
    });
};
const emptyAadState = {
    AADObjectId: "",
    AADEmail: "",
    AADName: "",
    AADType: ""
};
const CloneAssignment = () => {
    const history = useHistory();
    const { id, catalogId, policy } = useParams<{ id: string; catalogId: string; policy: string }>();
    const [assignments, setAssignments] = useState<AccessPackageAssignment[]>();
    const [comment, setComment] = useState<string>("");
    const [assignmentAccessPackageNames, setAssignmentAccessPackageNames] = useState<Set<string>>(new Set());
    const theme = useTheme();
    const { data } = useGetAssignmentsQuery({ catalogId, policy }, { skip: !catalogId || !policy });
    const [aadState, setAadState] = useState<AADState>(emptyAadState);
    const [commsState, setCommsState] = useState<AADState>(emptyAadState);
    const [error, setError] = useState<string>("");

    const [clone, { isLoading: cloneIsLoading, isError, error: cloneError, isSuccess }] = useCloneMutation();

    const { data: cloneAssignments } = useGetAssignmentsByUserQuery(aadState.AADObjectId, {
        skip: !aadState.AADObjectId
    });

    const [annoucement, setAnnouncement] = useState<{ show: boolean; msg: string; onClose: () => void }>({
        show: false,
        msg: "",
        onClose: () => {}
    });

    useEffect(() => {
        if (data) {
            const assignments = data.filter((a) => a.objectId === id);
            setAssignments(assignments);
        }
    }, [data]);

    useEffect(() => {
        if (cloneAssignments) {
            const names = new Set(cloneAssignments.map((a) => a.accessPackageName));
            setAssignmentAccessPackageNames(new Set<string>(names));
        }
    }, [cloneAssignments]);

    useEffect(() => {
        if (isError) {
            const errorMsg = getErrorMessage(cloneError) || "Error creating clone assignment request";
            setError(errorMsg);
            setAnnouncement({
                show: true,
                msg: "Error creating clone assignment request",
                onClose: () => {
                    setAnnouncement({ show: false, msg: "", onClose: () => {} });
                }
            });
        } else if (isSuccess) {
            setAnnouncement({
                show: true,
                msg: "Assignment requests has been created.",
                onClose: () => {
                    setAnnouncement({ show: false, msg: "", onClose: () => {} });
                    history.replace("/assignmentClones");
                }
            });
        }
    }, [isError, isSuccess]);

    const classNames = React.useMemo(() => generateStyles(theme), [theme]);

    const onRenderCell = React.useCallback(
        (item?: string, _index?: number): JSX.Element => {
            return (
                <div className={classNames.itemCell} data-is-focusable={true}>
                    <div className={classNames.itemContent}>
                        <div className={classNames.itemName}>{item}</div>
                    </div>
                </div>
            );
        },
        [classNames, assignments, cloneAssignments]
    );

    function handleSave() {
        if (aadState.AADObjectId && commsState.AADObjectId && catalogId && comment) {
            const cloneRequest: CloneRequest = {
                spnObjectId: assignments![0].objectId,
                msiObjectId: aadState.AADObjectId,
                commsAlias: commsState.AADEmail,
                catalogId: catalogId,
                comment: comment
            };

            setAnnouncement({
                show: true,
                msg: "Cloning assignment...",
                onClose: () => {
                    setAnnouncement({ show: false, msg: "", onClose: () => {} });
                    history.replace("/assignmentClones");
                }
            });

            clone(cloneRequest);
        }
    }

    function validateForm(): boolean {
        return !aadState.AADObjectId || !commsState.AADObjectId || !comment;
    }

    return (
        <>
            {assignments && (
                <StandardForm widthPercent={90} mediumWidthPercent={90} smallWidthPercent={90}>
                    <Stack.Item align="end" styles={{ root: { marginBottom: "-50px" } }}>
                        <IconButton
                            title="Close"
                            onClick={() => history.replace("/assignmentClones")}
                            iconProps={{ iconName: "Cancel" }}
                            allowDisabledFocus
                        />
                    </Stack.Item>
                    <StackRow>
                        <h2>{assignments[0].displayName}</h2>
                    </StackRow>
                    <SaveModal
                        hidden={!annoucement.show}
                        onClose={annoucement.onClose}
                        successMsg={annoucement.msg}
                        error={error}
                        loading={cloneIsLoading}
                    />
                    <StackRow columnWidth={40}>
                        <Stack>
                            <Label>Comms Alias</Label>
                            <GraphLookup
                                formState={commsState}
                                setState={setCommsState}
                                stateKey="AAD"
                                title="Comm Alias"
                                editDisabled={false}
                                type="AllMailEnabledGroups"
                            />
                        </Stack>
                        <Stack>
                            <Label>Clone Permissions To</Label>
                            <GraphLookup
                                formState={aadState}
                                setState={setAadState}
                                stateKey="AAD"
                                title="Clone Permissions To"
                                editDisabled={false}
                                type="ServicePrincipals"
                            />
                        </Stack>
                    </StackRow>
                    <StackRow>
                        <TextField
                            label="Comment"
                            multiline
                            rows={3}
                            value={comment}
                            onChange={(_e, v) => setComment(v || "")}
                        />
                    </StackRow>
                    {aadState.AADObjectId && assignments && (
                        <StackRow>
                            <Stack>
                                <Label>{aadState.AADName} will be added to the following Access Packages</Label>
                                <List
                                    items={[...assignments]
                                        .filter((x) => !assignmentAccessPackageNames.has(x.accessPackageName))
                                        .sort((a, b) => a.accessPackageName.localeCompare(b.accessPackageName))
                                        .map((x) => x.accessPackageName)}
                                    onRenderCell={onRenderCell}
                                />
                            </Stack>
                        </StackRow>
                    )}

                    <SaveButtonContainer>
                        <PrimaryButton
                            style={{ marginTop: 10 }}
                            onClick={handleSave}
                            marginHeight={10}
                            text="Save"
                            disabled={validateForm()}
                        />
                    </SaveButtonContainer>
                </StandardForm>
            )}
        </>
    );
};
export default CloneAssignment;

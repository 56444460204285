import axios, { getHeaders } from "./SipMonClient";
import { IFeed } from "../models/Feeds";
import { IFeedFreshness } from "../models/FeedFreshness";
import { ISipApiResponse } from "../models/SipApiResponse";
import { sliceName } from "../reducers/Feeds";
import { sliceName as feedFreshnessSliceName } from "../reducers/FeedFreshness";
import { StateActionType } from "../types";
import { FeedDetail } from "../models";
import { FeedAcl } from "../models/FeedAcl";

export const getFeeds = async (): Promise<ISipApiResponse<IFeed[]>> => {
    const { data, status } = await axios.get<IFeed[]>(`api/feeds`, getHeaders(sliceName, StateActionType.LoadAll));
    return { data, message: "", status };
};

export const getFeedDetail = async (id: number): Promise<ISipApiResponse<FeedDetail>> => {
    const { data, status } = await axios.get<FeedDetail>(
        `api/feeds/${id}`,
        getHeaders(sliceName, StateActionType.LoadSingle)
    );
    return { data, message: "", status };
};

export const getFeedAcls = async (id: number): Promise<ISipApiResponse<FeedAcl[]>> => {
    const { data, status } = await axios.get<FeedAcl[]>(`api/feeds/feedAcls/${id}`);
    return { data, message: "", status };
};

export const filterFeedsByAcl = async (objectIdOrName: string): Promise<ISipApiResponse<number[]>> => {
    const { data, status } = await axios.get<number[]>(`api/feeds/filterFeedsByAcls/?objectIdOrName=${objectIdOrName}`);
    return { data, message: "", status };
};
export const getFeedDetails = async (ids: number[]): Promise<ISipApiResponse<FeedDetail[]>> => {
    const { data, status } = await axios.post<FeedDetail[]>(
        `api/feeds/details`,
        ids,
        getHeaders(sliceName, StateActionType.LoadSingle)
    );
    return { data, message: "", status };
};

export const editFeed = async (feed: FeedDetail): Promise<ISipApiResponse<FeedDetail>> => {
    const id = feed.id;
    const { data, status } = await axios.put<FeedDetail>(
        `api/feeds/${id}`,
        feed,
        getHeaders(sliceName, StateActionType.Update)
    );

    return { data, message: "", status };
};

export const editFeeds = async (feeds: FeedDetail[]): Promise<ISipApiResponse<FeedDetail[]>> => {
    const { data, status } = await axios.put<FeedDetail[]>(
        `api/feeds`,
        feeds,
        getHeaders(sliceName, StateActionType.Update)
    );
    return { data, message: "", status };
};

export const getFeedFreshnesses = async (type: number): Promise<ISipApiResponse<IFeedFreshness[]>> => {
    const { data, status } = await axios.get<IFeedFreshness[]>(
        `api/feeds/freshness/${type}`,
        getHeaders(feedFreshnessSliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

export const getFeedFreshness = async (type: number, id: number): Promise<ISipApiResponse<IFeedFreshness>> => {
    const { data, status } = await axios.get<IFeedFreshness>(
        `api/feeds/freshness/${type}/${id}`,
        getHeaders(feedFreshnessSliceName, StateActionType.LoadSingle)
    );
    return { data, message: "", status };
};

export const getFeedFreshnessHistory = async (type: number, id: number): Promise<ISipApiResponse<Date[]>> => {
    const { data, status } = await axios.get<Date[]>(
        `api/feeds/freshnessHistory/${type}/${id}`,
        getHeaders(feedFreshnessSliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

import { IconButton } from "@fluentui/react";
import { IStandardColumn } from "../models/StandardColumn";
import { addSortLabels } from "./utils";
import { IInternalObjectTagging } from "../models";
import InternalObjectTagging from "../pages/InternalObjectTagging";
import { History } from "history";
import store from "../store";
import { deleteInternalObject } from "../reducers/AddInternalObject";
export function generateInternalObjectsColumns(
    history: History,
    setAnnouncement: React.Dispatch<
        React.SetStateAction<{
            show: boolean;
            msg: string;
            onClose: () => void;
        }>
    >): IStandardColumn[] {
    function _onClickDeleteInternalObj(internalobj: IInternalObjectTagging): void {
        if (internalobj.ids && internalobj.ids.length > 0) {
            alert("Category is associated with one or more feeds");
        } else {
            setAnnouncement({
                show: true,
                msg: `Are you sure you want to delete ${internalobj.description}?`,
                onClose: () => {
                    store.dispatch(deleteInternalObject(internalobj));
                    setAnnouncement({ show: false, msg: "", onClose: () => { } });
                }
            });
        }
    }
    function _onRenderDeleteColumn(item: IInternalObjectTagging) {
        return (
            <IconButton
                iconProps={{ iconName: "Delete" }}
                title="Delete"
                ariaLabel="Delete"
                onClick={() => _onClickDeleteInternalObj(item)}
            />
        );
    }


    const cols: IStandardColumn[] = [
        {
            key: "appId",
            name: "AppId",
            fieldName: "appId",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "objectId",
            name: "ObjectId",
            fieldName: "objectId",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "description",
            name: "Description",
            fieldName: "description",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        }
    ];
    cols.push({
        key: "Delete",
        name: "Delete",
        fieldName: "delete",
        minWidth: 50,
        maxWidth: 50,
        isResizable: false,
        isIconOnly: false,
        onRender: (item: IInternalObjectTagging) => _onRenderDeleteColumn(item)
    });
    addSortLabels(cols);
    return cols;
}

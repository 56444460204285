import {
    IconButton,
    Label,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    FontWeights,
    ITag,
    Spinner,
    SpinnerSize,
    Shimmer,
    IDropdownOption
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { DatasetType, ISDWDataset, SdwSchema, SDWSProcParamResponse } from "../../models/Dataset";
import { dataSetSelector } from "../../pages/Selectors";
import { getAccessPackageReferenceAsync } from "../../reducers/AccessPackages";
import {
    addSDWDataset,
    clearResponse,
    DatasetComponents,
    getAllDatasets,
    getAllDatasetTags,
    getAllPartitions,
    getAllServers,
    getDatabasesByServer,
    getDatasetById,
    getSDWDatasetByDSId,
    getSDWResources,
    getSProcParams,
    setCurrentDataset,
    setCurrentSDWDataset
} from "../../reducers/Dataset";
import { DialgContainer } from "../common/DialogContainer";

import { ShimmeredAutoComplete, ShimmeredDropdown, ShimmeredTextField } from "../common/Shimmered";
import { SProcInputPanel } from "./SProcInputPanel";
import { FieldState, anyFieldErrors, getITag, navigateToDataset } from "./utils";
import { PipelineCommandButton } from "../Feed/PipelineCommandButton";
import { getShimmerStyles } from "../../columns/utils";
import { useAssociatePipeline } from "../../hooks";
import StandardForm from "../common/StandardForm";
import { StackRow } from "../common/StackComponents";
import AssociatePipeline from "./AssociatePipeline";
import { DisplayInCatalogToggle } from "./DisplayInCatalogToggle";
import SaveButtonContainer from "./SaveButtonContainer";
import { useGetActionAndId } from "./useGetActionAndId";
import DatasetTagSelection from "./DatasetTagSelection";
import { DataTag } from "../common/AutoCompleteTextField";
import { parseNumberField } from "../common/utils";
import { checkSdwDuplicate, getDateTimeFieldsByEntityApi } from "../../api/DatasetApi";
import { LoadingOverLay } from "../common/LoadingOverLay";
import SaveModal from "./SaveModal";

export function SDWDatasetForm() {
    const { dsId, isAdd, isDatasetAdd } = useGetActionAndId();
    const history = useHistory();
    const dispatch = useAppDispatch();

    const {
        addSDWDatasetResponse,
        datasets,
        sdwDatabases,
        sdwServers,
        sdwResources,
        sProcParamResponse,
        dsErrors,
        currentDataset,
        currentSDWDataset,
        dsLoading
    } = useAppSelector(dataSetSelector);

    const [fieldState, setFieldState] = useState<FieldState>({
        message: "",
        errors: {}
    });

    async function checkDuplicate() {
        if (!currentSDWDataset || !currentSDWDataset.type) return;
        try {
            const isDup = await checkSdwDuplicate(
                currentSDWDataset.id,
                currentSDWDataset.sqlServer,
                currentSDWDataset.database,
                currentSDWDataset.type,
                currentSDWDataset.name
            );
            if (isDup) {
                setFieldState({
                    ...fieldState,
                    errors: { sqlServer: "Server, Database, and Name combination already exists" }
                });
            } else {
                setFieldState({ ...fieldState, errors: { sqlServer: "" } });
            }
        } catch (error) {
            setFieldState({ ...fieldState, errors: { sqlServer: "" } });
            console.error("An error occurred checking duplicates");
            console.error(JSON.stringify(error));
        }
    }

    const [associatePipelineProps, setShowDialog] = useAssociatePipeline();

    const sdwEntityTypes: Record<number, string> = {
        0: "View",
        1: "Stored Procedure",
        2: "Table"
    };

    const [showAnnouncment, setShowAnnouncement] = useState(false);
    const [showSProcModel, setShowSProcModel] = useState<boolean>(false);
    const [successMsg, setSuccessMsg] = useState<string>("");
    const [dsDateTimeFields, setDsDateTimeFields] = useState<IDropdownOption[]>([]);
    const [loadingDateTimeFields, setLoadingDateTimeFields] = useState<boolean>(false);

    // SDW Stored Procedure's Parameter List
    const [sProcParamRes, setSProcParamRes] = useState<SDWSProcParamResponse | undefined>(undefined);

    useEffect(() => {
        dispatch(getAllDatasetTags());
        if (dsId) {
            dispatch(getDatasetById(dsId));
            if (!isAdd) dispatch(getSDWDatasetByDSId(dsId));
        }
    }, [dsId]);

    useEffect(() => { getDateTimeFields(); }, [currentSDWDataset?.id])

    useEffect(() => {
        if (
            currentSDWDataset?.sqlServer &&
            currentSDWDataset?.database &&
            currentSDWDataset?.type &&
            currentSDWDataset?.name
        ) {
            checkDuplicate();
        }
    }, [currentSDWDataset?.sqlServer, currentSDWDataset?.database, currentSDWDataset?.type, currentSDWDataset?.name]);

    useEffect(() => {
        dispatch(getAccessPackageReferenceAsync());
        dispatch(getAllDatasets());
        dispatch(getAllPartitions());
        dispatch(getAllServers());
    }, [getAccessPackageReferenceAsync, getAllDatasets, getAllPartitions, getAllServers]);

    useEffect(() => {
        if (sProcParamResponse) {
            setSProcParamRes(sProcParamResponse);
        }
    }, [sProcParamResponse]);

    useEffect(() => {
        if (currentSDWDataset && currentSDWDataset.database && currentSDWDataset.type !== undefined) {
            dispatch(getSDWResources({ dbName: currentSDWDataset.database, type: currentSDWDataset.type }));
        }
    }, [currentSDWDataset?.type]);

    useEffect(() => {
        if (currentSDWDataset && currentSDWDataset.sqlServer) {
            dispatch(getDatabasesByServer(currentSDWDataset.sqlServer));
        }
    }, [currentSDWDataset?.sqlServer]);

    useEffect(() => {
        if (addSDWDatasetResponse === "Success") {
            setSuccessMsg("Update completed successfully.");
            setShowAnnouncement(true);
        }
    }, [addSDWDatasetResponse]);

    async function getDateTimeFields() {
        setLoadingDateTimeFields(true);
        if (currentSDWDataset != null && currentSDWDataset?.id != 0) {
            // Get list of datatime field from the given entity's schema which will be used to compute metrics like lastRefresh, firstAvailableDate, lastIngestion count. 
            const res = await getDateTimeFieldsByEntityApi(currentSDWDataset.id).then((res) => res);
            if (res != null && res.data) {

                res.data.map((option) => ({
                    key: option,
                    text: option,
                    title: option
                }))

                setDsDateTimeFields(res.data.map((option) => ({key: option,text: option,title: option})));
            }
        }
        setLoadingDateTimeFields(false);
    }
    function onChange<K extends keyof ISDWDataset>(value: any, stateKey: K): void {
        if (!currentSDWDataset) return;

        const update: ISDWDataset = { ...currentSDWDataset };
        if (stateKey === "sqlServer" || stateKey === "database") {
            update.name = "";
            update.type = undefined;
            setFieldState({ ...fieldState, errors: { sqlServer: "" } });
        }
        if (stateKey === "sqlServer") {
            update.database = "";
        }

        if (stateKey === "type") {
            update.name = "";
        }

        if (stateKey === "sqlServer" || stateKey === "database" || stateKey === "type" || stateKey === "name") {
            setFieldState({ ...fieldState, errors: { sqlServer: "" } });
        }
        update[stateKey] = value;

        dispatch(setCurrentSDWDataset(update));
    }

    function isFormValid() {
        if (!currentSDWDataset) return false;
        if (anyFieldErrors(fieldState)) return false;

        if (currentSDWDataset.sqlServer?.trim().length === 0) return false;
        if (currentSDWDataset.database?.trim().length === 0) return false;
        if (!currentSDWDataset.type === undefined) return false;
        if (!currentSDWDataset.pipelineId) return false;
        if (currentSDWDataset.name?.trim().length === 0) return false;

        return true;
    }

    // Get SProc Parameters
    async function getSProcParamsCall() {
        if (!currentSDWDataset) return;

        // Reset panel content
        setSProcParamRes(undefined);
        setShowSProcModel(true);

        dispatch(getSProcParams(currentSDWDataset.name));
    }

    async function submitSDWDatasetDetails(sProcSchema: any = null) {
        if (!currentSDWDataset) return;
        setShowAnnouncement(true);
        setShowSProcModel(false);
        const update = { ...currentSDWDataset, sProcSchemas: sProcSchema};
        dispatch(addSDWDataset(update));
    }

    function resetForm() {
        dispatch(clearResponse("addSDWDatasetResponse"));
        dispatch(clearResponse("sProcParamResponse"));
        dispatch(clearResponse("sProcSchemaResponse"));
        setShowAnnouncement(false);
        navigateToDataset(history, isAdd, dsId);
    }
    function goBackToDataset() {
        if (currentDataset) dispatch(setCurrentDataset({ ...currentDataset, sdwDataset: currentSDWDataset }));

        setSuccessMsg("Sdw dataset has been configured. You must save the dataset or changes will not be saved.");
        setShowAnnouncement(true);
    }
    function cancelAndGoBackToDataset() {
        dispatch(setCurrentSDWDataset(undefined));
        navigateToDataset(history, isAdd, dsId);
    }
    function onChangeDisplayInCatalog(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        if (!currentSDWDataset) return;
        const update = { ...currentSDWDataset, displayInCatalog: checked ? checked : false };
        dispatch(setCurrentSDWDataset(update));
    }

    const getsProcSchemaDetails = (sProcSchema: SdwSchema[]) => {
        if (!currentSDWDataset) return;
        const update = { ...currentSDWDataset, sProcSchemas: sProcSchema };       
        dispatch(setCurrentSDWDataset(update));
        
        //Save changes
        submitSDWDatasetDetails(sProcSchema);
    };

    function setDataSet(tag: DataTag | undefined) {
        if (!currentSDWDataset) return;
        if (!tag) {
            dispatch(setCurrentSDWDataset({ ...currentSDWDataset, datasetId: 0, datasetName: "" }));
            return;
        }
        const updatedFormState = {
            ...currentSDWDataset,
            datasetId: tag.data,
            datasetName: tag.name
        };
        dispatch(setCurrentSDWDataset(updatedFormState));
    }
    function getSProcModelContent() {
        return (
            <>
                {sProcParamRes == undefined ? (
                    <Spinner
                        size={SpinnerSize.small}
                        styles={{ label: { color: "black", fontWeight: FontWeights.semibold } }}
                        label="Please wait, retrieving required parameters!"
                        labelPosition="bottom"
                    />
                ) : (
                    <SProcInputPanel
                        sProcParamsResponse={sProcParamRes}
                        sProcName={currentSDWDataset?.name || ""}
                        sendSProcSchema={getsProcSchemaDetails}
                    />
                )}
            </>
        );
    }

    function getTypes() {
        return (
            Object.entries(sdwEntityTypes)
                .map((entry) => ({ key: `type_${entry[0]}`, data: +entry[0], name: entry[1] }))
                .sort((a, b) => a.name.localeCompare(b.name)) || []
        );
    }

    return (
        <StandardForm widthPercent={70} mediumWidthPercent={90} smallWidthPercent={90}>
            <LoadingOverLay isOverlayVisible={dsLoading[DatasetComponents.currentSDWDataset]} />

            {dsErrors[DatasetComponents.SDWDataset] && (
                <MessageBar messageBarType={MessageBarType.error} title="SDW Dataset Creation Error">
                    {dsErrors[DatasetComponents.SDWDataset]}
                </MessageBar>
            )}
            <SaveModal
                hidden={!showAnnouncment}
                onClose={resetForm}
                successMsg={successMsg}
                error={dsErrors[DatasetComponents.SDWDataset]}
                loading={dsLoading[DatasetComponents.SDWDataset]}
            />
            <Stack.Item align="end" style={{ textAlign: "right" }}>
                <IconButton
                    title="Close"
                    className="routing"
                    onClick={cancelAndGoBackToDataset}
                    iconProps={{ iconName: "Cancel" }}
                    allowDisabledFocus
                />
            </Stack.Item>

            <DisplayInCatalogToggle
                enabled={isAdd || !!currentSDWDataset}
                checked={currentSDWDataset?.displayInCatalog ?? false}
                onChange={onChangeDisplayInCatalog}
            />

            <StackRow columnWidth={40}>
                {!isAdd && (
                    <ShimmeredAutoComplete<DataTag>
                        label="Dataset"
                        loading={!datasets}
                        itemName="datasets"
                        disabled={!datasets || datasets.length === 0}
                        sendSelectedValue={setDataSet}
                        data={
                            datasets
                                ?.map((entry) => ({ key: `dataset_${entry.id}`, data: entry.id, name: entry.name }))
                                .sort((a, b) => a.name.localeCompare(b.name)) || []
                        }
                        val={getITag(currentSDWDataset?.datasetName, currentSDWDataset?.datasetId)}
                        placeHolderText={`Start typing for suggestions`}
                    />
                )}
                <Stack>
                    <Label className="label">Pipeline Name</Label>
                    <Shimmer
                        isDataLoaded={!!currentSDWDataset || isAdd}
                        ariaLabel="Loading Feed Pipeline Name"
                        width="40%"
                        styles={getShimmerStyles()}
                    >
                        <PipelineCommandButton
                            popUpShow={() => setShowDialog({ ...associatePipelineProps, showDialog: true })}
                            pipelineId={currentSDWDataset?.pipelineId!}
                            name={currentSDWDataset?.pipelineName!}
                            editDisabled={false}
                            datafactoryAuthorLink={currentSDWDataset?.datafactoryAuthorLink!}
                        />
                    </Shimmer>
                </Stack>
            </StackRow>
            <StackRow columnWidth={40}>
                <ShimmeredAutoComplete<ITag>
                    label="Server"
                    loading={!sdwServers}
                    itemName="servers"
                    disabled={!sdwServers || sdwServers.length === 0}
                    sendSelectedValue={(val) => onChange(val?.key, "sqlServer")}
                    data={
                        sdwServers
                            ?.map((entry) => ({ key: entry, name: entry }))
                            .sort((a, b) => a.name.localeCompare(b.name)) || []
                    }
                    val={getITag(currentSDWDataset?.sqlServer)}
                    placeHolderText={`Start typing for suggestions`}
                    errorMessage={fieldState.errors.sqlServer}
                />

                <ShimmeredAutoComplete<ITag>
                    label="Database"
                    loading={!isAdd && !currentSDWDataset}
                    itemName="databases"
                    disabled={!sdwDatabases || sdwDatabases.length === 0}
                    sendSelectedValue={(val) => onChange(val?.key, "database")}
                    data={
                        sdwDatabases
                            ?.map((entry) => ({ key: entry, name: entry }))
                            .sort((a, b) => a.name.localeCompare(b.name)) || []
                    }
                    val={getITag(currentSDWDataset?.database)}
                    placeHolderText={`Start typing for suggestions`}
                    errorMessage={fieldState.errors.sqlServer}
                />
            </StackRow>
            <StackRow columnWidth={40}>
                <ShimmeredAutoComplete<DataTag>
                    label="Type"
                    loading={!isAdd && !currentSDWDataset}
                    itemName="types"
                    disabled={!currentSDWDataset?.database}
                    sendSelectedValue={(val) => onChange(val?.data, "type")}
                    data={getTypes()}
                    val={getITag(sdwEntityTypes[currentSDWDataset?.type ?? sdwEntityTypes[0]], currentSDWDataset?.type)}
                    placeHolderText={`Start typing for suggestions`}
                    errorMessage={fieldState.errors.sqlServer}
                />

                <ShimmeredAutoComplete<ITag>
                    label="Name"
                    loading={!isAdd && !currentSDWDataset}
                    itemName="paths"
                    disabled={currentSDWDataset?.type === undefined}
                    sendSelectedValue={(x) => { onChange(x?.name, "name"); if (x?.name != undefined) getDateTimeFields() }}
                    data={sdwResources?.map((entry) => ({ key: entry, name: entry })) || []}
                    val={getITag(currentSDWDataset?.name)}
                    placeHolderText={`Start typing for suggestions`}
                    errorMessage={fieldState.errors.sqlServer}
                />
            </StackRow>
            <StackRow columnWidth={40}>
                <ShimmeredTextField
                    loading={!isAdd && !currentSDWDataset}
                    id="SLAinhours"
                    txtLabel="SLA in hours"
                    required
                    suffix="hrs"
                    isStackItem
                    numeric
                    onChange={(_e, newValue) => {
                        onChange(parseNumberField(newValue), "slaThresholdInHours");
                    }}
                    value={currentSDWDataset?.slaThresholdInHours?.toString() || "24"}
                    errorMessage={fieldState.errors["slaThresholdInHours"]}
                />

                <ShimmeredTextField
                    isStackItem
                    loading={!isAdd && !currentSDWDataset}
                    id="retentionDays"
                    txtLabel="Purge Retention Days"
                    numeric
                    onChange={(_e, newValue) => {
                        onChange(parseNumberField(newValue), "retentionInDays");
                    }}
                    value={currentSDWDataset?.retentionInDays?.toString() || "181"}
                    suffix="days"
                />
            </StackRow>
            <StackRow columnWidth={40}>
                <DatasetTagSelection
                    type={DatasetType.SDW}
                    isLoading={!isAdd && !currentSDWDataset}
                    disabled={!currentDataset}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <ShimmeredDropdown
                        isStackItem
                        loading={loadingDateTimeFields}
                        ddLabel="Get Metrics By"
                        selectedKey={currentSDWDataset?.getMetricsBy}
                        options={dsDateTimeFields}
                        onChange={(_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined,) => { onChange(option ? option.text : "", "getMetricsBy"); }}
                        disabled={currentSDWDataset?.id == 0}
                    />

                    <div>
                        {currentSDWDataset?.id == 0
                            ? <label><b>Note:</b>This feature is not available at the moment because the schema for this dataset has not been populated yet. Please check back later and configure this field to obtain metric details such as Last Refresh and Last Ingestion in DataCatalog.</label>
                            : <label><b>Note:</b> If you failed to configure this field, metrics such as Last Refresh and Last Ingestion will not be available for this dataset in DataCatalog.</label>}
                    </div>
                </div>
            </StackRow>
            <SaveButtonContainer>
                {currentSDWDataset?.type === 1 && !!currentSDWDataset.name ? (
                    <PrimaryButton
                        text={isDatasetAdd ? "Next" : "Save"}
                        type="button"
                        onClick={() => {
                            getSProcParamsCall();
                        }}
                    ></PrimaryButton>
                ) : (
                    <PrimaryButton
                        text={isDatasetAdd ? "Next" : "Save"}
                        type="button"
                        disabled={!isFormValid()}
                        onClick={() => {
                            isDatasetAdd ? goBackToDataset() : submitSDWDatasetDetails();
                        }}
                    ></PrimaryButton>
                )}
                <PrimaryButton text="Close" type="button" onClick={cancelAndGoBackToDataset}></PrimaryButton>
            </SaveButtonContainer>
            <DialgContainer
                {...{
                    showDialog: showSProcModel,
                    onClose: () => {
                        setShowSProcModel(false);
                    },
                    minHeight: "275px",
                    title: "Stored Procedure - Schema"
                }}
            >
                {getSProcModelContent()}
            </DialgContainer>
            {associatePipelineProps.showDialog && (
                <AssociatePipeline datasetName="currentSDWDataset" {...associatePipelineProps} />
            )}
        </StandardForm>
    );
}
export default SDWDatasetForm;

import { PayloadAction } from "@reduxjs/toolkit";

import { StateActionType } from "../types";

export type ErrorPayload = { msg: string; type: StateActionType };

function getDefaultCases<T>() {
    return {
        setCurrent(state, action: PayloadAction<T>) {
            state.currentEntity = action.payload;
        },
        reset(state, action: PayloadAction<StateActionType>) {
            state.state[action.payload] = "INIT";
            state.error = "";
            state.currentEntity = null;
        },
        error(state, action: PayloadAction<ErrorPayload>) {
            state.error = action.payload.msg;
            state.state[action.payload.type] = "ERROR";
        }
    };
}

export { getDefaultCases };

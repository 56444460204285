import { useEffect, useState } from "react";
import "../styles/App.css";
import StandardList from "../components/common/StandardList";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { StateValues } from "../types";
import { accessPackageSelector } from "./Selectors";
import { AccessPackageComponents, getAccessRequestsAsync } from "../reducers/AccessPackages";
import { generateAccessRequestColumns } from "../columns/AccessRequestColumns";
import CrudStatusDialog from "../components/common/CrudStatusDialog";
import { ComboBox, IComboBox, IComboBoxOption } from "@fluentui/react";
import { AccessRequest } from "../models";
function AccessRequestList() {
    const dispatch = useAppDispatch();

    const [stateValues, setStateValues] = useState<StateValues>("INIT");
    const [msg, setMsg] = useState<string>();
    const { accessRequests, loading, errors } = useAppSelector(accessPackageSelector);

    const [filteredAccessRequests, setFilteredAccessRequests] = useState<AccessRequest[] | undefined>(accessRequests);

    const [statusOptions, setStatusOptions] = useState<IComboBoxOption[]>([]);

    function handleStatusFilterChange(
        _event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        _index?: number | undefined,
        _value?: string | undefined
    ) {
        if (option && option.key !== "All") {
            const filtered = accessRequests?.filter((ar) => ar.approvalStatus === option.key);
            setFilteredAccessRequests(filtered);
        } else {
            setFilteredAccessRequests(accessRequests);
        }
    }

    useEffect(() => {
        document.title = "Summary of Access Request - SIP Portal";

        dispatch(getAccessRequestsAsync());
    }, []);

    useEffect(() => {
        // need to manual set the state since this is a non standard implementation
        if (loading[AccessPackageComponents.accessRequests] && !accessRequests) {
            setStateValues("LOADING_ALL");
        }

        if (accessRequests) {
            const uniqueStatuses = new Set(accessRequests.map((ar) => ar.approvalStatus));
            const options = Array.from(uniqueStatuses).map((status) => ({ key: status, text: status }));
            options.unshift({ key: "All", text: "All" });
            setFilteredAccessRequests(accessRequests);
            setStatusOptions(options);
            setStateValues("LOADED_ALL");
        }
    }, [accessRequests, loading[AccessPackageComponents.accessRequests]]);

    return (
        <StandardList
            entities={filteredAccessRequests}
            title={"Access Requests"}
            columns={generateAccessRequestColumns()}
            error={errors[AccessPackageComponents.accessRequests]}
            entityState={stateValues}
            extraHeaderItems={
                <>
                    <ComboBox
                        options={statusOptions}
                        defaultSelectedKey={"All"}
                        placeholder="Filter by Status"
                        onChange={handleStatusFilterChange}
                    />
                    <CrudStatusDialog
                        showDialog={msg !== undefined}
                        title={msg || ""}
                        onClose={() => setMsg(undefined)}
                        onDismiss={() => setMsg(undefined)}
                    />
                </>
            }
        />
    );
}
export default AccessRequestList;

import { ActivityItem, DefaultButton, IActivityItemProps, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useCallback, useState } from "react";
import { addAccessRequestComment } from "../../api/AccessPackageApi";
import { AccessRequest } from "../../models";
import "../../styles/App.css";
import CrudStatusDialog from "../common/CrudStatusDialog";
import Errorbar from "../common/ErrorBar";
import { getAccessRequestByIdAsync } from "../../reducers/AccessPackages";
import { useAppDispatch } from "../../hooks/ReduxHooks";
import { getUserName } from "../../service/authProvider";

export const AccessRequestCommentList = ({ comments, id, editDisabled }: AccessRequest & { editDisabled: boolean }) => {
    const [multiline, { toggle: toggleMultiline, setFalse }] = useBoolean(false);

    const [addStatus, setAddStatus] = useState<"Adding" | "Added" | "Init">("Init");
    const [error, setError] = useState<string>();
    const [comment, setComment] = useState<string>("");
    const dispatch = useAppDispatch();

    const addComment = useCallback(async () => {
        setAddStatus("Adding");
        try {
            const createdBy = getUserName();
            await addAccessRequestComment({ accessRequestId: id, createdBy, comment });
            setAddStatus("Added");
            setComment("");
            // refetch to get the latest comments
            dispatch(getAccessRequestByIdAsync(id));
        } catch (e: any) {
            setError(e.message);
            setAddStatus("Init");
        }
    }, [setAddStatus, comment, id, dispatch, getAccessRequestByIdAsync]);

    const isDisabled = editDisabled || addStatus === "Adding";

    const onChange = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newText: string | undefined
    ): void => {
        if (newText) {
            setComment(newText);
            const newMultiline = newText.length > 50;
            if (newMultiline !== multiline) {
                toggleMultiline();
            }
        } else {
            setComment("");
            setFalse();
        }
    };
    const _generateComments = useCallback(() => {
        const gitHistoryEle: (IActivityItemProps & { key: number; timeStamp: string })[] = comments.map((h, i) => ({
            key: i,
            activityDescription: [
                <span key={1}>
                    <strong>{h.createdBy}</strong>
                </span>
            ],
            activityPersonas: [{ imageUrl: "data:image/jpeg;base64," + h.base64UserImage }],
            timeStamp: new Date(h.lastUpdated).toLocaleString("en-US", {
                timeZoneName: "short"
            }),
            comments: h.comment
        }));
        return gitHistoryEle;
    }, [comments]);

    return (
        <div>
            {_generateComments()
                .sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime())
                .map((item) => (
                    <ActivityItem style={{ padding: "2px" }} {...item} key={item.key} />
                ))}
            <CrudStatusDialog
                showDialog={addStatus === "Added"}
                title={"Comment Added Successfully!"}
                onClose={() => setAddStatus("Init")}
                onDismiss={() => setAddStatus("Init")}
            />
            {error && <Errorbar msg={error} />}
            <Stack tokens={{ childrenGap: 5 }}>
                <Stack.Item>
                    <TextField
                        label="Add Comment"
                        value={comment}
                        multiline={multiline}
                        onChange={onChange}
                        disabled={isDisabled}
                    />
                </Stack.Item>
                <Stack horizontalAlign="end" tokens={{ childrenGap: 5 }} horizontal>
                    <Stack.Item>
                        <PrimaryButton text="Save" onClick={addComment} disabled={isDisabled || !comment} />
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton text="Clear" onClick={() => setComment("")} disabled={isDisabled || !comment} />
                    </Stack.Item>
                </Stack>
            </Stack>
        </div>
    );
};

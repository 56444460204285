import { ITextFieldProps, Label, Shimmer, TextField } from "@fluentui/react";
import { getShimmerStyles } from "../../../columns/utils";
type ShimmeredProps = {
    loading: boolean;
    txtLabel: string;
    width?: string;
    numeric?: boolean;
    isStackItem?: boolean;
} & ITextFieldProps;

function ShimmeredTextField(props: ShimmeredProps) {
    //only allow numbers
    function onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        if (props.numeric) {
            const charCode = e.key;
            if (charCode !== "." && (charCode < "0" || charCode > "9")) {
                e.preventDefault();
            }
        }
    }

    return (
        <div className={props.isStackItem ? "" : "col"}>
            <Label className="label"> {props.txtLabel} </Label>
            <Shimmer isDataLoaded={!props.loading} ariaLabel={`Loading ${props.txtLabel}`} styles={getShimmerStyles()}>
                <TextField onKeyPress={onKeyPress} ariaLabel={props.txtLabel} {...props} />
            </Shimmer>
        </div>
    );
}

export { ShimmeredTextField };

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { onComplete, getInitialState, StateActionType, onLoading } from "../types";

import { getDefaultCases } from "./DefaultCases";
import { ISourceOwner } from "../models";
import { createSourceOwner } from "../api/SourceOwnerApi";


const sliceName = "sourceowners";

const initialState = getInitialState<ISourceOwner, ISourceOwner>();

const addSourceOwner = createAsyncThunk(
    `${sliceName}/create`,
    async (props: { sourceOwnerID: number; sourceOwnerName: string; contactMethodID: number; sourceOwnerValue: string;}, _thunkAPI) => {
        const response = await createSourceOwner(props.sourceOwnerID, props.sourceOwnerName, props.contactMethodID, props.sourceOwnerValue);
        return response.data;
    }
);

const sourceOwnersSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<ISourceOwner>(),
    extraReducers: (builder) => {
        builder.addCase(addSourceOwner.pending, (state, _action) => {
            onLoading(state, StateActionType.Add);
        });
        builder.addCase(addSourceOwner.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Add);
        });
    }
});

export const { reset, error, setCurrent } = sourceOwnersSlice.actions;
export { addSourceOwner, sliceName };
export const { reducer } = sourceOwnersSlice;

import axios, { getHeaders } from "./SipMonClient";
import { IPipeline, ISipApiResponse, IChangeHistory } from "../models";
import { sliceName } from "../reducers/Pipelines";
import { StateActionType } from "../types";

export const getPipelines = async (): Promise<ISipApiResponse<IPipeline[]>> => {
    const { data, status } = await axios.get<IPipeline[]>(
        `api/pipelines`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};
export const getPipeline = async (id: number): Promise<ISipApiResponse<IPipeline>> => {
    const { data, status } = await axios.get<IPipeline>(
        `api/pipelines/${id}`,
        getHeaders(sliceName, StateActionType.LoadSingle)
    );
    return { data, message: "", status };
};

export const editPipeline = async (values: IPipeline): Promise<ISipApiResponse<IPipeline>> => {
    const id = values.id;
    const { data, status } = await axios.put<IPipeline>(
        `api/pipelines/${id}`,
        values,
        getHeaders(sliceName, StateActionType.Update)
    );

    return { data, message: "", status };
};

export const getPipelineHistory = async (id: number): Promise<ISipApiResponse<IChangeHistory[]>> => {
    const { data, status } = await axios.get<IChangeHistory[]>(`api/Pipelines/githistory/${id}`);

    return { data, message: "", status };
};
export const createPipeline = async (
    datafactoryId: number,
    pipelineName: string,
    isProducer: boolean,
    gitBranch: string
): Promise<ISipApiResponse<IPipeline>> => {
    const { data, status } = await axios.post<IPipeline>(
        `api/pipelines`,
        { name: pipelineName, datafactoryId, isProducer, gitBranch },
        getHeaders(sliceName, StateActionType.Add)
    );
    return { data, message: "", status };
};

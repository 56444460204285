export interface ISubscription {
    id: string;
    name: string;
    pipelineId: number;
    isMonitoringEnabled: boolean;
    lastUpdated?: Date;
    sysCreatedTime: Date;
    platformId: number;
}

export const EmptySubscription: ISubscription = {
    id: "",
    name: "",
    pipelineId: 0,
    isMonitoringEnabled: false,
    sysCreatedTime: new Date(),
    platformId: 0
};

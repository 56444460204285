import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInitialState, onComplete, onLoading, StateActionType } from "../types";
import { getDataFactories, getDataFactory, editDataFactory } from "../api/DataFactoryApi";
import { IDataFactory } from "../models";
import { getDefaultCases } from "./DefaultCases";

const sliceName = "datafactories";

const getAllDatafactories = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getDataFactories();
    return response.data;
});

const getDatafactoryById = createAsyncThunk(`${sliceName}/get_by_id`, async (id: number, _thunkAPI) => {
    const response = await getDataFactory(id);
    return response.data;
});

const updateDatafactory = createAsyncThunk(`${sliceName}/update`, async (dataFactory: IDataFactory, _thunkAPI) => {
    const response = await editDataFactory(dataFactory);
    return response.data;
});

const initialState = getInitialState<IDataFactory, IDataFactory>();

// Then, handle actions in your reducers:
const datafactoriesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IDataFactory>(),
    extraReducers: (builder) => {
        builder.addCase(getAllDatafactories.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllDatafactories.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getDatafactoryById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(getDatafactoryById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadSingle);
        });
        builder.addCase(updateDatafactory.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(updateDatafactory.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Update);
        });
    }
});

export const { reset, error, setCurrent } = datafactoriesSlice.actions;
export { getAllDatafactories, getDatafactoryById, updateDatafactory, sliceName };
export const { reducer } = datafactoriesSlice;

import React, { useEffect, useRef } from "react";
import "../styles/App.css";
import { dataSourceSelector } from "./Selectors";
import StandardList from "../components/common/StandardList";
import { generateDataSourceColumns } from "../columns";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { StateActionType } from "../types";
import { getAllDataSources } from "../reducers/DataSource";
import { PrimaryButton, Stack } from "@fluentui/react";

function DataSourceList() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {
        entities: dataSources,
        error: dataSourcesError,
        state: dataSourcesState
    } = useAppSelector(dataSourceSelector);
    const addButtonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.title = "Summary of Data Sources - SIP Portal";
        if (dataSourcesState[StateActionType.LoadAll] !== "LOADED_ALL") {
            dispatch(getAllDataSources());
        }
    }, []);
    return (
        <StandardList
            entities={
                dataSources &&
                dataSources.map((e) => ({
                    ...e
                }))
            }
            title={"Data Sources"}
            columns={generateDataSourceColumns(history)}
            error={dataSourcesError}
            entityState={dataSourcesState[StateActionType.LoadAll]}
            extraHeaderItems={
                <div
                    ref={addButtonRef}
                    onFocus={() => {
                        addButtonRef.current &&
                            addButtonRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                                inline: "start"
                            });
                    }}
                >
                    <Stack.Item grow align="center">
                        <PrimaryButton
                            text="Add New Data Source"
                            onClick={() => {
                                history.push(`datasources/new`);
                            }}
                            allowDisabledFocus
                        />
                    </Stack.Item>
                </div>
            }
        />
    );
}
export default DataSourceList;

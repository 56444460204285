import { keyframes, mergeStyles } from "@fluentui/react";
import React from "react";

const fadeInOut = keyframes({
    "0%": {
        opacity: 0,
        transform: "translate3d(0px, -20px, 0px)",
        pointerEvents: "none"
    },

    "100%": {
        opacity: 1,
        transform: "translate3d(0px, 0px, 0px)",
        pointerEvents: "auto"
    }
});

const styles = mergeStyles({
    animationName: fadeInOut,
    animationDuration: "0.367s",
    animationTimingFunction: "cubic-bezier(0.1, 0.9, 0.2, 1)",
    animationFillMode: "both",
    fontFamily:
        "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
    WebkitFontSmoothing: "antialiased",
    fontSize: "12px",
    fontWeight: 400,
    color: "rgb(164, 38, 44)",
    margin: "0px",
    paddingTop: "5px",
    display: "flex",
    alignItems: "center"
});

export const FieldErrorMessage = ({ errorMessage }: { errorMessage?: string }) => {
    return (
        <div style={{ display: errorMessage ? "inherit" : "none" }} role="alert">
            <p className={`ms-TextField-errorMessage ${styles}`}>
                <span data-automation-id="error-message">{errorMessage}</span>
            </p>
        </div>
    );
};

import { ITag, Label, Shimmer, Stack } from "@fluentui/react";
import { getShimmerStyles } from "../../columns/utils";
import { DatasetType } from "../../models";
import { AutoCompleteTextField } from "../common/AutoCompleteTextField";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { dataSetSelector } from "../../pages/Selectors";
import { setDatasetTags } from "../../reducers/Dataset";

const DatasetTagSelection = ({
    isLoading,
    disabled,
    type
}: {
    isLoading: boolean;
    disabled: boolean;
    type: DatasetType;
}) => {
    const { datasetTags, currentDataset } = useAppSelector(dataSetSelector);
    const dispatch = useAppDispatch();
    function setSelectedTags(tags: ITag[] | undefined) {
        if (tags) dispatch(setDatasetTags({ tags: tags.map((x) => x.name), type }));
        else dispatch(setDatasetTags({ tags: [], type }));
    }
    return (
        <Stack>
            <Label className="label">Tags</Label>
            <Shimmer isDataLoaded={!isLoading} ariaLabel="Loading Tags" height={32} styles={getShimmerStyles()}>
                <AutoCompleteTextField<ITag[]>
                    itemName="tags"
                    disabled={disabled}
                    allowFreeform
                    multiSelect
                    sendSelectedValue={setSelectedTags}
                    data={datasetTags?.map((x) => ({ key: x.name, name: x.name })) || []}
                    val={currentDataset?.datasetTags
                        ?.filter((x) => x.type === type)
                        .map((x): ITag => ({ key: x.name, name: x.name }))}
                    placeHolderText={`Start typing for suggestions`}
                />
            </Shimmer>
        </Stack>
    );
};

export default DatasetTagSelection;

import { PayloadAction, createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
    createAdlsDataset,
    createDataset,
    createKustoDataset,
    createSDWDataset,
    getADLSDataset,
    getDataset,
    getDatasets,
    getDatasetTags,
    getAdlsAutoDiscoveryEntries,
    getKustoClusters,
    getKustoDatabases,
    getKustoDataset,
    getKustoTables,
    getPartitions,
    getSDWDatabases,
    getSDWDataset,
    getSDWResourceList,
    getServerDetails,
    getSProcParamsApi,
    getSProcSchemaApi,
    getStorageAccounts,
    putDataset
} from "../api/DatasetApi";

import { IFeedPartition, DatasetPipelineInfo } from "../models";
import {
    IAdlsDataset,
    IDataset,
    IDatasetAssociation,
    IDatasetTag,
    IKustoCluster,
    IKustoDatabase,
    IKustoDataset,
    ISDWDataset,
    SDWSProcParamResponse,
    SDWSProcSchemaResponse,
    SProcSchemaRequest,
    StorageAccount,
    DatasetType,
    EmptyAdlsDataset,
    EmptyDataset,
    EmptyKustoDataset,
    EmptySDWDataset,
    AdlsAutoDiscoveryEntry,
    IDatasetAlert
} from "../models/Dataset";
import { getDefaultCases } from "./DefaultCases";

const sliceName = "dataSets";

export enum DatasetComponents {
    Dataset,
    AdlsDataset,
    SDWDataset,
    KustoDataset,
    Datasets,
    DatasetTags,
    currentDataset,
    currentADLSDataset,
    currentKustoDataset,
    currentSDWDataset,
    StorageAccounts,
    Partitions,
    SDWServers,
    SDWDatabases,
    SDWResources,
    KustoClusters,
    KustoDatabases,
    KustoTables,
    DatasetPaths,
    sProcParamResponse,
    sProcSchemaResponse
}

export type DatasetState = {
    datasets?: IDataset[];
    datasetTags?: IDatasetTag[];
    originalDatasetTags: IDatasetTag[];
    currentDataset?: IDataset;
    currentADLSDataset?: IAdlsDataset;
    currentKustoDataset?: IKustoDataset;
    currentSDWDataset?: ISDWDataset;
    storageAccounts?: StorageAccount[];
    partitions?: IFeedPartition[];
    adlsAutoDiscoveryEntries?: AdlsAutoDiscoveryEntry[];
    sdwDatabases?: string[];
    sdwServers?: string[];
    sdwResources?: string[];
    kustoClusters?: IKustoCluster[];
    kustoDatabases?: IKustoDatabase[];
    kustoTables?: string[];
    sProcParamResponse?: SDWSProcParamResponse;
    sProcSchemaResponse?: SDWSProcSchemaResponse;
    addDatasetResponse?: number;
    updateDatasetResponse?: string;
    addADLSDatasetResponse?: string;
    addSDWDatasetResponse?: string;
    addKustoDatasetResponse?: string;
    dsErrors: Record<DatasetComponents, string>;
    dsLoading: Record<DatasetComponents, boolean>;
    datasetAssociation: IDatasetAssociation[];
    datasetAlerts: IDatasetAlert[];
    selectedTab?: string;
};

const initialState: DatasetState = {
    dsErrors: {
        [DatasetComponents.StorageAccounts]: "",
        [DatasetComponents.Datasets]: "",
        [DatasetComponents.DatasetTags]: "",
        [DatasetComponents.currentDataset]: "",
        [DatasetComponents.currentADLSDataset]: "",
        [DatasetComponents.currentKustoDataset]: "",
        [DatasetComponents.currentSDWDataset]: "",
        [DatasetComponents.Partitions]: "",
        [DatasetComponents.SDWDatabases]: "",
        [DatasetComponents.SDWServers]: "",
        [DatasetComponents.KustoClusters]: "",
        [DatasetComponents.KustoDatabases]: "",
        [DatasetComponents.KustoTables]: "",
        [DatasetComponents.SDWResources]: "",
        [DatasetComponents.AdlsDataset]: "",
        [DatasetComponents.KustoDataset]: "",
        [DatasetComponents.SDWDataset]: "",
        [DatasetComponents.Dataset]: "",
        [DatasetComponents.DatasetPaths]: "",
        [DatasetComponents.sProcParamResponse]: "",
        [DatasetComponents.sProcSchemaResponse]: ""
    },
    dsLoading: {
        [DatasetComponents.Dataset]: false,
        [DatasetComponents.StorageAccounts]: false,
        [DatasetComponents.SDWDatabases]: false,
        [DatasetComponents.SDWServers]: false,
        [DatasetComponents.SDWResources]: false,
        [DatasetComponents.Partitions]: false,
        [DatasetComponents.KustoClusters]: false,
        [DatasetComponents.KustoDatabases]: false,
        [DatasetComponents.KustoTables]: false,
        [DatasetComponents.Datasets]: false,
        [DatasetComponents.DatasetTags]: false,
        [DatasetComponents.currentDataset]: false,
        [DatasetComponents.currentADLSDataset]: false,
        [DatasetComponents.currentKustoDataset]: false,
        [DatasetComponents.currentSDWDataset]: false,
        [DatasetComponents.AdlsDataset]: false,
        [DatasetComponents.SDWDataset]: false,
        [DatasetComponents.KustoDataset]: false,
        [DatasetComponents.DatasetPaths]: false,
        [DatasetComponents.sProcParamResponse]: false,
        [DatasetComponents.sProcSchemaResponse]: false
    },
    datasetAssociation: [],
    originalDatasetTags: [],
    datasetAlerts: []
};

function getCurrentDatasetByType(state: DatasetState, datasetType: DatasetType) {
    switch (datasetType) {
        case DatasetType.Adls:
            return state.currentADLSDataset;
        case DatasetType.Kusto:
            return state.currentKustoDataset;
        case DatasetType.SDW:
            return state.currentSDWDataset;
    }
}

// Data Retrieval
const getAllDatasets = createAsyncThunk(`${sliceName}/get_all_datasets`, async () => {
    const response = await getDatasets();
    return response.data;
});

// Dataset Tags Retieval
const getAllDatasetTags = createAsyncThunk(`${sliceName}/get_all_datasets_tags`, async () => {
    const response = await getDatasetTags();
    return response.data;
});

// Dataset Retrieval by Id
const getDatasetById = createAsyncThunk(`${sliceName}/get_dataset_by_id`, async (dsID: number) => {
    const response = await getDataset(dsID);
    return response.data;
});

// ADLS Dataset Retrieval by Dataset Id
const getAdlsDatasetByDSId = createAsyncThunk(`${sliceName}/get_adls_dataset_by_dsid`, async (dsID: number) => {
    const response = await getADLSDataset(dsID);
    return response.data;
});

// Kusto Dataset Retrieval by Dataset Id
const getKustoDatasetByDSId = createAsyncThunk(`${sliceName}/get_kusto_dataset_by_dsid`, async (dsID: number) => {
    const response = await getKustoDataset(dsID);
    return response.data;
});

// SDW Dataset Retrieval by Dataset Id
const getSDWDatasetByDSId = createAsyncThunk(`${sliceName}/get_sdw_dataset_by_dsid`, async (dsID: number) => {
    const response = await getSDWDataset(dsID);
    return response.data;
});

const getAllPartitions = createAsyncThunk(`${sliceName}/get_all_partitions`, async () => {
    const response = await getPartitions();
    return response.data;
});

const getAllServers = createAsyncThunk(`${sliceName}/get_all_servers`, async () => {
    const response = await getServerDetails();
    return response.data;
});

const getDatabasesByServer = createAsyncThunk(`${sliceName}/get_all_databases`, async (serverName: string) => {
    const response = await getSDWDatabases(serverName);
    return response.data;
});

const getAllKustoClusters = createAsyncThunk(`${sliceName}/get_all_kusto_clusters`, async () => {
    const response = await getKustoClusters();
    return response.data;
});

const getAllKustoDatabases = createAsyncThunk(`${sliceName}/get_all_kusto_databases`, async (clusterID: number) => {
    const response = await getKustoDatabases(clusterID);
    return response.data;
});

const getAllKustoTables = createAsyncThunk(`${sliceName}/get_all_kusto_tables`, async (dbId: number) => {
    const response = await getKustoTables(dbId);
    return response.data;
});

const getAllStorageAccounts = createAsyncThunk(
    `${sliceName}/get_all_storage_account`,
    async (subscriptionId: string) => {
        const response = await getStorageAccounts(subscriptionId);
        return response.data;
    }
);

const getAdlsAutoDiscoveryEntriesAsync = createAsyncThunk(
    `${sliceName}/get_directory_list`,
    async (props: { subID: string; storageID: number }) => {
        const response = await getAdlsAutoDiscoveryEntries(props.subID, props.storageID);
        return response.data;
    }
);

const getSDWResources = createAsyncThunk(
    `${sliceName}/get_sdwresource_list`,
    async (props: { dbName: string; type: number }) => {
        const response = await getSDWResourceList(props.dbName, props.type);
        return response.data;
    }
);

// Dataset - Creation

const addDataset = createAsyncThunk(`${sliceName}/create_dataset`, async (formData: IDataset) => {
    const response = await createDataset(formData);
    return response.data;
});

const updateDataset = createAsyncThunk(`${sliceName}/update_dataset`, async (formData: IDataset) => {
    const response = await putDataset(formData);
    return response.data;
});

// ADLS Dataset - Creation

const addAdlsDataset = createAsyncThunk(`${sliceName}/create_adls_dataset`, async (formData: IAdlsDataset) => {
    const response = await createAdlsDataset(formData);
    return response.data;
});

// SDW Dataset - Creation

const addSDWDataset = createAsyncThunk(`${sliceName}/create_sdw_dataset`, async (formData: ISDWDataset) => {
    const response = await createSDWDataset(formData);
    return response.data;
});

const getSProcParams = createAsyncThunk(`${sliceName}/get_sproc_param_List`, async (sprocName: string) => {
    const response = await getSProcParamsApi(sprocName);
    return response.data;
});

const getSProcSchema = createAsyncThunk(`${sliceName}/get_sproc_schema`, async (sProcSchemaReq: SProcSchemaRequest) => {
    const response = await getSProcSchemaApi(sProcSchemaReq);
    return response.data;
});

// Kusto Dataset - Creation

const addKustoDataset = createAsyncThunk(`${sliceName}/create_kusto_dataset`, async (formData: IKustoDataset) => {
    const response = await createKustoDataset(formData);
    return response.data;
});

export type ResponseType =
    | "addADLSDatasetResponse"
    | "addDatasetResponse"
    | "addKustoDatasetResponse"
    | "addSDWDatasetResponse"
    | "sProcParamResponse"
    | "updateDatasetResponse"
    | "sProcSchemaResponse";
export type DatasetName = "currentADLSDataset" | "currentSDWDataset" | "currentKustoDataset";
// Then, handle actions in your reducers:
const dataSetSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        ...getDefaultCases<IDataset>(),
        setPipelineAssociation: (
            state,
            action: PayloadAction<{
                datasetName: DatasetName;
                pipelineId?: number;
                activityName?: string;
                pipelineName?: string;
                datafactoryAuthorLink?: string;
            }>
        ) => {
            if (state[action.payload.datasetName]) {
                const currentDataset = state[action.payload.datasetName] as DatasetPipelineInfo;
                currentDataset.pipelineId = action.payload.pipelineId;
                currentDataset.activityName = action.payload.activityName;
                currentDataset.pipelineName = action.payload.pipelineName;
                currentDataset.datafactoryAuthorLink = action.payload.datafactoryAuthorLink;
            }
        },
        setCurrentAdlsDataset: (state, action: PayloadAction<IAdlsDataset | undefined>) => {
            if (!action.payload) return;
            state.currentADLSDataset = { ...action.payload, tagChanges: { add: [], delete: [] } };
        },
        setCurrentKustoDataset: (state, action: PayloadAction<IKustoDataset | undefined>) => {
            if (!action.payload) return;
            state.currentKustoDataset = { ...action.payload, tagChanges: { add: [], delete: [] } };
        },
        setCurrentSDWDataset: (state, action: PayloadAction<ISDWDataset | undefined>) => {
            if (!action.payload) return;
            state.currentSDWDataset = { ...action.payload, tagChanges: { add: [], delete: [] } };
        },
        setCurrentDataset: (state, action: PayloadAction<IDataset | undefined>) => {
            if (!action.payload) return;
            state.currentDataset = action.payload;
            state.originalDatasetTags = action.payload.datasetTags;
        },
        resetDatasets: (state) => {
            state.currentADLSDataset = EmptyAdlsDataset;
            state.currentDataset = EmptyDataset;
            state.currentKustoDataset = EmptyKustoDataset;
            state.currentSDWDataset = EmptySDWDataset;
            state.originalDatasetTags = [];
            state.datasetAssociation = [];
            state.datasetAlerts = [];
            state.adlsAutoDiscoveryEntries = [];
        },
        clearResponse: (state, action: PayloadAction<ResponseType>) => {
            state[action.payload] = undefined;
        },
        setDatasetAssociation: (state, action: PayloadAction<IDatasetAssociation[]>) => {
            state.datasetAssociation = action.payload;
        },
        setDatasetAlert: (state, action: PayloadAction<IDatasetAlert[]>) => {
            state.datasetAlerts = action.payload;
        },
        setDatasetTags: (state, action: PayloadAction<{ tags: string[]; type: DatasetType }>) => {
            // get diff on tags between current and new for current dataset
            const current = getCurrentDatasetByType(state, action.payload.type);

            if (current && state.currentDataset) {
                const type = action.payload.type;
                const newTags = action.payload.tags;
                const currentFilteredTags = state.currentDataset.datasetTags.filter((x) => x.type !== type);

                state.currentDataset.datasetTags = [
                    ...currentFilteredTags,
                    ...newTags.map((tag) => ({ name: tag, type: type }))
                ];

                const addedTags = newTags.filter(
                    (tag) =>
                        !state.originalDatasetTags
                            .filter((x) => x.type === type)
                            .map((x) => x.name)
                            .includes(tag)
                );
                const removedTags = state.originalDatasetTags
                    .filter((x) => x.type === type)
                    .map((x) => x.name)
                    .filter((tag) => !newTags.includes(tag));

                current.tagChanges.add = addedTags;
                current.tagChanges.delete = removedTags;
            }
        },
        setSelectedTab: (state, action: PayloadAction<string>) => {
            state.selectedTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        //Retrive Storage Accounts by subscription
        builder.addCase(getAllStorageAccounts.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.StorageAccounts] = true;
            state.dsErrors[DatasetComponents.StorageAccounts] = "";
        });
        builder.addCase(getAllStorageAccounts.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.StorageAccounts] = false;
            state.storageAccounts = _action.payload;
        });
        builder.addCase(getAllStorageAccounts.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.StorageAccounts] = false;
            state.dsErrors[DatasetComponents.StorageAccounts] =
                "Error occured while trying to fetch the storage accounts";
        });

        //Retrive Datasets
        builder.addCase(getAllDatasets.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.Datasets] = true;
            state.dsErrors[DatasetComponents.Datasets] = "";
        });
        builder.addCase(getAllDatasets.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.Datasets] = false;
            state.datasets = _action.payload;
        });
        builder.addCase(getAllDatasets.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.Datasets] = false;
            state.dsErrors[DatasetComponents.Datasets] = "Error occured while trying to fetch the datasets";
        });

        //Retrive Dataset by ID
        builder.addCase(getDatasetById.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.currentDataset] = true;
            state.dsErrors[DatasetComponents.currentDataset] = "";
        });
        builder.addCase(getDatasetById.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.currentDataset] = false;
            state.currentDataset = _action.payload;
        });
        builder.addCase(getDatasetById.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.currentDataset] = false;
            state.dsErrors[DatasetComponents.currentDataset] = "Error occured while trying to fetch the dataset";
        });

        //Retrive Datasets
        builder.addCase(getAllDatasetTags.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.DatasetTags] = true;
            state.dsErrors[DatasetComponents.DatasetTags] = "";
        });
        builder.addCase(getAllDatasetTags.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.DatasetTags] = false;
            state.datasetTags = _action.payload;
        });
        builder.addCase(getAllDatasetTags.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.DatasetTags] = false;
            state.dsErrors[DatasetComponents.DatasetTags] = "Error occured while trying to fetch the dataset tags";
        });

        //Retrive ADLS Dataset by dataset ID
        builder.addCase(getAdlsDatasetByDSId.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.currentADLSDataset] = true;
            state.dsErrors[DatasetComponents.currentADLSDataset] = "";
        });
        builder.addCase(getAdlsDatasetByDSId.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.currentADLSDataset] = false;
            state.currentADLSDataset = { ..._action.payload, tagChanges: { add: [], delete: [] } };
        });
        builder.addCase(getAdlsDatasetByDSId.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.currentADLSDataset] = false;
            state.dsErrors[DatasetComponents.currentADLSDataset] =
                "Error occured while trying to fetch the adls dataset";
        });

        //Retrive Partitions
        builder.addCase(getAllPartitions.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.Partitions] = true;
            state.dsErrors[DatasetComponents.Partitions] = "";
        });
        builder.addCase(getAllPartitions.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.Partitions] = false;
            state.partitions = _action.payload;
        });
        builder.addCase(getAllPartitions.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.Partitions] = false;
            state.dsErrors[DatasetComponents.Partitions] = "Error occured while trying to fetch the partitions";
        });

        //Retrive SDW Database details
        builder.addCase(getDatabasesByServer.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWDatabases] = true;
            state.dsErrors[DatasetComponents.SDWDatabases] = "";
        });
        builder.addCase(getDatabasesByServer.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWDatabases] = false;
            state.sdwDatabases = _action.payload;
        });
        builder.addCase(getDatabasesByServer.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWDatabases] = false;
            state.dsErrors[DatasetComponents.SDWDatabases] = "Error occured while trying to fetch the Database details";
        });

        //Retrive SDW Servers details
        builder.addCase(getAllServers.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWServers] = true;
            state.dsErrors[DatasetComponents.SDWServers] = "";
        });
        builder.addCase(getAllServers.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWServers] = false;
            state.sdwServers = _action.payload;
        });
        builder.addCase(getAllServers.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWServers] = false;
            state.dsErrors[DatasetComponents.SDWServers] = "Error occured while trying to fetch the Server details";
        });

        //Retrive Kusto Cluster details
        builder.addCase(getAllKustoClusters.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoClusters] = true;
            state.dsErrors[DatasetComponents.KustoClusters] = "";
        });
        builder.addCase(getAllKustoClusters.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoClusters] = false;
            state.kustoClusters = _action.payload;
        });
        builder.addCase(getAllKustoClusters.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoClusters] = false;
            state.dsErrors[DatasetComponents.KustoClusters] =
                "Error occured while trying to fetch the Kusto Cluster details";
        });

        //Retrive Kusto Databases details
        builder.addCase(getAllKustoDatabases.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoDatabases] = true;
            state.dsErrors[DatasetComponents.KustoDatabases] = "";
        });
        builder.addCase(getAllKustoDatabases.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoDatabases] = false;
            state.kustoDatabases = _action.payload;
        });
        builder.addCase(getAllKustoDatabases.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoDatabases] = false;
            state.dsErrors[DatasetComponents.KustoDatabases] =
                "Error occured while trying to fetch the Kusto Database details";
        });

        //Retrive Kusto Tables details
        builder.addCase(getAllKustoTables.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoTables] = true;
            state.dsErrors[DatasetComponents.KustoTables] = "";
        });
        builder.addCase(getAllKustoTables.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoTables] = false;
            state.kustoTables = _action.payload;
        });
        builder.addCase(getAllKustoTables.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoTables] = false;
            state.dsErrors[DatasetComponents.KustoTables] =
                "Error occured while trying to fetch the Kusto Tables details";
        });

        //Retrive Kusto Dataset by dataset ID
        builder.addCase(getKustoDatasetByDSId.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.currentKustoDataset] = true;
            state.dsErrors[DatasetComponents.currentKustoDataset] = "";
        });
        builder.addCase(getKustoDatasetByDSId.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.currentKustoDataset] = false;
            state.currentKustoDataset = { ..._action.payload, tagChanges: { add: [], delete: [] } };
        });
        builder.addCase(getKustoDatasetByDSId.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.currentKustoDataset] = false;
            state.dsErrors[DatasetComponents.currentKustoDataset] =
                "Error occured while trying to fetch the kusto dataset";
        });

        //Retrive Dataset Paths by StorageID
        builder.addCase(getAdlsAutoDiscoveryEntriesAsync.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.DatasetPaths] = true;
            state.dsErrors[DatasetComponents.DatasetPaths] = "";
        });
        builder.addCase(getAdlsAutoDiscoveryEntriesAsync.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.DatasetPaths] = false;
            state.adlsAutoDiscoveryEntries = _action.payload;
        });
        builder.addCase(getAdlsAutoDiscoveryEntriesAsync.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.AdlsDataset] = false;
            state.dsErrors[DatasetComponents.AdlsDataset] =
                "Error occured while trying to fetch the directory paths for the selected storage account";
        });

        //Retrive Dataset Paths by StorageID
        builder.addCase(getSDWResources.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWResources] = true;
            state.dsErrors[DatasetComponents.SDWResources] = "";
        });
        builder.addCase(getSDWResources.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWResources] = false;
            state.sdwResources = _action.payload;
        });
        builder.addCase(getSDWResources.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWResources] = false;
            state.dsErrors[DatasetComponents.SDWResources] =
                "Error occured while trying to fetch the SDW resources for the selected type";
        });

        //Dataset Creation
        builder.addCase(addDataset.pending, (state, _action) => {
            state.addDatasetResponse = undefined;
            state.dsLoading[DatasetComponents.Dataset] = true;
            state.dsErrors[DatasetComponents.Dataset] = "";
        });
        builder.addCase(addDataset.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.Dataset] = false;
            state.addDatasetResponse = _action.payload.id;
            state.currentDataset = _action.payload;
        });
        builder.addCase(addDataset.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.Dataset] = false;
            state.addDatasetResponse = -1;
            state.dsErrors[DatasetComponents.Dataset] = "Error occured while creating Dataset, please try again later.";
        });

        //Dataset Updation
        builder.addCase(updateDataset.pending, (state, _action) => {
            state.updateDatasetResponse = "";
            state.dsLoading[DatasetComponents.Dataset] = true;
            state.dsErrors[DatasetComponents.Dataset] = "";
        });
        builder.addCase(updateDataset.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.Dataset] = false;
            state.updateDatasetResponse = "Success";
        });
        builder.addCase(updateDataset.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.Dataset] = false;
            state.updateDatasetResponse = "Failed";
            state.dsErrors[DatasetComponents.Dataset] = "Error occured while updating Dataset, please try again later.";
        });
        // ADLS Dataset Creation
        builder.addCase(addAdlsDataset.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.AdlsDataset] = true;
            state.dsErrors[DatasetComponents.AdlsDataset] = "";
        });
        builder.addCase(addAdlsDataset.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.AdlsDataset] = false;
            state.addADLSDatasetResponse = "Success";
        });
        builder.addCase(addAdlsDataset.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.AdlsDataset] = false;
            state.addADLSDatasetResponse = "Failed";
            state.dsErrors[DatasetComponents.AdlsDataset] =
                "Error occured while creating Adls dataset, please try again later.";
        });
        // SDW Dataset Creation
        builder.addCase(addSDWDataset.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWDataset] = true;
            state.dsErrors[DatasetComponents.SDWDataset] = "";
        });
        builder.addCase(addSDWDataset.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWDataset] = false;
            state.addSDWDatasetResponse = "Success";
        });
        builder.addCase(addSDWDataset.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.SDWDataset] = false;
            state.addSDWDatasetResponse = "Failed";
            state.dsErrors[DatasetComponents.SDWDataset] =
                "Error occured while creating SDW dataset, please try again later.";
        });

        //Retrive SDW Dataset by dataset ID
        builder.addCase(getSDWDatasetByDSId.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.currentSDWDataset] = true;
            state.dsErrors[DatasetComponents.currentSDWDataset] = "";
        });
        builder.addCase(getSDWDatasetByDSId.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.currentSDWDataset] = false;
            state.currentSDWDataset = { ..._action.payload, tagChanges: { add: [], delete: [] } };
        });
        builder.addCase(getSDWDatasetByDSId.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.currentSDWDataset] = false;
            state.dsErrors[DatasetComponents.currentSDWDataset] = "Error occured while trying to fetch the SDW dataset";
        });

        //Get SDW SProc's Parameters

        builder.addCase(getSProcParams.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.sProcParamResponse] = true;
            state.dsErrors[DatasetComponents.sProcParamResponse] = "";
        });
        builder.addCase(getSProcParams.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.sProcParamResponse] = false;
            state.sProcParamResponse = _action.payload;
        });
        builder.addCase(getSProcParams.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.sProcParamResponse] = false;
            state.dsErrors[DatasetComponents.sProcParamResponse] =
                "Error occured while retrieving SProc Parameters, please try again later.";
        });

        //Get SDW SProc's Schema

        builder.addCase(getSProcSchema.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.sProcSchemaResponse] = true;
            state.dsErrors[DatasetComponents.sProcSchemaResponse] = "";
        });
        builder.addCase(getSProcSchema.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.sProcSchemaResponse] = false;
            state.sProcSchemaResponse = _action.payload;
        });
        builder.addCase(getSProcSchema.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.sProcSchemaResponse] = false;
            state.dsErrors[DatasetComponents.sProcSchemaResponse] =
                "Error occured while retrieving SProc Schema, please try again later.";
        });
        // Kusto Dataset Creation
        builder.addCase(addKustoDataset.pending, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoDataset] = true;
            state.dsErrors[DatasetComponents.KustoDataset] = "";
        });
        builder.addCase(addKustoDataset.fulfilled, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoDataset] = false;
            state.addKustoDatasetResponse = "Success";
        });
        builder.addCase(addKustoDataset.rejected, (state, _action) => {
            state.dsLoading[DatasetComponents.KustoDataset] = false;
            state.addKustoDatasetResponse = "Failed";
            state.dsErrors[DatasetComponents.KustoDataset] =
                "Error occured while creating Kusto dataset, please try again later.";
        });
    }
});

export const {
    reset,
    error,
    setCurrent,
    setPipelineAssociation,
    setCurrentAdlsDataset,
    setCurrentSDWDataset,
    setCurrentKustoDataset,
    setCurrentDataset,
    clearResponse,
    setDatasetAssociation,
    setDatasetAlert,
    setDatasetTags,
    setSelectedTab,
    resetDatasets
} = dataSetSlice.actions;
export {
    getAllDatasets,
    getAllDatasetTags,
    getDatasetById,
    getAdlsDatasetByDSId,
    getKustoDatasetByDSId,
    getSDWDatasetByDSId,
    getAllPartitions,
    getDatabasesByServer,
    getAllServers,
    getSDWResources,
    getAllStorageAccounts,
    getAllKustoClusters,
    getAllKustoDatabases,
    getAllKustoTables,
    getAdlsAutoDiscoveryEntriesAsync as getDirectoryPath,
    addDataset,
    updateDataset,
    addAdlsDataset,
    addSDWDataset,
    addKustoDataset,
    sliceName,
    getSProcParams,
    getSProcSchema
};
export const { reducer } = dataSetSlice;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllDatasetAlert, getDatasetAlertById } from "../components/DatasetAlert/api";
import { EmptyAlert, IDatasetAlert } from "../components/DatasetAlert/Types";
import { getDefaultCases } from "./DefaultCases";


const sliceName = "dataSetAlerts";

export enum DatasetAlertComponents {
    currentDatasetAlert,
    datasetAlerts
}

export type DatasetAlertState = {
    currentDatasetAlert?: IDatasetAlert;
    DatasetAlerts?: IDatasetAlert[];
    datasetAlerts: IDatasetAlert[];
    dsAlertErrors: Record<DatasetAlertComponents, string>;
    dsAlertLoading: Record<DatasetAlertComponents, boolean>;
}

const initialState: DatasetAlertState = {
    dsAlertErrors: {
        [DatasetAlertComponents.currentDatasetAlert]: "",
        [DatasetAlertComponents.datasetAlerts]: ""
    },
    dsAlertLoading: {
        [DatasetAlertComponents.currentDatasetAlert]: false,
        [DatasetAlertComponents.datasetAlerts]: false
    },
    datasetAlerts: []
}

// Dataset alert retrieval by Id
const getDatasetAlert = createAsyncThunk(`${sliceName}/get_dataset_alert_by_id`, async (dsAlertID: number) => {
    const response = await getDatasetAlertById(dsAlertID);
    return response.data;
});

// Dataset Retrieval by Id
const getAllDatasetAlerts = createAsyncThunk(`${sliceName}/get_all_dataset_alert_by_id`, async () => {
    const response = await getAllDatasetAlert();
    return response.data;
});
const dataSetAlertSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        ...getDefaultCases<IDatasetAlert>(),
        setDatasetAlert: (state, action: PayloadAction<IDatasetAlert[]>) => {
            state.datasetAlerts = action.payload;
        },
        resetDatasetAlert: (state) => {
            state.currentDatasetAlert = EmptyAlert;
        },
    },
    extraReducers: (builder) => {
        //Retrive Dataset by ID
        builder.addCase(getDatasetAlert.pending, (state, _action) => {
            state.dsAlertLoading[DatasetAlertComponents.currentDatasetAlert] = true;
            state.dsAlertErrors[DatasetAlertComponents.currentDatasetAlert] = "";
        });
        builder.addCase(getDatasetAlert.fulfilled, (state, _action) => {
            state.dsAlertLoading[DatasetAlertComponents.currentDatasetAlert] = false;
            state.currentDatasetAlert = _action.payload;
        });
        builder.addCase(getDatasetAlert.rejected, (state, _action) => {
            state.dsAlertLoading[DatasetAlertComponents.currentDatasetAlert] = false;
            state.dsAlertErrors[DatasetAlertComponents.currentDatasetAlert] = "Error occured while trying to fetch the dataset alert";
        });

        //Retrive all Dataset alerts 
        builder.addCase(getAllDatasetAlerts.pending, (state, _action) => {
            state.dsAlertLoading[DatasetAlertComponents.datasetAlerts] = true;
            state.dsAlertErrors[DatasetAlertComponents.datasetAlerts] = "";
        });
        builder.addCase(getAllDatasetAlerts.fulfilled, (state, _action) => {
            state.dsAlertLoading[DatasetAlertComponents.datasetAlerts] = false;
            state.datasetAlerts = _action.payload;
        });
        builder.addCase(getAllDatasetAlerts.rejected, (state, _action) => {
            state.dsAlertLoading[DatasetAlertComponents.datasetAlerts] = false;
            state.dsAlertErrors[DatasetAlertComponents.datasetAlerts] = "Error occured while trying to fetch the dataset alerts";
        });
    }
});


export const {
    setDatasetAlert,
    resetDatasetAlert,
} = dataSetAlertSlice.actions;

export { getDatasetAlert, getAllDatasetAlerts }

export const { reducer } = dataSetAlertSlice;
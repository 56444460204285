import { IconButton } from "@fluentui/react";
import { IStandardColumn } from "../models/StandardColumn";
import { addSortLabels } from "./utils";
import { KeyVaultSecretEntry } from "../models/KeyVaultSecretEntry";

export function generateKeyVaultSecretColumns(): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "keyVaultName",
            name: "Key Vault",
            fieldName: "keyVaultName",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "subscriptionName",
            name: "Subscription",
            fieldName: "subscriptionName",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "createdOn",
            name: "Created On",
            fieldName: "createdOn",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true,
            isDate: true
        },
        {
            key: "expiresOn",
            name: "Expires On",
            fieldName: "expiresOn",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true,
            isDate: true
        },
        {
            key: "link",
            name: "View in Key Vault",
            fieldName: "link",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true,
            onRender: (item: KeyVaultSecretEntry) => (
                <IconButton
                    title="View in Key Vault"
                    className="routing"
                    disabled={!item.link}
                    href={
                        "https://ms.portal.azure.com/#@microsoft.onmicrosoft.com/asset/Microsoft_Azure_KeyVault/Secret/" +
                        item.link
                    }
                    iconProps={{ iconName: "KeyVaults" }}
                    allowDisabledFocus
                    target="_blank"
                />
            )
        }
    ];

    addSortLabels(cols);
    return cols;
}

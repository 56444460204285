import { useEffect} from "react";
import "../styles/App.css";
import { serverAdministrationSelector } from "./Selectors"
import StandardList from "../components/common/StandardList";
import { ServerAdministrationHistoryColumns } from "../columns";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { StateActionType } from "../types";
import { getServerAdministrationHistoryById } from "../reducers/ServerAdministration";
import { toNumber } from "lodash";

function RemoteServerAdministrationHistory() {
    let { id } = useParams<{ id: string }>();
    let serverID = toNumber(id)
    const history = useHistory();
    const dispatch = useAppDispatch();

    const {
        currentEntity: serverAdministrationsHistories,
        error: serverAdministrationsError,
        state: serverAdministrationsState
    } = useAppSelector(serverAdministrationSelector);

    useEffect(() => {
        document.title = "Remote Server Aministration History - SIP Portal";
        dispatch(getServerAdministrationHistoryById(serverID));
    }, []);

    return (
        <>
            <StandardList
                entities={
                    serverAdministrationsHistories ?
                        serverAdministrationsHistories.map((e) => ({
                            ...e
                        }))
                        : undefined
                }
                title={"Server Administration History"}
                columns={ServerAdministrationHistoryColumns(history)}
                error={serverAdministrationsError}
                entityState={serverAdministrationsState[StateActionType.LoadAll]}
                extraHeaderItems={<></>}
            />
        </>
    );
}


export default RemoteServerAdministrationHistory;

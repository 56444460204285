import { Checkbox, IDropdownOption, IconButton, Label, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { DatasetType } from "../../models";
import SuppressionDialogProps, { ISuppressionState } from "../../models/dialogProps/SuppressionDialogProps";
import { dataSetAlertSelector } from "../../pages/Selectors";
import { DatasetAlertComponents, getDatasetAlert, resetDatasetAlert } from "../../reducers/DataSetAlert";
import { getUserName } from "../../service/authProvider";
import SaveButtonContainer from "../Dataset/SaveButtonContainer";
import SaveModal from "../Dataset/SaveModal";
import { SuppressionDialog } from "../Pipeline/SuppressionDialog";
import { LoadingOverLay } from "../common/LoadingOverLay";
import { ShimmeredComboBox, ShimmeredToggle } from "../common/Shimmered";
import { StackRow } from "../common/StackComponents";
import StandardForm from "../common/StandardForm";
import {
    ConfigSettingProps,
    DatasetAlertCategory,
    EmptyAlert,
    IDatasetAlert,
    InitialConfigSettings,
    configSettings
} from "./Types";
import { submitAlertInfo } from "./api";
import { setSelectedTab } from "../../reducers/Dataset";

export function AlertForm() {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [currentAlert, setCurrentAlert] = useState<IDatasetAlert>(EmptyAlert);

    /* Suppression */
    const getDefaultSuppressionState = (): ISuppressionState => {
        const endTime = new Date();
        endTime.setHours(23, 59, 0, 0);

        return { startTime: new Date(), endTime, justification: "", suppressedBy: "" };
    };
    const [suppressionState, setSuppressionState] = useState<ISuppressionState>(getDefaultSuppressionState());

    const getDefaultDialogState = (): SuppressionDialogProps => ({
        showDialog: false,
        maxSuppressionTime: new Date(currentAlert!.maxSuppressionTime),
        state: suppressionState,
        setDate: setSuppressionState,
        onClose: () => _popUpClose()
    });
    const [dialogState, setShowDialog] = useState<SuppressionDialogProps>(getDefaultDialogState());

    const { action, dsID } = useParams<{ dsID: string; action: string }>();
    const { currentDatasetAlert, dsAlertLoading } = useAppSelector(dataSetAlertSelector);

    const [showAnnouncment, setShowAnnouncement] = useState(false);

    /*Alert Type*/
    const [isICMSelected, setICMSelected] = useState<boolean>(false);
    const [isEmailSelected, setEmailSelected] = useState<boolean>(false);

    /*Config Settings*/
    const [configSettings, setConfigSettings] = useState<configSettings>(InitialConfigSettings);

    /*Setting 1*/
    const [isSetting1Selected, setSetting1Selected] = useState<boolean>(false);
    /*Setting 2*/
    const [isSetting2Selected, setSetting2Selected] = useState<boolean>(false);

    const isAdd = action.toLowerCase() === "new";

    // Edit Form
    let alertId: number = -1;
    if (!isAdd) {
        alertId = parseInt(dsID);
    }

    /*Ensuring all dataset alerts have been retrived*/
    useEffect(() => {
        if (!isAdd && dsID) {
            dispatch(getDatasetAlert(alertId));
        }
    }, [location.href]);

    useEffect(() => {
        if (currentDatasetAlert) {
            if (currentDatasetAlert.alertType.indexOf("ICM") != -1) {
                setICMSelected(true);
            }
            if (currentDatasetAlert.alertType.indexOf("Email") != -1) {
                setEmailSelected(true);
            }

            // Parse Config Settings
            if (currentDatasetAlert.configurations) {
                const settings: configSettings = JSON.parse(currentDatasetAlert.configurations);
                setConfigSettings(settings);

                if (settings.setting1.operator) {
                    setSetting1Selected(true);
                }
                if (settings.setting2.operator) {
                    setSetting2Selected(true);
                }
            }
            const cAlert = { ...currentDatasetAlert };

            cAlert.category = Number(DatasetAlertCategory[currentDatasetAlert.category]);
            cAlert.type = Number(DatasetType[currentDatasetAlert.type]);
            cAlert.isSupressed = currentDatasetAlert.isSupressed;
            cAlert.suppressedBy = currentDatasetAlert.suppressedBy;
            cAlert.suppressionJustification = currentDatasetAlert.suppressionJustification;
            cAlert.suppressionStartTime = currentDatasetAlert.suppressionStartTime;
            cAlert.suppressionEndTime = currentDatasetAlert.suppressionEndTime;
            setSuppressionState((p) => ({
                ...p,
                startTime: currentDatasetAlert.suppressionStartTime
                    ? new Date(currentDatasetAlert.suppressionStartTime)
                    : new Date(),
                endTime: currentDatasetAlert.suppressionEndTime
                    ? new Date(currentDatasetAlert!.suppressionEndTime)
                    : new Date(),
                suppressedBy: currentDatasetAlert.suppressedBy
            }));
            setCurrentAlert(cAlert);
        }
    }, [currentDatasetAlert]);

    useEffect(() => {
        if (suppressionState.changed) {
            setCurrentAlert((p) => ({ ...p, isSupressed: true }));
            setSuppressionState((p) => ({ ...p, changed: false }));
        }
    }, [suppressionState]);

    // category options for dropdown
    const options: IDropdownOption[] = [{ key: DatasetAlertCategory.DataVolume, text: "Data Volume" }];
    const typeOptions: IDropdownOption[] = [
        { key: DatasetType.Adls, text: "Adls" }
        //{ key: DatasetType.Kusto, text: "Kusto" },
        //{ key: DatasetType.SDW, text: "SDW" },
    ];
    const opOptions: IDropdownOption[] = [
        { key: "==", text: "==" },
        { key: "<", text: "<" },
        { key: "<=", text: "<=" },
        { key: ">", text: ">" },
        { key: ">=", text: ">=" },
        { key: "!=", text: "!=" }
    ];
    const handleChange = (_event, newValue) => {
        if (!newValue) true;
        // Check if the input is numeric
        else if (!/^[1-9]\d*$/.test(newValue)) {
            return false;
        }
        return true;
    };

    function onChange<K extends keyof IDatasetAlert>(value: any, stateKey: K) {
        if (currentAlert) {
            const cAlert = { ...currentAlert };
            if (stateKey != "alertType") {
                cAlert[stateKey] = value;
                setCurrentAlert(cAlert);
            }
        }
    }

    function setting1CheckChange() {
        if (configSettings) {
            const tmpConfigSettings = { ...configSettings };
            tmpConfigSettings.setting1[ConfigSettingProps.Operator] = "";
            tmpConfigSettings.setting1[ConfigSettingProps.BenchmarkValue] = "";
            setConfigSettings(tmpConfigSettings);
        }
    }
    function setting2CheckChange() {
        if (configSettings) {
            const tmpConfigSettings = { ...configSettings };
            tmpConfigSettings.setting2[ConfigSettingProps.Operator] = "";
            tmpConfigSettings.setting2[ConfigSettingProps.BenchmarkValue] = "";
            tmpConfigSettings.setting2[ConfigSettingProps.LookBackPeriod] = "";
            setConfigSettings(tmpConfigSettings);
        }
    }

    function setConfigProps(path: string, propName: string, propValue: string) {
        if (configSettings) {
            const tmpConfigSettings = { ...configSettings };
            if (tmpConfigSettings.hasOwnProperty(path)) {
                tmpConfigSettings[path][propName] = propValue;
            }
            setConfigSettings(tmpConfigSettings);
        }
    }
    function getConfigProps(path: string, propName: string) {
        if (configSettings) {
            if (configSettings.hasOwnProperty(path)) {
                return configSettings[path][propName];
            }
        }
        return "";
    }

    function isValidForm() {
        if (currentAlert.type == -1 || !currentAlert.name) return false;
        if (!isICMSelected && !isEmailSelected) return false;

        if (!isSetting1Selected && !isSetting2Selected) return false;

        if (isSetting1Selected && (!configSettings?.setting1.operator || !configSettings?.setting1.benchmarkValue))
            return false;
        if (
            isSetting2Selected &&
            (!configSettings?.setting2.benchmarkValue ||
                !configSettings?.setting2.lookBackPeriod ||
                !configSettings?.setting2.operator)
        )
            return false;

        return true;
    }

    async function submitAlertDetails() {
        let alertType = "";
        if (currentAlert) {
            const createdBy = getUserName().split("@")[0];
            const objAlert = { ...currentAlert };

            if (isICMSelected) alertType = "ICM;";
            if (isEmailSelected) alertType += "Email";

            objAlert.alertType = alertType;
            if (isAdd) {
                objAlert.datasetId = Number(dsID);
            }
            setCurrentAlert(objAlert);

            //validateConfigSettings();
            objAlert.configurations = JSON.stringify(configSettings); //configSettingBuilder();

            if (isAdd) objAlert.createdBy = createdBy;

            objAlert.modifiedBy = createdBy;

            objAlert.suppressionStartTime = new Date(suppressionState.startTime);
            objAlert.suppressionEndTime = new Date(suppressionState.endTime);
            objAlert.suppressionJustification = suppressionState.justification;
            objAlert.suppressedBy = getUserName().split("@")[0];

            try {
                const { status } = await submitAlertInfo(objAlert);
                if (status == 200) {
                    setShowAnnouncement(true);
                } else {
                    alert("Something went wrong, try again later.");
                }
            } catch (e) {}
        }
    }
    function _popUpClose() {
        setShowDialog((p) => ({ ...p, showDialog: false, state: getDefaultSuppressionState() }));
    }
    function _popUpShow() {
        if (dialogState)
            setShowDialog({
                ...dialogState,
                state: suppressionState,
                maxSuppressionTime: new Date(currentAlert!.maxSuppressionTime),
                showDialog: true
            });
    }
    function _on_ChangeSuppress(_ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) {
        const checked = isChecked === null || isChecked === undefined ? false : isChecked;
        if (!checked) {
            _popUpShow();
        } else {
            const objAlert = { ...currentAlert, isSupressed: !checked };
            setCurrentAlert(objAlert);
            setSuppressionState((p) => ({ ...p, ...getDefaultSuppressionState() }));
        }
    }
    function closeForm() {
        dispatch(setSelectedTab("Alerts"));
        dispatch(resetDatasetAlert());
        history.replace(
            isAdd
                ? `/datasets/Edit${dsID ? "/" + dsID : ""}`
                : `/datasets/Edit${currentAlert.datasetId ? "/" + currentAlert.datasetId : ""}`
        );
    }

    return (
        <>
            {!isAdd && <LoadingOverLay isOverlayVisible={dsAlertLoading[DatasetAlertComponents.currentDatasetAlert]} />}
            <SaveModal
                hidden={!showAnnouncment}
                onClose={closeForm}
                successMsg={
                    isAdd
                        ? "Dataset alert has been created successfully"
                        : "Dataset alert has been updated successfully"
                }
            />
            <StandardForm widthPercent={70} mediumWidthPercent={90} smallWidthPercent={90}>
                <Stack.Item align="end" style={{ textAlign: "right" }}>
                    <IconButton
                        title="Close"
                        className="routing"
                        onClick={() => closeForm()}
                        iconProps={{ iconName: "Cancel" }}
                        allowDisabledFocus
                    />
                </Stack.Item>

                <StackRow columnWidth={100}>
                    <Stack style={{ paddingLeft: 15, display: "block" }}>
                        <Label className="label">Alert Name</Label>
                        <TextField
                            value={currentAlert.name}
                            onChange={(_e, newValue) => {
                                if (newValue !== undefined) onChange(newValue, "name");
                            }}
                            required
                            styles={{ root: { width: 350 } }}
                        ></TextField>
                    </Stack>
                    {!isAdd && (
                        <Stack style={{ paddingLeft: 15, display: "block" }}>
                            <ShimmeredToggle
                                isStackItem
                                loading={dsAlertLoading[DatasetAlertComponents.currentDatasetAlert]}
                                toggleLabel="Active"
                                className="suppressed"
                                id="suppressed"
                                onText="Yes"
                                offText={`Disabled until ${
                                    suppressionState?.endTime
                                        ? suppressionState.endTime.toLocaleString("en-US", {
                                              timeZoneName: "short"
                                          })
                                        : "N/A"
                                }`}
                                checked={!currentAlert.isSupressed}
                                onChange={_on_ChangeSuppress}
                            />
                        </Stack>
                    )}
                </StackRow>
                <StackRow columnWidth={100}>
                    <Stack>
                        <ShimmeredComboBox
                            loading={isAdd ? false : !dsAlertLoading}
                            cbxLabel="Category"
                            selectedKey={currentAlert.category}
                            placeholder="Select Category"
                            autoComplete="on"
                            options={options}
                            onChange={(_ev, option) => onChange(parseInt(option!.key as string), "category")}
                        />
                    </Stack>
                    <Stack>
                        <ShimmeredComboBox
                            loading={isAdd ? false : !dsAlertLoading}
                            cbxLabel="Dataset Type"
                            selectedKey={currentAlert.type}
                            placeholder="Select Dataset Type"
                            autoComplete="on"
                            options={typeOptions}
                            onChange={(_ev, option) => onChange(parseInt(option!.key as string), "type")}
                        />
                    </Stack>
                </StackRow>

                {/*Setting #1*/}
                <StackRow columnWidth={100}>
                    <Stack style={{ paddingLeft: 15, display: "block" }}>
                        <Label className="label"> Settings</Label>
                        <div style={{ display: "flex", width: "auto", paddingTop: 10 }}>
                            <div style={{ float: "left", paddingRight: 0, paddingTop: 5, width: "auto" }}>
                                <Checkbox
                                    checked={isSetting1Selected}
                                    label="When Last 24 hours ingested count"
                                    styles={{ root: { display: "flex" } }}
                                    onChange={() => {
                                        setSetting1Selected(!isSetting1Selected);
                                        setting1CheckChange();
                                    }}
                                />
                            </div>
                            <div style={{ float: "left" }}>
                                <ShimmeredComboBox
                                    loading={isAdd ? false : !dsAlertLoading}
                                    showLabel={false}
                                    cbxLabel="Operators"
                                    selectedKey={getConfigProps(
                                        ConfigSettingProps.Setting1,
                                        ConfigSettingProps.Operator
                                    )}
                                    required
                                    style={{ width: 75 }}
                                    autoComplete="on"
                                    options={opOptions}
                                    onChange={(_ev, option) =>
                                        setConfigProps(
                                            ConfigSettingProps.Setting1,
                                            ConfigSettingProps.Operator,
                                            option!.key as string
                                        )
                                    }
                                    disabled={!isSetting1Selected}
                                />
                            </div>
                            <div style={{ float: "left" }}>
                                <TextField
                                    placeholder="Bench mark value"
                                    value={getConfigProps(
                                        ConfigSettingProps.Setting1,
                                        ConfigSettingProps.BenchmarkValue
                                    )}
                                    onChange={(_e, newValue) => {
                                        if (handleChange(_e, newValue)) {
                                            if (newValue !== undefined)
                                                setConfigProps(
                                                    ConfigSettingProps.Setting1,
                                                    ConfigSettingProps.BenchmarkValue,
                                                    newValue
                                                );
                                        }
                                    }}
                                    disabled={!isSetting1Selected}
                                    style={{ width: 200 }}
                                ></TextField>
                            </div>
                        </div>
                    </Stack>
                </StackRow>

                {/*Setting #2*/}
                <StackRow columnWidth={100}>
                    <Stack style={{ paddingLeft: 15, display: "block" }}>
                        <div style={{ display: "flex", width: "auto", paddingTop: 10 }}>
                            <div style={{ float: "left", paddingRight: 0, paddingTop: 5, width: "auto" }}>
                                <Checkbox
                                    checked={isSetting2Selected}
                                    label="When Last 24 hours ingested count"
                                    styles={{ root: { display: "flex" } }}
                                    onChange={() => {
                                        setSetting2Selected(!isSetting2Selected);
                                        setting2CheckChange();
                                    }}
                                />
                            </div>
                            <div style={{ float: "left" }}>
                                <ShimmeredComboBox
                                    loading={isAdd ? false : !dsAlertLoading}
                                    showLabel={false}
                                    cbxLabel="Operators"
                                    selectedKey={getConfigProps(
                                        ConfigSettingProps.Setting2,
                                        ConfigSettingProps.Operator
                                    )}
                                    required
                                    style={{ width: 75 }}
                                    autoComplete="on"
                                    options={opOptions}
                                    onChange={(_ev, option) =>
                                        setConfigProps(
                                            ConfigSettingProps.Setting2,
                                            ConfigSettingProps.Operator,
                                            option!.key as string
                                        )
                                    }
                                    disabled={!isSetting2Selected}
                                />
                            </div>
                            <div style={{ float: "left", paddingRight: 5 }}>
                                <TextField
                                    placeholder="Bench mark value in %"
                                    onChange={(_e, newValue) => {
                                        if (handleChange(_e, newValue)) {
                                            if (newValue !== undefined)
                                                setConfigProps(
                                                    ConfigSettingProps.Setting2,
                                                    ConfigSettingProps.BenchmarkValue,
                                                    newValue
                                                );
                                        }
                                    }}
                                    style={{ width: 200 }}
                                    value={getConfigProps(
                                        ConfigSettingProps.Setting2,
                                        ConfigSettingProps.BenchmarkValue
                                    )}
                                    disabled={!isSetting2Selected}
                                ></TextField>
                            </div>
                            <div style={{ float: "left", paddingTop: 5, paddingRight: 5 }}>
                                <span> % of the average of ingestions over the last</span>
                            </div>
                            <div style={{ float: "left" }}>
                                <TextField
                                    onChange={(_e, newValue) => {
                                        if (handleChange(_e, newValue)) {
                                            if (newValue !== undefined)
                                                setConfigProps(
                                                    ConfigSettingProps.Setting2,
                                                    ConfigSettingProps.LookBackPeriod,
                                                    newValue
                                                );
                                        }
                                    }}
                                    style={{ width: 40, paddingRight: 5 }}
                                    value={getConfigProps(
                                        ConfigSettingProps.Setting2,
                                        ConfigSettingProps.LookBackPeriod
                                    )}
                                    disabled={!isSetting2Selected}
                                ></TextField>
                            </div>
                            <div style={{ float: "left", paddingLeft: 5, paddingTop: 5 }}>
                                <span>day(s)</span>
                            </div>
                        </div>
                    </Stack>
                </StackRow>

                <StackRow columnWidth={100}>
                    <Stack style={{ paddingLeft: 15, display: "block" }}>
                        <Label className="label"> Alerty Type</Label>
                        <div style={{ display: "flex", width: "100%", paddingTop: 10 }}>
                            <div style={{ float: "left", paddingRight: 10 }}>
                                <Checkbox
                                    label="ICM"
                                    checked={isICMSelected}
                                    styles={{ root: { display: "flex" } }}
                                    onChange={() => {
                                        setICMSelected(!isICMSelected);
                                        onChange(!isICMSelected, "alertType");
                                    }}
                                />
                            </div>
                            <div style={{ float: "left" }}>
                                <Checkbox
                                    label="Email"
                                    checked={isEmailSelected}
                                    styles={{ root: { display: "flex" } }}
                                    onChange={() => {
                                        setEmailSelected(!isEmailSelected);
                                        onChange(!isEmailSelected, "alertType");
                                    }}
                                />
                            </div>
                        </div>
                    </Stack>
                </StackRow>

                <SaveButtonContainer>
                    <PrimaryButton
                        text={isAdd ? "Create" : "Update"}
                        type="button"
                        disabled={!isValidForm()}
                        onClick={() => {
                            submitAlertDetails();
                        }}
                    ></PrimaryButton>
                    <PrimaryButton text="Close" type="button" onClick={() => closeForm()}></PrimaryButton>
                </SaveButtonContainer>
            </StandardForm>
            {dialogState.showDialog && <SuppressionDialog {...dialogState} />}
        </>
    );
}

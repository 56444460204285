import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInitialState, onComplete, onLoading, StateActionType } from "../types";
import { getDataSources, getDataSource, editDataSource, createDataSource } from "../api/DataSourceApi";
import { IDataSource } from "../models";
import { getDefaultCases } from "./DefaultCases";

const sliceName = "dataSources";

const getAllDataSources = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getDataSources();
    return response.data;
});

const getDataSourceById = createAsyncThunk(`${sliceName}/get_by_id`, async (id: number, _thunkAPI) => {
    const response = await getDataSource(id);
    return response.data;
});

const updateDataSource = createAsyncThunk(`${sliceName}/update`, async (dataSource: IDataSource, _thunkAPI) => {
    const response = await editDataSource(dataSource);
    return response.data;
});

const addDataSources = createAsyncThunk(`${sliceName}/create`, async (dataSource: IDataSource, _thunkAPI) => {
    const response = await createDataSource(dataSource);
    return response.data;
});

const initialState = getInitialState<IDataSource, IDataSource>();

// Then, handle actions in your reducers:
const dataSourcesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IDataSource>(),
    extraReducers: (builder) => {
        builder.addCase(getAllDataSources.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllDataSources.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getDataSourceById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(getDataSourceById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadSingle);
        });
        builder.addCase(updateDataSource.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(updateDataSource.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Update);
        });
        builder.addCase(addDataSources.pending, (state, _action) => {
            onLoading(state, StateActionType.Add);
        });
        builder.addCase(addDataSources.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Add);
        });
    }
});

export const { reset, error, setCurrent } = dataSourcesSlice.actions;
export { getAllDataSources, getDataSourceById, updateDataSource, addDataSources, sliceName };
export const { reducer } = dataSourcesSlice;

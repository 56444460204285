import React, { useEffect, useRef, useState } from "react";
import "../styles/App.css";

import { useHistory } from "react-router-dom";

import { feedCategorySelector } from "./Selectors";
import StandardList from "../components/common/StandardList";
import { generateFeedCategoryColumns } from "../columns";
import { IButton, PrimaryButton, Stack } from "@fluentui/react";
import { StateActionType } from "../types";
import { getAllFeedCategories, reset } from "../reducers/FeedCategories";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import CrudStatusDialog from "../components/common/CrudStatusDialog";

function FeedCategoryTable() {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const {
        entities: feedCategories,
        error: feedCategoryError,
        state: feedCategoryState
    } = useAppSelector(feedCategorySelector);

    const addButtonRef = useRef<HTMLDivElement>(null);
    const [showAnnouncment, setShowAnnouncement] = useState<{ show: boolean; msg: string; onClose: () => void }>({
        show: false,
        msg: "",
        onClose: () => setShowAnnouncement({ show: false, msg: "", onClose: () => {} })
    });
    useEffect(() => {
        document.title = "List of Feed Categories - SIP Portal";
        if (feedCategoryState[StateActionType.LoadAll] === "INIT") dispatch(getAllFeedCategories());
    }, []);

    useEffect(() => {
        if (feedCategoryState[StateActionType.Delete] === "DELETED") {
            dispatch(getAllFeedCategories());
            setShowAnnouncement({
                show: true,
                msg: "Feed Category successfully deleted",
                onClose: () => setShowAnnouncement({ show: false, msg: "", onClose: () => {} })
            });
            dispatch(reset(StateActionType.Delete));
        }
    }, [feedCategoryState[StateActionType.Delete]]);

    return (
        <StandardList
            entities={
                feedCategories &&
                feedCategories.map((fc) => ({
                    ...fc,
                    dataBusinessOwner: fc.dataBusinessOwnerName,
                    dataSourceOwner: fc.dataSourceOwnerName,
                    secondaryUseAccessApprover: fc.secondaryUseAccessApproverName,
                    securityUseAccessApprover: fc.securityUseAccessApproverName,
                    feedCount: fc.feedIds ? fc.feedIds.length : 0
                }))
            }
            title={"Feed Categories"}
            columns={generateFeedCategoryColumns(history, setShowAnnouncement)}
            error={feedCategoryError}
            entityState={feedCategoryState[StateActionType.LoadAll]}
            extraHeaderItems={
                <>
                    <div
                        ref={addButtonRef}
                        onFocus={() => {
                            addButtonRef.current &&
                                addButtonRef.current.scrollIntoView({
                                    behavior: "smooth",
                                    block: "nearest",
                                    inline: "start"
                                });
                        }}
                    >
                        <Stack.Item grow align="center">
                            <PrimaryButton text="Add New Category" onClick={() => history.push(`feedcategories/new`)} />
                        </Stack.Item>
                    </div>
                    <CrudStatusDialog
                        showDialog={showAnnouncment.show}
                        title={showAnnouncment.msg}
                        onClose={showAnnouncment.onClose}
                        onDismiss={() => setShowAnnouncement({ show: false, msg: "", onClose: () => {} })}
                    />
                </>
            }
        />
    );
}

export default FeedCategoryTable;

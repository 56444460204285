import React, { useCallback } from "react";
import { useGetAssignmentsQuery, useGetPoliciesQuery } from "../reducers/AssignmentsApi";
import PreferredSelect from "../components/common/PreferredSelect";
import { AccessPackageAssignment } from "../models";
import { useGetCatalogsQuery } from "../reducers/GraphElmApi";
import { SearchBox, Stack, getTheme, MessageBar, MessageBarType } from "@fluentui/react";
import StandardForm from "../components/common/StandardForm";
import { StackRow } from "../components/common/StackComponents";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { ShimmeredComboBox, ShimmeredText } from "../components/common/Shimmered";
import { getErrorMessage } from "../components/AccessPackages/utils";
import GroupedAssignmentList from "../components/Assignments/GroupedAssignmentList";

const AssignmentsList = () => {
    const history = useHistory();
    const [catalogId, setCatalogId] = React.useState<string>("");
    const [assignments, setAssignments] = React.useState<AccessPackageAssignment[]>([]);
    const [error, setError] = React.useState<string>("");
    const [filterdAssignments, setFilteredAssignments] = React.useState<AccessPackageAssignment[]>([]);
    const [policy, setPolicy] = React.useState<string>("");
    const {
        data,
        isLoading: assignmentLoading,
        error: assignmentError
    } = useGetAssignmentsQuery({ catalogId, policy }, { skip: !catalogId || !policy });

    const {
        data: policies,
        isLoading: policiesLoading,
        error: policyError
    } = useGetPoliciesQuery(catalogId, { skip: !catalogId });
    const theme = getTheme();
    const { data: catalogs, error: catalogError } = useGetCatalogsQuery(undefined, {
        pollingInterval: 3600000 // 1 hour
    });

    const filterSearch = (searchString: string) => {
        const lowerCaseSearchString = searchString.toLowerCase();

        if (!searchString && data) return setFilteredAssignments(assignments);

        if (!assignments) return;

        const filteredAssignments = assignments.filter(
            (assignment) =>
                assignment.objectId.toLowerCase().includes(lowerCaseSearchString) ||
                assignment.applicationId.toLowerCase().includes(lowerCaseSearchString) ||
                assignment.accessPackageName.toLowerCase().includes(lowerCaseSearchString) ||
                assignment.displayName.toLowerCase().includes(lowerCaseSearchString)
        );
        setFilteredAssignments(filteredAssignments);
    };

    const debouncedFilterSearch = useCallback(
        debounce((newValue: string) => filterSearch(newValue), 300),
        [assignments]
    );
    React.useEffect(() => {
        if (data) {
            setAssignments(data);
        }
    }, [data]);

    React.useEffect(() => {
        if (catalogError) {
            const errorMsg = getErrorMessage(catalogError) || "Error fetching catalogs";
            setError(errorMsg);
        } else if (policyError) {
            const errorMsg = getErrorMessage(policyError) || "Error fetching policies";
            setError(errorMsg);
        } else if (assignmentError) {
            const errorMsg = getErrorMessage(assignmentError) || "Error fetching assignments";
            setError(errorMsg);
        } else {
            setError("");
        }
    }, [catalogError, policyError, assignmentError]);

    React.useEffect(() => {
        if (assignments) {
            setFilteredAssignments(assignments);
        }
    }, [assignments]);

    function cloneAssignment(e: React.MouseEvent<HTMLButtonElement>, objectId: string, catalogId: string) {
        e.preventDefault();
        history.push(`/assignmentclone/${objectId}/${catalogId}/${policy}`);
    }

    return (
        <StandardForm widthPercent={90} mediumWidthPercent={90} smallWidthPercent={90}>
            <div
                style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    height: "150px",
                    borderBottom: `1px solid ${theme.palette.neutralLight}`,
                    backgroundColor: theme.palette.white
                }}
            >
                {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
                <Stack tokens={{ childrenGap: 10 }}>
                    <StackRow tokens={{ childrenGap: 10 }} horizontal horizontalAlign="space-between">
                        <SearchBox
                            width="100%"
                            placeholder="Search"
                            onChange={(_event, newValue) => debouncedFilterSearch(newValue || "")}
                        />
                        <PreferredSelect
                            setEntryId={setCatalogId}
                            title="Catalog"
                            selectedEntryId={catalogId}
                            entries={catalogs}
                            disabled={false}
                        />
                    </StackRow>
                    <StackRow verticalAlign="end">
                        <ShimmeredText variant="mediumPlus" isStackItem loading={assignmentLoading}>
                            Count: {filterdAssignments.length > 0 ? filterdAssignments.length : ""}
                        </ShimmeredText>

                        <ShimmeredComboBox
                            loading={policiesLoading}
                            cbxLabel="Policy"
                            showLabel={false}
                            placeholder="Select a policy"
                            isStackItem={true}
                            selectedKey={policy}
                            disabled={!catalogId || !policies || policies.length === 0}
                            onChange={(_event, option) => setPolicy(option?.key as string)}
                            options={policies?.map((policy) => ({ key: policy, text: policy })) || []}
                        />
                    </StackRow>
                </Stack>
            </div>
            <div>
                {data && (
                    <GroupedAssignmentList
                        catalogId={catalogId}
                        filterdAssignments={filterdAssignments}
                        cloneAssignment={cloneAssignment}
                    />
                )}
            </div>
        </StandardForm>
    );
};

export default AssignmentsList;

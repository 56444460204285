import {
    EmptyDatasetAssociation,
    IAdlsDataset,
    IDatasetAssociation,
    IDatasetTag,
    IKustoDataset,
    ISDWDataset,
    DatasetType
} from "../../models";
import { History } from "history";
import { DataTag } from "../common/AutoCompleteTextField";

export type FieldState = {
    message: string;

    errors: { [index: string]: string };
};

export function navigateToDataset(history: History, isAdd?: boolean, dsId?: number) {
    history.replace(`/datasets/${isAdd && !dsId ? "New" : `Edit/${dsId}`}`);
}
export function anyFieldErrors(fieldState: FieldState) {
    return Object.values(fieldState.errors).some((x) => x.length > 0);
}
export function getAdlsDatasetAssociation(
    adlsDataset: IAdlsDataset,
    datasetId: number,
    datasetTags?: IDatasetTag[]
): IDatasetAssociation {
    const association = { ...EmptyDatasetAssociation };
    association.id = adlsDataset.id;
    association.type = "Adls";
    association.dsID = datasetId;
    association.endpoint = `Account: ${adlsDataset.storageAccountName}, Path: ${adlsDataset.container}/${adlsDataset.path}`;
    association.displayInCatalog = adlsDataset.displayInCatalog;
    association.pipelineName = adlsDataset.pipelineName;
    association.tags = datasetTags
        ? datasetTags
              .filter((x) => x.type === DatasetType.Adls)
              .map((x) => x.name)
              .join(", ")
        : "";

    return association;
}

export function getKustoDatasetAssociation(
    kustoDataset: IKustoDataset,
    datasetId: number,
    datasetTags?: IDatasetTag[]
) {
    const association = { ...EmptyDatasetAssociation };
    association.id = kustoDataset.id;
    association.type = "Kusto";
    association.dsID = datasetId;
    association.endpoint =
        "Cluster: " +
        kustoDataset.cluster +
        "; Database: " +
        kustoDataset.database +
        "; Table Name: " +
        kustoDataset.tableName;
    association.tags = datasetTags
        ? datasetTags
              .filter((x) => x.type == DatasetType.Kusto)
              .map((x) => x.name)
              .join(", ")
        : "";
    association.displayInCatalog = kustoDataset.displayInCatalog;
    association.pipelineName = kustoDataset.pipelineName;
    return association;
}

export function getSdwDatasetAssociation(sdwDataset: ISDWDataset, datasetId: number, datasetTags?: IDatasetTag[]) {
    const association = { ...EmptyDatasetAssociation };
    association.id = sdwDataset.id;
    association.type = "SDW";
    association.dsID = datasetId;
    association.endpoint =
        "Server: " + sdwDataset.sqlServer + "; Database: " + sdwDataset.database + "; Name: " + sdwDataset.name;
    association.tags = datasetTags
        ? datasetTags
              .filter((x) => x.type == DatasetType.SDW)
              .map((x) => x.name)
              .join(", ")
        : "";
    association.displayInCatalog = sdwDataset.displayInCatalog;
    association.pipelineName = sdwDataset.pipelineName;
    return association;
}

export function getITag(name?: string, key?: string | number): DataTag | undefined {
    if (name === "0" || key === "0" || name === "-1" || key === "-1" || key === -1) return undefined;
    if (!name && !key) return undefined;
    if (name && key) {
        return { name: name, key: key };
    }
    if (name && !key) {
        return { name: name, key: name };
    }
    if (!name && key) {
        return { name: key.toString(), key: key };
    }

    return undefined;
}

export interface IContactMethod{
    id: number;
    name: string;
    lastUpdated: Date;
}

export const EmptyContactMethod: IContactMethod = {
    id: 0,
    name: "",
    lastUpdated: new Date()
};

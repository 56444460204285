import { Link } from "@fluentui/react";

const IcmLink = (props: { link: string }) => {
    return (
        <Link
            href={props.link}
            target="_blank"
            style={{
                backgroundSize: "2rem auto",
                display: "inline-block",
                width: "2rem",
                height: "1.5rem",
                backgroundPosition: "left center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjEwMXB4IiBoZWlnaHQ9IjcycHgiIHZpZXdCb3g9IjAgMCAxMDEgNzIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEwMSA3MiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHJlY3QgeD0iMzQiIHk9IjIzIiBmaWxsPSIjMDA3OEQ0IiB3aWR0aD0iNyIgaGVpZ2h0PSIzNCIvPjxwb2x5bGluZSBmaWxsPSIjMDA3OEQ0IiBwb2ludHM9Ijk2LDM2Ljc1IDk2LDU3IDEwMSw1NyAxMDEsMjMgOTYsMjMgODUsNTAuMjUgNzQsMjMgNjksMjMgNjksNTcgNzQsNTcgNzQsMzYuNzUgODIuNzUsNTcgODcuMjUsNTcgOTYsMzYuNzUgIi8+PHBvbHlsaW5lIGZpbGw9IiMwMDc4RDQiIHBvaW50cz0iOTYsMzYuNzUgOTYsNTcgMTAxLDU3IDEwMSwyMyA5NiwyMyA4NSw1MC4yNSA3NCwyMyA2OSwyMyA2OSw1NyA3NCw1NyA3NCwzNi43NSA4Mi43NSw1NyA4Ny4yNSw1NyA5NiwzNi43NSAiLz48cGF0aCBmaWxsPSIjMDA3OEQ0IiBkPSJNNTguNSw1MmMtNC4xNDIsMC03LjUtMy4zNTgtNy41LTcuNXMzLjM1OC03LjUsNy41LTcuNWMyLjE3NiwwLDQuMTMsMC45MzMsNS41LDIuNDEzdi02LjU2OWMtMS42Ny0wLjc5LTMuNTMtMS4yNDQtNS41LTEuMjQ0Yy03LjEyNSwwLTEyLjksNS43NzYtMTIuOSwxMi45czUuNzc1LDEyLjksMTIuOSwxMi45YzEuOTcsMCwzLjgzLTAuNDU1LDUuNS0xLjI0NHYtNi41NjlDNjIuNjMsNTEuMDY3LDYwLjY3Niw1Miw1OC41LDUyeiIvPjxwYXRoIGZpbGw9IiMwMDc4RDQiIGQ9Ik01OC41LDUyYy00LjE0MiwwLTcuNS0zLjM1OC03LjUtNy41czMuMzU4LTcuNSw3LjUtNy41YzIuMTc2LDAsNC4xMywwLjkzMyw1LjUsMi40MTN2LTYuNTY5Yy0xLjY3LTAuNzktMy41My0xLjI0NC01LjUtMS4yNDRjLTcuMTI1LDAtMTIuOSw1Ljc3Ni0xMi45LDEyLjlzNS43NzUsMTIuOSwxMi45LDEyLjljMS45NywwLDMuODMtMC40NTUsNS41LTEuMjQ0di02LjU2OUM2Mi42Myw1MS4wNjcsNjAuNjc2LDUyLDU4LjUsNTJ6Ii8+PGc+PHBhdGggZmlsbD0iIzAwNzhENCIgZD0iTTY0LjAyNSw2MGwyLjkyNSw0Ljc0Nkw2Ni44MDQsNjVIOC4zMmwtMC4xOTUtMC4zNGwyOS4zOS01Ny4yNDlsMTEuODYzLDI0LjA4NmMxLjkwNC0xLjM0LDQuMTEzLTIuMjY0LDYuNTAyLTIuNjYzTDQxLjU3NiwwaC04LjEyM0wwLjE1NCw2NC44NTZMNC4yNzksNzJoNjYuNTY2bDQuMDc2LTcuMDU5TDcxLjg5Niw2MEg2NC4wMjV6Ii8+PC9nPjwvc3ZnPg==)`
            }}
        />
    );
};

export default IcmLink;

export interface IFeedCategory {
    id: number;
    name: string;
    description?: string;
    dataSourceOwnerId: number;
    dataSourceOwnerName: string;
    dataSourceOwnerEmail: string;
    dataSourceOwnerObjectId: string;

    dataBusinessOwnerId: number;
    dataBusinessOwnerName: string;
    dataBusinessOwnerEmail: string;
    dataBusinessOwnerObjectId: string;

    secondaryUseAccessApproverId: number;
    secondaryUseAccessApproverName: string;
    secondaryUseAccessApproverEmail: string;
    secondaryUseAccessApproverObjectId: string;

    securityUseAccessApproverId: number;
    securityUseAccessApproverName: string;
    securityUseAccessApproverEmail: string;
    securityUseAccessApproverObjectId: string;

    sourceCardLink?: string;
    comments?: string;
    feedIds?: number[];
}
// create empty IFeedCategory object
export const EmptyFeedCategory: IFeedCategory = {} as IFeedCategory;

import axios from "./SipMonClient";
import { authProvider } from "../service/authProvider";

export const getPhoto = async (): Promise<string> => {
    try {
        const account = authProvider.getActiveAccount();
        if (account) {
            const token = await authProvider.acquireTokenSilent({
                scopes: [`${process.env.REACT_APP_GRAPH_ENDPOINT}/user.read`],
                account
            });

            const headers = {
                Authorization: token.accessToken,
                "Content-Type": "image/jpg"
            };

            const { data } = await axios.get<any>(
                `${process.env.REACT_APP_GRAPH_ENDPOINT}/v1.0/me/photos/48x48/$value`,
                {
                    headers,
                    responseType: "blob"
                }
            );

            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(data);
            return blobUrl;
        } else {
            return "";
        }
    } catch (er) {
        console.error(er);
        return "";
    }
};

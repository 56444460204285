import { IColumn, IconButton } from "@fluentui/react";
import { History } from "history";
import { AdminUser } from "../constants";
import { IDatasetAssociation } from "../models/Dataset";
import { isInRole } from "../service/authProvider";
import { _renderShimmer } from "./utils";

export function generateDatasetAssociationColumns(
    history: History,
    invokeDeleteCallback: Function,
    allowDelete: boolean
): IColumn[] {
    const cols: IColumn[] = [
        {
            key: "type",
            name: "Type",
            fieldName: "type",
            minWidth: 75,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: "endpoint",
            name: "Endpoint",
            fieldName: "endpoint",
            minWidth: 275,
            maxWidth: 400,
            isResizable: true
        },
        {
            key: "tags",
            name: "Tags",
            fieldName: "tags",
            minWidth: 100,
            maxWidth: 300,
            isResizable: true
        },
        {
            key: "pipelineName",
            name: "Pipeline",
            fieldName: "pipelineName",
            minWidth: 100,
            maxWidth: 300,
            isResizable: true
        },
        {
            key: "displayincatalog",
            name: "Display In Catalog",
            fieldName: "displayincatalog",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: IDatasetAssociation) => {
                return item.displayInCatalog ? "Yes" : "No";
            }
        }
    ];
    if (isInRole(AdminUser)) {
        cols.push({
            key: "edit",
            name: "Edit",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDatasetAssociation) => renderEditColumn(item, history)
        });
    }
    if (isInRole(AdminUser)) {
        cols.push({
            key: "delete",
            name: "Delete",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDatasetAssociation) => renderDeleteColumn(item, invokeDeleteCallback, allowDelete)
        });
    }

    return cols;
}

export function renderEditColumn(item: IDatasetAssociation, history: History) {
    const pathParts = history.location.pathname.split("/").slice(-2);
    let actionPath = "";
    if (pathParts[0] === "datasets") {
        actionPath = pathParts[1];
    } else {
        actionPath = pathParts.join("/");
    }

    if (item.name === "shimmer") {
        return _renderShimmer();
    }
    let path = "";
    if (item.type == "Adls") {
        path = `/AdlsDataset/${actionPath}`;
    } else if (item.type == "Kusto") {
        path = `/KustoDataset/${actionPath}`;
    } else {
        path = `/SDWDataset/${actionPath}`;
    }
    return (
        <IconButton
            title={item.name + " Edit Button"}
            className="routing"
            onClick={() => {
                history.push(path);
            }}
            iconProps={{ iconName: "Edit" }}
            allowDisabledFocus
        />
    );
}

// Delete Function Callback
export function renderDeleteColumn(item: IDatasetAssociation, invokeDeleteCallback: Function, allowDelete: boolean) {
    if (item.name === "shimmer") {
        return _renderShimmer();
    }
    return (
        <IconButton
            title={
                !allowDelete
                    ? "Currenlty disabled, as it's mandatory to have atleast one consumption layer association."
                    : item.name + " Delete Button"
            }
            className="routing"
            onClick={() => {
                invokeDeleteCallback(item.id, item.type);
            }}
            iconProps={{ iconName: "Delete" }}
            allowDisabledFocus
            disabled={!allowDelete}
        />
    );
}

import { Checkbox, DefaultPalette, Stack } from "@fluentui/react";
import { Link } from "@fluentui/react/lib/Link";
import { useHistory } from "react-router";
import { FlexIcon } from "../components/common/FlexIcon";
import { AdminUser } from "../constants";
import { AccessRequest } from "../models";
import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { addSortLabels, renderViewColumn } from "./utils";

export function generateAccessRequestColumns(): IStandardColumn[] {
    const history = useHistory();

    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Access Package Name",
            fieldName: "name",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "icmId",
            name: "Via",
            fieldName: "icmId",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true,
            onRender: (req: AccessRequest) => (req.icmId ? "ICM" : "Data Catalog")
        },
        {
            key: "approvalStatus",
            name: "Approval Status",
            fieldName: "approvalStatus",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "requestedBy",
            name: "Requested By",
            fieldName: "requestedBy",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "owners",
            name: "Owners",
            fieldName: "owners",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },

        {
            key: "objectName",
            name: "Object Name",
            fieldName: "objectName",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "isRoleGroup",
            name: "Role Group",
            fieldName: "isRoleGroup",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true,
            onRender: (item: AccessRequest) => {
                return (
                    <Stack horizontal horizontalAlign="center">
                        <Checkbox checked={item.isRoleGroup} />
                    </Stack>
                );
            }
        },
        {
            key: "lastUpdated",
            name: "Last Updated",
            fieldName: "lastUpdated",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true,
            isDate: true,
            onRender: (req: AccessRequest) =>
                new Date(req.lastUpdated).toLocaleString("en-US", {
                    timeZoneName: "short"
                })
        }
    ];

    if (isInRole(AdminUser)) {
        cols.push({
            key: "id",
            name: "View/Edit",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: AccessRequest) => renderViewColumn(item, "accessRequests", history)
        });
    }

    addSortLabels(cols);
    return cols;
}

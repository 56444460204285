import { History } from "history";
import { AdminUser } from "../constants";
import { IRemoteServerConfig } from "../models";
import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { ActionType } from "../types";
import { addSortLabels, renderViewColumn } from "./utils";

export function RemoteServerActionColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "serverName",
            name: "Remote Server Name",
            fieldName: "serverName",
            filterBy: true,
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
            isSorted: true
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            filterBy: true,
            minWidth: 75,
            maxWidth: 250,
            isResizable: true,
            isSorted: true,
            onRender: (item: IRemoteServerConfig) => renderStatusColumn(item)
        },
        {
            key: "reboot",
            name: "Reboot - Latest Action Time",
            fieldName: "reboot",
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
            onRender: (item: IRemoteServerConfig) => renderActionButton(item, item.reboot, ActionType.Reboot)
        },
        {
            key: "restartService",
            name: "Service Restart - Latest Action Time",
            fieldName: "restartService",
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
            onRender: (item: IRemoteServerConfig) =>
                renderActionButton(item, item.restartService, ActionType["Service Restart"])
        }
    ];
    if (isInRole(AdminUser)) {
        cols.push({
            key: "id",
            name: "History",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IRemoteServerConfig) => renderViewColumn(item, "administrationhistory", history)
        });
    }
    addSortLabels(cols);
    return cols;
}

function renderStatusColumn(item: IRemoteServerConfig) {
    if (item != null) {
        if (item.status == "Online") {
            return (
                <>
                    <p style={{ color: "green" }}>{item.status}</p>
                </>
            );
        } else
            return (
                <>
                    <p style={{ color: "red" }}>{item.status}</p>
                </>
            );
    }
}

// Custom method to render Action button based on it's eligibility
export function renderActionButton(item: IRemoteServerConfig, isEnabled: boolean, actionType: ActionType) {
    if (actionType == ActionType.Reboot) {
        if (item.reboot == true) {
            if (item.rebootStatus != null) {
                try {
                    const obj = JSON.parse(item.rebootStatus);
                    if (obj != null && obj.completedTime) {
                        return (
                            <>
                                <div style={{ paddingTop: "8px" }}>{formatDate(obj.completedTime)}</div>
                            </>
                        );
                    }
                } catch (e) {
                    return (
                        <>
                            <div style={{ paddingTop: "8px" }}>{item.rebootStatus}</div>
                        </>
                    );
                }
            }
        } else
            return (
                <>
                    <i>
                        'Reboot' action has been disabled
                        <br /> on this server
                    </i>
                </>
            );
    } else if (actionType == ActionType["Service Restart"]) {
        if (item.restartService == true) {
            if (item.serviceRestartStatus != null) {
                try {
                    const obj = JSON.parse(item.serviceRestartStatus);
                    if (obj != null && obj.completedTime) {
                        return (
                            <>
                                <div style={{ paddingTop: "8px" }}>{formatDate(obj.completedTime)}</div>
                            </>
                        );
                    }
                } catch (e) {
                    return (
                        <>
                            <div style={{ paddingTop: "8px" }}>{item.serviceRestartStatus}</div>
                        </>
                    );
                }
            }
        } else
            return (
                <>
                    <i>
                        'Service Restart' has been disabled
                        <br /> on this server
                    </i>
                </>
            );
    }
    return <></>;
}

export function formatDate(dateValue) {
    if (dateValue != null) {
        let isoDateTime = new Date(dateValue);
        let localDateTime = isoDateTime.toLocaleDateString() + " " + isoDateTime.toLocaleTimeString();
        return localDateTime;
    }
    return <></>;
}

import { useParams } from "react-router-dom";

type UseGetActionAndIdParams = {
    dsId?: number;
    isAdd?: boolean;
    isDatasetAdd?: boolean;
};

const useGetActionAndId = (): UseGetActionAndIdParams => {
    const { action, dsID } = useParams<{ dsID: string; action: string }>();

    const isAdd = action === "New" || !dsID;
    const isDatasetAdd = action === "New" && !dsID;
    const dsId = dsID ? parseInt(dsID) : undefined;

    return { dsId, isAdd, isDatasetAdd };
};

export { useGetActionAndId };

import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { TextField, Label, TextFieldBase, IconButton } from "@fluentui/react";
import "../../styles/App.css";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { EmptyFeedFreshness, IFeedFreshness } from "../../models/FeedFreshness";
import ExpectedRefreshIntervalDonut from "./trends-charts/ExpectedRefreshIntervalDonut";
import SlaHistoryDonut from "./trends-charts/SlaHistoryDonut";
import { feedFreshnessSelector } from "../../pages/Selectors";
import { feedFreshnessHistorySelector } from "../../pages/Selectors";
import { StateActionType } from "../../types";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { getAllFeedFreshness, getFeedFreshnessHistory } from "../../reducers/FeedFreshness";

const textfieldControlStyles = {
    root: {
        maxWidth: "300px"
    }
};

interface IParentProps {
    feedId: number;
}

interface IProps extends IParentProps {
    feedFreshnesses?: IFeedFreshness[];
    onClose: () => void;
    hasHistory: () => Boolean;
    type: number;
}

export function TrendsFeedFreshness(props: IProps) {
    const nameRef = useRef<TextFieldBase>(null);

    const [currentFeedFreshness, setCurrentFeedFreshness] = useState<IFeedFreshness>(EmptyFeedFreshness);

    const dispatch = useAppDispatch();
    const feedFreshnessProperties = useAppSelector(feedFreshnessSelector);

    const { timestamps, error } = useAppSelector(feedFreshnessHistorySelector);

    useEffect(() => {
        if (feedFreshnessProperties.state[StateActionType.LoadAll] === "INIT") {
            dispatch(getAllFeedFreshness(0));
        }

        if (props.feedId && props.feedFreshnesses && currentFeedFreshness.feedId === 0) {
            if (props.feedFreshnesses.length > 0) {
                const currentFeedFreshness = getFeedFromStore(props.feedFreshnesses, props.feedId);
                if (currentFeedFreshness) {
                    setCurrentFeedFreshness(currentFeedFreshness);
                }
            }
        }
    }, [props]);

    useEffect(() => {
        if (currentFeedFreshness && currentFeedFreshness.feedId !== 0) {
            dispatch(getFeedFreshnessHistory({ type: props.type, id: currentFeedFreshness.feedId }));
        }
    }, [currentFeedFreshness.feedId]);

    function getFeedFromStore(feedlist: IFeedFreshness[], feedId: number): IFeedFreshness {
        if (feedlist.length > 0) {
            const findFeed = feedlist.find((item) => item.feedId === feedId);
            if (findFeed) return findFeed;
        }
        return EmptyFeedFreshness;
    }

    return (
        <>
            {" "}
            <form id={"create-post-form"} noValidate={true}>
                <div className="top-margin25 left-margin40">
                    <span style={{ float: "right" }}>
                        <IconButton
                            title="Close"
                            className="routing"
                            onClick={() => props.onClose()}
                            iconProps={{ iconName: "Cancel" }}
                            allowDisabledFocus
                        />
                    </span>

                    <div className="top-margin25">
                        <Label className="label">Name </Label>
                        <TextField
                            id="component"
                            componentRef={nameRef}
                            ariaLabel="Name"
                            styles={textfieldControlStyles}
                            value={currentFeedFreshness.feedName.toString()}
                            className="name"
                            disabled={true}
                            readOnly={true}
                        />
                        <Label className="label"> Storage </Label>
                        <TextField
                            id="component"
                            ariaLabel="Storage"
                            styles={textfieldControlStyles}
                            value={currentFeedFreshness.storageAccountName}
                            className="storage"
                            disabled={true}
                            readOnly={true}
                        />
                        <Label className="label"> Last Refresh </Label>
                        <TextField
                            id="component"
                            ariaLabel="LastRefresh"
                            styles={textfieldControlStyles}
                            value={currentFeedFreshness.lastRefresh.toLocaleString("en-US", { timeZoneName: "short" })}
                            className="lastRefresh"
                            disabled={true}
                            readOnly={true}
                        />
                        <Label className="label"> Expected Refresh Interval</Label>
                        <TextField
                            id="component"
                            ariaLabel="ExpectedRefreshInterval"
                            styles={textfieldControlStyles}
                            value={populateERIField(
                                currentFeedFreshness.expectedRefreshInterval!,
                                currentFeedFreshness.expectedRefreshIntervalUnit!
                            )}
                            className="expectedRefreshInterval"
                            disabled={true}
                            readOnly={true}
                        />
                        <Label className="label"> Last Refresh Interval </Label>
                        <TextField
                            id="component"
                            ariaLabel="LastRefreshInterval"
                            styles={textfieldControlStyles}
                            value={populateIntervalField(currentFeedFreshness.lastRefreshFrequency)}
                            className="expectedRefreshInterval"
                            disabled={true}
                            readOnly={true}
                        />
                        <Label className="label"> SLA </Label>
                        <TextField
                            id="component"
                            ariaLabel="SLA"
                            styles={textfieldControlStyles}
                            value={currentFeedFreshness.slaThresholdInHours + "h"}
                            className="SLA"
                            disabled={true}
                            readOnly={true}
                        />
                        <Label className="label"> Time Over SLA </Label>
                        <TextField
                            id="component"
                            ariaLabel="TimeOverSLA"
                            styles={textfieldControlStyles}
                            value={populateTimeOverSLAField(
                                currentFeedFreshness.timeOverSlaInHours,
                                currentFeedFreshness.isDormant
                            )}
                            className="TimeOverSLA"
                            disabled={true}
                            readOnly={true}
                        />
                    </div>
                    {timestamps && (
                        <div style={{ margin: "10px 0" }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <ExpectedRefreshIntervalDonut
                                                feedFreshness={currentFeedFreshness}
                                                refreshTimeStamps={timestamps}
                                            />
                                        </td>
                                        <td>
                                            <SlaHistoryDonut
                                                feedFreshness={currentFeedFreshness}
                                                historicalTimestamps={timestamps}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </form>
        </>
    );
}

const populateTimeOverSLAField = (timeOverSLA: number, isDormant: boolean) => {
    if (isDormant) {
        return "Dormant";
    } else if (timeOverSLA > 0) {
        const hrs = Math.floor(timeOverSLA);
        const mins = Math.floor((timeOverSLA - hrs) * 60);
        const fieldContent = hrs + "h " + mins + "m";
        return fieldContent;
    } else {
        return "Not Over SLA";
    }
};

const populateERIField = (formExpectedInterval: number, formExpectedIntervalUnit: string) => {
    const eri = formExpectedInterval;
    const unit = formExpectedIntervalUnit;

    if (eri === -1 || (unit !== "Month" && unit !== "Week" && unit !== "Day" && unit !== "Hour" && unit !== "Minute")) {
        return "Unknown";
    }

    if (unit === "Minute") {
        const fieldContent = "0h " + eri + "m";
        return fieldContent;
    }
    if (unit === "Hour") {
        const fieldContent = eri + "h " + " 0m";
        return fieldContent;
    }
    if (unit === "Day") {
        const fieldContent = 24 * eri + "h 0m";
        return fieldContent;
    }
    if (unit === "Week") {
        const fieldContent = 168 * eri + "h 0m";
        return fieldContent;
    }
    if (unit === "Month") {
        const fieldContent = 730 * eri + "h 0m";
        return fieldContent;
    }
};

const populateIntervalField = (formInterval: number) => {
    const interval = formInterval;
    const hrs = Math.floor(interval);
    const mins = Math.floor((interval - hrs) * 60);
    const fieldContent = hrs + "h " + mins + "m ";
    return fieldContent;
};

const mapStateToProps = (store: IApplicationState, ownProps: IProps) => {
    return {
        feedId: ownProps.feedId,
        feedFreshnesses: store.feedFreshness.entities
    };
};

export default connect(mapStateToProps, null)(TrendsFeedFreshness);

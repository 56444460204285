export interface IPowerBIReports {
    id: number,
    name: string;
    description: string;
    endpoint: string;
    link: string,
    tags: string,
    repoLink: string,
    contacts: string,
    category: string,
    sysLastModifiedTime: Date
}

export const EmptyPowerBIReports: IPowerBIReports = {
    id: 0,
    name: "",
    description: "",
    endpoint: "",
    link: "",
    tags: "",
    repoLink: "",
    contacts: "",
    category: "",
    sysLastModifiedTime: new Date()
};

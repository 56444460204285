import { IDropdownOption, Dropdown, Text, Stack, Icon } from "@fluentui/react";

import { useEffect } from "react";

const PreferredSelect = ({
    selectedEntryId,
    entries,
    setEntryId,
    title,
    disabled,
    showLabel,
    urlParam
}: {
    selectedEntryId?: string;
    showLabel?: boolean;
    title: string;
    urlParam?: string;
    entries?: { id: string; displayName: string }[];
    setEntryId: React.Dispatch<React.SetStateAction<string | undefined>> | React.Dispatch<React.SetStateAction<string>>;
    disabled?: boolean;
}) => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const entryId = urlParam && urlParams.get(urlParam);
        if (entries) {
            if (entryId) {
                setEntryId(entryId);
                return;
            }
            if (preferredEntryId) {
                setEntryId(preferredEntryId);
            } else {
                if (entries.length > 0) setEntryId(entries[0].id);
            }
        }
    }, [entries]);
    const preferredEntryId = localStorage.getItem(`preferred${title}`);
    const onRenderOption = (option?: IDropdownOption): JSX.Element => {
        return (
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                <Stack.Item>
                    <Icon
                        onClick={() => {
                            localStorage.setItem(`preferred${title}`, option!.key as string);
                        }}
                        iconName={option!.key === preferredEntryId ? "FavoriteStarFill" : "FavoriteStar"}
                        title={`Favorite ${title}`}
                        style={{ fontSize: "18px", color: "#F6C654", zIndex: 9999 }}
                    />
                </Stack.Item>

                <Stack.Item align="center">
                    <Text>{option!.text}</Text>
                </Stack.Item>
            </Stack>
        );
    };

    return (
        <Dropdown
            selectedKey={selectedEntryId}
            label={showLabel ? title : undefined}
            placeholder={`Select a ${title}`}
            styles={{ root: { minWidth: "300px" } }}
            options={
                entries
                    ? entries.map((entry) => ({
                          key: entry.id,
                          text: entry.displayName
                      }))
                    : []
            }
            onChange={(_ev, option) => (option ? setEntryId(option.key as string) : () => {})}
            onRenderOption={onRenderOption}
            disabled={disabled || !entries}
        />
    );
};

export default PreferredSelect;

import { IconButton } from "@fluentui/react";
import React from "react";
import { AdminUser } from "../constants";
import { IDirectoryAudit } from "../models";
import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { addSortLabels, renderViewColumn } from "./utils";
import { History } from "history";
import { FlexIcon } from "../components/common/FlexIcon";

export function generateDirectoryAuditColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "App Name",
            fieldName: "name",
            filterBy: true,
            minWidth: 75,
            maxWidth: 250,
            isResizable: true,
            isSorted: true
        },
        {
            key: "totalOwners",
            name: "Total Owners",
            fieldName: "totalOwners",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: "owners",
            name: "Owners",
            fieldName: "owners",
            filterBy: true,
            minWidth: 50,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: "isProduction",
            name: "Production",
            fieldName: "isProduction",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            onRender: (item: IDirectoryAudit) => {
                return (
                    <FlexIcon
                        iconName={item.isProduction ? "Checkmark" : "Cancel"}
                        title={item.isProduction ? "Production" : "Non-Production"}
                        color={item.isProduction ? "green" : "red"}
                    />
                );
            }
        },
        {
            key: "url",
            name: "Link",
            fieldName: "url",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            onRender: (item: IDirectoryAudit) => (
                <IconButton
                    title="Url"
                    className="routing"
                    href={item.url}
                    iconProps={{ iconName: "AzureLogo" }}
                    allowDisabledFocus
                    target="_blank"
                />
            )
        }
    ];

    if (isInRole(AdminUser)) {
        cols.push({
            key: "id",
            name: "View",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDirectoryAudit) => renderViewColumn(item, "directoryAudit", history)
        });
    }
    addSortLabels(cols);
    return cols;
}

import React, { useEffect } from "react";
import AdlsSummary from "../components/AdlsCapacityTrend/AdlsSummary";
function SizingDataList() {
   
    return (
        <>

            <div>
                <AdlsSummary


                />
            </div>

        </>
    );
}
export default SizingDataList;
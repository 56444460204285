import axios from "axios";

import { getHeaders } from "../../api/SipMonClient";
import { StateActionType } from "../../types";
import { IDatasetAlert } from "./Types";
import { ISipApiResponse } from "../../models";


const sliceName = "datasetAlerts";

export const deleteDatasetAlertApi = async (id: number): Promise<ISipApiResponse<IDatasetAlert>> => {
    const { data, status } = await axios.delete(
        `api/Dataset/Alert/${id}`,
        getHeaders(sliceName, StateActionType.Delete)
    );

    return { data, message: "", status };
};

//Post Alert details
export const submitAlertInfo = async (formData: IDatasetAlert): Promise<ISipApiResponse<IDatasetAlert>> => {
    const { data, status } = await axios.post(
        `api/Dataset/SubmitAlert`,
        formData,
        getHeaders(sliceName, StateActionType.Add)
    );
    return { data, message: "", status };
};

// Get Dataset by Id
export const getDatasetAlertById = async (dsAlertID: number): Promise<ISipApiResponse<IDatasetAlert>> => {
    const { data, status } = await axios.get(`api/Dataset/getDatasetAlert/${dsAlertID}`);
    return {
        data,
        message: "",
        status
    };
};

// Get Dataset by Id
export const getAllDatasetAlert = async (): Promise<ISipApiResponse<IDatasetAlert[]>> => {
    const { data, status } = await axios.get(`api/Dataset/getAllDatasetAlert`);
    return {
        data,
        message: "",
        status
    };
};
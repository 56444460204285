import { IconButton, MessageBar, MessageBarType, PrimaryButton, Stack, Shimmer, Label } from "@fluentui/react";
import { ITag } from "@fluentui/react/lib/Pickers";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { AdlsAutoDiscoveryEntry, DatasetType, IAdlsDataset } from "../../models/Dataset";
import { dataSetSelector } from "../../pages/Selectors";
import { getAccessPackageReferenceAsync } from "../../reducers/AccessPackages";
import {
    addAdlsDataset,
    clearResponse,
    DatasetComponents,
    getAdlsDatasetByDSId,
    getAllDatasets,
    getAllDatasetTags,
    getAllPartitions,
    getAllStorageAccounts,
    getDatasetById,
    getDirectoryPath,
    setCurrentAdlsDataset,
    setCurrentDataset
} from "../../reducers/Dataset";
import CrudStatusDialog from "../common/CrudStatusDialog";
import { ShimmeredAutoComplete, ShimmeredComboBox, ShimmeredTextField, ShimmeredToggle } from "../common/Shimmered";
import AssociatePipeline from "./AssociatePipeline";
import { PipelineCommandButton } from "../Feed/PipelineCommandButton";
import { getShimmerStyles } from "../../columns/utils";
import { StackRow } from "../common/StackComponents";
import { useAssociatePipeline } from "../../hooks";
import StandardForm from "../common/StandardForm";
import { DisplayInCatalogToggle } from "./DisplayInCatalogToggle";
import SaveButtonContainer from "./SaveButtonContainer";
import { useGetActionAndId } from "./useGetActionAndId";
import DatasetTagSelection from "./DatasetTagSelection";
import { FieldState, anyFieldErrors, getITag, navigateToDataset } from "./utils";
import { LoadingOverLay } from "../common/LoadingOverLay";
import { DataTag } from "../common/AutoCompleteTextField";
import { parseNumberField } from "../common/utils";
import { checkAdlsDuplicate } from "../../api/DatasetApi";
import SaveModal from "./SaveModal";
import CrudStatusDialogV2 from "../common/CrudStatusDialogV2";

export function AdlsDatasetForm() {
    const history = useHistory();
    let { dsId, isAdd, isDatasetAdd } = useGetActionAndId();

    const dispatch = useAppDispatch();

    const {
        datasets,
        currentDataset,
        currentADLSDataset,
        storageAccounts,
        adlsAutoDiscoveryEntries,
        addADLSDatasetResponse,
        dsErrors,
        partitions,
        dsLoading
    } = useAppSelector(dataSetSelector);

    const [showAnnouncment, setShowAnnouncement] = useState(false);
    const [showPurgingAnnouncment, setShowPurgingAnnouncement] = useState(false);
    const [autoDiscoveryEntries, setAutoDiscoveryEntries] = React.useState<AdlsAutoDiscoveryEntry[]>([]);
    const [dupCheckInit, setDupCheckInit] = useState<boolean>(false);
    const [successMsg, setSuccessMsg] = useState<string>("");
    const [fieldState, setFieldState] = useState<FieldState>({
        message: "",
        errors: {}
    });

    async function checkDuplicate() {
        if (!currentADLSDataset) return;
        try {
            const isDup = await checkAdlsDuplicate(
                currentADLSDataset.id,
                currentADLSDataset.storageId,
                currentADLSDataset.path
            );
            if (isDup) {
                setFieldState({
                    ...fieldState,
                    errors: { storageId: "Storage account and path combination already exists" }
                });
            } else {
                setFieldState({ ...fieldState, errors: { storageId: "" } });
            }
        } catch (error) {
            setFieldState({ ...fieldState, errors: { storageId: "" } });
            console.error("An error occurred checking duplicates");
            console.error(JSON.stringify(error));
        }
    }

    useEffect(() => {
        setAutoDiscoveryEntries([]);

        if (currentADLSDataset?.storageId && currentDataset?.subscriptionId) {
            dispatch(
                getDirectoryPath({ subID: currentDataset.subscriptionId, storageID: currentADLSDataset.storageId })
            );
        }
    }, [currentADLSDataset?.storageId]);

    useEffect(() => {
        // avoid check on first load
        if (dupCheckInit) {
            if (currentADLSDataset?.storageId && currentADLSDataset?.path) {
                checkDuplicate();
            }
        } else {
            setDupCheckInit(true);
        }
    }, [currentADLSDataset?.storageId, currentADLSDataset?.path]);

    useEffect(() => {
        if (currentADLSDataset && currentADLSDataset.storageId !== -1 && storageAccounts) {
            const storageName = storageAccounts.find((x) => x.id === currentADLSDataset.storageId)?.name;
            if (storageName) {
                setCurrentAdlsDataset({ ...currentADLSDataset!, storageAccountName: storageName });
            }
        }
    }, [currentADLSDataset?.storageId, storageAccounts]);
    const [associatePipelineProps, setShowDialog] = useAssociatePipeline();
    // Refresh Current ADLS Dataset
    useEffect(() => {
        dispatch(getAllDatasetTags());
        if (dsId) {
            dispatch(getDatasetById(dsId));
            if (!isAdd) dispatch(getAdlsDatasetByDSId(dsId));
        }
    }, [dsId]);

    const setSelectedPathValue = (selValue: DataTag | undefined) => {
        if (!currentADLSDataset) return;
        setFieldState({ ...fieldState, errors: { storageId: "" } });

        if (!selValue) {
            dispatch(setCurrentAdlsDataset({ ...currentADLSDataset, path: "", container: "", fileExtension: "" }));
        } else {
            dispatch(
                setCurrentAdlsDataset({
                    ...currentADLSDataset,
                    path: selValue.name,
                    fileExtension: selValue.data[0],
                    isDeltaTable: selValue.data[1]
                })
            );
        }
    };

    useEffect(() => {
        dispatch(getAccessPackageReferenceAsync());
        dispatch(getAllDatasets());
        dispatch(getAllPartitions());
    }, [getAccessPackageReferenceAsync, getAllDatasets, getAllPartitions]);

    useEffect(() => {
        if (currentDataset && currentDataset.subscriptionId) {
            getStorageAccounts(currentDataset.subscriptionId);
        }
    }, [currentDataset]);

    useEffect(() => {
        if (adlsAutoDiscoveryEntries) {
            setAutoDiscoveryEntries(adlsAutoDiscoveryEntries);
        }
    }, [adlsAutoDiscoveryEntries]);

    // exit when the creation is complete
    useEffect(() => {
        if (addADLSDatasetResponse === "Success") {
            setSuccessMsg((p) => "Update completed successfully.");
        }
    }, [addADLSDatasetResponse]);

    function getStorageAccounts(subscriptionId: string) {
        if (!storageAccounts) {
            dispatch(getAllStorageAccounts(subscriptionId));
            if (currentADLSDataset) {
                dispatch(
                    getDirectoryPath({
                        subID: subscriptionId,
                        storageID: currentADLSDataset.storageId
                    })
                );
            }
        }
    }

    function validateRetentionInDays(value: any) {
        fieldState.errors.retentionInDays = "";
        if (value !== undefined && value < 181) {
            fieldState.errors.retentionInDays = "The value must be at least 181 days.";
        }
    }
    function onChange<K extends keyof IAdlsDataset>(value: any, stateKey: K) {

        if (currentADLSDataset) {
            const temp = { ...currentADLSDataset };

            temp[stateKey] = value;

            dispatch(setCurrentAdlsDataset(temp));
        }
    }

    function goBackToDataset() {
        if (currentDataset) dispatch(setCurrentDataset({ ...currentDataset, adlsDataset: currentADLSDataset }));
        setSuccessMsg("Adls dataset has been configured. You must save the dataset or changes will not be saved.");
        setShowAnnouncement(true);
    }

    function cancelAndGoBackToDataset() {
        dispatch(setCurrentAdlsDataset(undefined));
        navigateToDataset(history, isAdd, dsId);
    }

    function isFormValid() {
        if (currentADLSDataset) {
            if (anyFieldErrors(fieldState)) return false;

            if (!currentADLSDataset.path || currentADLSDataset.path.trim().length === 0) return false;
            if (!currentADLSDataset.storageId) return false;
            if (!currentADLSDataset.fileExtension || currentADLSDataset.fileExtension?.trim().length === 0)
                return false;
            if (!currentADLSDataset.pipelineId) return false;
            if (currentADLSDataset.isPurgingEnabled && currentADLSDataset.isDeltaTable && !(!!currentADLSDataset.purgeBy)) return false;
        }
        return true;
    }

    function submitAdlsDatasetDetails() {
        if (!currentADLSDataset || !currentDataset) return;
        setSuccessMsg((p) => "Saving ADLS dataset...");
        setShowAnnouncement(true);
        dispatch(setCurrentDataset({ ...currentDataset, adlsDataset: currentADLSDataset }));

        dispatch(addAdlsDataset(currentADLSDataset));
    }

    function resetForm() {
        dispatch(clearResponse("addADLSDatasetResponse"));
        setShowAnnouncement(false);
        navigateToDataset(history, isAdd, dsId);
    }

    function setStorageAccount(tag: ITag | undefined) {
        if (!currentADLSDataset) return;

        setFieldState({ ...fieldState, errors: { storageId: "" } });
        const temp = { ...currentADLSDataset };
        if (!tag) {
            temp.path = "";
            temp.container = "";
            temp.fileExtension = "";
            temp.isDeltaTable = undefined;
            temp.storageId = 0;
            temp.storageAccountName = "";
            dispatch(setCurrentAdlsDataset(temp));
            return;
        }

        // look up the storage account by name
        const storageAccount = storageAccounts?.find((x) => x.name === tag?.name);
        if (!storageAccount) return;

        temp.path = "";
        temp.container = "";
        temp.fileExtension = "";
        temp.isDeltaTable = undefined;
        temp.storageId = storageAccount.id;
        temp.storageAccountName = storageAccount.name;
        dispatch(setCurrentAdlsDataset(temp));

        return;
    }

    function handlePartitionChange(tags?: ITag[]) {
        if (!currentADLSDataset) return;

        if (!tags) {
            dispatch(setCurrentAdlsDataset({ ...currentADLSDataset, partitionSchema: "" }));
            return;
        }
        const temp = { ...currentADLSDataset };

        temp.partitionSchema = Object.keys(tags)
            .map((key) => `${tags[key].name}`)
            .join(",");

        dispatch(setCurrentAdlsDataset(temp));
    }

    return (
        <StandardForm widthPercent={70} mediumWidthPercent={90} smallWidthPercent={90}>
            <LoadingOverLay
                isOverlayVisible={
                    dsLoading[DatasetComponents.currentADLSDataset] || dsLoading[DatasetComponents.AdlsDataset]
                }
            />
            {dsErrors[DatasetComponents.AdlsDataset] && (
                <MessageBar messageBarType={MessageBarType.error} title="ADLS Dataset Creation Error">
                    {dsErrors[DatasetComponents.AdlsDataset]}
                </MessageBar>
            )}
            <CrudStatusDialogV2
                errorMsg={undefined}
                success={true}
                show={showPurgingAnnouncment}
                title={"Purging related configuration"}
                message={"Please ensure that configured Retention Days value is accurate"}
                onClose={() => setShowPurgingAnnouncement(false)}
            />
            <SaveModal
                hidden={!showAnnouncment}
                onClose={resetForm}
                successMsg={successMsg}
                error={dsErrors[DatasetComponents.AdlsDataset]}
                loading={dsLoading[DatasetComponents.AdlsDataset]}
            />
            <Stack.Item align="end" style={{ textAlign: "right" }}>
                <IconButton
                    title="Close"
                    className="routing"
                    onClick={cancelAndGoBackToDataset}
                    iconProps={{ iconName: "Cancel" }}
                    allowDisabledFocus
                />
            </Stack.Item>

            <DisplayInCatalogToggle
                enabled={isAdd || !!currentADLSDataset}
                checked={currentADLSDataset?.displayInCatalog || false}
                onChange={(_ev, checked) => onChange(checked, "displayInCatalog")}
            />

            <StackRow columnWidth={40}>
                {!isAdd && (
                    <ShimmeredComboBox
                        loading={!datasets}
                        cbxLabel="Dataset"
                        selectedKey={currentDataset?.id || 0}
                        placeholder="Select Dataset"
                        autoComplete="on"
                        options={datasets?.map((b) => ({ key: b.id!, text: b.name })) || []}
                        onChange={(_ev, option) => onChange(parseInt(option!.key as string), "datasetId")}
                        isStackItem
                    />
                )}
                <Stack>
                    <Label className="label">Pipeline Name</Label>
                    <Shimmer
                        isDataLoaded={isAdd || !!currentADLSDataset}
                        ariaLabel="Loading Feed Pipeline Name"
                        styles={getShimmerStyles()}
                    >
                        <PipelineCommandButton
                            popUpShow={() => setShowDialog({ ...associatePipelineProps, showDialog: true })}
                            pipelineId={currentADLSDataset?.pipelineId!}
                            name={currentADLSDataset?.pipelineName!}
                            editDisabled={false}
                            datafactoryAuthorLink={currentADLSDataset?.datafactoryAuthorLink!}
                        />
                    </Shimmer>
                </Stack>
            </StackRow>
            <StackRow columnWidth={40}>
                <ShimmeredAutoComplete<ITag>
                    label="Storage Account"
                    loading={!isAdd && !currentADLSDataset}
                    itemName="storage accounts"
                    disabled={!storageAccounts || storageAccounts.length === 0}
                    sendSelectedValue={setStorageAccount}
                    data={
                        storageAccounts
                            ?.map((entry) => ({ key: entry.id, name: entry.name }))
                            .sort((a, b) => a.name.localeCompare(b.name)) || []
                    }
                    val={getITag(currentADLSDataset?.storageAccountName)}
                    placeHolderText={`Start typing for suggestions`}
                    errorMessage={fieldState.errors.storageId}
                />

                <ShimmeredAutoComplete<DataTag>
                    label="Path"
                    loading={!isAdd && !currentADLSDataset}
                    itemName="paths"
                    allowFreeform
                    disabled={!currentADLSDataset?.storageAccountName}
                    sendSelectedValue={setSelectedPathValue}
                    data={autoDiscoveryEntries.map((entry) => ({
                        key: entry.path,
                        name: entry.path,
                        data: [entry.fileExtension, entry.isDeltaTable]
                    }))}
                    val={getITag(
                        currentADLSDataset?.path
                            ? `${currentADLSDataset.container}${currentADLSDataset.container ? "/" : ""}${currentADLSDataset.path
                            }`
                            : undefined,
                        currentADLSDataset?.path
                    )}
                    placeHolderText={`Start typing for suggestions, format: <containername>/<path>`}
                    errorMessage={fieldState.errors.storageId}
                />
            </StackRow>
            <StackRow columnWidth={40}>
                <ShimmeredAutoComplete<ITag[]>
                    label="Partition By"
                    loading={!isAdd && !currentADLSDataset}
                    itemName="partitions"
                    disabled={false}
                    sendSelectedValue={handlePartitionChange}
                    allowFreeform
                    multiSelect
                    data={partitions?.map((x) => ({ key: x.id, name: x.name })) || []}
                    val={
                        currentADLSDataset?.partitionSchema
                            ? currentADLSDataset?.partitionSchema.split(",").map((x) => ({ name: x, key: x })) || []
                            : []
                    }
                    placeHolderText={`Start typing for suggestions, format: <containername>/<path>`}
                />

                <ShimmeredTextField
                    loading={!isAdd && !currentADLSDataset}
                    txtLabel="File Extension"
                    onChange={(_e, newValue) => {
                        if (newValue !== undefined && newValue.length <= 200) onChange(newValue, "fileExtension");
                    }}
                    required
                    isStackItem
                    value={currentADLSDataset?.fileExtension || ""}
                ></ShimmeredTextField>
            </StackRow>

            <StackRow columnWidth={40}>
                <ShimmeredToggle
                    loading={!isAdd && !currentADLSDataset}
                    toggleLabel="Automatic Purge Status"
                    title={`Purging is ${currentADLSDataset?.isPurgingEnabled ? "enabled" : "not enabled"}`}
                    onChange={(_e, newValue) => {
                        onChange(newValue, "isPurgingEnabled");
                        if (newValue) {
                            setShowPurgingAnnouncement(true)
                            validateRetentionInDays(currentADLSDataset?.retentionInDays);
                        }
                    }}
                    isStackItem
                    checked={currentADLSDataset?.isPurgingEnabled || false}
                />
                <ShimmeredToggle
                    loading={!isAdd && !currentADLSDataset}
                    toggleLabel="Delta Table"
                    title={`${currentADLSDataset?.isDeltaTable ? "Is a" : "Is not a"} delta table`}
                    onChange={(_e, newValue) => {
                        onChange(newValue, "isDeltaTable");
                    }}
                    isStackItem
                    checked={currentADLSDataset?.isDeltaTable || false}
                />
            </StackRow>
            {currentADLSDataset?.isPurgingEnabled && currentADLSDataset?.isDeltaTable ? (
                <StackRow columnWidth={40} horizontalAlign="start" verticalAlign="start" >
                    <div style={{ display: "inline" }}>
                        <ShimmeredComboBox
                            required={currentADLSDataset?.isPurgingEnabled && currentADLSDataset?.isDeltaTable}
                            isStackItem={true}
                            loading={!datasets}
                            selectedKey={currentADLSDataset?.purgeBy || ""}
                            cbxLabel={"Purge by"}
                            placeholder="Select a purge by value"
                            options={partitions?.filter(x => x.name.toLowerCase().indexOf("date") != -1).map((x) => ({ key: x.name, name: x.name, text: x.name })) || []}
                            autoComplete="on"
                            onChange={(_ev, option) => onChange(option!.text, "purgeBy")}
                        />
                        <label style={{ fontSize: "small", }} ><b>Note:</b> Column that you want to purge by when dataset meets the retention policy</label>
                    </div>
                </StackRow>
            ) : <></>
            }

            <StackRow columnWidth={40}>
                <ShimmeredTextField
                    loading={!isAdd && !currentADLSDataset}
                    id="updateFrequencyInHours"
                    txtLabel="Update frequency in hours"
                    required
                    suffix="hrs"
                    isStackItem
                    numeric
                    onChange={(_e, newValue) => {
                        onChange(parseNumberField(newValue), "updateFrequencyInHours");
                    }}
                    value={currentADLSDataset?.updateFrequencyInHours?.toString() || "24"}
                />

                <ShimmeredTextField
                    loading={!isAdd && !currentADLSDataset}
                    id="SLAinhours"
                    txtLabel="SLA in hours"
                    required
                    suffix="hrs"
                    isStackItem
                    numeric
                    onChange={(_e, newValue) => {
                        onChange(parseNumberField(newValue), "slaThresholdInHours");
                    }}
                    value={currentADLSDataset?.slaThresholdInHours?.toString() || "24"}
                />
            </StackRow>
            <StackRow columnWidth={40}>
                <ShimmeredTextField
                    isStackItem
                    loading={!isAdd && !currentADLSDataset}
                    id="retentionDays"
                    txtLabel="Purge Retention Days"
                    errorMessage={fieldState.errors.retentionInDays}
                    numeric
                    onChange={(_e, newValue) => {
                        onChange(parseNumberField(newValue), "retentionInDays");
                        validateRetentionInDays(newValue);
                    }}
                    value={currentADLSDataset?.retentionInDays?.toString() || "181"}
                    suffix="days"
                />

                <DatasetTagSelection
                    type={DatasetType.Adls}
                    isLoading={!isAdd && !currentADLSDataset}
                    disabled={!currentDataset}
                />
            </StackRow>

            <SaveButtonContainer>
                <PrimaryButton
                    text={isDatasetAdd ? "Next" : "Save"}
                    type="button"
                    disabled={!isFormValid()}
                    onClick={() => {
                        isDatasetAdd ? goBackToDataset() : submitAdlsDatasetDetails();
                    }}
                ></PrimaryButton>
                <PrimaryButton text="Close" type="button" onClick={cancelAndGoBackToDataset}></PrimaryButton>
            </SaveButtonContainer>

            {associatePipelineProps.showDialog && (
                <AssociatePipeline datasetName="currentADLSDataset" {...associatePipelineProps} />
            )}
        </StandardForm>
    );
}
export default AdlsDatasetForm;

import React, { Dispatch, SetStateAction } from "react";

import DashboardItem from "./DashboardItem";
import { IFeedFreshness } from "../../../models/FeedFreshness";
import { Icon, ISliderProps, Slider, TooltipHost } from "@fluentui/react";
import { SharedColors } from "@uifabric/fluent-theme";
import { ITolerance } from "../../../pages/FeedFreshnessList";
import { InfoToolTip } from "../../common/InfoToolTip";
import { DonutChart } from "@fluentui/react-charting";
import { ToolTippedSlider } from "../../common/ToolTippedSlider";

interface ISlaDonutProps {
    feedFreshnesses: IFeedFreshness[];
    href?: string;
    tolerance: ITolerance;
    setTolerance: Dispatch<SetStateAction<ITolerance>>;
}

function SlaDonut(props: ISlaDonutProps) {
    const feedFreshnesses = props.feedFreshnesses;
    let numOverSla = 0;
    let numUnknownSla = 0;
    for (let i = 0; i < feedFreshnesses.length; i++) {
        const element = feedFreshnesses[i];
        const { timeOverSlaInHours, slaThresholdInHours, isDormant } = element;
        if (
            slaThresholdInHours === null ||
            slaThresholdInHours === undefined ||
            slaThresholdInHours === -1 ||
            isDormant
        ) {
            numUnknownSla += 1;
            continue;
        }

        if (timeOverSlaInHours > slaThresholdInHours * props.tolerance.sla_tolerance) {
            numOverSla += 1;
        }
    }
    const meetingSlaLegend = "Meeting SLA";
    const meetingSlaData = feedFreshnesses.length - numOverSla - numUnknownSla;
    const notMeetingSlaLegend = "Not Meeting SLA";

    const points = [
        {
            legend: meetingSlaLegend,
            data: meetingSlaData,
            color: SharedColors.green20
        },
        { legend: notMeetingSlaLegend, data: numOverSla, color: SharedColors.red20 }
    ];

    const data = { chartTitle: "Feeds by SLA", chartData: points };
    const chart = <DonutChart data={data} innerRadius={50} href={props.href ? props.href : ""} />;

    const sliderToolTipsProps = {
        id: "slaToleranceSlider",
        content:
            "Feeds will be marked as meeting SLA if they are less than this percentage over their SLA. This value is also applied to the filters in the table below."
    };
    const sliderProps: ISliderProps = {
        min: 0,
        max: 0.15,
        step: 0.0025,
        ariaLabel: "SLA Tolerance",
        value: props.tolerance.sla_tolerance,
        onChanged: (event, value: number) => props.setTolerance({ ...props.tolerance, sla_tolerance: value }),
        valueFormat: (value: number) => `${(value * 100).toFixed(2)}%`
    };

    const child = (
        <div>
            {chart}
            <ToolTippedSlider label="Tolerance" toolTipsProps={sliderToolTipsProps} sliderProps={sliderProps} />
        </div>
    );

    return (
        <DashboardItem
            label="Feeds by SLA "
            tooltip="This chart shows the proportion of feeds that are currently meeting their SLA."
            child={child}
        />
    );
}

export default SlaDonut;

import { IDirectoryAudit } from "../models/DirectoryAudit";
import { BaseAction, StateValues } from ".";
export enum DirectoryAuditActionTypes {
    GETALL = "DIRECTORYAUDIT/GETALL",
    INIIALIZE = "DIRECTORYAUDIT/INIT",
    LOADING = "DIRECTORYAUDIT/LOADING",
    LOADINGERROR = "DIRECTORYAUDIT/ERROR",
    TESTDirectoryAudit = "TEST/DirectoryAudit",
    UPDATED = "DIRECTORYAUDIT/UPDATED",
    UPDATING = "DIRECTORYAUDIT/UPDATING",
    POSTUPDATE = "DIRECTORYAUDIT/POSTUPDATE"
}

interface IDirectoryAuditTest extends BaseAction {
    type: typeof DirectoryAuditActionTypes.TESTDirectoryAudit;
}

export interface IDirectoryAuditError extends BaseAction<string> {
    type: DirectoryAuditActionTypes.LOADINGERROR;
}

export interface IDirectoryAuditGetAllAction extends BaseAction<IDirectoryAudit[]> {
    type: DirectoryAuditActionTypes.GETALL;
}

// export post update action
export interface IDirectoryAuditPostUpdateAction extends BaseAction {
    type: DirectoryAuditActionTypes.POSTUPDATE;
}

export interface IDirectoryAuditUpdatedAction extends BaseAction<IDirectoryAudit> {
    type: DirectoryAuditActionTypes.UPDATED;
}

export interface IDirectoryAuditUpdatingAction extends BaseAction {
    type: DirectoryAuditActionTypes.UPDATING;
}

export interface IDirectoryAuditLoading extends BaseAction {
    type: DirectoryAuditActionTypes.LOADING;
}

export interface IDirectoryAuditInit extends BaseAction {
    type: DirectoryAuditActionTypes.INIIALIZE;
}

export type DirectoryAuditActions =
    | IDirectoryAuditTest
    | IDirectoryAuditGetAllAction
    | IDirectoryAuditInit
    | IDirectoryAuditLoading
    | IDirectoryAuditError
    | IDirectoryAuditUpdatedAction
    | IDirectoryAuditUpdatingAction
    | IDirectoryAuditPostUpdateAction;

export interface IDirectoryAuditState {
    readonly directoryAudit: IDirectoryAudit[];
    readonly directoryAuditLoading: boolean;
    readonly directoryAuditState: StateValues;
    readonly directoryAuditError: string;
}

export enum DatasetAlertCategory {
    DataVolume = 0,
}

export type IDatasetAlert = {
    id: number;
    datasetId: number;
    configurations: string;
    alertType: string;
    name: string;
    datasetName: string;
    category: number;
    type: number;
    isSupressed: boolean;
    suppressionStartTime?: Date | null;
    suppressionEndTime?: Date | null;
    suppressionJustification?: string;
    suppressedBy?: string;
    maxSuppressionTime: number;
    lastUpdated: Date;
    createdBy: string;
    modifiedBy: string;
    createdDate: Date;
    lastTriggered?: Date;
};

export const EmptyAlert: IDatasetAlert = {
    id: 0,
    datasetId: 0,
    configurations: "",
    alertType: "",
    name: "",
    datasetName:"",
    category: 0,
    type: 0,
    isSupressed: false,
    maxSuppressionTime: 0,
    suppressionEndTime: undefined,
    suppressionStartTime: undefined,
    lastUpdated: new Date(),
    createdBy: "",
    modifiedBy: "",
    createdDate: new Date()

}
export enum ConfigSettingProps {
    Setting1 = "setting1",
    Setting2 = "setting2",
    Operator = "operator",
    BenchmarkValue = "benchmarkValue",
    LookBackPeriod = "lookBackPeriod"
}

export type configSettings = {
    [ConfigSettingProps.Setting1]: {
        [ConfigSettingProps.Operator]?: string;
        [ConfigSettingProps.BenchmarkValue]?: string;
    },
    [ConfigSettingProps.Setting2]: {
        [ConfigSettingProps.Operator]?: string;
        [ConfigSettingProps.BenchmarkValue]?: string;
        [ConfigSettingProps.LookBackPeriod]?: string;
    }
}

export const InitialConfigSettings: configSettings = {
    setting1: {
        operator: "",
        benchmarkValue: "",
    },
    setting2: {
        operator: "",
        benchmarkValue: "",
        lookBackPeriod: ""
    }
}

import { ContextualMenu, DefaultButton, IContextualMenuProps } from "@fluentui/react";
import { ValidationResult } from "../../models/ValidationResult";

type MenuButtonProps = {
    data: ValidationResult[] | undefined;
    filterLabel: string;
    setFilteredData: (data: ValidationResult[] | undefined) => void;
    setFilterLabel: (label: string) => void;
};

const MenuButton = ({ data, setFilterLabel, setFilteredData, filterLabel }: MenuButtonProps) => {
    function getMenuItems() {
        if (!data) return [];

        const types = data.reduce((result: string[], item) => {
            if (item.type && !result.includes(item.type)) {
                result.push(item.type);
            }
            return result;
        }, []);

        // Create a menu item for each type
        const typeItems = types?.map((type) => ({
            key: `filterByType-${type}`,
            text: `Only ${type}`,
            onClick: () => {
                setFilterLabel(`Showing Only ${type}`);
                setFilteredData(data?.filter((x) => x.type === type));
            }
        }));

        const items = [
            {
                key: "clear",
                text: "Clear all filters",
                onClick: () => {
                    setFilteredData(data);
                    setFilterLabel("Filter By");
                }
            },
            {
                key: "filterByDismissed",
                text: "Only Dismissed",
                onClick: () => {
                    setFilteredData(data?.filter((x) => x.dismissedBy));
                    setFilterLabel("Showing Only Dismissed");
                }
            },
            {
                key: "filterByNotDismissed",
                text: "Only Active",
                onClick: () => {
                    setFilteredData(data?.filter((x) => !x.dismissedBy));
                    setFilterLabel("Showing Only Active");
                }
            },
            ...typeItems
        ];

        return items;
    }

    const menuProps: IContextualMenuProps = {
        items: getMenuItems(),
        directionalHintFixed: true
    };

    function _getMenu(props: IContextualMenuProps): JSX.Element {
        return <ContextualMenu {...props} />;
    }

    return (
        <DefaultButton
            text={filterLabel}
            menuProps={menuProps}
            menuAs={_getMenu}
            allowDisabledFocus
            disabled={false}
            checked={true}
        />
    );
};

export default MenuButton;

import {
    FontWeights,
    IconButton,
    ITextStyles,
    Toggle,
    Text,
    PrimaryButton,
    Stack,
    getFocusStyle,
    getTheme,
    ITheme,
    mergeStyleSets,
    Shimmer,
    Label,
    TextField,
    DefaultButton,
    Separator,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import { useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { getShimmerStyles } from "../../columns/utils";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { ProcessSipAccessRequest } from "../../models";
import { accessPackageSelector } from "../../pages/Selectors";
import {
    AccessPackageComponents,
    getAccessRequestByIdAsync,
    getAccessRequestsAsync,
    resetAccessRequestState,
    submitSipRequestAsync
} from "../../reducers/AccessPackages";
import CrudStatusDialog from "../common/CrudStatusDialog";
import Errorbar from "../common/ErrorBar";
import IcmLink from "../common/IcmLink";
import { LoadingOverLay } from "../common/LoadingOverLay";
import { ShimmeredList, ShimmeredText } from "../common/Shimmered";
import AccessPackageDialog from "./AccessPackageDialog";
import { AccessRequestCommentList } from "./AccessRequestCommentList";
import StandardForm from "../common/StandardForm";
import { StackRow } from "../common/StackComponents";
import SaveButtonContainer from "../Dataset/SaveButtonContainer";
import { useIsSmallScreen } from "../../hooks";
import { getUserName } from "../../service/authProvider";

type CommmentDialogProps = {
    show: boolean;
    onClick: (ev: any) => void;
    title: string;
    processing: boolean;
};
export function AccessRequestEdit() {
    const { id } = useParams<{ id: string }>();

    const accessRequestId = +id;
    const emptyCommentDialog: CommmentDialogProps = {
        show: false,
        onClick: (_ev: any) => {},
        title: "",
        processing: false
    };
    const [editDisabled, setEditDisabled] = useState(true);
    const [commentDialog, setCommentDialog] = useState<CommmentDialogProps>(emptyCommentDialog);
    const [comment, setComment] = useState<string>();
    const [aggregatedError, setAggregatedError] = useState<string>();
    const [msg, setMsg] = useState<string>();
    const history = useHistory();
    const smallScreen = useIsSmallScreen();
    const siteTextStyles: ITextStyles = {
        root: {
            fontWeight: FontWeights.semibold
        }
    };

    // get selector for audit
    const { currentAccessRequest, errors, loading, sipApproval } = useAppSelector(accessPackageSelector);
    const dispatch = useAppDispatch();

    // we need to keep the comment in a ref so that we can access it in the async function
    // everything else is updated before the dialog is shown
    const commentRef = useRef<string | null>(null);

    useEffect(() => {
        commentRef.current = comment || "";
    }, [comment]);

    useEffect(() => {
        if (!currentAccessRequest || currentAccessRequest.id !== accessRequestId) {
            dispatch(getAccessRequestByIdAsync(accessRequestId));
        }

        return () => {
            dispatch(resetAccessRequestState());
        };
    }, [accessRequestId]);

    useEffect(() => {
        if (errors) {
            const a = Object.entries(errors)
                .filter((x) => x[1])
                .filter((x) =>
                    [
                        AccessPackageComponents.sipApproval,
                        AccessPackageComponents.approvedRequestStatus,
                        AccessPackageComponents.currentAccessRequest
                    ].includes(+x[0])
                )

                .map((x) => x[1])
                .join("\n");

            setAggregatedError(a);
            if (a) {
                setCommentDialog(emptyCommentDialog);
            }
        }
    }, [
        errors[AccessPackageComponents.sipApproval],
        errors[AccessPackageComponents.currentAccessRequest],
        errors[AccessPackageComponents.approvedRequestStatus]
    ]);

    useEffect(() => {
        if (sipApproval && accessRequestId) {
            setCommentDialog(emptyCommentDialog);
            if (sipApproval === "Rejected By SIP") {
                setMsg("Request has been rejected and owner has been notified");
            } else if (sipApproval === "Pending Owner Approval") {
                setMsg("Request has been approved and is waiting on source owner");
            } else if (sipApproval === "Pending Existing Assignment Removal") {
                setMsg("Request is waiting on existing assignment removal, this may take a few minutes. ");
            }
            dispatch(getAccessRequestByIdAsync(accessRequestId));
            dispatch(getAccessRequestsAsync());
        }
    }, [sipApproval]);

    function _reject(_ev: any) {
        if (accessRequestId) {
            const req: ProcessSipAccessRequest = {
                requestId: accessRequestId,
                accessPackageId: "",
                approverAlias: getUserName(),
                message: commentRef.current || "",
                type: "Rejection"
            };
            setCommentDialog((prev) => ({ ...prev, processing: true }));
            dispatch(submitSipRequestAsync(req));
        }
    }

    const _approve = (_ev: any) => {
        if (currentAccessRequest) {
            const { accessPackageId } = currentAccessRequest;

            const req: ProcessSipAccessRequest = {
                accessPackageId,
                requestId: accessRequestId,
                approverAlias: getUserName(),
                type: "Approval",
                message: commentRef.current || ""
            };
            dispatch(submitSipRequestAsync(req));
        } else {
            console.error(`Couldn't find access request for ${accessRequestId}`);
        }
    };

    const theme: ITheme = getTheme();
    const { palette, semanticColors, fonts } = theme;

    const classNames = mergeStyleSets({
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 20,
                padding: 10,
                maxWidth: "60%",
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                selectors: {
                    "&:hover": { background: palette.neutralLight }
                }
            }
        ],
        itemName: [
            fonts.mediumPlus,
            {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
            }
        ]
    });

    const onRenderCell = (
        item: { name: string } | undefined,
        _index: number | undefined,
        _isScrolling: boolean | undefined
    ): JSX.Element => {
        if (!item) {
            return <span></span>;
        }
        return (
            <div className={classNames.itemCell} data-is-focusable={true}>
                <div className={classNames.itemName}>{item.name}</div>
            </div>
        );
    };

    return (
        <StandardForm widthPercent={70} mediumWidthPercent={90} smallWidthPercent={90}>
            <LoadingOverLay
                isOverlayVisible={
                    loading[AccessPackageComponents.approvedRequestStatus] ||
                    loading[AccessPackageComponents.sipApproval]
                }
            />
            <CrudStatusDialog
                showDialog={msg !== undefined}
                title={msg || ""}
                onClose={() => setMsg(undefined)}
                onDismiss={() => setMsg(undefined)}
            />
            <StackRow>
                <Toggle
                    label={
                        <Text variant="xLarge" styles={siteTextStyles}>
                            Edit
                        </Text>
                    }
                    className="editfeed"
                    inlineLabel={smallScreen ? false : true}
                    defaultChecked={false}
                    onText="On"
                    offText="Off"
                    onChange={(_e, checked) => setEditDisabled(!checked)}
                />

                <Stack.Item align="auto" style={{ textAlign: "right" }}>
                    <IconButton
                        title="Close"
                        className="routing"
                        onClick={() => history.goBack()}
                        iconProps={{ iconName: "Cancel" }}
                        allowDisabledFocus
                    />
                </Stack.Item>
            </StackRow>
            {aggregatedError && <Errorbar msg={aggregatedError} />}
            <AccessPackageDialog
                showDialog={commentDialog.show}
                title=""
                onClose={() => history.push("/accessRequests")}
            >
                <Stack tokens={{ childrenGap: 10 }} verticalFill>
                    <TextField onChange={(_e, v) => setComment(v)} label={commentDialog.title} />
                    <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign="center">
                        {!loading[AccessPackageComponents.sipApproval] ? (
                            <>
                                <PrimaryButton disabled={!comment} onClick={commentDialog.onClick} text="Submit" />
                                <DefaultButton
                                    onClick={() => {
                                        setCommentDialog(emptyCommentDialog);
                                    }}
                                    text="Cancel"
                                />
                            </>
                        ) : (
                            <Spinner size={SpinnerSize.medium} label="Waiting for submission" />
                        )}
                    </Stack>
                </Stack>
            </AccessPackageDialog>
            {currentAccessRequest?.icmId && (
                <StackRow horizontalAlign="end">
                    <Stack verticalFill>
                        <Label style={{ marginBottom: "10px" }} className="label">
                            Link
                        </Label>
                        <Shimmer
                            isDataLoaded={!loading[AccessPackageComponents.currentAccessRequest]}
                            ariaLabel="Loading Access Request Status"
                            styles={getShimmerStyles()}
                        >
                            <IcmLink link={currentAccessRequest?.link || ""} />
                        </Shimmer>
                    </Stack>
                </StackRow>
            )}
            <StackRow>
                <ShimmeredText
                    isStackItem={true}
                    loading={loading[AccessPackageComponents.currentAccessRequest]}
                    txtLabel="Access Package Name"
                >
                    {currentAccessRequest?.name}
                </ShimmeredText>

                <ShimmeredText
                    isStackItem={true}
                    loading={loading[AccessPackageComponents.currentAccessRequest]}
                    txtLabel="Requestor"
                >
                    {currentAccessRequest?.requestedBy}
                </ShimmeredText>

                <ShimmeredText
                    isStackItem={true}
                    loading={loading[AccessPackageComponents.currentAccessRequest]}
                    txtLabel="Spn Name"
                >
                    <Stack>
                        <Text>{currentAccessRequest?.objectName}</Text>
                        <Text>({currentAccessRequest?.objectId})</Text>
                    </Stack>
                </ShimmeredText>
            </StackRow>
            <StackRow>
                <ShimmeredText
                    isStackItem={true}
                    loading={loading[AccessPackageComponents.currentAccessRequest]}
                    txtLabel="Approval Request"
                >
                    {currentAccessRequest?.approvalStatus}
                </ShimmeredText>
                <ShimmeredList
                    isStackItem={true}
                    loading={loading[AccessPackageComponents.currentAccessRequest]}
                    lsLabel="Comms Alias"
                    onRenderCell={onRenderCell}
                    items={currentAccessRequest && currentAccessRequest.owners.split(", ").map((s) => ({ name: s }))}
                />
                <ShimmeredText
                    isStackItem={true}
                    loading={loading[AccessPackageComponents.currentAccessRequest]}
                    txtLabel="Justification"
                >
                    {currentAccessRequest?.justification}
                </ShimmeredText>
            </StackRow>

            <Separator />
            <StackRow horizontalAlign="end">
                <Stack>
                    <Label className="label">Comments</Label>
                    <Shimmer
                        isDataLoaded={currentAccessRequest !== undefined}
                        ariaLabel="Loading Ado history for pipeline"
                        width="30%"
                        styles={getShimmerStyles()}
                    >
                        {currentAccessRequest && (
                            <AccessRequestCommentList {...currentAccessRequest} editDisabled={editDisabled} />
                        )}
                    </Shimmer>
                </Stack>
            </StackRow>
            {currentAccessRequest?.isRoleGroup && (
                <SaveButtonContainer>
                    <PrimaryButton
                        text="Submit"
                        onClick={() =>
                            setCommentDialog({
                                ...commentDialog,
                                show: true,
                                onClick: (ev) => _approve(ev),
                                title: "Provide Submission Comment"
                            })
                        }
                        disabled={
                            editDisabled || currentAccessRequest?.isComplete || !currentAccessRequest?.isValidated
                        }
                    ></PrimaryButton>
                    <PrimaryButton
                        onClick={() =>
                            setCommentDialog({
                                ...commentDialog,
                                show: true,
                                onClick: _reject,
                                title: "Provide Rejection Comment"
                            })
                        }
                        text="Reject"
                        disabled={
                            editDisabled || currentAccessRequest?.isComplete || !currentAccessRequest?.isValidated
                        }
                    ></PrimaryButton>
                </SaveButtonContainer>
            )}
        </StandardForm>
    );
}

import axios, { getHeaders } from "./SipMonClient";
import { IDataFactory } from "../models/DataFactory";
import { ISipApiResponse } from "../models/SipApiResponse";
import { StateActionType } from "../types";
import { sliceName } from "../reducers/DataFactory";

export const getDataFactories = async (): Promise<ISipApiResponse<IDataFactory[]>> => {
    const { data, status } = await axios.get<IDataFactory[]>(
        `api/datafactories`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

export const getDataFactory = async (id: number): Promise<ISipApiResponse<IDataFactory>> => {
    const { data, status } = await axios.get(
        `api/datafactories/${id}`,
        getHeaders(sliceName, StateActionType.LoadSingle)
    );
    return { data, message: "", status };
};

export const editDataFactory = async (values: IDataFactory): Promise<ISipApiResponse<IDataFactory>> => {
    const id = values.id;
    const { data, status } = await axios.put(
        `api/datafactories/${id}`,
        values,
        getHeaders(sliceName, StateActionType.Update)
    );

    return { data, message: "", status };
};

import { useEffect } from "react";

export const useTempError = (error: string, setError: (error: string) => void, time?: number) =>
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (error) {
            timeout = setTimeout(() => {
                console.error(error);
                setError("");
            }, time || 10000);
        }
        return () => clearTimeout(timeout);
    }, [error]);

import React from "react";
import { DialgContainer } from "../common/DialogContainer";
import PipelineFeedDialogProps from "../../models/dialogProps/PipelineFeedDialogProps";
import FeedEdit from "../Feed/EditFeed";

export interface InitiateDialogState {
    showDialog: boolean;
}

export const PipelineFeedDialog: React.FC<PipelineFeedDialogProps> = (props) => {
    return (
        <DialgContainer {...props}>
            <FeedEdit />
        </DialgContainer>
    );
};

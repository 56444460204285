import { DialgContainer } from "../common/DialogContainer";
import DialogContainerProps from "../../models/dialogProps/DialogContainerProps";
import { AccessPackageAddValidationResponse } from "../../models";
import { PrimaryButton, Stack, Text } from "@fluentui/react";

type AddValidationDialogProps = {
    validationResponse: AccessPackageAddValidationResponse;

    handleClick: () => void;
} & DialogContainerProps;

function AddValidationDialog(props: AddValidationDialogProps) {
    const { existingAccessPackageName, existingAccessPackageId, securityGroupName, existsInElm } =
        props.validationResponse;

    return (
        <DialgContainer {...{ ...props, title: "Failed Validation", minHeight: "0", width: "40vw" }}>
            <Stack verticalAlign="space-evenly">
                <Stack>
                    <Text>
                        There is already a access package, {existingAccessPackageName} (Id: {existingAccessPackageId}),
                        associated with the security group {securityGroupName} you must manually delete it from ELM
                        first before recreating it.
                    </Text>
                    <Stack tokens={{ childrenGap: 20 }} style={{ marginTop: "20px" }} horizontal>
                        <Stack.Item>
                            <PrimaryButton onClick={props.handleClick}>Ok</PrimaryButton>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
        </DialgContainer>
    );
}

export default AddValidationDialog;

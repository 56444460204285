import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { IApplicationState } from "../store";
import { NotificationListItem } from "@cseo/controls";

interface NotificationState {
    notifications: NotificationListItem[];
}
export const sliceName = "notifications";

const initialState: NotificationState = {
    notifications: []
};

export const notificationsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<NotificationListItem>) => {
            state.notifications.push(action.payload as Draft<NotificationListItem>);
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            state.notifications.splice(
                state.notifications.findIndex((x) => x.itemKey === action.payload),
                1
            );
        },
        clearNotifications: (state) => {
            state.notifications = [];
        }
    }
});

export const { addNotification, removeNotification, clearNotifications } = notificationsSlice.actions;

export const selectNotifications = (state: IApplicationState) => state.notifications;

export default notificationsSlice.reducer;

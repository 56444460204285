import React from "react";
import { Fabric } from "@fluentui/react/lib/Fabric";
import TrendsFeedFreshness from "./TrendsFeedFreshness";
import { RouteComponentProps } from "react-router";
import { IApplicationState } from "../../store";
import { connect } from "react-redux";

interface IParentProps {
    feedId: string;
}

interface IFeedFreshnessProps extends RouteComponentProps<IParentProps> {
    // Id: number;
    type: number;
}

export function FeedFreshnessTrendsContainer(props: IFeedFreshnessProps) {
    function _popUpClose() {
        setTimeout(() => {
            props.history.replace("/feedfreshness");
        }, 1500);
    }
    function _hasHistory() {
        return props.history.length > 2;
    }
    const Id: number = parseInt(props.match.params.feedId);
    return (
        <div className="row">
            <div className="col">
                <TrendsFeedFreshness feedId={Id} onClose={_popUpClose} hasHistory={_hasHistory} type={props.type} />
            </div>
        </div>
    );
}

const mapStateToProps = (store: IApplicationState, ownProps: IFeedFreshnessProps) => {
    const feedId = parseInt(ownProps.match.params.feedId, 10);
    return {
        Id: feedId
    };
};

export default connect(mapStateToProps, null)(FeedFreshnessTrendsContainer);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPowerBIReports } from "../models";
import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import { getReports, createReport, deleteDashboardTileApi } from "../api/PowerBIReportsApi";
import { getDefaultCases } from "./DefaultCases";

const sliceName = "powerBIReports";

const initialState = getInitialState<IPowerBIReports, IPowerBIReports>();

const getAllReports = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getReports();
    return response.data;
});

const AddNewReport = createAsyncThunk(
    `${sliceName}/add`,
    async (props: { name: string; description: string; link: string, tags: string, repoLink: string, contacts: string, category: string }, _thunkAPI) => {
        const response = await createReport(props.name, props.description, props.link, props.tags, props.repoLink, props.contacts, props.category);
        return response.data;
    }
);

const deleteDashboardTile = createAsyncThunk(`${sliceName}/delete`, async (id: number, _thunkAPI) => {
    const response = await deleteDashboardTileApi(id);

    return response.data;
});

const powerBIReportsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IPowerBIReports>(),
    extraReducers: (builder) => {
        builder.addCase(getAllReports.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllReports.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(AddNewReport.pending, (state, _action) => {
            onLoading(state, StateActionType.Add);
        });
        builder.addCase(AddNewReport.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Add);
        });
        builder.addCase(deleteDashboardTile.pending, (state, _action) => {
            onLoading(state, StateActionType.Delete);
        });
        builder.addCase(deleteDashboardTile.fulfilled, (state, action) => {
            onComplete(state, StateActionType.Delete);
        });
    }
});
export const { reset, error, setCurrent } = powerBIReportsSlice.actions;
export { getAllReports, sliceName, AddNewReport, deleteDashboardTile };
export const { reducer } = powerBIReportsSlice;
import { ActionButton, DetailsList, Dialog, IIconProps, SelectionMode, Separator, Stack } from "@fluentui/react";
import { generateDatasetAlertColumns } from "../../columns/DatasetAlertColumns";
import { useHistory } from "react-router-dom";
import { IDatasetAlert } from "../../models";
import { getDatasetById } from "../../reducers/Dataset";
import { useAppDispatch } from "../../hooks/ReduxHooks";
import { deleteDatasetAlertApi } from "./api";
import { resetDatasetAlert } from "../../reducers/DataSetAlert";

interface AlertProps {
    datasetID: number
    datasetAlerts: IDatasetAlert[]
    disabled: boolean
}
function Alerts(props: AlertProps) {
    const dispatch = useAppDispatch();
    const invokeDelete = async (id: number, dsId: number) => {
        // Deleting selected alert
        if (confirm(`Are you sure you want to delete?`)) {
            await deleteDatasetAlertApi(id);
            dispatch(getDatasetById(dsId));
        }
    }
    const addAlertIcon: IIconProps = { iconName: 'Add' };
    const history = useHistory();
    return <>

        <ActionButton styles={{ root: { width: 125 } }} iconProps={addAlertIcon} allowDisabledFocus onClick={() => { dispatch(resetDatasetAlert); history.replace(`/Alert/New${props.datasetID ? "/" + props.datasetID : ""}`); }}>
            Add Alert
        </ActionButton>
        <DetailsList
            items={props.datasetAlerts}
            selectionMode={SelectionMode.none}
            getKey={undefined}
            columns={generateDatasetAlertColumns(history, invokeDelete)}
        />
    </>
};

export default Alerts;
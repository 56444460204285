import { History } from "history";
import { AdminUser } from "../constants";

import { IDataset } from "../models/Dataset";
import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { addSortLabels, renderViewColumn } from "./utils";

export function generateDatasetColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Name",
            fieldName: "Name",
            filterBy: true,
            minWidth: 75,
            maxWidth: 350,
            isResizable: true,
            isSorted: true
        },
        {
            key: "description",
            name: "Description",
            fieldName: "description",
            filterBy: true,
            minWidth: 275,
            maxWidth: 400,
            isResizable: true,
            isSorted: true
        },
        {
            key: "displayincatalog",
            name: "Display In Catalog",
            fieldName: "displayincatalog",
            filterBy: true,
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
            isSorted: true,
            onRender: (item: IDataset) => {
                return item.displayInCatalog ? "true" : "false";
            }
        },
        {
            key: "availableIn",
            name: "Available In",
            fieldName: "availableIn",
            filterBy: true,
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
            isSorted: true
        }
    ];
    if (isInRole(AdminUser)) {
        cols.push({
            key: "id",
            name: "View/Edit",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDataset) => renderViewColumn(item, "datasets/Edit", history)
        });
    }

    addSortLabels(cols);
    return cols;
}

export interface IDirectoryAudit {
    id: string;
    name: string;
    totalOwners: number;
    owners: string;
    url: string;
    isProduction: boolean;
    lastUpdated: string;
    subscriptionId: string;
}

export const EmptyDirectoryAudit: IDirectoryAudit = {
    id: "",
    name: "",
    totalOwners: 0,
    owners: "",
    url: "",
    isProduction: false,
    lastUpdated: "",
    subscriptionId: ""
};

import { useEffect, useState } from "react";
import { AzureResourceProps } from "./AzureResourceProps";
import SqlUserPicker from "./SqlUserPicker";
import { getAzureResourceSubType } from "./utils";

const EditAzureSqlDb = (props: AzureResourceProps) => {
    const { azureResource } = props;
    const [sqlDbType, setSqlDbType] = useState<string>("");

    useEffect(() => {
        if (azureResource && !sqlDbType) {
            const type = getAzureResourceSubType(azureResource.type);
            if (type) {
                setSqlDbType(type);
            }
        }
    }, [azureResource]);

    return (
        <>
            {sqlDbType === "DataWarehouse" && <SqlUserPicker {...props} />}
            {sqlDbType !== "DataWarehouse" && <h1>No features are supported for {sqlDbType}</h1>}
        </>
    );
};

export default EditAzureSqlDb;

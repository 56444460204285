import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import { getPipeline, getPipelines, editPipeline, createPipeline, getPipelineHistory } from "../api/PipelineApi";

import { getDefaultCases } from "./DefaultCases";
import { IPipeline } from "../models";
import store from "../store";

const sliceName = "pipelines";

const initialState = getInitialState<IPipeline, IPipeline>();

const getAllPipelines = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getPipelines();
    return response.data;
});

const getPipelineById = createAsyncThunk(`${sliceName}/get_by_id`, async (id: number, _thunkAPI) => {
    const response = await getPipeline(id);
    return response.data;
});
const updatePipeline = createAsyncThunk(`${sliceName}/edit`, async (pipeline: IPipeline, _thunkAPI) => {
    const response = await editPipeline(pipeline);
    return response.data;
});
const addPipeline = createAsyncThunk(
    `${sliceName}/add`,
    async (props: { dfId: number; pipelineName: string; isProducer: boolean; gitBranch: string }, _thunkAPI) => {
        const response = await createPipeline(props.dfId, props.pipelineName, props.isProducer, props.gitBranch);
        return response.data;
    }
);

const pipelinessSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IPipeline>(),
    extraReducers: (builder) => {
        builder.addCase(getAllPipelines.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllPipelines.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getPipelineById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(getPipelineById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadSingle);
        });
        builder.addCase(updatePipeline.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(updatePipeline.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Update);
        });
        builder.addCase(addPipeline.pending, (state, _action) => {
            onLoading(state, StateActionType.Add);
        });
        builder.addCase(addPipeline.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Add);
        });
    }
});

export const { reset, error, setCurrent } = pipelinessSlice.actions;
export { getAllPipelines, getPipelineById, updatePipeline, addPipeline, sliceName };
export const { reducer } = pipelinessSlice;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StandardEntity } from "../components/common/StandardList";

type SearchState = {
    [page: string]: { searchString: string; filteredEntities: StandardEntity[] }; // replace any[] with the type of your entities
};

const initialState: SearchState = {};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setSearchString: (state, action: PayloadAction<{ page: string; searchString: string }>) => {
            if (!state[action.payload.page]) {
                state[action.payload.page] = { searchString: "", filteredEntities: [] };
            }
            state[action.payload.page].searchString = action.payload.searchString;
        },
        setFilteredEntities: (state, action: PayloadAction<{ page: string; filteredEntities: StandardEntity[] }>) => {
            if (!state[action.payload.page]) {
                state[action.payload.page] = { searchString: "", filteredEntities: [] };
            }
            state[action.payload.page].filteredEntities = action.payload.filteredEntities;
        }
    }
});

export const { setSearchString, setFilteredEntities } = searchSlice.actions;

export const { reducer } = searchSlice;

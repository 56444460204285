import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../../service/authProvider";
import { QualityMetricsValidationResult } from "../../models/QualityMetricsValidationResult";

const baseUrl = "/api/validation";

export const dQMValidationApi = createApi({
    reducerPath: "dQMValidationApi",
    tagTypes: ["DataQualityMetricsValidationResult"],
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers) => {
            const token = await getAccessToken();
            if (token) {
                headers.set("authorization", `Bearer ${token.accessToken}`);
            }
            return;
        }
    }),
    endpoints: (builder) => ({
        getDQMValidationResults: builder.query<QualityMetricsValidationResult[], void>({
            query: () => "/dqmValidationResults",
            providesTags: () => [{ type: "DataQualityMetricsValidationResult", id: "LIST" }]
        }),
        getDQMValidationResult: builder.query<QualityMetricsValidationResult, number>({
            query: (id) => `/dqmValidationResult/${id}`,
            providesTags: (result) => [{ type: "DataQualityMetricsValidationResult", id: result?.id }]
        }),
        dismiss: builder.mutation<QualityMetricsValidationResult, { id: number; comment: string }>({
            query: (props) => ({
                url: `dqmValidationResult/dismiss/${props.id}?comment=${props.comment}`,
                method: "PUT"
            }),
            invalidatesTags: (result) => [
                { type: "DataQualityMetricsValidationResult", id: result?.id },
                { type: "DataQualityMetricsValidationResult", id: "LIST" }
            ]
        })
    })
});

// Export the generated hooks for easy usage
export const { useGetDQMValidationResultsQuery, useGetDQMValidationResultQuery, useDismissMutation } = dQMValidationApi;

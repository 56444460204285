import React, { useEffect, useState } from "react";
import "../styles/App.css";
import FeedFreshnessTableView from "../components/FeedFreshness/FeedFreshnessTableContainer";
import { feedFreshnessSelector } from "./Selectors";
import Dashboard from "../components/FeedFreshness/dashboard/Dashboard";
import { StateActionType } from "../types";
import { getAllFeedFreshness } from "../reducers/FeedFreshness";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";

interface IFeedFreshnessListProps {
    type: number;
}

export interface ITolerance {
    eri_tolerance: number;
    sla_tolerance: number;
}

function FeedFreshnessList(props: IFeedFreshnessListProps) {
    const dispatch = useAppDispatch();
    const feedFreshnessProperties = useAppSelector(feedFreshnessSelector);
    const rsFeedFreshness = feedFreshnessProperties.entities;
    const rsFeedFreshnessState = feedFreshnessProperties.state;
    useEffect(() => {
        document.title = "List of Feed Freshnesses - SIP Portal";
        if (rsFeedFreshnessState[StateActionType.LoadAll] === "INIT") {
            dispatch(getAllFeedFreshness(props.type));
        }
    }, []);

    const [tolerance, setTolerance] = useState<ITolerance>({ eri_tolerance: 0.01, sla_tolerance: 0 });

    return (
        <div>
            <Dashboard feedFreshnesses={rsFeedFreshness || []} tolerance={tolerance} setTolerance={setTolerance} />
            <FeedFreshnessTableView
                feedFreshnesses={rsFeedFreshness || []}
                enableRouting={true}
                tolerance={tolerance}
            />
        </div>
    );
}
export default FeedFreshnessList;

import { History } from "history";
import { AdminUser } from "../constants";
import { IDataSource } from "../models";
import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { ProvisionType } from "../types";
import { addSortLabels, customRender, renderViewColumn } from "./utils";

export function generateDataSourceColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Name",
            fieldName: "Name",
            filterBy: true,
            minWidth: 75,
            maxWidth: 350,
            isResizable: true,
            isSorted: true
        },
        {
            key: "endpoint",
            name: "End point",
            fieldName: "Endpoint",
            filterBy: true,
            minWidth: 275,
            maxWidth: 400,
            isResizable: true,
            isSorted: true
        },
        {
            key: "type",
            name: "Linked Service Type",
            fieldName: "Type",
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
            filterBy: true
        },
        {
            key: "dataFactoryName",
            name: "Datafactory Name",
            fieldName: "DataFactoryName",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true
        }
    ];
    if (isInRole(AdminUser)) {
        cols.push({
            key: "id",
            name: "View/Edit",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDataSource) => renderViewColumn(item, "datasources", history)
        });
    }

    addSortLabels(cols);
    return cols;
}

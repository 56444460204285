import {
    Fabric,
    IRenderFunction,
    mergeStyleSets,
    ScrollablePane,
    ScrollbarVisibility,
    Sticky,
    StickyPositionType,
    TooltipHost,
    IDetailsColumnRenderTooltipProps,
    IDetailsHeaderProps
} from "@fluentui/react";
const styles = mergeStyleSets({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        overflowY: "auto"
    },
    main: {
        flex: 1,
        position: "relative",
        overflowY: "scroll"
    }
});
export function StickyWrapper(props) {
    return (
        <Fabric className={styles.wrapper}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always}>
                <span style={{ background: "white" }}>{props.children}</span>
            </ScrollablePane>
        </Fabric>
    );
}

export const renderStickyHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
        <TooltipHost {...tooltipHostProps} />
    );
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip
            })}
        </Sticky>
    );
};

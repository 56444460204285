import { IButtonProps, IconButton, Label, Shimmer } from "@fluentui/react";
import { getShimmerStyles } from "../../../columns/utils";
type ShimmeredProps = {
    loading: boolean;
    iconBtnLabel: string;
    width?: string;
} & IButtonProps;

function ShimmeredIconButton(props: ShimmeredProps) {
    return (
        <div className="col">
            <Label className="label"> {props.iconBtnLabel} </Label>
            <Shimmer
                isDataLoaded={!props.loading}
                ariaLabel={`Loading ${props.iconBtnLabel}`}
                styles={getShimmerStyles()}
            >
                <IconButton ariaLabel={props.iconBtnLabel} {...props} />
            </Shimmer>
        </div>
    );
}

export { ShimmeredIconButton };

import { stat } from "fs";
import {
    FontWeights,
    IconButton,
    ITextStyles,
    Label,
    Toggle,
    Text,
    PrimaryButton,
    Stack,
    List,
    getFocusStyle,
    getTheme,
    ITheme,
    mergeStyleSets
} from "@fluentui/react";
import React from "react";

import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { EmptyDirectoryAudit, IDirectoryAudit } from "../../models";
import { directoryAuditSelector } from "../../pages/Selectors";
import {
    getAllDirectoryAudits,
    reset,
    updateDirectoryAudit,
    getDirectoryAuditById
} from "../../reducers/DirectoryAudit";
import { StateActionType } from "../../types";
import CrudStatusDialog from "../common/CrudStatusDialog";
import Errorbar from "../common/ErrorBar";
import { ShimmeredIconButton, ShimmeredList, ShimmeredText, ShimmeredToggle } from "../common/Shimmered";

export function EditDirectoryAudit() {
    // get params from url
    const { id } = useParams<{ id: string }>();

    const [editDisabled, setEditDisabled] = React.useState(true);
    // only gets set upon update completion just in case someone hits the back button or closes the window
    // to prevent refetching the data if no update occurred
    const [isDismissed, setIsDismissed] = React.useState(true);
    const [showAnnouncment, setShowAnnouncement] = React.useState(false);
    const [audit, setAudit] = React.useState<IDirectoryAudit>(EmptyDirectoryAudit);
    const history = useHistory();

    const siteTextStyles: ITextStyles = {
        root: {
            fontWeight: FontWeights.semibold
        }
    };

    // get selector for audit
    const { state, error, currentEntity } = useAppSelector(directoryAuditSelector);
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        if (!currentEntity || currentEntity.id !== id) {
            dispatch(getDirectoryAuditById(id));
        }
    }, []);

    React.useEffect(() => {
        if (currentEntity && currentEntity.id === id) {
            setAudit(currentEntity);
        }
    }, [currentEntity]);

    React.useEffect(() => {
        // once the update is complete, go back to the list
        if (state[StateActionType.Update] === "UPDATED") {
            setIsDismissed(false);
            if (!showAnnouncment) {
                setShowAnnouncement(true);
            }
        }
    }, [state[StateActionType.Update]]);

    React.useEffect(() => {
        return () => {
            // cleanup on unmount
            if (!isDismissed) {
                dispatch(getAllDirectoryAudits());
                dispatch(reset(StateActionType.Update));
            }
        };
    }, [isDismissed]);

    function _on_processFeedSubmission(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (audit) dispatch(updateDirectoryAudit(audit));
    }

    const theme: ITheme = getTheme();
    const { palette, semanticColors, fonts } = theme;

    const classNames = mergeStyleSets({
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 20,
                padding: 10,
                maxWidth: "60%",
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                selectors: {
                    "&:hover": { background: palette.neutralLight }
                }
            }
        ],
        itemName: [
            fonts.mediumPlus,
            {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
            }
        ]
    });

    const onRenderCell = (
        item: { name: string } | undefined,
        _index: number | undefined,
        _isScrolling: boolean | undefined
    ): JSX.Element => {
        if (!item) {
            return <span></span>;
        }
        return (
            <div className={classNames.itemCell} data-is-focusable={true}>
                <div className={classNames.itemName}>{item.name}</div>
            </div>
        );
    };

    return (
        <form id={"create-post-form"} onSubmit={_on_processFeedSubmission} noValidate={true}>
            {error && <Errorbar msg={error} />}
            <CrudStatusDialog
                showDialog={showAnnouncment}
                title={`Directory Audit Record ${audit?.name} was successfully updated!`}
                onClose={history.goBack}
            />
            <div className="top-margin25 left-margin40">
                <span style={{ float: "right" }}>
                    <IconButton
                        title="Close"
                        className="routing"
                        onClick={() => history.push("/directoryaudit")}
                        iconProps={{ iconName: "Cancel" }}
                        allowDisabledFocus
                    />
                </span>
                <Toggle
                    label={
                        <Text variant="xLarge" styles={siteTextStyles}>
                            Edit
                        </Text>
                    }
                    className="editfeed"
                    inlineLabel
                    defaultChecked={false}
                    onText="On"
                    offText="Off"
                    onChange={(_e, checked) => setEditDisabled(!checked)}
                />

                <div className="row top-margin25">
                    <ShimmeredText
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        txtLabel="Name"
                        className="label"
                    >
                        {audit.name}
                    </ShimmeredText>
                    <Text className="path"> </Text>

                    <ShimmeredToggle
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        toggleLabel="Status"
                        onText="Production"
                        offText="Non-Production"
                        checked={audit.isProduction}
                        onChange={(_ev, checked) => setAudit({ ...audit, isProduction: checked as boolean })}
                        disabled={editDisabled}
                    />
                </div>

                <div className="row top-margin25">
                    <ShimmeredList
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        lsLabel="Owners"
                        onRenderCell={onRenderCell}
                        items={audit.owners.split(", ").map((s) => ({ name: s }))}
                    />
                    <ShimmeredText
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        txtLabel="Total Owner Count"
                        className="storageName"
                    >
                        {audit.totalOwners}
                    </ShimmeredText>
                    <ShimmeredIconButton
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        iconBtnLabel="Link"
                        href={audit.url}
                        iconProps={{ iconName: "AzureLogo" }}
                        allowDisabledFocus
                        target="_blank"
                    />
                </div>
            </div>

            <div className="left-margin40 top-margin30">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text="Save Changes" type="submit" disabled={editDisabled}></PrimaryButton>
                </Stack>
            </div>
        </form>
    );
}

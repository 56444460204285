import {
    FontWeights,
    IconButton,
    ITextStyles,
    Toggle,
    Text,
    PrimaryButton,
    Stack,
    TextField,
    TextFieldBase,
    Dropdown,
    IComboBoxStyles,
    IDropdownOption,
    Link,
    IDropdownStyles,
    Label
} from "@fluentui/react";
import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { EmptyDataSource, IDataFactory, IDataSource, ISourceOwner } from "../../models";
import { dataFactorySelector, dataSourceContactSelector, dataSourceSelector } from "../../pages/Selectors";
import { reset } from "../../reducers/DirectoryAudit";

import { DataSourceType, StateActionType } from "../../types";
import CrudStatusDialog from "../common/CrudStatusDialog";
import Errorbar from "../common/ErrorBar";
import { ShimmeredText } from "../common/Shimmered";
import { toNumber } from "lodash";
import { addDataSources, getAllDataSources, getDataSourceById, updateDataSource } from "../../reducers/DataSource";

import { lsTypeOptions, propOptions } from "./constants";

import AddSourceOwner from "../SourceOwner/AddSourceOwner";
import { DialgContainer } from "../common/DialogContainer";
import DialogContainerProps from "../../models/dialogProps/DialogContainerProps";
import { getSourceOwnerNames } from "../../api/SourceOwnerApi";
import StandardList from "../common/StandardList";
import { generateDataSourceContactsColumns } from "../../columns/DataSourceContactsColumns";
import { getDataSourceContactsByID } from "../../reducers/DataSourceContact";
import { StackComboBox } from "../common/StackedComboBox";
import { getAllDatafactories } from "../../reducers/DataFactory";

interface IFormState extends IDataSource {
    message: string;
}

export function EditDataSource(props: DialogContainerProps) {
    // get params from url
    let { id } = useParams<{ id: string }>();
    if (id == "new") id = "0";

    let dsID = toNumber(id);
    const [showAddSODialog, setShowAddSODialog] = React.useState<boolean>(false);
    const [sourceOwnerNames, setSourceOwnerNames] = React.useState<ISourceOwner[]>([]);

    const [selectedSourceOwners, setSelectedKeys] = React.useState<string[]>([]);
    const [error, setError] = React.useState<string>("");
    const [editDisabled, setEditDisabled] = useState(true);
    // only gets set upon update completion just in case someone hits the back button or closes the window
    // to prevent refetching the data if no update occurred
    const [isDismissed, setIsDismissed] = useState(true);
    const [showAnnouncment, setShowAnnouncement] = useState(false);
    const [dataSource, setDataSource] = useState<IDataSource>(EmptyDataSource);

    /* Add Data Source - start */

    const [dsName, setDSName] = React.useState<string>("");
    const [lsType, setLsType] = React.useState<string>("");
    const [showAddAnnouncment, setShowAddAnnouncment] = useState(false);
    const [dfID, setDFID] = useState<number>(0);
    const [dfName, setDFName] = useState<string>("");
    const { entities: dataFactories, state: dataFactoriesState } = useAppSelector(dataFactorySelector);
    const [endpointValues, setEndpointValues] = useState([{ prop: "", value: "" }]);

    /* Add Data Source - end */

    const history = useHistory();

    const siteTextStyles: ITextStyles = {
        root: {
            fontWeight: FontWeights.semibold
        }
    };
    const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 500, paddingBottom: 20 } };
    const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 500 } };
    const stackedComboBoxStyles = {
        root: {
            width: "90%",
            border: "1px solid #ccc"
        }
    };

    // get selector for audit
    const { state, currentEntity } = useAppSelector(dataSourceSelector);
    const [FormState, setFormState] = useState<IFormState>({
        id: 0,
        name: dataSource.name,
        endpoint: dataSource.endpoint,
        dataFactoryID: dataSource.dataFactoryID,
        dataFactoryName: dataSource.dataFactoryName,
        type: "",
        provisionedBy: "",
        contactInfo: "",
        message: "",
        lastUpdated: new Date()
    });

    const getSourceOwnersCallback = useCallback(async () => {
        setError("");
        try {
            const response = await getSourceOwnerNames();
            setSourceOwnerNames(response.data);
        } catch (error: any) {
            setError(error.title);
        }
    }, [getSourceOwnerNames]);

    const dispatch = useAppDispatch();
    if (id !== "0") {
        useEffect(() => {
            dispatch(getDataSourceContactsByID(Number(id)));
        }, []);
        React.useEffect(() => {
            if (!currentEntity || currentEntity.id !== dsID) {
                dispatch(getDataSourceById(dsID));
            }
        }, []);
    }

    React.useEffect(() => {
        if (currentEntity && currentEntity.id === dsID) {
            setDataSource(currentEntity);
            let val: any = [];
            currentEntity.contactInfo.split(",").forEach((elt) => {
                val.push(Number(elt));
            });
            setSelectedKeys(val);
        }
    }, [currentEntity]);

    React.useEffect(() => {
        // once the update is complete, go back to the list
        if (state[StateActionType.Update] === "UPDATED") {
            setIsDismissed(false);
        }
    }, [state[StateActionType.Update]]);

    React.useEffect(() => {
        return () => {
            // cleanup on unmount
            if (!isDismissed) {
                dispatch(getAllDataSources());
                dispatch(reset(StateActionType.Update));
            }
        };
    }, [isDismissed]);

    React.useEffect(() => {
        getSourceOwnersCallback();
    }, []);

    useEffect(() => {
        if (dataFactoriesState[StateActionType.LoadAll] === "INIT") {
            dispatch(getAllDatafactories());
        }
        // cleanup on unmount
        return () => {
            dispatch(reset(StateActionType.Add));
        };
    }, []);

    useEffect(() => {
        // Endpoint
        if (dataSource && dataSource.endpoint) {
            let newEndpointValues = [...endpointValues];
            let obj = JSON.parse(dataSource.endpoint);
            Object.keys(obj).forEach((key, i) => {
                if (newEndpointValues[i] === undefined) {
                    newEndpointValues.push({ prop: "", value: "" });
                }
                newEndpointValues[i]["prop"] = key;
                newEndpointValues[i]["value"] = obj[key];
            });
            setEndpointValues(newEndpointValues);
        }
    }, [dataSource, setEndpointValues]);

    let endPointValuePreview = "";
    endpointValues.map((element, index) => {
        if (element.prop) {
            endPointValuePreview += '"' + element.prop + '": "' + element.value + '"';
        }
        if (element.prop && index + 1 != endpointValues.length) {
            endPointValuePreview += ", ";
        }
    });
    endPointValuePreview = "{" + endPointValuePreview + "}";

    function updateDatasource() {
        let selOwnerIDs = "";
        selectedSourceOwners.forEach((element) => {
            selOwnerIDs += element + ",";
        });
        const currentDataSource: IDataSource = {
            id: dataSource.id,
            name: FormState.name.length === 0 ? dataSource.name : FormState.name,
            endpoint: endPointValuePreview,
            dataFactoryID: FormState.dataFactoryID != 0 ? FormState.dataFactoryID : dataSource.dataFactoryID,
            dataFactoryName: FormState.dataFactoryName ? FormState.dataFactoryName : dataSource.dataFactoryName,
            type: FormState.type ? FormState.type : dataSource.type,
            provisionedBy: dataSource.provisionedBy,
            contactInfo: selOwnerIDs,
            lastUpdated: new Date()
        };
        if (currentDataSource) {
            dispatch(updateDataSource(currentDataSource));
            setShowAnnouncement(true);
        }
    }
    function addDataSource() {
        let selOwnerIDs = "";
        selectedSourceOwners.forEach((element) => {
            selOwnerIDs += element + ",";
        });
        const currentDataSource: IDataSource = {
            id: 0,
            name: dsName,
            endpoint: endPointValuePreview,
            dataFactoryID: dfID,
            dataFactoryName: dfName,
            type: lsType,
            provisionedBy: "Manual",
            contactInfo: selOwnerIDs,
            lastUpdated: new Date()
        };
        if (currentDataSource) {
            dispatch(addDataSources(currentDataSource));
            setShowAddAnnouncment(true);
        }
    }

    const onChangeSourceOwner = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ): void => {
        if (option) {
            setSelectedKeys(
                option.selected
                    ? [...selectedSourceOwners, option.key as string]
                    : selectedSourceOwners.filter((key) => key !== option.key)
            );
        }
    };
    const onChangeLSType = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
        if (option) {
            setLsType(option.key as string);
        }
    };
    const onChangeDataFactory = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ): void => {
        if (option) {
            setDFID(option.key as number);
            setDFName(option.text);

            const updatedFormState = {
                ...FormState,
                dataFactoryID: option.key as number,
                dataFactoryName: option.text
            };
            setFormState(updatedFormState);
        }
    };
    const onChangeTypeEdit = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ): void => {
        if (option) {
            const updatedFormState = {
                ...FormState,
                type: option.key as string
            };
            setFormState(updatedFormState);
        }
    };
    const {
        entities: dsContacts,
        error: dataSourcesError,
        state: dataSourceContactsState
    } = useAppSelector(dataSourceContactSelector);

    function resetAnnouncement() {
        setShowAnnouncement(false);
        setShowAddAnnouncment(false);
        history.push("/datasources");
    }

    let handleChange = (i, e) => {
        let newEndpointValues = [...endpointValues];
        newEndpointValues[i][e.target.name] = e.target.value;
        setEndpointValues(newEndpointValues);
    };
    let handlePropChange = (i, e) => {
        let newEndpointValues = [...endpointValues];
        newEndpointValues[i]["prop"] = e;
        setEndpointValues(newEndpointValues);
    };
    let removeFormFields = (i) => {
        let newEndpointValues = [...endpointValues];
        newEndpointValues.splice(i, 1);
        setEndpointValues(newEndpointValues);
    };
    let addFormFields = () => {
        setEndpointValues([...endpointValues, { prop: "", value: "" }]);
    };

    return (
        <form id={"create-post-form"} noValidate={true}>
            {error && <Errorbar msg={error} />}
            <span style={{ float: "right" }}>
                <IconButton
                    title="Close"
                    className="routing"
                    onClick={() => history.push("/datasources")}
                    iconProps={{ iconName: "Cancel" }}
                    allowDisabledFocus
                />
            </span>
            {id !== "0" ? (
                <>
                    <CrudStatusDialog
                        showDialog={showAnnouncment}
                        title={`Data Source ${
                            FormState.name.length === 0 ? dataSource?.name : FormState.name
                        } was successfully updated!`}
                        onClose={() => resetAnnouncement()}
                    />
                    <div className="top-margin25 left-margin40">
                        <Toggle
                            label={
                                <Text variant="xLarge" styles={siteTextStyles}>
                                    Edit
                                </Text>
                            }
                            className="editfeed"
                            inlineLabel
                            defaultChecked={false}
                            onText="On"
                            offText="Off"
                            onChange={(_e, checked) => {
                                setEditDisabled(!checked);
                                const updatedFormState = {
                                    ...FormState,
                                    contactInfo: dataSource.contactInfo
                                };
                                setFormState(updatedFormState);
                            }}
                        />
                        {dataSource && (
                            <div className="row top-margin25">
                                <div style={{ marginLeft: 14, minWidth: 500 }}>
                                    <label style={{ marginBottom: 20, display: "flex" }}>
                                        <b>Endpoint -&nbsp;</b> {`${endPointValuePreview}`}
                                    </label>

                                    {dataSource.provisionedBy == "Automation" ||
                                        (!editDisabled && (
                                            <>
                                                {endpointValues.map((element, index) => (
                                                    <div key={index}>
                                                        <div
                                                            style={{
                                                                width: "1400px",
                                                                clear: "both",
                                                                marginTop: "15px",
                                                                marginBottom: "15px",
                                                                display: "flex"
                                                            }}
                                                        >
                                                            <div style={{ width: "20%", float: "left" }}>
                                                                <label>Property: </label>
                                                                <StackComboBox
                                                                    name="Select a property"
                                                                    onChange={(e) => handlePropChange(index, e)}
                                                                    options={propOptions}
                                                                    selectedKey={element.prop || ""}
                                                                    styles={stackedComboBoxStyles}
                                                                />
                                                            </div>
                                                            <div style={{ width: "48%", float: "left" }}>
                                                                <label>Value: </label>
                                                                <br />
                                                                <input
                                                                    style={{
                                                                        width: "280px",
                                                                        height: "30px",
                                                                        marginRight: "10px"
                                                                    }}
                                                                    type="text"
                                                                    name="value"
                                                                    value={element.value || ""}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                />
                                                                {index ? (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => removeFormFields(index)}
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <button
                                                    style={{
                                                        float: "left",
                                                        clear: "both",
                                                        marginTop: "10px",
                                                        marginBottom: 20
                                                    }}
                                                    className="button add"
                                                    type="button"
                                                    onClick={() => addFormFields()}
                                                >
                                                    Add Attribute
                                                </button>
                                            </>
                                        ))}
                                </div>

                                <div style={{ width: "1400px" }}>
                                    <div style={{ width: "40%", float: "left" }}>
                                        <ShimmeredText
                                            loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                                            txtLabel="Name"
                                            className="label"
                                        >
                                            {
                                                <TextField
                                                    disabled={dataSource.provisionedBy == "Automation" || editDisabled}
                                                    styles={{ root: { width: 500, paddingBottom: 20 } }}
                                                    onChange={_on_ChangeName}
                                                    required
                                                    value={FormState.name ? FormState.name : dataSource.name}
                                                ></TextField>
                                            }
                                        </ShimmeredText>
                                    </div>
                                    <div style={{ width: "48%", float: "left" }}>
                                        <ShimmeredText
                                            loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                                            txtLabel="Data Factory"
                                            className="label"
                                        >
                                            {
                                                <Dropdown
                                                    placeholder="Select a data factory"
                                                    selectedKey={
                                                        FormState.dataFactoryID == 0
                                                            ? dataSource.dataFactoryID
                                                            : FormState.dataFactoryID
                                                    }
                                                    onChange={onChangeDataFactory}
                                                    styles={comboBoxStyles}
                                                    options={
                                                        dataFactories
                                                            ? dataFactories.map((b) => ({ key: b.id, text: b.name }))
                                                            : []
                                                    }
                                                    disabled={dataSource.provisionedBy == "Automation" || editDisabled}
                                                    required={true}
                                                />
                                            }
                                        </ShimmeredText>
                                    </div>
                                </div>
                                <div style={{ width: "1400px" }}>
                                    <div style={{ width: "40%", float: "left" }}>
                                        <ShimmeredText
                                            loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                                            txtLabel="DataSource Type"
                                            className="storageName"
                                        >
                                            <Dropdown
                                                placeholder="Select a type"
                                                selectedKey={FormState.type ? FormState.type : dataSource.type}
                                                options={lsTypeOptions}
                                                styles={comboBoxStyles}
                                                onChange={onChangeTypeEdit}
                                                disabled={dataSource.provisionedBy == "Automation" || editDisabled}
                                                required={true}
                                            />
                                        </ShimmeredText>
                                    </div>
                                    <div style={{ width: "48%", float: "left" }}>
                                        <ShimmeredText
                                            loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                                            txtLabel="Provisioned By"
                                            className="storageName"
                                        >
                                            {dataSource.provisionedBy}
                                        </ShimmeredText>
                                    </div>
                                </div>

                                <Text className="path"> </Text>
                            </div>
                        )}
                        <br />
                        {!editDisabled ? (
                            <>{renderAddOwnerContent()}</>
                        ) : (
                            <StandardList
                                entities={
                                    dsContacts &&
                                    dsContacts.map((e) => ({
                                        ...e
                                    }))
                                }
                                title={"Data Source Contacts"}
                                columns={generateDataSourceContactsColumns(history)}
                                error={dataSourcesError}
                                entityState={dataSourceContactsState[StateActionType.LoadAll]}
                            />
                        )}
                    </div>
                    <div className="left-margin40 top-margin30">
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <PrimaryButton
                                text="Save Changes"
                                type="button"
                                disabled={editDisabled}
                                onClick={() => updateDatasource()}
                            ></PrimaryButton>
                        </Stack>
                    </div>
                </>
            ) : (
                <>
                    <CrudStatusDialog
                        showDialog={showAddAnnouncment}
                        title={`Data Source was successfully added!`}
                        onClose={() => resetAnnouncement()}
                    />
                    <div className="top-margin25 left-margin40">
                        <Stack verticalFill verticalAlign={"space-evenly"} horizontalAlign="stretch">
                            <div>
                                <label>
                                    <b>{`Endpoint - ${endPointValuePreview}`}</b>
                                </label>
                                {endpointValues.map((element, index) => (
                                    <div key={index}>
                                        <div
                                            style={{
                                                width: "1400px",
                                                clear: "both",
                                                marginTop: "15px",
                                                marginBottom: "15px",
                                                display: "flex"
                                            }}
                                        >
                                            <div style={{ width: "20%", float: "left" }}>
                                                <label>Property: </label>
                                                <StackComboBox
                                                    name="Select a property"
                                                    onChange={(e) => handlePropChange(index, e)}
                                                    options={propOptions}
                                                    selectedKey={element.prop || ""}
                                                    styles={stackedComboBoxStyles}
                                                />
                                            </div>
                                            <div style={{ width: "48%", float: "left" }}>
                                                <label>Value: </label>
                                                <br />
                                                <input
                                                    style={{ width: "280px", height: "30px", marginRight: "10px" }}
                                                    type="text"
                                                    name="value"
                                                    value={element.value || ""}
                                                    onChange={(e) => handleChange(index, e)}
                                                />
                                                {index ? (
                                                    <button type="button" onClick={() => removeFormFields(index)}>
                                                        Remove
                                                    </button>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <button
                                    style={{ float: "left", clear: "both", marginTop: "10px", marginBottom: 20 }}
                                    className="button add"
                                    type="button"
                                    onClick={() => addFormFields()}
                                >
                                    Add Attribute
                                </button>
                            </div>
                            <div style={{ width: "1400px" }}>
                                <div style={{ width: "40%", float: "left" }}>
                                    <TextField
                                        label="Data Source Name"
                                        styles={{ root: { width: 500, paddingBottom: 20 } }}
                                        onChange={_on_ChangeName}
                                        required
                                        value={dsName}
                                    ></TextField>
                                </div>
                                <div style={{ width: "48%", float: "left" }}>
                                    <Dropdown
                                        placeholder="Select a data factory"
                                        label="Data Factory"
                                        selectedKey={dfID}
                                        onChange={onChangeDataFactory}
                                        styles={comboBoxStyles}
                                        options={
                                            dataFactories ? dataFactories.map((b) => ({ key: b.id, text: b.name })) : []
                                        }
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div style={{ width: "1400px" }}>
                                <div style={{ width: "40%", float: "left" }}>
                                    <Dropdown
                                        placeholder="Select a type"
                                        label="Linked Service Type"
                                        selectedKey={lsType}
                                        options={lsTypeOptions}
                                        styles={comboBoxStyles}
                                        onChange={onChangeLSType}
                                        required={true}
                                    />
                                </div>
                                <div style={{ width: "48%", float: "left" }}>{renderAddOwnerContent()}</div>
                            </div>
                            <br />
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <PrimaryButton
                                    text="Add"
                                    disabled={isFormValid()}
                                    type="button"
                                    onClick={() => addDataSource()}
                                ></PrimaryButton>
                            </Stack>
                        </Stack>
                    </div>
                </>
            )}
            <DialgContainer
                {...{
                    showDialog: showAddSODialog,
                    onClose: () => {
                        setShowAddSODialog(false);
                        getSourceOwnersCallback();
                    },
                    minHeight: "275px"
                }}
            >
                <AddSourceOwner />
            </DialgContainer>
        </form>
    );

    function isFormValid() {
        if (dsName.trim().length == 0 || lsType.trim().length == 0 || dfID == 0) {
            return true;
        }

        return false;
    }

    function renderAddOwnerContent() {
        return (
            <>
                <Dropdown
                    placeholder="Select Source Owner(s)"
                    label="Contact Details"
                    selectedKeys={selectedSourceOwners}
                    onChange={onChangeSourceOwner}
                    multiSelect
                    options={sourceOwnerNames.map((b) => ({ key: b.id, text: b.name }))}
                    styles={dropdownStyles}
                />
                <Link
                    aria-label="Add Source Owner"
                    onClick={() => {
                        setShowAddSODialog(true);
                    }}
                    style={{ textDecoration: "none", paddingTop: 10 }}
                >
                    {" "}
                    <b>+</b> Add Source Owner
                </Link>
            </>
        );
    }

    function _on_ChangeName(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setDSName(newText);
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            } else setDSName("");
        }
    }
}

import axios, { getHeaders } from "./SipMonClient";
import { AzureResource } from "../models";
import { ISipApiResponse } from "../models/SipApiResponse";
import { StateActionType } from "../types";
import { sliceName } from "../reducers/DataFactory";
import { IPortalHttpError } from "../models/PortalHttpError";

export const getAzureResources = async (): Promise<ISipApiResponse<AzureResource[]>> => {
    const { data, status } = await axios.get<AzureResource[]>(
        `api/azureResources`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

export const getAzureResource = async (id: number): Promise<ISipApiResponse<AzureResource>> => {
    const { data, status } = await axios.get(
        `api/azureResources/${id}`,
        getHeaders(sliceName, StateActionType.LoadSingle)
    );
    return { data, message: "", status };
};

export const getSqlDbUsers = async (id: number, filter: string): Promise<ISipApiResponse<string[]>> => {
    try {
        const { data, status } = await axios.get(`api/azureResources/getSqlUsers/${id}/${filter}`);
        return { data, message: "", status };
    } catch (err: any) {
        const error = err as IPortalHttpError;
        return { data: [], message: error.title, status: error.status, error };
    }
};

export const editAzureResource = async (values: AzureResource): Promise<ISipApiResponse<AzureResource>> => {
    const id = values.id;
    const { data, status } = await axios.put(
        `api/azureResources/${id}`,
        values,
        getHeaders(sliceName, StateActionType.Update)
    );

    return { data, message: "", status };
};

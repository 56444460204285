import React, { useEffect, useState } from "react";
import { DialgContainer } from "../common/DialogContainer";
import { DefaultButton, DialogFooter, ITag, Label, PrimaryButton, ProgressIndicator, Stack } from "@fluentui/react";
import { activitiesSelector, dataFactorySelector, pipelineSelector } from "../../pages/Selectors";
import { IPipelineActivity } from "../../models/PipelineActivtiy";
import Errorbar from "../common/ErrorBar";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { StateActionType } from "../../types";
import { getAllActivites } from "../../reducers/Activities";
import { getAllDatafactories } from "../../reducers/DataFactory";
import { getAllPipelines } from "../../reducers/Pipelines";
import DialogContainerProps from "../../models/dialogProps/DialogContainerProps";
import { DatasetName, setPipelineAssociation } from "../../reducers/Dataset";
import { AutoCompleteTextField, DataTag } from "../common/AutoCompleteTextField";

interface IAssociatePipelineState {
    datasetName: DatasetName;
    datafactoryName?: string;
    datafactoryId?: number;
    pipelineName?: string;
    datafactoryAuthorLink?: string;
    pipelineId?: number;
    activityName?: string;
    activities: IPipelineActivity[];
}
export type AssociatePipelineProps = { externalError?: string; datasetName: DatasetName } & DialogContainerProps;

const AssociatePipeline: React.FC<AssociatePipelineProps> = (props) => {
    const { externalError } = props;
    const initState: IAssociatePipelineState = {
        datasetName: props.datasetName,
        datafactoryName: "",
        datafactoryId: -1,
        pipelineId: -1,
        activityName: "",
        activities: []
    };
    const dispatch = useAppDispatch();
    const activitiesProperties = useAppSelector(activitiesSelector);
    const rsActivityState = activitiesProperties.state;

    const [errorState, setErrorState] = useState<string>("");
    const { entities: dataFactories } = useAppSelector(dataFactorySelector);
    const { entities: pipelines } = useAppSelector(pipelineSelector);

    const [dialogState, setDialogState] = useState<IAssociatePipelineState>(initState);

    useEffect(() => {
        setErrorState(activitiesProperties.error || externalError || "");
    }, [activitiesProperties.error, externalError]);

    useEffect(() => {
        // make sure we don't overwrite dialogState by checkin datafactoryid
        if (rsActivityState[StateActionType.LoadAll] === "LOADED_ALL" && dialogState.datafactoryId !== -1) {
            setDialogState({ ...dialogState, activities: activitiesProperties.entities || [] });
        }
    }, [rsActivityState]);

    useEffect(() => {
        const { datafactoryId, pipelineId } = dialogState;

        if (datafactoryId && datafactoryId !== -1 && pipelineId && pipelineId !== -1) {
            dispatch(getAllActivites({ dfId: datafactoryId, pipelineId }));
        }
    }, [dialogState.pipelineId]);

    useEffect(() => {
        if (!pipelines) {
            dispatch(getAllPipelines());
        }

        if (!dataFactories) {
            dispatch(getAllDatafactories());
        }
    }, []);

    function onSubmit() {
        dispatch(
            setPipelineAssociation({
                datasetName: dialogState.datasetName,
                pipelineId: dialogState.pipelineId,
                activityName: dialogState.activityName,
                pipelineName: dialogState.pipelineName,
                datafactoryAuthorLink: dialogState.datafactoryAuthorLink
            })
        );
        props.onClose();
    }

    return (
        <DialgContainer {...props}>
            <Stack>
                <Label className="label">Data Factory</Label>
                <AutoCompleteTextField<DataTag>
                    itemName="data factories"
                    disabled={!dataFactories || dataFactories.length === 0}
                    sendSelectedValue={(value) => {
                        setDialogState({
                            ...dialogState,
                            datafactoryName: value?.name,
                            datafactoryId: value?.key as number,
                            pipelineId: -1,
                            pipelineName: "",
                            activities: [],
                            activityName: ""
                        });
                    }}
                    data={
                        dataFactories
                            ?.filter((x) => !x.isDeleted)
                            .map((df) => ({ key: df.id, name: `(${df.platformName}) - ${df.name}` })) || []
                    }
                    val={
                        dialogState.datafactoryName
                            ? { name: dialogState.datafactoryName, key: dialogState.datafactoryName }
                            : undefined
                    }
                    placeHolderText={`Start typing for suggestions`}
                />
            </Stack>
            <Stack>
                <Label className="label">Pipeline</Label>
                <AutoCompleteTextField<ITag>
                    itemName="pipelines"
                    disabled={dialogState.datafactoryId === -1 || !pipelines || pipelines.length === 0}
                    sendSelectedValue={(value) => value && updatePipelineState(value)}
                    data={
                        pipelines
                            ?.filter((p) => p.dataFactoryId === dialogState.datafactoryId)
                            .map((p) => ({ key: p.id, name: p.name, data: p.datafactoryAuthorLink })) || []
                    }
                    val={
                        dialogState.pipelineName
                            ? { name: dialogState.pipelineName, key: dialogState.pipelineName }
                            : undefined
                    }
                    placeHolderText={`Start typing for suggestions`}
                />
            </Stack>

            <Stack>
                <Label className="label">Activites</Label>
                <AutoCompleteTextField<ITag>
                    itemName="activites"
                    disabled={
                        dialogState.pipelineId === -1 || !dialogState.activities || dialogState.activities.length === 0
                    }
                    sendSelectedValue={(value) =>
                        value &&
                        setDialogState({
                            ...dialogState,
                            activityName: value.name
                        })
                    }
                    data={
                        dialogState.activities
                            ?.filter((p) => p.dataFactoryId === dialogState.datafactoryId)
                            .map((p) => ({ key: p.name, name: p.name })) || []
                    }
                    val={
                        dialogState.activityName
                            ? { name: dialogState.activityName, key: dialogState.activityName }
                            : undefined
                    }
                    placeHolderText={`Start typing for suggestions`}
                />
            </Stack>

            {rsActivityState[StateActionType.LoadAll] !== "LOADED_ALL" &&
                !errorState &&
                dialogState.pipelineId !== -1 && <ProgressIndicator />}
            <DialogFooter>
                <PrimaryButton onClick={onSubmit} disabled={dialogState.activityName === ""} text="Update" />
                <DefaultButton onClick={props.onClose} text="Cancel" />
            </DialogFooter>
            {errorState && <Errorbar msg={errorState} />}
        </DialgContainer>
    );

    function updatePipelineState(value: DataTag): void {
        return setDialogState({
            ...dialogState,
            pipelineName: value.name,
            pipelineId: value.key as number,
            datafactoryAuthorLink: value.data,
            activities: [],
            activityName: ""
        });
    }
};

export default AssociatePipeline;

import { useEffect } from "react";
import "../styles/App.css";
import { AzureResourceSelector } from "./Selectors";
import StandardList from "../components/common/StandardList";
import { generateAzureResourceColumns } from "../columns";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { StateActionType } from "../types";
import { getAllAzureResources } from "../reducers/AzureResources";
import useSubscriptionData from "../hooks/useSubscriptionData";
import { Stack } from "@fluentui/react";
import PreferredSelect from "../components/common/PreferredSelect";

function AzureResourceList() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {
        entities: azureResources,
        error: azureResourcesError,
        state: azureResourcesState
    } = useAppSelector(AzureResourceSelector);
    const { subscriptionId, setSubscriptionId, subscriptionEntries, subscriptionError } = useSubscriptionData();

    useEffect(() => {
        document.title = "Summary of Azure Resources - SIP Portal";
        if (azureResourcesState[StateActionType.LoadAll] !== "LOADED_ALL") {
            dispatch(getAllAzureResources());
        }
    }, []);

    return (
        <StandardList
            entities={
                azureResources &&
                azureResources
                    .filter((e) => subscriptionId === undefined || e.subscriptionId === subscriptionId)
                    .map((e) => ({
                        ...e,
                        resourceStatus: e.isDeleted ? "Deleted" : "Active",
                        monitoringStatus: e.isMonitoringEnabled ? "Active" : "Disabled"
                    }))
            }
            title={"Azure Resources"}
            columns={generateAzureResourceColumns(history)}
            error={azureResourcesError || subscriptionError}
            entityState={azureResourcesState[StateActionType.LoadAll]}
            extraHeaderItems={
                <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                    <PreferredSelect
                        title="Subscription"
                        entries={subscriptionEntries}
                        selectedEntryId={subscriptionId}
                        setEntryId={setSubscriptionId}
                    />
                </Stack>
            }
        />
    );
}
export default AzureResourceList;

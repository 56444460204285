import { Label, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import React, { FormEvent, useCallback, useEffect } from "react";
import Errorbar from "../common/ErrorBar";
import { getSourceOwnerNames, getContactMethods } from "../../api/SourceOwnerApi";
import { ISourceOwner, IContactMethod } from "../../models";
import { StackComboBox } from "../common/StackedComboBox";
import { addSourceOwner } from "../../reducers/SourceOwners";
import { useAppDispatch } from "../../hooks/ReduxHooks";



function AddSourceOwner() {
    const dispatch = useAppDispatch();
    const [error, setError] = React.useState<string>("");
    const [successMsg, setSuccessMsg] = React.useState<string>("");
    const [selectedType, setSelectedType] = React.useState<string | undefined>('0');

    const [sourceOwnerName, setSourceOwnerName] = React.useState<string>("");
    const [sourceOwnerValue, setSourceOwnerValue] = React.useState<string>("");

    const [sourceOwnerNames, setSourceOwnerNames] = React.useState<ISourceOwner[]>([]);
    const [sourceOwnerNamesLoading, setSourceOwnerNamesLoading] = React.useState<boolean>(false);
    const [sourceOwnerID, setSourceOwnerID] = React.useState<number>(-1);

    const [contactMethods, setContactMethods] = React.useState<IContactMethod[]>([]);
    const [contactMethodLoading, setcontactMethodLoading] = React.useState<boolean>(false);
    const [contactMethodID, setContactMethodID] = React.useState<number>(-1);

    const [formInputErrorMsg, setFormInputErrorMsg] = React.useState<string>("");

    const options: IChoiceGroupOption[] = [
        { key: '0', text: 'New Owner' },
        { key: '1', text: 'Use Existing' }
    ];

    const getSourceOwnersCallback = useCallback(async () => {
        setError("");
        setSourceOwnerNamesLoading(true);
        try {
            const response = await getSourceOwnerNames();
            setSourceOwnerNames(response.data);
            setSourceOwnerNamesLoading(false);
        } catch (error: any) {
            setError(error.title);
            setSourceOwnerNamesLoading(false);
        }
    }, [setSourceOwnerNamesLoading, getSourceOwnerNames, setSourceOwnerNamesLoading]);

    const getContactMethodCallback = useCallback(async () => {
        setError("");
        setcontactMethodLoading(true);
        try {
            const response = await getContactMethods();
            setContactMethods(response.data);
            setcontactMethodLoading(false);
        } catch (error: any) {
            setError(error.title);
            setcontactMethodLoading(false);
        }
    }, [setcontactMethodLoading, getContactMethods, setcontactMethodLoading]);

    useEffect(() => {
        getSourceOwnersCallback();
    }, []);

    useEffect(() => {
        getContactMethodCallback();
    }, []);

    const onChange = React.useCallback((ev: FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined) => {
        if (option !== undefined) {
            setSelectedType(option.key);
            getSourceOwnersCallback();
            setSuccessMsg("");
        }
    }, []);

    return (
        <div style={{ height: "400px", padding: 20 }}>

            {successMsg && <MessageBar
                messageBarType={MessageBarType.success} isMultiline={false} truncated={true} dismissButtonAriaLabel="Close" onDismiss={() => setSuccessMsg("")} >
                {successMsg}
            </MessageBar>}
            {error && <Errorbar msg={error} />}
            <Stack
                verticalFill
                verticalAlign={"space-evenly"}
                horizontalAlign="stretch"
            >
                <ChoiceGroup selectedKey={selectedType} options={options} onChange={onChange} label="" styles={{
                    flexContainer: [
                        {
                            selectors: {
                                ".ms-ChoiceField": {
                                    float: "left",
                                    paddingTop: 20,
                                    paddingRight: 20
                                }
                            }
                        }
                    ]
                }} />
                <br />

                {selectedType == '0' ? <TextField label="Name" styles={{ root: { width: "90%", paddingBottom: 20 } }} onChange={_on_ChangeName} required value={sourceOwnerName}></TextField> :
                    <><Label required>Name</Label>
                        <StackComboBox
                            name="Source Owner"
                            selectedKey={sourceOwnerID}
                            onChange={setSourceOwnerID}
                            options={sourceOwnerNames.map((b) => ({ key: b.id, text: b.name }))}
                            loading={!sourceOwnerID && sourceOwnerNamesLoading}
                        />
                    </>
                }
                <Label required>Contact Method</Label>
                <StackComboBox
                    name="ContactMethod"
                    selectedKey={contactMethodID}
                    onChange={(e) => {
                        setContactMethodID(e); setFormInputErrorMsg("")
                    }}
                    options={contactMethods.map((b) => ({ key: b.id, text: b.name }))}
                    loading={!contactMethodID && contactMethodLoading}
                />
                <TextField label="Value" styles={{ root: { width: "90%" } }} onChange={_on_ChangeValue} required value={sourceOwnerValue} ></TextField>
                <label style={{ color: "red" }} hidden={!formInputErrorMsg}>{formInputErrorMsg}</label>
                <div className="addOwnerFooter">
                    <PrimaryButton text="Add" styles={{ root: { width: "10%" } }} onClick={() => submitForm()} disabled={isValidForm()} />
                </div>
            </Stack>

        </div>
    );

    function submitForm() {

        let isValid = true;
        const seloption = contactMethods.filter(x => x.id == contactMethodID);
        if (seloption && seloption.length == 1) {
            if (seloption[0].name == "Email") {
                const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const isEmailValid = emailPattern.test(String(sourceOwnerValue.trim()).toLowerCase())
                if (isEmailValid != true) {
                    isValid = false;
                    setFormInputErrorMsg("Please provide valid email address")
                }
            }
            else if (seloption[0].name == "Phone") {
                const phoneNumberPattern = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
                const isEmailValid = phoneNumberPattern.test(String(sourceOwnerValue.trim()).toLowerCase())
                if (isEmailValid != true) {
                    isValid = false;
                    setFormInputErrorMsg("Please provide valid phone number")
                }
            }
        }
        if (isValid) {
            dispatch(
                addSourceOwner({
                    sourceOwnerID: selectedType == '0' ? 0 : sourceOwnerID,
                    sourceOwnerName: sourceOwnerName,
                    contactMethodID: contactMethodID,
                    sourceOwnerValue: sourceOwnerValue
                })
            );
            // Form Reset
            if (selectedType == '0') {
                setSuccessMsg(`Successfully added ${sourceOwnerName}`)
            }
            else {
                setSuccessMsg(`Successfully updated`)
            }
            // Reset
            setSourceOwnerName("")
            setContactMethodID(-1)
            setSourceOwnerID(-1)
            setSourceOwnerValue("")
        }
    }

    function isValidForm() {
        if (error) {
            return true;
        }
        if (sourceOwnerValue.trim().length == 0 || contactMethodID == -1) {
            return true;
        }
        // New
        if (selectedType == '0' && sourceOwnerName.trim().length == 0) {
            return true;
        }
        // Update
        if (selectedType == '1' && sourceOwnerID == -1) {
            return true;
        }
        return false;
    }

    function _on_ChangeName(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setSourceOwnerName(newText);
            }
            else
                setSourceOwnerName("");
        }
    }
    function _on_ChangeValue(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        setFormInputErrorMsg("")
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setSourceOwnerValue(newText);
            }
            else
                setSourceOwnerValue("");
        }
    }
}

export default AddSourceOwner;

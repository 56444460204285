import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
    Stack,
    Text,
    FontWeights,
    ITextStyles,
    IStackTokens,
    Label,
    IComboBoxOption,
    Toggle,
    PrimaryButton,
    TextFieldBase,
    IconButton,
    Shimmer,
    TooltipHost,
    Icon,
    Separator
} from "@fluentui/react";
import "../../styles/App.css";
import Errorbar from "../common/ErrorBar";
import { getFeedAcls } from "../../api/FeedApi";
import { feedSelector, activitiesSelector } from "../../pages/Selectors";
import AssociatePipelineDialog from "./AssociatePipelineDialog";
import AssociatePipelineDialogProps from "../../models/dialogProps/AssociatePipelineDialogProps";
import { StateActionType } from "../../types";
import { PipelineCommandButton } from "./PipelineCommandButton";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { getAllFeeds, reset, updateFeed, getFeedById } from "../../reducers/Feeds";
import { getAllPipelines } from "../../reducers/Pipelines";
import {
    ShimmeredComboBox,
    ShimmeredGraphLookup,
    ShimmeredText,
    ShimmeredTextField,
    ShimmeredToggle
} from "../common/Shimmered";
import { useHistory, useParams } from "react-router-dom";
import { FeedDetail } from "../../models";
import { EmptyFeedDetail } from "../../models/FeedDetail";
import { getShimmerStyles } from "../../columns/utils";
import { FeedAcl } from "../../models/FeedAcl";
import AclList from "./AclList";
import { isInRole } from "../../service/authProvider";
import { PMUser } from "../../constants";
import StandardForm from "../common/StandardForm";
import { StackRow } from "../common/StackComponents";
import SaveModal from "../Dataset/SaveModal";
const siteTextStyles: ITextStyles = {
    root: {
        fontWeight: FontWeights.semibold
    }
};

const controlStyles = {
    root: {
        minWidth: "100px",
        margin: "0 30px 20px 0",
        maxWidth: "100px"
    }
};

const dropdownControlStyles = {
    root: {
        minWidth: "150px",
        margin: "0 30px 20px 0",
        maxWidth: "450px"
    }
};

const retentionDaysLimit = 181;

interface IFormState extends FeedDetail {
    message: string;
    dropdownValues: IComboBoxOption[];
    feedCategoryDropdownValues: IComboBoxOption[];

    disablePipeline: boolean;
    errors: { [index: string]: string };
    error: boolean;
    editDisabled: boolean;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

export function EditFeed() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const nameRef = useRef<TextFieldBase>(null);
    const slaInHoursRef = useRef<TextFieldBase>(null);
    const updateFrequencyInHoursRef = useRef<TextFieldBase>(null);
    const [annoucement, setAnnouncement] = useState<{ show: boolean; msg: string; onClose: () => void }>({
        show: false,
        msg: "",
        onClose: () => {}
    });
    const [acls, setAcls] = useState<FeedAcl[]>();
    const [FormState, setFormState] = useState<IFormState>({
        ...EmptyFeedDetail,
        message: "",
        dropdownValues: [],
        feedCategoryDropdownValues: [],
        errors: {},
        disablePipeline: false,
        error: false,
        editDisabled: true
    });
    function _popUpShow() {
        if (currentFeed) {
            const eh: AssociatePipelineDialogProps = {
                ...associateFeeddialogProps,
                feed: currentFeed,
                showDialog: true
            };

            setShowDialog(eh);
        }
    }

    function _popUpClose() {
        setShowDialog({ ...associateFeeddialogProps, showDialog: false });
    }

    const { currentEntity: currentFeed, state, error } = useAppSelector(feedSelector);

    const activityProperties = useAppSelector(activitiesSelector);
    // only gets set upon update completion just in case someone hits the back button or closes the window
    // to prevent refetching the data if no update occurred
    const [isDismissed, setIsDismissed] = useState(true);

    const [associateFeeddialogProps, setShowDialog] = useState<AssociatePipelineDialogProps>({
        feed: EmptyFeedDetail,
        showDialog: false,
        onClose: _popUpClose
    });

    const feedId = parseInt(id, 10);

    useEffect(() => {
        if (!annoucement.show) {
            if (currentFeed && currentFeed.id === feedId) {
                const updatedFormState = { ...FormState, ...currentFeed };
                setFormState(updatedFormState);
            } else {
                dispatch(getFeedById(feedId));
            }
        }
    }, [currentFeed]);

    useEffect(() => {
        if (feedId) {
            getFeedAcls(feedId)
                .then((acls) => {
                    setAcls(acls.data);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [feedId]);

    // clean up on unmount
    useEffect(() => {
        return () => {
            if (!isDismissed) {
                // refetch to update state with new values
                dispatch(getAllFeeds());
                dispatch(getAllPipelines());
            }

            // timeout to allow the form to close before resetting the state
            setTimeout(() => {
                dispatch(reset(StateActionType.Update));
            }, 500);
        };
    }, [isDismissed]);

    useEffect(() => {
        if (associateFeeddialogProps.showDialog) {
            setShowDialog({
                ...associateFeeddialogProps,
                externalError: error
            });
        }
    }, [error]);

    useEffect(() => {
        if (activityProperties.state[StateActionType.Update] === "UPDATED" && associateFeeddialogProps.showDialog) {
            dispatch(getFeedById(feedId));
            _popUpClose();
        }
    }, [activityProperties.state[StateActionType.Update]]);

    useEffect(() => {
        if (state[StateActionType.Update] === "UPDATED") {
            setIsDismissed(false);
            setAnnouncement((p) => ({
                ...p,
                msg: `${FormState.name} was successfully updated!`
            }));
        }

        if (state[StateActionType.Update] === "UPDATING") {
            setAnnouncement({
                show: true,
                msg: "",
                onClose: history.goBack
            });
        }
    }, [state[StateActionType.Update]]);

    function _on_KeyPress(
        ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
        stateKey: string,
        valueIfEmpty: any,
        allowNonNumeric = true
    ): void {
        const keyCode = ev.keyCode;
        if (keyCode === 8 && ev.currentTarget.value.length === 1) {
            const updatedFormState = {
                ...FormState,
                [stateKey]: valueIfEmpty
            };
            setFormState(updatedFormState);
        }

        if (!allowNonNumeric) {
            if (keyCode !== 46 && keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
                ev.preventDefault();
            }
        }
    }

    function _onChange(value: any, stateKey: string): void {
        const updatedFormState = {
            ...FormState,
            [stateKey]: value
        };
        setFormState(updatedFormState);
    }

    function formIsValid(): boolean {
        const { slaInHours, updateFrequencyInHours, name } = FormState;
        const formErrors: { [index: string]: string } = {};
        if (!updateFrequencyInHours) {
            formErrors.updateFrequencyInHours = "Update Frequency is required.";
            if (updateFrequencyInHoursRef.current) {
                updateFrequencyInHoursRef.current.focus();
            }
        }
        if (!slaInHours) {
            formErrors.slaInHours = "SLA is required.";
            if (slaInHoursRef.current) {
                slaInHoursRef.current.focus();
            }
        }
        if (!name) {
            formErrors.name = "Feed name is required.";
            if (nameRef.current) {
                nameRef.current.focus();
            }
        }
        if (Object.keys(formErrors).length !== 0) {
            const updatedFormState = {
                ...FormState,
                errors: formErrors,
                message: ""
            };
            setFormState(updatedFormState);
        }
        return Object.keys(formErrors).length === 0;
    }

    function processFormSubmission(): void {
        if (!formIsValid()) {
            return;
        }

        const currentFeed: FeedDetail = {
            ...FormState,
            lastUpdated: new Date()
        };
        dispatch(updateFeed(currentFeed));
    }

    function copyAction(stateKey: string) {
        navigator.clipboard.writeText(FormState[stateKey] != null ? FormState[stateKey] : "");
    }
    function renderGraphLookupFieldLabel(stateKey: string, labelText: string): React.ReactNode {
        return (
            <>
                {labelText}
                <IconButton
                    iconProps={{ iconName: "copy" }}
                    title="Copy"
                    onClick={() => {
                        copyAction(stateKey);
                    }}
                ></IconButton>
            </>
        );
    }

    return (
        <>
            <StandardForm widthPercent={90} mediumWidthPercent={90} smallWidthPercent={90}>
                <SaveModal
                    hidden={!annoucement.show}
                    onClose={annoucement.onClose}
                    successMsg={annoucement.msg}
                    error={error}
                    loading={state[StateActionType.Update] === "UPDATING"}
                />
                <Stack.Item align="end" styles={{ root: { marginBottom: "-50px" } }}>
                    <IconButton
                        title="Close"
                        onClick={() => history.replace("/feeds")}
                        iconProps={{ iconName: "Cancel" }}
                        allowDisabledFocus
                    />
                </Stack.Item>
                <StackRow horizontalAlign="space-between" columnWidth={100}>
                    <Toggle
                        label={<Label className="label">Edit</Label>}
                        defaultChecked={false}
                        onText="On"
                        offText="Off"
                        onChange={(_e, checked) => _onChange(!checked, "editDisabled")}
                    />
                </StackRow>
                <Separator />
                {error && <Errorbar msg={error} />}

                <StackRow>
                    <ShimmeredTextField
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        id="component"
                        componentRef={nameRef}
                        txtLabel="Name"
                        isStackItem
                        required
                        onChange={(_e, newValue) => {
                            if (newValue && newValue.length <= 200) _onChange(newValue, "name");
                        }}
                        onKeyDown={(_e) => _on_KeyPress(_e, "name", "")}
                        value={FormState.name.toString()}
                        disabled={FormState.editDisabled}
                        errorMessage={FormState.errors["name"]}
                    />

                    <ShimmeredToggle
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        isStackItem
                        onText="Active"
                        offText="Deprecated"
                        toggleLabel="Status"
                        checked={!FormState.isDeprecated}
                        onChange={(_ev, checked) => _onChange(!checked, "isDeprecated")}
                        disabled={FormState.editDisabled}
                    />
                </StackRow>

                <StackRow>
                    <ShimmeredText
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        txtLabel="Path"
                        className="path"
                        isStackItem
                    >
                        {FormState.path}
                    </ShimmeredText>
                    <ShimmeredText
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        txtLabel="Storage Account Name"
                        className="storageName"
                        isStackItem
                    >
                        {FormState.storageName}
                    </ShimmeredText>

                    <ShimmeredText
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        txtLabel="Platform"
                        className="platformName"
                        isStackItem
                    >
                        {FormState.platformName}
                    </ShimmeredText>
                </StackRow>
                <StackRow>
                    <Stack>
                        <Label className="label">Pipeline Name</Label>
                        <Shimmer
                            isDataLoaded={state[StateActionType.LoadSingle] === "LOADED_SINGLE"}
                            ariaLabel="Loading Feed Pipeline Name"
                            width="40%"
                            styles={getShimmerStyles()}
                        >
                            <PipelineCommandButton
                                popUpShow={_popUpShow}
                                pipelineId={FormState.pipelineId!}
                                name={FormState.pipelineName!}
                                editDisabled={FormState.editDisabled}
                                datafactoryAuthorLink={FormState.datafactoryAuthorLink}
                            />
                        </Shimmer>
                    </Stack>
                    <ShimmeredTextField
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        id="slaInHours"
                        txtLabel="SLA in hours"
                        componentRef={slaInHoursRef}
                        suffix="hrs"
                        required
                        isStackItem
                        styles={controlStyles}
                        onChange={(_e, newValue) => {
                            if (newValue && newValue.length <= 2) _onChange(parseInt(newValue, 10), "slaInHours");
                        }}
                        onKeyDown={(_e) => _on_KeyPress(_e, "slaInHours", 0, false)}
                        value={FormState.slaInHours ? FormState.slaInHours.toString() : ""}
                        disabled={FormState.editDisabled}
                        errorMessage={FormState.errors["slaInHours"]}
                    />
                    <ShimmeredTextField
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        id="updateFrequencyInHours"
                        componentRef={updateFrequencyInHoursRef}
                        txtLabel="Update frequency in hours"
                        required
                        suffix="hrs"
                        isStackItem
                        styles={controlStyles}
                        onChange={(_e, newValue) => {
                            if (newValue && newValue.length <= 2)
                                _onChange(parseInt(newValue, 10), "updateFrequencyInHours");
                        }}
                        onKeyDown={(_e) => _on_KeyPress(_e, "updateFrequencyInHours", 0, false)}
                        value={FormState.updateFrequencyInHours ? FormState.updateFrequencyInHours.toString() : "0"}
                        disabled={FormState.editDisabled}
                        errorMessage={FormState.errors["updateFrequencyInHours"]}
                    />
                </StackRow>
                <StackRow>
                    <ShimmeredComboBox
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        cbxLabel="Feed Category"
                        styles={dropdownControlStyles}
                        selectedKey={FormState.feedCategoryId ? FormState.feedCategoryId : undefined}
                        placeholder="Select a feed category"
                        options={Object.entries(FormState.feedCategories).map(([key, value]) => ({
                            key: value,
                            text: key
                        }))}
                        isStackItem
                        allowFreeform
                        autoComplete="on"
                        onChange={(_ev, option) => _onChange(parseInt(option!.key as string), "feedCategoryId")}
                        disabled={FormState.editDisabled}
                    />
                    <ShimmeredToggle
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        onText="Enabled"
                        offText="Disabled"
                        isStackItem
                        toggleLabel="Automatic Purge Status"
                        checked={FormState.purgeIsEnabled}
                        onChange={(_ev, checked) => _onChange(checked, "purgeIsEnabled")}
                        disabled={FormState.editDisabled}
                    />
                    <ShimmeredTextField
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        id="retentionDays"
                        isStackItem
                        txtLabel="Purge Retention Days"
                        styles={controlStyles}
                        onChange={(_ev, value) => {
                            if (value && value.length <= 4) _onChange(parseInt(value, 10), "purgeRetentionInDays");
                        }}
                        onKeyDown={(_e) => _on_KeyPress(_e, "retentionDays", 0, false)}
                        suffix="days"
                        value={FormState.purgeRetentionInDays ? FormState.purgeRetentionInDays.toString() : "0"}
                        disabled={FormState.editDisabled || !FormState.purgeIsEnabled}
                        errorMessage={
                            !FormState.editDisabled &&
                            FormState.isDeltaLake &&
                            FormState.purgeIsEnabled &&
                            FormState.purgeRetentionInDays < retentionDaysLimit
                                ? "value should be greater than 180 days"
                                : FormState.errors["purgeRetentionDays"]
                        }
                    />
                </StackRow>
                <StackRow>
                    <ShimmeredGraphLookup
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        txtLabel={renderGraphLookupFieldLabel("feedGroupAADName", "AAD Security Group")}
                        formState={FormState}
                        setState={setFormState}
                        stateKey="feedGroupAAD"
                        title="AAD Security Group"
                        editDisabled={FormState.editDisabled}
                        type="AADGroups"
                    ></ShimmeredGraphLookup>

                    <ShimmeredGraphLookup
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        formState={FormState}
                        setState={setFormState}
                        stateKey="feedGroupAD"
                        txtLabel={renderGraphLookupFieldLabel("feedGroupADName", "AD Security Group")}
                        title="AD Security Group"
                        editDisabled={FormState.editDisabled}
                        type="ADGroups"
                    ></ShimmeredGraphLookup>

                    <ShimmeredText
                        loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        txtLabel="Comms Alias"
                        className="commsAlias"
                    >
                        {FormState.feedGroupADEmail}
                    </ShimmeredText>
                </StackRow>

                {!FormState.editDisabled && FormState.purgeIsEnabled && FormState.isDeltaLake ? (
                    <>
                        <br />
                        <br />
                        <Text variant="xLarge" styles={siteTextStyles}>
                            Purging configuration <span style={{ color: "red" }}>* </span>
                            <TooltipHost content="Name of the column that you want to purge by when data meets the retention policy">
                                <Icon iconName="Info" />
                            </TooltipHost>
                        </Text>
                        <div className="row top-margin25">
                            <ShimmeredComboBox
                                loading={state[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                                cbxLabel="Purge by"
                                styles={dropdownControlStyles}
                                selectedKey={FormState.feedPartitionId != -1 ? FormState.feedPartitionId : undefined}
                                placeholder="Select a purge by value"
                                options={Object.entries(FormState.feedPartitions).map(([key, value]) => ({
                                    key: value,
                                    text: key
                                }))}
                                allowFreeform
                                autoComplete="on"
                                onChange={(_ev, option) =>
                                    _onChange(parseInt(option!.key as string), "feedPartitionId")
                                }
                                disabled={FormState.editDisabled}
                            />
                        </div>
                    </>
                ) : (
                    <></>
                )}

                <Separator />
                <StackRow columnWidth={100}>
                    <PrimaryButton
                        text="Save"
                        onClick={processFormSubmission}
                        disabled={FormState.editDisabled}
                    ></PrimaryButton>
                </StackRow>

                {isInRole(PMUser) &&
                    currentFeed?.accessPackageIsPlaceholder !== false &&
                    currentFeed?.storageType === "ADLSGen2" && (
                        <Stack horizontalAlign="center" horizontal tokens={stackTokens}>
                            <PrimaryButton onClick={() => history.push(`/migrateAccessPackage/${feedId}`)}>
                                Migrate to ELM
                            </PrimaryButton>
                        </Stack>
                    )}

                <Stack styles={{ root: { marginTop: "20px", width: "100%" } }}>
                    <Stack.Item align="center">
                        <Label className="label">ACLs</Label>
                    </Stack.Item>
                    {acls === undefined ||
                        (acls.length > 0 && (
                            <Stack.Item>
                                <Shimmer
                                    isDataLoaded={acls !== undefined}
                                    ariaLabel="Loading Feed Acls"
                                    styles={getShimmerStyles()}
                                >
                                    <AclList acls={acls || []} />
                                </Shimmer>
                            </Stack.Item>
                        ))}
                </Stack>
            </StandardForm>
            {associateFeeddialogProps.showDialog && <AssociatePipelineDialog {...associateFeeddialogProps} />}
        </>
    );
}

export default EditFeed;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../service/authProvider";
import { KeyVaultSecretEntry } from "../models/KeyVaultSecretEntry";

export const keyVaultApi = createApi({
    reducerPath: "keyVaultApi",
    tagTypes: ["SecreetEntries"],
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, api) => {
            const token = await getAccessToken();
            if (token) {
                headers.set("authorization", `Bearer ${token.accessToken}`);
            }
            return;
        }
    }),
    endpoints: (builder) => ({
        getKeyVaultSecretEntries: builder.query<KeyVaultSecretEntry[], string | undefined>({
            query: (subscriptionId?: string) => ({
                url: `api/KeyVault/secrets/${subscriptionId}`,
                method: "GET"
            }),
            providesTags: () => [{ type: "SecreetEntries" as const, id: "LIST" }],
            keepUnusedDataFor: 120
        })
    })
});

export const { useGetKeyVaultSecretEntriesQuery } = keyVaultApi;

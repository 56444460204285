import { mergeStyleSets, Overlay, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";

export const LoadingOverLay = ({ isOverlayVisible }: { isOverlayVisible: boolean }) => {
    const styles = mergeStyleSets({
        root: {
            top: "0",
            height: "100%",
            color: "white",
            left: "0",
            position: "absolute",
            zIndex: 9999,
            right: "0",
            backdropFilter: "blur(10px)",
            alignContent: "center"
        }
    });

    return (
        <>
            {isOverlayVisible && (
                <Overlay>
                    <div className={styles.root}>
                        <Spinner
                            style={{
                                position: "absolute",
                                left: 0,
                                top: "50%",
                                transform: "translateY(-50%)",
                                width: "100%"
                            }}
                            size={SpinnerSize.large}
                        />
                    </div>
                </Overlay>
            )}
        </>
    );
};

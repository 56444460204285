import { DetailsList } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import { generateDatasetAlertListColumns } from "../../columns/DatasetAlertListColumns";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { dataSetAlertSelector } from "../../pages/Selectors";
import { DatasetAlertComponents, getAllDatasetAlerts } from "../../reducers/DataSetAlert";
import { useEffect, useState } from "react";
import { StateValues } from "../../types";
import StandardList from "../common/StandardList";

function AlertsList() {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { datasetAlerts, dsAlertLoading, dsAlertErrors } = useAppSelector(dataSetAlertSelector);
    const [errorMsg, setErrorMsg] = useState<string>(dsAlertErrors[DatasetAlertComponents.datasetAlerts]);
    const [stateValues, setStateValues] = useState<StateValues>("INIT");

    useEffect(() => {
        dispatch(getAllDatasetAlerts());
    }, []);

    useEffect(() => {
        // need to manual set the state since this is a non standard implementation
        if (dsAlertLoading[DatasetAlertComponents.datasetAlerts]) {
            setStateValues("LOADING_ALL");
        }

        if (datasetAlerts && !dsAlertLoading[DatasetAlertComponents.datasetAlerts]) {
            setStateValues("LOADED_ALL");
        }
    }, [datasetAlerts]);

    return <>
        <StandardList
            entities={datasetAlerts}
            title={"Dataset Alerts"}
            columns={generateDatasetAlertListColumns(history)}
            error={errorMsg}
            entityState={stateValues}
        />
    </>
};

export default AlertsList;
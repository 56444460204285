import { ComboBox, IComboBoxOption, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";

const dropdownControlStyles = {
    root: {
        width: "90%",
        margin: "0 30px 20px 0",
        border: "1px solid #ccc"
    }
};

function isDeleteKey(keyCode: number) {
    return keyCode === 46 || keyCode === 8;
}
export type StackComboBoxProps = {
    name: string;
    onChange(key: any): void;
    options: IComboBoxOption[];
    selectedKey?: string | number;
    disabled?: boolean;
    loading?: boolean;
    styles?: any;
};

export function StackComboBox({ name, onChange, options, selectedKey, disabled, loading, styles }: StackComboBoxProps) {
    const [stateOptions, setStateOptions] = React.useState(options);

    // ½ is the char code for the _ and -
    const regexp = new RegExp(/^[a-zA-Z0-9½ ]*$/);
    return (
        <div style={{ position: "relative" }}>
            <ComboBox
                ariaLabel={`${name} selection`}
                styles={styles ? styles : dropdownControlStyles}
                placeholder={`Select ${name}`}
                selectedKey={selectedKey}
                allowFreeform={true}
                onKeyUp={(event) => {
                    // if you try to use event.key, arrows will show as "ArrowUp" which will look like a valid character
                    if (event && (regexp.test(String.fromCharCode(event.keyCode)) || isDeleteKey(event.keyCode))) {
                        setStateOptions(
                            options.filter((option) =>
                                option.text
                                    .toLowerCase()
                                    .includes((event.target as HTMLInputElement).value.toLowerCase())
                            )
                        );
                    }

                    if ((event.target as HTMLInputElement).value === "") {
                        setStateOptions(options);
                    }
                }}
                onChange={(_ev, option) => {
                    if (option) {
                        onChange(option.key);
                    }
                }}
                onFocus={() => {
                    // reset the options to the original list when a new dropdown is opened (they might have been filtered)
                    setStateOptions(options);
                }}
                autoComplete="off"
                openOnKeyboardFocus={true}
                options={stateOptions}
                disabled={disabled}
            />

            {loading && (
                <div
                    style={{
                        zIndex: 9999,
                        position: "absolute",
                        top: "22%",
                        left: "80%"
                    }}
                >
                    <Spinner size={SpinnerSize.xSmall} />
                </div>
            )}
        </div>
    );
}

import "@fluentui/react/dist/css/fabric.css";
import { Stack } from "@fluentui/react";
import HomePage from "./HomePage";

function HomePageContainer() {
    return (
        <>
            <Stack tokens={{ childrenGap: 30 }}>
                <HomePage></HomePage>
            </Stack>
        </>
    );
}

export default HomePageContainer;

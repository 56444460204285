import React, { useCallback, useEffect, useMemo, useRef } from "react";
import "../styles/App.css";
import { remoteServerConfigSelector } from "./Selectors";
import StandardList from "../components/common/StandardList";
import { RemoteServerActionColumns } from "../columns";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { ActionType, StateActionType } from "../types";
import { getAllRemoteServerConfigDetails, postAdministrationCmd } from "../reducers/RemoteServerConfig";
import { Label, PrimaryButton, SelectionMode, Stack, TextField, IObjectWithKey } from "@fluentui/react";
import { DialgContainer } from "../components/common/DialogContainer";
import { useState } from "react";
import { IRemoteServerAdministration, IRemoteServerServiceConfig } from "../models";
import { StackComboBox } from "../components/common/StackedComboBox";
import { getAllRemoteServerServiceConfigs, postAdministrationCommand } from "../api/RemoteServerConfigApi";

function RemoteServerActionList() {
    const MAXSELECTIONALLOWED = 5;
    const [error, setError] = React.useState<string>("");
    const [selectedServiceName, setSelectedServiceName] = React.useState<string>("");
    const [serviceNamesLoading, setserviceNamesLoading] = React.useState<boolean>(false);
    const [serviceNames, setserviceNames] = React.useState<IRemoteServerServiceConfig[]>([]);

    const [showServiceRestartDialog, setShowServiceRestartDialog] = useState(false);
    const [showLoadingDialog, setShowLoadingDialog] = useState(false);
    const [showRefreshingDialog, setShowRefreshingDialog] = useState(false);

    // state to handle the selection
    const [disableActionButton, setDisableActionButton] = useState(true);
    const [disableRebootActionButton, setDisableRebootActionButton] = useState(false);
    const [disableServiceRestartActionButton, setDisableServiceRestartActionButton] = useState(false);
    const [showSelErrorMsg, setsShowSelErrorMsg] = useState(false);
    const [selectedServerDetails, setSelectedServerDetails] = React.useState<IObjectWithKey[]>([]);

    // method will execute when list row has been selected
    const selection = useMemo(
        () => (items: IObjectWithKey[]) => {
            setSelectedServerDetails(items);
            setDisableActionButton(true);
            setsShowSelErrorMsg(false);
            if (items.length > MAXSELECTIONALLOWED) {
                setsShowSelErrorMsg(true);
            }
            // allow only for 3 selection max.
            else if (items.length != 0 && items.length <= MAXSELECTIONALLOWED) {
                setDisableActionButton(false);
            }
            if (items.length != 0) {
                setDisableRebootActionButton(false);
                setDisableServiceRestartActionButton(false);
                // disable Reboot Action button
                let servers = items.filter((s) => s["reboot"] == 0);
                if (servers.length != 0) setDisableRebootActionButton(true);

                servers = items.filter((s) => s["restartService"] == 0);
                // disable Service Restart Action button
                if (servers.length != 0) setDisableServiceRestartActionButton(true);
            }
        },
        []
    );

    // Disable row selection when there is an action already going on
    const canSelectItem = (item: any): boolean => {
        return (
            item.status == "Online" &&
            item.rebootStatus != "In progress" &&
            item.serviceRestartStatus != "In progress" &&
            !(!item.reboot && !item.restartService)
        );
    };

    const dispatch = useAppDispatch();
    const history = useHistory();
    const {
        entities: remoteServerConfigs,
        error: remoteServerConfigsError,
        state: remoteServerConfigsState
    } = useAppSelector(remoteServerConfigSelector);

    const addButtonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.title = "Summary of Remote Servers - SIP Portal";
        if (remoteServerConfigsState[StateActionType.LoadAll] !== "LOADED_ALL") {
            dispatch(getAllRemoteServerConfigDetails());
        }
        // content refresh on every 30 secs.
        const timer = setTimeout(() => {
            setShowRefreshingDialog(true);
            dispatch(getAllRemoteServerConfigDetails());
            setShowRefreshingDialog(false);
        }, 30000);
        return () => clearTimeout(timer);
    }, [remoteServerConfigs]);

    const getRemoteServerServiceCallback = useCallback(async () => {
        setError("");
        setserviceNamesLoading(true);
        try {
            const response = await getAllRemoteServerServiceConfigs();
            setserviceNames(response.data);
            if (response.data != null) {
                setSelectedServiceName(response.data[0].name);
            }
            setserviceNamesLoading(false);
        } catch (error: any) {
            setError(error.title);
            setserviceNamesLoading(false);
        }
    }, [setserviceNamesLoading, getAllRemoteServerServiceConfigs, setserviceNamesLoading]);

    async function executeRebootAction() {
        const obj: IRemoteServerAdministration[] = [];
        selectedServerDetails.forEach((item) => {
            let o: IRemoteServerAdministration = {
                actionType: ActionType.Reboot,
                actionHistoryID: 0,
                serverID: item["id"],
                serverName: item["serverName"],
                serviceName: null
            };
            obj.push(o);
        });
        let isExecuted = confirm("Are you sure to execute this action?");
        if (isExecuted) {
            setShowLoadingDialog(true);
            await postAdministrationCommand(obj);
            showLoadingOnSubmit();
        } else {
            alert("Action has been canceled");
        }
    }
    async function executeServiceRestartAction() {
        const obj: IRemoteServerAdministration[] = [];
        selectedServerDetails.forEach((item) => {
            let o: IRemoteServerAdministration = {
                actionType: ActionType["Service Restart"],
                actionHistoryID: 0,
                serverID: item["id"],
                serverName: item["serverName"],
                serviceName: selectedServiceName
            };
            obj.push(o);
        });
        setShowLoadingDialog(true);
        await postAdministrationCommand(obj);
        if (serviceNames != null) {
            setSelectedServiceName(serviceNames[0].name);
        }
        setShowServiceRestartDialog(false);
        showLoadingOnSubmit();
    }

    useEffect(() => {
        getRemoteServerServiceCallback();
    }, []);

    function showLoadingOnSubmit() {
        setShowLoadingDialog(true);
        setTimeout(function () {
            // Data Refresh
            dispatch(getAllRemoteServerConfigDetails());
            setShowLoadingDialog(false);
        }, 3000);
    }

    function renderDialogContent() {
        return (
            <>
                <Stack>
                    <div>
                        <Label>Service name</Label>
                        <StackComboBox
                            name="Service Name"
                            selectedKey={selectedServiceName}
                            onChange={setSelectedServiceName}
                            options={serviceNames.map((b) => ({ key: b.name, text: b.displayName }))}
                            loading={!selectedServiceName && serviceNamesLoading}
                        />
                    </div>
                </Stack>
                <Stack style={{ float: "right", paddingRight: 0 }}>
                    <div>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <PrimaryButton
                                text="Confirm"
                                type="button"
                                onClick={() => executeServiceRestartAction()}
                            ></PrimaryButton>
                        </Stack>
                    </div>
                </Stack>
            </>
        );
    }

    return (
        <>
            <StandardList
                entities={
                    remoteServerConfigs &&
                    remoteServerConfigs.map((e) => ({
                        ...e
                    }))
                }
                title={"Remote Servers"}
                columns={RemoteServerActionColumns(history)}
                error={remoteServerConfigsError}
                entityState={remoteServerConfigsState[StateActionType.LoadAll]}
                extraHeaderItems={
                    <div
                        ref={addButtonRef}
                        onFocus={() => {
                            addButtonRef.current &&
                                addButtonRef.current.scrollIntoView({
                                    behavior: "smooth",
                                    block: "nearest",
                                    inline: "start"
                                });
                        }}
                    >
                        <Stack.Item grow align="center">
                            <PrimaryButton
                                text="Reboot"
                                onClick={() => {
                                    executeRebootAction();
                                }}
                                allowDisabledFocus
                                disabled={disableActionButton || disableRebootActionButton}
                                style={{ marginRight: 10 }}
                            />
                            <PrimaryButton
                                text="Service Restart"
                                onClick={() => {
                                    setShowServiceRestartDialog(true);
                                }}
                                allowDisabledFocus
                                disabled={disableActionButton || disableServiceRestartActionButton}
                            />
                            {showSelErrorMsg && (
                                <p style={{ color: "red", fontSize: "12px" }}>* exceeded maximum number of selection</p>
                            )}
                        </Stack.Item>
                    </div>
                }
                selection={selection}
                canSelectItem={canSelectItem}
                selectionMode={SelectionMode.multiple}
            />
            <DialgContainer
                {...{
                    showDialog: showRefreshingDialog,
                    onClose: () => {
                        setShowRefreshingDialog(false);
                    }
                }}
            >
                <div>Refreshing...</div>
            </DialgContainer>
            <DialgContainer
                {...{
                    showDialog: showLoadingDialog,
                    onClose: () => {
                        setShowLoadingDialog(false);
                    }
                }}
            >
                <div>Please wait...</div>
            </DialgContainer>
            <DialgContainer
                {...{
                    showDialog: showServiceRestartDialog,
                    onClose: () => {
                        setShowServiceRestartDialog(false);
                    },
                    minHeight: "180px"
                }}
            >
                {renderDialogContent()}
            </DialgContainer>
        </>
    );
}

export default RemoteServerActionList;

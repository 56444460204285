import { IFeedFreshness } from "../models/FeedFreshness";
import { BaseAction, StateValues } from ".";
export enum FeedFreshnessActionTypes {
    GETALL = "FEEDFRESHNESS/GETALL",
    GETSINGLE = "FEEDFRESHNESS/GETSINGLE",
    GETHISTORY = "FEEDFRESHNESS/GETHISTORY",
    SETCURRENT = "FEEDFRESHNESS/SETCURRENT",
    INITIALIZE = "FEEDFRESHNESS/INIT",
    SEARCHFEEDFRESHNESS = "FEEDFRESHNESS/SEARCH",
    LOADINGFEEDFRESHNESS = "FEEDFRESHNESS/LOADING",
    LOADINGFEEDFRESHNESSHISTORY = "FEEDFRESHNESS/LOADINGHISTORY",
    LOADINGERROR = "FEEDFRESHNESS/ERROR",
    HISTORYERROR = "FEEDFRESHNESS/HISTORYERROR"
}

export interface IFeedFreshnessError extends BaseAction<string> {
    type: FeedFreshnessActionTypes.LOADINGERROR;
}

export interface IFeedFreshnessSearchAction extends BaseAction<string> {
    type: FeedFreshnessActionTypes.SEARCHFEEDFRESHNESS;
}

export interface ISetCurrentFeedFreshnessAction extends BaseAction<IFeedFreshness> {
    type: FeedFreshnessActionTypes.SETCURRENT;
}

export interface IFeedFreshnessGetAllAction extends BaseAction<IFeedFreshness[]> {
    type: FeedFreshnessActionTypes.GETALL;
}

export interface IFeedFreshnessInit extends BaseAction {
    type: FeedFreshnessActionTypes.INITIALIZE;
}

export interface IFeedFreshnessLoading extends BaseAction {
    type: FeedFreshnessActionTypes.LOADINGFEEDFRESHNESS;
}

export interface IFeedFreshnessHistoryLoading extends BaseAction {
    type: FeedFreshnessActionTypes.LOADINGFEEDFRESHNESSHISTORY;
}

export interface IFeedFreshnessGetHistoryAction extends BaseAction<Date[]> {
    type: FeedFreshnessActionTypes.GETHISTORY;
}

export interface IFeedFreshnessGetHistoryError extends BaseAction<string> {
    type: FeedFreshnessActionTypes.HISTORYERROR;
}

export interface IFeedFreshnessGetSingleAction extends BaseAction<IFeedFreshness> {
    type: FeedFreshnessActionTypes.GETSINGLE;
}

export type FeedFreshnessActions =
    | IFeedFreshnessGetAllAction
    | IFeedFreshnessGetSingleAction
    | IFeedFreshnessInit
    | IFeedFreshnessSearchAction
    | IFeedFreshnessLoading
    | IFeedFreshnessError
    | ISetCurrentFeedFreshnessAction
    | IFeedFreshnessHistoryLoading
    | IFeedFreshnessGetHistoryAction
    | IFeedFreshnessGetHistoryError;

export interface IFeedFreshnessState {
    readonly currentFeedFreshness: IFeedFreshness | null;
    readonly currentFeedFreshnessHistory?: Date[];
    readonly feedFreshnesses: IFeedFreshness[];
    readonly feedFreshnessLoading: boolean;
    readonly feedFreshnessHistroyLoading: boolean;
    readonly feedFreshnessHistoryError: string;
    readonly feedFreshnessState: StateValues;
    readonly feedFreshnessSearch: string;
    readonly feedFreshnessError: string;
}

import React, { useMemo, useState } from "react";
import { Stack, Text, Separator, DetailsList, SelectionMode, mergeStyles, DefaultEffects } from "@fluentui/react";
import { IFeed } from "../../models";

const CompactFeedList = ({
    feeds,
    title,
    selectionMode
}: {
    feeds: IFeed[];
    title: string;
    selectionMode?: SelectionMode;
    onSelectionChange?: (feed: IFeed) => void;
}) => {
    const containerStyles = mergeStyles({
        width: "30%",
        background: "#fff",
        borderRadius: DefaultEffects.roundedCorner2,
        padding: "35px",
        boxSizing: "content-box",
        boxShadow: DefaultEffects.elevation4,
        "@media (max-width: 1500px)": {
            width: "50%"
        },
        "@media (max-width: 800px)": {
            width: "80%"
        }
    });

    return (
        <Stack.Item className={containerStyles} align="center">
            <Stack>
                <Stack.Item align="center">
                    <Text variant="mediumPlus">{title}</Text>
                </Stack.Item>
                <Separator />

                <DetailsList
                    items={feeds}
                    selectionMode={selectionMode || SelectionMode.none}
                    styles={{ root: { overflow: "auto" } }}
                    compact
                    selectionPreservedOnEmptyClick={true}
                    columns={[
                        { key: "name", name: "Name", fieldName: "name", minWidth: 200 },
                        {
                            key: "storageName",
                            name: "Storage Account",
                            fieldName: "storageName",
                            minWidth: 200
                        },
                        { key: "path", name: "Path", fieldName: "path", minWidth: 400 }
                    ]}
                />
            </Stack>
        </Stack.Item>
    );
};

export default CompactFeedList;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import { getFeedGroup, getFeedGroups, getFeedGroupUser } from "../api/FeedGroupApi";

import { getDefaultCases } from "./DefaultCases";
import { IFeedGroup } from "../models";

const sliceName = "feedGroups";

const initialState = getInitialState<IFeedGroup, IFeedGroup>();

const getAllFeedGroups = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getFeedGroups();
    return response.data;
});

const getFeedGroupById = createAsyncThunk(`${sliceName}/get_by_id`, async (id: number, _thunkAPI) => {
    const response = await getFeedGroup(id);
    return response.data;
});

const getFeedGroupsByUser = createAsyncThunk(`${sliceName}/get_by_mail`, async (mail: string, _thunkAPI) => {
    const response = await getFeedGroupUser(mail);
    return response.data;
});

const feedGroupSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IFeedGroup>(),
    extraReducers: (builder) => {
        builder.addCase(getAllFeedGroups.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllFeedGroups.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getFeedGroupById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(getFeedGroupById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadSingle);
        });
        builder.addCase(getFeedGroupsByUser.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getFeedGroupsByUser.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
    }
});

export const { reset, error, setCurrent } = feedGroupSlice.actions;
export { getAllFeedGroups, getFeedGroupById, sliceName, getFeedGroupsByUser };
export const { reducer } = feedGroupSlice;

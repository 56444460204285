import { History } from "history";
import { AdminUser } from "../constants";

import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { _renderShimmer, addSortLabels } from "./utils";
import { IDatasetAlert } from "../models";
import { IButtonStyles, IContextualMenuProps, Icon, IconButton } from "@fluentui/react";
export function generateDatasetAlertListColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Alert Name",
            fieldName: "name",
            filterBy: true,
            minWidth: 50,
            maxWidth: 200,
            isResizable: true
        },
        {
            key: "datasetName",
            name: "Dataset Name",
            fieldName: "datasetName",
            filterBy: true,
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            isSorted: true
        },
        {
            key: "type",
            name: "Type",
            fieldName: "type",
            filterBy: true,
            minWidth: 50,
            maxWidth: 50,
            isResizable: true
        },
        {
            key: "category",
            name: "Category",
            fieldName: "category",
            filterBy: true,
            minWidth: 75,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: "alertType",
            name: "Alert Type",
            fieldName: "alertType",
            filterBy: true,
            minWidth: 75,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: "createdBy",
            name: "Created By",
            fieldName: "createdBy",
            filterBy: true,
            minWidth: 150,
            maxWidth: 200,
            isResizable: true
        },
        {
            key: "isSupressed",
            name: "Is Active",
            fieldName: "isSupressed",
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDatasetAlert) => renderIsActiveColumn(item, history)
        },
        {
            key: "lastTriggered",
            name: "Last Triggered",
            fieldName: "lastTriggered",
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDatasetAlert) => renderLastTriggered(item)
        }
    ];

    if (isInRole(AdminUser)) {
        cols.push({
            key: "edit",
            name: "Edit",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDatasetAlert) => renderEditColumn(item, "Alert/Edit", history)
        });
    }

    addSortLabels(cols);
    return cols;
}

function renderLastTriggered(item: IDatasetAlert) {
    return item.lastTriggered && item.lastTriggered.toString().indexOf("0001") == -1
        ? item.lastTriggered.toString().split("T")[0]
        : "";
}
function renderIsActiveColumn(item: IDatasetAlert, history: History) {
    const inActiveString = item.isSupressed ? `(until ${item.suppressionEndTime.toString().split("T")[0]})` : "";
    return (
        <div style={{ float: "left", width: "100%" }}>
            <div style={{ float: "left", width: "15%" }}>
                <Icon
                    title={!item.isSupressed ? "Active" : inActiveString}
                    iconName={!item.isSupressed ? "Ringer" : "RingerOff"}
                    styles={{
                        root: {
                            alignContent: "center",
                            color: item.isSupressed ? "gray" : "rgb(0, 100, 191)",
                            fontSize: 18
                        }
                    }}
                />
            </div>
            <div style={{ float: "left", width: "40%" }}>{inActiveString}</div>
        </div>
    );
}
function renderEditColumn(item: IDatasetAlert, path: string, history: History) {
    if (item.name === "shimmer") {
        return _renderShimmer();
    }
    const url = window.location.origin + `/${path}/${item.id}`;
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: "newTab",
                text: "Open in New Tab",
                iconProps: {
                    iconName: "OpenInNewWindow"
                },
                onClick: () => {
                    window.open(url, "_blank");
                }
            }
        ]
    };
    const customSplitButtonStyles: IButtonStyles = {
        splitButtonMenuButton: { backgroundColor: "inherit", width: 28, border: "none" },
        splitButtonMenuIcon: { fontSize: "7px" },
        splitButtonDivider: { backgroundColor: "#c8c8c8", width: 1, right: 26, position: "absolute", top: 4, bottom: 4 }
    };

    return (
        <IconButton
            split
            styles={customSplitButtonStyles}
            title={item.name + " View Button"}
            className="routing"
            onClick={() => {
                history.replace(`/Alert/Edit${item.id ? "/" + item.id : ""}`);
            }}
            menuProps={menuProps}
            iconProps={{ iconName: "Edit" }}
            allowDisabledFocus
        />
    );
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import {
    getContacts,
    getContact,
    addContact as addContactApi,
    deleteContact as deleteContactApi
} from "../api/ContactApi";
import { getDefaultCases } from "./DefaultCases";
import { IContact } from "../models";

const sliceName = "contacts";

const initialState = getInitialState<IContact, IContact>();

const getAllContacts = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getContacts();
    return response.data;
});

const getContactById = createAsyncThunk(`${sliceName}/get_by_id`, async (id: number, _thunkAPI) => {
    const response = await getContact(id);
    return response.data;
});

const addContact = createAsyncThunk(`${sliceName}/add`, async (contact: IContact, _thunkAPI) => {
    const response = await addContactApi(contact);
    return response.data;
});

const deleteContact = createAsyncThunk(`${sliceName}/delete`, async (contact: IContact, _thunkAPI) => {
    const response = await deleteContactApi(contact);
    return response.data;
});

const contactsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IContact>(),
    extraReducers: (builder) => {
        builder.addCase(getAllContacts.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllContacts.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getContactById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(getContactById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadSingle);
        });
        builder.addCase(addContact.pending, (state, _action) => {
            onLoading(state, StateActionType.Add);
        });
        builder.addCase(addContact.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Add);
        });
        builder.addCase(deleteContact.pending, (state, _action) => {
            onLoading(state, StateActionType.Delete);
        });
        builder.addCase(deleteContact.fulfilled, (state, _action) => {
            onComplete(state, StateActionType.Delete);
        });
    }
});

export const { reset, error, setCurrent } = contactsSlice.actions;
export { getAllContacts, getContactById, addContact, deleteContact, sliceName };
export const { reducer } = contactsSlice;

import { Label, Separator, Toggle } from "@fluentui/react";
import { useIsSmallScreen } from "../../hooks";
import { StackRow } from "../common/StackComponents";

const DisplayInCatalogToggle = ({
    enabled,
    checked,
    onChange
}: {
    enabled: boolean;
    checked: boolean;
    onChange: (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => void;
}) => {
    const smallScreen = useIsSmallScreen();

    return (
        <>
            <StackRow>
                <Toggle
                    label={<Label className="label">Display in Catalog</Label>}
                    checked={checked}
                    disabled={!enabled}
                    styles={{ root: { marginTop: "35px" } }}
                    inlineLabel={!smallScreen}
                    onText="True"
                    offText="False"
                    onChange={onChange}
                />
            </StackRow>
            <Separator />
        </>
    );
};

export { DisplayInCatalogToggle };

import { createAsyncThunk, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";

import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import {
    getFeedFreshness,
    getFeedFreshnesses,
    getFeedFreshnessHistory as getFeedFreshnessHistoryApi
} from "../api/FeedApi";

import { getDefaultCases } from "./DefaultCases";
import { IFeedFreshness } from "../models";

const sliceName = "feedFreshness";

const wrapperFoo = () => getInitialState<IFeedFreshness, IFeedFreshness>();

type FFState = ReturnType<typeof wrapperFoo> & { history: Date[] };

const initialState: FFState = { ...getInitialState<IFeedFreshness, IFeedFreshness>(), history: [] };

const getAllFeedFreshness = createAsyncThunk(`${sliceName}/get_all`, async (type: number, _thunkAPI) => {
    const response = await getFeedFreshnesses(type);
    return response.data;
});

type ApiProps = { type: number; id: number };

const getFeedFreshnessById = createAsyncThunk(`${sliceName}/get_by_id`, async (props: ApiProps, _thunkAPI) => {
    const response = await getFeedFreshness(props.type, props.id);
    return response.data;
});
const getFeedFreshnessHistory = createAsyncThunk(`${sliceName}/get_history`, async (props: ApiProps, _thunkAPI) => {
    const response = await getFeedFreshnessHistoryApi(props.type, props.id);
    return response.data;
});

const feedFreshnessSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IFeedFreshness>(),
    extraReducers: (builder) => {
        builder.addCase(getAllFeedFreshness.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllFeedFreshness.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getFeedFreshnessById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(getFeedFreshnessById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadSingle);
        });
        builder.addCase(getFeedFreshnessHistory.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getFeedFreshnessHistory.fulfilled, (state, action) => {
            state.history = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
    }
});

export const { setCurrent, reset, error } = feedFreshnessSlice.actions;
export { getAllFeedFreshness, getFeedFreshnessById, getFeedFreshnessHistory, sliceName };
export const { reducer } = feedFreshnessSlice;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IInternalObjectTagging } from "../models";
import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import { getInternalObj, addInternalObj, deleteInternalObj } from "../api/InternalObjectTaggingApi";
import { getDefaultCases } from "./DefaultCases";


const sliceName = "internalObjectTagging";

const initialState = getInitialState<IInternalObjectTagging, IInternalObjectTagging>();

const getInternalObjs = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getInternalObj();
    return response.data;
});


const createInternalObject = createAsyncThunk(
    `${sliceName}/add`,
    async (props: { appId: string; objectId: string; description: string }, _thunkAPI) => {
        const response = await addInternalObj(props.appId, props.objectId, props.description);
        return response.data;
    }
);

const deleteInternalObject = createAsyncThunk(`${sliceName}/delete`, async (internalobj: IInternalObjectTagging, _thunkAPI) => {
    const response = await deleteInternalObj(internalobj);

    return response.data;
});

const internalObjectTaggingSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IInternalObjectTagging>(),
    extraReducers: (builder) => {
        builder.addCase(getInternalObjs.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getInternalObjs.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(createInternalObject.pending, (state, _action) => {
            onLoading(state, StateActionType.Add);
        });
        builder.addCase(createInternalObject.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Add);
        });
        builder.addCase(deleteInternalObject.pending, (state, _action) => {
            onLoading(state, StateActionType.Delete);
        });
        builder.addCase(deleteInternalObject.fulfilled, (state, action) => {
            onComplete(state, StateActionType.Delete);
        });
    }
});
export const { reset, error, setCurrent } = internalObjectTaggingSlice.actions;
export { getInternalObjs, sliceName, createInternalObject, deleteInternalObject };
export const { reducer } = internalObjectTaggingSlice;
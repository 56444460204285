import React, { useState, useEffect } from "react";
import {
    TextField,
    FontWeights,
    ITextStyles,
    Label,
    Toggle,
    PrimaryButton,
    DefaultButton,
    IconButton,
    IDropdownOption,
    ProgressIndicator,
    Link,
    IActivityItemProps,
    Stack,
    Shimmer,
    TooltipHost,
    FontIcon,
    Separator
} from "@fluentui/react";

import "../../styles/App.css";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";

import { IPipeline } from "../../models/Pipeline";
import { IFeed } from "../../models/Feeds";
import { pipelineSelector, activitiesSelector } from "../../pages/Selectors";

import SuppressionDialogProps, { ISuppressionState } from "../../models/dialogProps/SuppressionDialogProps";
import AssociateFeedsDialogProps from "../../models/dialogProps/AssociateFeedsDialogProps";
import { SuppressionDialog } from "./SuppressionDialog";

import AssociateFeedsDialog from "./AssociateFeedsDialog";
import { IPipelineActivity } from "../../models/PipelineActivtiy";

import { generateFeedColumns } from "../../columns";
import StandardList from "../common/StandardList";
import { IApplicationState } from "../../store";
import { getAllPipelines, getPipelineById, reset, updatePipeline } from "../../reducers/Pipelines";
import { StateActionType } from "../../types";
import { getAllFeeds } from "../../reducers/Feeds";
import { getAllActivites } from "../../reducers/Activities";
import useParamsInt from "../../hooks/UseParamsInt";
import { getPipelineHistory } from "../../api/PipelineApi";
import { useBoolean } from "@fluentui/react-hooks";
import { getShimmerStyles } from "../../columns/utils";
import { ShimmeredDropdown, ShimmeredText, ShimmeredToggle } from "../common/Shimmered";
import Errorbar from "../common/ErrorBar";
import { StackRow } from "../common/StackComponents";
import StandardForm from "../common/StandardForm";
import { AdoHistory } from "./AdoHistory";
import SaveModal from "../Dataset/SaveModal";
import ShimmeredLink from "../common/Shimmered/ShimmeredLink";

const dropdownControlStyles = {
    root: {
        minWidth: "150px",
        margin: "0 30px 20px 0",
        maxWidth: "450px"
    }
};

interface IFormState extends IPipeline {
    errors: { [index: string]: string };

    editDisabled: boolean;
    sliceRelevanceDropdownValues: IDropdownOption[];
}

interface IParentprops {
    id: string;
}

export type GitHistory = IActivityItemProps & {
    key: number;
};

interface IProps extends RouteComponentProps<IParentprops> {}

function EditPipeline(props: IProps) {
    const dispatch = useAppDispatch();
    const routerHistory = useHistory();
    const { entities: feeds, state: feedState } = useAppSelector((state: IApplicationState) => state.feeds);

    const id = useParamsInt();
    const pipelineProperties = useAppSelector(pipelineSelector);
    const activityProperties = useAppSelector(activitiesSelector);

    const rsPipelineState = pipelineProperties.state;

    const rsActivities = activityProperties.entities;
    const rsActivityState = activityProperties.state;

    const currentPipeline = pipelineProperties.currentEntity;

    const [annoucement, setAnnouncement] = useState<{ show: boolean; msg: string; onClose: () => void }>({
        show: false,
        msg: "",
        onClose: () => {}
    });

    const [FormState, setFormState] = useState<IFormState>({
        id: 0,
        dataFactoryName: "",
        platformName: "",
        suppressed: false,
        maxSuppressionTime: 0,
        suppressionStartTime: undefined,
        suppressionEndTime: undefined,
        name: "",
        feedIds: [],
        sliceRelevanceDropdownValues: [],
        sliceRelevanceType: "",
        isIdempotent: false,
        errors: {},
        editDisabled: true,
        sliceRelevanceMapping: [],
        sliceRelevanceId: 0,
        tsgLink: undefined,
        dataFactoryId: 0,
        isProducer: true,
        subscriptionId: ""
    });

    const getDefaultSuppressionState = (): ISuppressionState => {
        const endTime = new Date();
        endTime.setHours(23, 59, 0, 0);

        return { startTime: new Date(), endTime, justification: "", suppressedBy: "" };
    };

    const [suppressionState, setSuppressionState] = useState<ISuppressionState>(getDefaultSuppressionState());
    const [filteredFeeds, setFilteredFeeds] = useState<IFeed[]>([]);
    const [pipelineActivities, setPipelineActivites] = useState<IPipelineActivity[]>([]);
    const [gitHistory, setGitHistory] = useState<GitHistory[]>([]);
    const [gitHistoryLoading, setGitHistoryLoading] = useBoolean(false);
    // only gets set upon update completion just in case someone hits the back button or closes the window
    // to prevent refetching the data if no update occurred
    const [isDismissed, setIsDismissed] = React.useState(true);

    const getDefaultDialogState = (): SuppressionDialogProps => ({
        showDialog: false,
        maxSuppressionTime: new Date(FormState.maxSuppressionTime),
        state: suppressionState,

        setDate: setSuppressionState,
        onClose: () => _popUpClose()
    });

    const [dialogState, setShowDialog] = useState<SuppressionDialogProps>(getDefaultDialogState());

    function _popUpClose() {
        setShowDialog((p) => ({ ...p, showDialog: false, state: getDefaultSuppressionState() }));
    }

    useEffect(() => {
        if (suppressionState.changed) {
            setFormState((p) => ({ ...p, suppressed: true }));
            setSuppressionState((p) => ({ ...p, changed: false }));
        }
    }, [suppressionState]);

    useEffect(() => {
        if (feedState[StateActionType.LoadAll] === "INIT") {
            dispatch(getAllFeeds());
        }

        setGitHistoryLoading.setTrue();

        (async () => {
            if (id) {
                try {
                    const hist = await getPipelineHistory(id);
                    const gitHistoryEle: (IActivityItemProps & { key: number })[] = hist.data.map((h, i) => ({
                        key: i,
                        activityDescription: [
                            <span key={1}>
                                <strong>{h.authorName}</strong> committed{" "}
                            </span>,
                            <Link key={2} href={h.url} target="_blank">
                                {h.commitId}
                            </Link>
                        ],
                        activityPersonas: [{ imageUrl: h.authorImage }],
                        timeStamp: new Date(h.authorDate).toLocaleString("en-US", {
                            timeZoneName: "short"
                        }),
                        comments: h.comment
                    }));

                    setGitHistory(gitHistoryEle);
                } catch (error) {
                    setGitHistory([]);
                    setGitHistoryLoading.setFalse();
                }
            }

            setGitHistoryLoading.setFalse();
        })();
    }, [setGitHistory, getPipelineHistory]);

    useEffect(() => {
        // clean up on unmount
        return () => {
            // refetch to update state with new values
            if (!isDismissed) {
                dispatch(getAllFeeds());
                dispatch(getAllPipelines());
                // timeout to allow the form to close before resetting the state
                setTimeout(() => {
                    dispatch(reset(StateActionType.Update));
                }, 500);
            }
        };
    }, [isDismissed]);

    useEffect(() => {
        if (currentPipeline) {
            if (rsActivityState[StateActionType.LoadAll] === "INIT") {
                dispatch(getAllActivites({ dfId: currentPipeline.dataFactoryId, pipelineId: currentPipeline.id }));
            } else if (
                rsActivityState[StateActionType.LoadAll] === "LOADED_ALL" &&
                rsActivities &&
                rsActivities.length > 0
            ) {
                const lastPipelinePull = rsActivities[0];

                if (lastPipelinePull.pipelineId !== currentPipeline.id) {
                    dispatch(getAllActivites({ dfId: currentPipeline.dataFactoryId, pipelineId: currentPipeline.id }));
                }
            }
        }
    }, [currentPipeline]);

    // exit when the update is complete
    useEffect(() => {
        if (rsPipelineState[StateActionType.Update] === "UPDATED") {
            setIsDismissed(false);

            setAnnouncement((p) => ({
                ...p,
                msg: `Pipeline ${FormState.name} updated successfully`
            }));
        }

        if (rsPipelineState[StateActionType.Update] === "UPDATING") {
            setAnnouncement({
                show: true,
                msg: `Updating`,
                onClose: routerHistory.goBack
            });
        }
    }, [rsPipelineState[StateActionType.Update]]);

    useEffect(() => {
        if (rsActivityState[StateActionType.LoadAll] === "LOADED_ALL" && rsActivities && rsActivities.length > 0) {
            setPipelineActivites(rsActivities);
        }
    }, [rsActivityState]);

    useEffect(() => {
        if (rsActivityState[StateActionType.Update] === "UPDATED" && associateFeeddialogProps.showDialog) {
            _associatePopUpClose();
            if (rsActivities && rsActivities.length > 0) {
                setPipelineActivites(rsActivities); //so the activities will be updated if the form is immediately reopened
            }
            dispatch(getAllFeeds()); //so the pipelines list filtered feeds list will refresh
            if (currentPipeline) {
                dispatch(getPipelineById(currentPipeline.id));
            }

            if (!annoucement.show) {
                setAnnouncement({
                    show: true,
                    msg: `Feeds for pipeline ${FormState.name} updated successfully`,
                    onClose: () => setAnnouncement({ ...annoucement, show: false })
                });
            }
        }
    }, [rsActivityState[StateActionType.Update]]);

    useEffect(() => {
        setAssociateShowDialog({ ...associateFeeddialogProps, activities: pipelineActivities });
    }, [pipelineActivities]);

    useEffect(() => {
        if (currentPipeline && currentPipeline.id === id && feeds && feeds.length > 0) {
            let updatedFormState = { ...FormState, ...currentPipeline };

            const pipelinefeedIds = currentPipeline.feedIds;
            if (pipelinefeedIds && pipelinefeedIds.length > 0) {
                const filteredFeeds = feeds.filter(function (feed) {
                    return pipelinefeedIds.indexOf(feed.id) > -1;
                });
                setFilteredFeeds(filteredFeeds);
                setAssociateShowDialog({ ...associateFeeddialogProps, feeds: feeds, currentFeeds: filteredFeeds });
            } else {
                setFilteredFeeds([]);
            }

            setSuppressionState({
                ...suppressionState,
                startTime: new Date(currentPipeline.suppressionStartTime || new Date()),
                endTime: new Date(currentPipeline.suppressionEndTime || new Date()),
                justification: currentPipeline.suppressionJustification || "",
                suppressedBy: currentPipeline.suppressedBy
            });

            updatedFormState = {
                ...updatedFormState,
                sliceRelevanceDropdownValues: [
                    ...currentPipeline.sliceRelevanceMapping.map((item) => ({
                        key: item.id,
                        text: item.name,
                        title: item.description
                    }))
                ]
            };

            setFormState(updatedFormState);
        } else if (id && (!currentPipeline || currentPipeline.id !== id)) {
            dispatch(getPipelineById(id));
        }
    }, [currentPipeline, feedState[StateActionType.LoadAll]]);

    function processFormSubmission(e: React.MouseEvent<HTMLButtonElement>): void {
        const {
            name,
            id,
            dataFactoryName,
            platformName,
            suppressed,
            maxSuppressionTime,
            feedIds,
            isIdempotent,
            sliceRelevanceType,
            sliceRelevanceMapping,
            sliceRelevanceId,
            tsgLink,
            dataFactoryId,
            isProducer,
            subscriptionId
        } = { ...FormState };

        const currentPipeline: IPipeline = {
            id: id,
            name: name,
            dataFactoryName: dataFactoryName,
            platformName: platformName,
            suppressed: suppressed,
            suppressionEndTime: suppressionState.endTime,
            suppressionStartTime: suppressionState.startTime,
            suppressionJustification: suppressionState.justification,
            maxSuppressionTime: maxSuppressionTime,
            feedIds: feedIds,
            isIdempotent: isIdempotent,
            lastUpdated: new Date(),
            sliceRelevanceType: sliceRelevanceType,
            sliceRelevanceMapping: sliceRelevanceMapping,
            sliceRelevanceId: sliceRelevanceId,
            tsgLink: tsgLink,
            dataFactoryId: dataFactoryId,
            isProducer,
            subscriptionId
        };

        dispatch(updatePipeline(currentPipeline));
    }
    function _popUpShow() {
        if (dialogState)
            setShowDialog({
                ...dialogState,
                state: suppressionState,
                maxSuppressionTime: new Date(FormState!.maxSuppressionTime),
                showDialog: true
            });
    }

    const initialAssociateFeeddialogProps: AssociateFeedsDialogProps = {
        showDialog: false,
        pipelineId: FormState.id,
        feeds: feeds || [],
        onClose: () => _associatePopUpClose(),
        activities: pipelineActivities,
        activitiesError: activityProperties.error,
        currentFeeds: filteredFeeds
    };

    const [associateFeeddialogProps, setAssociateShowDialog] = useState<AssociateFeedsDialogProps>(
        initialAssociateFeeddialogProps
    );

    function _associatePopUpShow() {
        setAssociateShowDialog({
            ...associateFeeddialogProps,
            pipelineId: FormState.id,
            feeds: feeds || [],
            showDialog: true,
            activities: pipelineActivities,
            activitiesError: activityProperties.error,
            currentFeeds: filteredFeeds
        });
    }

    function _associatePopUpClose() {
        setAssociateShowDialog({
            ...associateFeeddialogProps,
            showDialog: false
        });
    }

    function _on_ChangeSuppress(_ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) {
        const checked = isChecked === null || isChecked === undefined ? false : isChecked;

        if (!checked) {
            _popUpShow();
        } else {
            const updatedFormState: IFormState = {
                ...FormState,
                suppressed: false
            };
            setFormState(updatedFormState);
            setSuppressionState((p) => ({ ...p, ...getDefaultSuppressionState() }));
        }
    }

    function _onChangeSliceRelevanceType(
        _event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined,
        _index?: number | undefined
    ) {
        if (!option) {
            return;
        }
        const dropdownKey: any = option.key;
        const dropdownValue: any = option.text;
        const updatedFormState = {
            ...FormState,
            sliceRelevanceId: dropdownKey,
            sliceRelevanceType: dropdownValue
        };
        setFormState(updatedFormState);
    }

    function _onChangeEditToggle(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        const updatedFormState = {
            ...FormState,
            editDisabled: !checked
        };
        setFormState(updatedFormState);
    }

    return (
        <>
            <StandardForm widthPercent={90} mediumWidthPercent={90} smallWidthPercent={90}>
                <SaveModal
                    hidden={!annoucement.show}
                    onClose={annoucement.onClose}
                    successMsg={annoucement.msg}
                    error={pipelineProperties.error}
                    loading={rsPipelineState[StateActionType.Update] === "UPDATING"}
                />
                <Stack.Item align="end" styles={{ root: { marginBottom: "-50px" } }}>
                    <IconButton
                        title="Close"
                        className="routing"
                        onClick={() => props.history.replace("/pipelines")}
                        iconProps={{ iconName: "Cancel" }}
                        allowDisabledFocus
                    />
                </Stack.Item>
                <StackRow horizontalAlign="space-between" columnWidth={100}>
                    <Toggle
                        label={<Label className="label">Edit</Label>}
                        className="editpipeline"
                        defaultChecked={false}
                        onText="On"
                        offText="Off"
                        onChange={_onChangeEditToggle}
                    />
                </StackRow>
                <Separator />
                {pipelineProperties.error && <Errorbar msg={pipelineProperties.error} />}
                <StackRow>
                    <ShimmeredText
                        className="name"
                        isStackItem
                        loading={rsPipelineState[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        txtLabel={"Name"}
                    >
                        {FormState.name}
                    </ShimmeredText>
                    <ShimmeredText
                        className="dataFactory"
                        isStackItem
                        loading={rsPipelineState[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        txtLabel={"Data Factory"}
                    >
                        {FormState.dataFactoryName}
                    </ShimmeredText>
                    <ShimmeredText
                        className="platformName"
                        isStackItem
                        loading={rsPipelineState[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        txtLabel={"Platform Name"}
                    >
                        {FormState.platformName}
                    </ShimmeredText>
                </StackRow>
                <StackRow>
                    <Stack horizontal horizontalAlign="start">
                        <ShimmeredToggle
                            isStackItem
                            loading={rsPipelineState[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                            toggleLabel="Notifications"
                            className="suppressed"
                            id="suppressed"
                            onText="Enabled"
                            offText={`Disabled until ${
                                suppressionState?.endTime
                                    ? suppressionState.endTime.toLocaleString("en-US", {
                                          timeZoneName: "short"
                                      })
                                    : "N/A"
                            }`}
                            checked={!FormState.suppressed}
                            onChange={_on_ChangeSuppress}
                            disabled={FormState.editDisabled}
                        />
                        {FormState.suppressed && !FormState.editDisabled && (
                            <FontIcon
                                style={{ marginTop: "35px", cursor: "pointer" }}
                                onClick={_popUpShow}
                                iconName="edit"
                            />
                        )}
                    </Stack>
                    <ShimmeredToggle
                        isStackItem
                        loading={rsPipelineState[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        toggleLabel="Idempotence"
                        checked={FormState.isIdempotent}
                        onText="True"
                        offText="False"
                        disabled={FormState.editDisabled}
                        onChange={() => setFormState({ ...FormState, isIdempotent: !FormState.isIdempotent })}
                    />
                    <ShimmeredDropdown
                        isStackItem
                        loading={rsPipelineState[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                        ddLabel="Slice Relevance Type"
                        styles={dropdownControlStyles}
                        selectedKey={FormState.sliceRelevanceId}
                        options={FormState.sliceRelevanceDropdownValues}
                        onChange={_onChangeSliceRelevanceType}
                        disabled={FormState.editDisabled}
                    />
                </StackRow>
                <StackRow>
                    <ShimmeredLink
                        label="TSG Link"
                        loading={rsPipelineState[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                        onChange={(value) => setFormState({ ...FormState, tsgLink: value })}
                        link={FormState.tsgLink || ""}
                        disabled={FormState.editDisabled}
                    />

                    <Stack>
                        <Label className="label"> View In Datafactory </Label>
                        <Shimmer
                            isDataLoaded={rsPipelineState[StateActionType.LoadSingle] === "LOADED_SINGLE"}
                            ariaLabel="Loading View In Datafactory"
                            width="30%"
                            styles={getShimmerStyles()}
                        >
                            <IconButton
                                styles={{ root: { marginLeft: "50px" } }}
                                title="View in Datafactory"
                                className="routing"
                                href={FormState.datafactoryAuthorLink}
                                iconProps={{
                                    iconName:
                                        FormState.datafactoryAuthorLink &&
                                        FormState.datafactoryAuthorLink.startsWith("https://ms-adf.azure.com")
                                            ? "ADF"
                                            : "Synapse"
                                }}
                                allowDisabledFocus
                                target="_blank"
                            />
                        </Shimmer>
                    </Stack>

                    <TooltipHost
                        content="If true, this means the pipeline produces feeds/datasets, if false it's a maintence pipeline."
                        id="isProducerTooltip"
                    >
                        <ShimmeredToggle
                            loading={rsPipelineState[StateActionType.LoadSingle] == "LOADING_SINGLE"}
                            toggleLabel="Producer"
                            checked={FormState.isProducer === true}
                            onText="True"
                            offText="False"
                            isStackItem
                            disabled={FormState.editDisabled}
                            aria-describedby="isProducerTooltip"
                            onChange={() => setFormState({ ...FormState, isProducer: !FormState.isProducer })}
                        />
                    </TooltipHost>
                </StackRow>
                <StackRow columnWidth={32.5} horizontalAlign="end">
                    <AdoHistory gitHistory={gitHistory} loading={gitHistoryLoading} />
                </StackRow>
                <Separator />
                <StackRow columnWidth={100}>
                    <PrimaryButton
                        text="Save"
                        onClick={processFormSubmission}
                        disabled={FormState.editDisabled}
                    ></PrimaryButton>
                </StackRow>
            </StandardForm>

            {feedState[StateActionType.LoadAll] != "LOADED_ALL" && <ProgressIndicator />}

            <StandardList
                entities={filteredFeeds}
                title="Feeds"
                columns={generateFeedColumns(routerHistory)}
                entityState={feedState[StateActionType.LoadAll]}
                extraHeaderItems={
                    <Stack.Item className="p-1" align="end">
                        <PrimaryButton
                            title="Associate Feeds"
                            onClick={_associatePopUpShow}
                            iconProps={{ iconName: "AddLink" }}
                            disabled={FormState.editDisabled}
                        />
                    </Stack.Item>
                }
            />

            <div style={{ height: "50px" }}></div>

            {associateFeeddialogProps.showDialog && <AssociateFeedsDialog {...associateFeeddialogProps} />}

            {dialogState.showDialog && <SuppressionDialog {...dialogState} />}
        </>
    );
}

export default EditPipeline;

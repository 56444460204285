import { useEffect, useState } from "react";
import { subscriptionSelector } from "../pages/Selectors";
import { getAllSubscriptions } from "../reducers/Subscription";
import { useAppDispatch, useAppSelector } from "./ReduxHooks";

const useSubscriptionData = () => {
    const { entities: subscriptionEntity, error: subscriptionError } = useAppSelector(subscriptionSelector);
    const [subscriptionId, setSubscriptionId] = useState<string>();

    const [subscriptionEntries, setSubscriptionEntries] = useState<{ id: string; displayName: string }[]>([]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!subscriptionEntity && !subscriptionError) {
            dispatch(getAllSubscriptions());
        } else if (subscriptionEntity) {
            setSubscriptionEntries(subscriptionEntity.map((sub) => ({ id: sub.id, displayName: sub.name })) || []);
        }
    }, [subscriptionEntity, subscriptionError]);

    return { subscriptionId, setSubscriptionId, subscriptionEntries, subscriptionError };
};

export default useSubscriptionData;

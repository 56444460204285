import React, { Dispatch, SetStateAction } from "react";
import { DonutChart, IChartDataPoint, IChartProps } from "@fluentui/react-charting";
import DashboardItem from "./DashboardItem";
import { IFeedFreshness } from "../../../models/FeedFreshness";
import { ISliderProps } from "@fluentui/react";
import { SharedColors } from "@uifabric/fluent-theme";
import { ITolerance } from "../../../pages/FeedFreshnessList";
import { ToolTippedSlider } from "../../common/ToolTippedSlider";
interface IExpectedTimeDonutProps {
    feedFreshnesses: IFeedFreshness[];
    href?: string;
    tolerance: ITolerance;
    setTolerance: Dispatch<SetStateAction<ITolerance>>;
}

function ExpectedTimeDonut(props: IExpectedTimeDonutProps) {
    const feedFreshnesses = props.feedFreshnesses;
    let numOverExpected = 0;
    let numUnknown = 0;
    for (let i = 0; i < feedFreshnesses.length; i++) {
        const element = feedFreshnesses[i];
        const { lastRefreshFrequency, expectedRefreshInterval, expectedRefreshIntervalUnit } = element;

        if (
            expectedRefreshInterval === null ||
            expectedRefreshInterval === undefined ||
            expectedRefreshInterval === -1
        ) {
            numUnknown += 1;
            continue;
        }
        let hours = -1;
        switch (expectedRefreshIntervalUnit) {
            case "Month":
                hours = expectedRefreshInterval * 30 * 24;
                break;
            case "Week":
                hours = expectedRefreshInterval * 7 * 24;
                break;
            case "Day":
                hours = expectedRefreshInterval * 24;
                break;
            case "Hour":
                hours = expectedRefreshInterval;
                break;
            case "Minute":
                hours = expectedRefreshInterval / 60;
                break;
            default:
                numUnknown += 1;
                continue;
        }
        numOverExpected =
            hours * (1 + props.tolerance.eri_tolerance) < lastRefreshFrequency ? numOverExpected + 1 : numOverExpected;
    }

    const eriData = feedFreshnesses.length - numOverExpected - numUnknown;
    const eriLegend = "Meeting Expected Refresh Interval";

    const eriLegendNot = "Not Meeting Expected Refresh Interval";

    const points: IChartDataPoint[] = [
        {
            legend: eriLegend,
            data: eriData,
            color: SharedColors.green20
        },
        { legend: eriLegendNot, data: numOverExpected, color: SharedColors.red20 }
        //{legend: "Expected Refresh Interval Unknown", data: numUnknown, color: SharedColors.gray20},
    ];

    const data: IChartProps = { chartTitle: "Feeds by Refresh Interval", chartData: points };

    const chart = <DonutChart data={data} innerRadius={50} href={props.href ? props.href : ""} />;

    const sliderToolTipsProps = {
        id: "riSliderLabel",
        content:
            "Feeds will be marked as meeting their Expected Refresh Interval if their Most Recent Refresh Interval is less than this percentage over their Expected Refresh Interval. This value is also applied to the filters in the table below."
    };
    const sliderProps: ISliderProps = {
        min: 0,
        max: 0.25,
        step: 0.0025,
        ariaLabel: "Expected Refresh Interval Tolerance",
        ariaValueText: (num) => `${(num * 100).toFixed(2)}%`,
        value: props.tolerance.eri_tolerance,
        onChanged: (event, value: number) => props.setTolerance({ ...props.tolerance, eri_tolerance: value }),
        valueFormat: (value: number) => `${(value * 100).toFixed(2)}%`
    };

    const child = (
        <div>
            {chart}
            <ToolTippedSlider label="Tolerance" toolTipsProps={sliderToolTipsProps} sliderProps={sliderProps} />
        </div>
    );

    return (
        <DashboardItem
            label="Feeds by Refresh Interval"
            tooltip="This chart shows the proportion of feeds whose Most Recent Refresh Interval was less than or equal to their Expected Refresh Interval (ERI)."
            child={child}
        />
    );
}

export default ExpectedTimeDonut;

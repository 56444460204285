import { Dropdown, IDropdownProps, Label, Shimmer } from "@fluentui/react";
import { getShimmerStyles } from "../../../columns/utils";
type ShimmeredProps = {
    loading: boolean;
    ddLabel: string;
    width?: string;
    isStackItem?: boolean;
} & IDropdownProps;

function ShimmeredDropdown(props: ShimmeredProps) {
    return (
        <div className={props.isStackItem ? "" : "col"}>
            <Label className="label"> {props.ddLabel} </Label>
            <Shimmer isDataLoaded={!props.loading} ariaLabel={`Loading ${props.ddLabel}`} styles={getShimmerStyles()}>
                <Dropdown ariaLabel={props.ddLabel} {...props} />
            </Shimmer>
        </div>
    );
}

export { ShimmeredDropdown };

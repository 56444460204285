import CustomerHomePage from "./pages/HomePageContainer";
import PipelineList from "./pages/PipelineList";
import FeedList from "./pages/FeedList";
import DataFactoryList from "./pages/DataFactoryList";
import FeedCategoryList from "./pages/FeedCategoryList";
import FeedFreshnessList from "./pages/FeedFreshnessList";
import FeedFreshnessTrendsContainer from "./components/FeedFreshness/FeedFreshnessTrendsContainer";
import EditPipeline from "./components/Pipeline/EditPipeline";
import FeedEdit from "./components/Feed/EditFeed";
import EditDataFactory from "./components/DataFactory/EditDataFactory";
import EditFeedCategory from "./components/FeedCategory/EditFeedCategory";
import DirectoryAuditLIst from "./pages/DirectoryAuditList";
import { SpinnerBasicExample } from "./pages/Test";
import RbacRoute from "./components/common/RbacRoute";
import { AllUsers, AdminUser } from "./constants";
import { EditDirectoryAudit } from "./components/DirectoryAudit/EditDirectoryAudit";
import DataSourceList from "./pages/DataSourceList";
import { EditDataSource } from "./components/DataSource/EditDataSource";
import AzureResourceList from "./pages/AzureResourceList";
import EditAzureResource from "./components/AzureResources/EditAzureResource";
import SizingDataList from "./pages/SizingDataList";
import RemoteServerAdministrationHistory from "./pages/RemoteServerAdministrationHistory";
import PowerBIReports from "./pages/PowerBIReports";
import AddPowerBIReports from "./components/PowerBIReports/AddPowerBIReports";
import RemoteServerActionList from "./pages/RemoteServerActionList";
import AdlsDatasetForm from "./components/Dataset/AdlsDatasetForm";
import AddSDWsDataset from "./components/Dataset/SDWDatasetForm";
import KustoDatasetForm from "./components/Dataset/KustoDatasetForm";

import DatasetList from "./pages/DatasetList";
import DatasetForm from "./components/Dataset/DatasetForm";
import { DataValidationView } from "./components/DataValidation/DataValidationView";
import { EditDatasetValidation } from "./components/DataValidation/EditDatasetValidation";
import { QMValidationView } from "./components/QualityMetricsValidation/QMValidationView";
import { EditQMValidation } from "./components/QualityMetricsValidation/EditQMValidation";
import KeyVaultSecretList from "./pages/KeyVaultSecretList";
import InternalObjectTagging from "./pages/InternalObjectTagging";

import { AlertForm } from "./components/DatasetAlert/AlertForm";
import AlertsList  from "./components/DatasetAlert/AlertsList";



const StandardRoutes = () => {
    return (
        <>
            <RbacRoute role={AllUsers} exact path="/" component={CustomerHomePage} />
            <RbacRoute role={AdminUser} exact path="/datafactories" component={DataFactoryList} />
            <RbacRoute role={AdminUser} exact path="/datafactories/:id" component={EditDataFactory} />
            <RbacRoute role={AllUsers} exact path="/pipelines" component={PipelineList} />
            <RbacRoute role={AllUsers} exact path="/test" component={SpinnerBasicExample} />
            <RbacRoute role={AdminUser} path={"/pipelines/:id"} exact component={EditPipeline} />
            <RbacRoute role={AdminUser} path={"/feeds/:id"} exact component={FeedEdit} />
            <RbacRoute role={AllUsers} exact path="/feeds" component={FeedList} />
            <RbacRoute role={AllUsers} exact path="/feedcategories" component={FeedCategoryList} />
            <RbacRoute role={AdminUser} path={"/feedcategories/:id"} exact component={EditFeedCategory} />
            <RbacRoute role={AdminUser} exact path="/datasources" component={DataSourceList} />
            <RbacRoute role={AdminUser} exact path="/datasources/:id" component={EditDataSource} />
            <RbacRoute role={AllUsers} path={"/AdlsDataset/:action/:dsID?"} exact component={AdlsDatasetForm} />
            <RbacRoute role={AllUsers} path={"/SDWDataset/:action/:dsID?"} exact component={AddSDWsDataset} />
            <RbacRoute role={AllUsers} path={"/KustoDataset/:action/:dsID?"} exact component={KustoDatasetForm} />
            <RbacRoute role={AllUsers} path={"/Datasets"} exact component={DatasetList} />
            <RbacRoute role={AllUsers} path={"/Datasets/:action/:dsID?"} exact component={DatasetForm} />
            <RbacRoute role={AllUsers} path={"/DatasetAlerts"} exact component={AlertsList} />
            <RbacRoute role={AllUsers} path={"/Alert/:action/:dsID?"} exact component={AlertForm} />

            <RbacRoute
                role={AllUsers}
                exact
                path="/feedfreshness"
                render={(props) => <FeedFreshnessList {...props} type={0} />}
            />
            <RbacRoute
                role={AllUsers}
                path={"/trends/:feedId"}
                exact
                render={(props) => <FeedFreshnessTrendsContainer {...props} type={0} />}
            />
            <RbacRoute role={AdminUser} path={"/directoryAudit"} exact component={DirectoryAuditLIst} />
            <RbacRoute role={AdminUser} path={"/directoryAudit/:id"} exact component={EditDirectoryAudit} />
            <RbacRoute role={AdminUser} path={"/azureResources"} exact component={AzureResourceList} />
            <RbacRoute role={AdminUser} path={"/azureResources/:id"} exact component={EditAzureResource} />
            <RbacRoute role={AllUsers} path={"/sizingMetrics"} exact component={SizingDataList} />
            <RbacRoute role={AllUsers} path={"/powerBIReports"} exact component={PowerBIReports} />
            <RbacRoute role={AllUsers} path={"/powerBIReports/:id"} exact component={AddPowerBIReports} />
            <RbacRoute role={AllUsers} path={"/remoteserveradmin"} exact component={RemoteServerActionList} />
            <RbacRoute
                role={AllUsers}
                path={"/administrationhistory/:id"}
                exact
                component={RemoteServerAdministrationHistory}
            />
            <RbacRoute role={AdminUser} path={"/dataCatalogValidations"} exact component={DataValidationView} />
            <RbacRoute role={AdminUser} path={"/dataCatalogValidations/:id"} exact component={EditDatasetValidation} />

            <RbacRoute role={AdminUser} path={"/qualityMetricsValidations"} exact component={QMValidationView} />
            <RbacRoute role={AdminUser} path={"/qualityMetricsValidations/:id"} exact component={EditQMValidation} />
            <RbacRoute role={AdminUser} path={"/keyVaultSecrets"} exact component={KeyVaultSecretList} />
            <RbacRoute role={AdminUser} path={"/tagInternalObjects"} exact component={InternalObjectTagging} />
        </>
    );
};

export default StandardRoutes;

import {
    FontWeights,
    Icon,
    Label,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack
} from "@fluentui/react";
import { FunctionComponent, useState } from "react";
import { SdwSchema, SDWSProcParamResponse, SProcParam, SProcSchemaRequest } from "../../models/Dataset";
import { DatasetComponents, getSProcSchema } from "../../reducers/Dataset";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { dataSetSelector } from "../../pages/Selectors";

interface IPanelProps {
    sProcParamsResponse: SDWSProcParamResponse | undefined;
    sProcName: string;
    sendSProcSchema: (val: SdwSchema[]) => void;
}

export const SProcInputPanel: FunctionComponent<IPanelProps> = (props: IPanelProps) => {
    const [isSchemaFetching, setIsSchemaFetching] = useState<boolean>(false);
    const [isSchemaRetrieved, setIsSchemaRetrieved] = useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const { sProcSchemaResponse, dsLoading } = useAppSelector(dataSetSelector);
    const { sProcParamsResponse, sProcName } = props;

    const dispatch = useAppDispatch();
    let initialValue: SProcParam[] = [];

    if (sProcParamsResponse?.errorMessage) {
        return (
            <>
                <br />
                <MessageBar messageBarType={MessageBarType.error}>{sProcParamsResponse?.errorMessage}</MessageBar>
            </>
        );
    }

    let sProcParams = sProcParamsResponse?.sdwsProcParams;

    if (sProcParams && sProcParams.length == 0) {
        return <>Parameters not found!</>;
    }
    const [inputFields, setInputFields] = useState(initialValue);

    React.useEffect(() => {
        if (!dsLoading[DatasetComponents.sProcSchemaResponse] && sProcSchemaResponse != null) {
            if (sProcSchemaResponse.errorMessage) {
                setIsSchemaFetching(false);
                setIsSchemaRetrieved(false);
                setShowErrorMessage(true);
            } else {
                setIsSchemaFetching(false);
                setIsSchemaRetrieved(true);
                setTimeout(() => {
                    props.sendSProcSchema(sProcSchemaResponse.sdwSchema);
                }, 3000);
            }
        }
    }, [sProcSchemaResponse, dsLoading[DatasetComponents.sProcSchemaResponse]]);

    if (sProcParams != undefined) {
        sProcParams.map((p) => {
            initialValue.push({ name: p.name, value: "", type: p.type });
        });
        if (inputFields.length == 0) setInputFields(initialValue);
    }

    const handleFormChange = (index, e) => {
        let data = [...inputFields];
        data[index][e.target.name] = e.target.value;
        setInputFields(data);
    };

    function GetRowField(param: SProcParam, index: number) {
        let lblText = param.name + " (" + param.type.replace("Ansi", "") + ")";
        return (
            <>
                <Stack.Item key={"stkItm1" + index} style={{ width: "300px", paddingLeft: 10 }}>
                    <Label>{lblText}: </Label>
                </Stack.Item>
                <Stack.Item key={"stkItm2" + index}>
                    <input
                        key={param.name}
                        name={param.name}
                        onChange={(event) => handleFormChange(index, event)}
                        style={{ height: 27 }}
                    />
                </Stack.Item>
                <br />
            </>
        );
    }

    async function getSchemaDetailsCall() {
        setShowErrorMessage(false);
        if (inputFields != null && inputFields.length != 0) {
            let paramList: SProcParam[] = [];
            inputFields.map((p, index) => {
                paramList.push({ name: p.name, value: p[p.name], type: p.type });
            });
            let schemaRequest: SProcSchemaRequest = { sProcName: sProcName, paramList: paramList };

            // Executing SProc with required Params and get Schema Details
            setIsSchemaRetrieved(false);
            setIsSchemaFetching(true);
            dispatch(await getSProcSchema(schemaRequest));
        }
    }
    return (
        <div>
            <Stack tokens={{ childrenGap: 10 }} style={{ marginBottom: "10px" }}>
                <Stack.Item>
                    <MessageBar messageBarType={MessageBarType.warning}>
                        <b>Attension:</b> We need to excute the SProc in order to get Schema details.{" "}
                    </MessageBar>
                </Stack.Item>
                <Stack.Item style={{ height: "20px", display: "block", paddingLeft: 10, paddingBottom: 8 }}>
                    Name: <b>{sProcName}</b>
                </Stack.Item>
            </Stack>
            {sProcParams &&
                sProcParams.map((p: SProcParam, index) => {
                    return (
                        <Stack
                            key={"Stack" + index}
                            tokens={{ childrenGap: 10 }}
                            horizontal
                            style={{ marginBottom: "10px" }}
                        >
                            {GetRowField(p, index)}
                        </Stack>
                    );
                })}
            {sProcParams && (
                <Stack>
                    <Stack.Item key={"btnSection"} style={{ paddingLeft: 10, marginTop: 10 }}>
                        <div style={{ float: "left", width: "20%" }}>
                            <PrimaryButton
                                text="Execute"
                                type="button"
                                onClick={() => {
                                    getSchemaDetailsCall();
                                }}
                                disabled={isSchemaFetching}
                            ></PrimaryButton>
                        </div>
                        <div style={{ float: "left", paddingLeft: 15 }}>
                            {isSchemaFetching && (
                                <span style={{ paddingTop: 8, display: "block" }}>
                                    <Spinner
                                        size={SpinnerSize.small}
                                        styles={{ label: { color: "black", fontWeight: FontWeights.semibold } }}
                                        label="Please wait, collecting schema deails by executing stored procedures"
                                        labelPosition="right"
                                    />
                                </span>
                            )}
                            {isSchemaRetrieved && (
                                <span style={{ paddingBottom: 2, display: "block", fontSize: "12px" }}>
                                    {" "}
                                    <Icon iconName="CompletedSolid" styles={{ root: { color: "green" } }} /> Schema
                                    details have been retrieved successfully.
                                </span>
                            )}
                            {isSchemaRetrieved && (
                                <Spinner
                                    size={SpinnerSize.small}
                                    styles={{
                                        label: {
                                            color: "black",
                                            fontWeight: FontWeights.semibold,
                                            justifyContent: "left !important"
                                        }
                                    }}
                                    label="Saving your changes."
                                    labelPosition="right"
                                />
                            )}
                            {showErrorMessage && (
                                <span style={{ color: "red", fontSize: "12px", paddingTop: 6 }}>
                                    Failed: {sProcSchemaResponse?.errorMessage}
                                </span>
                            )}
                        </div>
                    </Stack.Item>
                </Stack>
            )}
        </div>
    );
};

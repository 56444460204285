import React, { useEffect, useRef } from "react";
import "../styles/App.css";
import { useHistory } from "react-router-dom";
import { pipelineSelector } from "./Selectors";
import StandardList from "../components/common/StandardList";
import { generatePipelineColumns } from "../columns";
import { StateActionType } from "../types";
import { getAllPipelines } from "../reducers/Pipelines";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { PrimaryButton, Stack } from "@fluentui/react";
import AddPipeline from "../components/Pipeline/AddPipeline";
import PreferredSelect from "../components/common/PreferredSelect";
import useSubscriptionData from "../hooks/useSubscriptionData";

function PipelineTable() {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const { state: pipelineState, entities: pipelines, error: pipelineError } = useAppSelector(pipelineSelector);

    const [showAddPipeline, setShowAddPipeline] = React.useState(false);
    const addButtonRef = useRef<HTMLDivElement>(null);

    const { subscriptionId, setSubscriptionId, subscriptionEntries, subscriptionError } = useSubscriptionData();

    useEffect(() => {
        document.title = "List of Pipelines - SIP Portal";
        if (pipelineState[StateActionType.LoadAll] !== "LOADED_ALL") {
            dispatch(getAllPipelines());
        }
    }, []);

    return (
        <>
            <StandardList
                entities={
                    pipelines &&
                    pipelines
                        .filter((p) => subscriptionId === undefined || p.subscriptionId === subscriptionId)
                        .map((e) => ({
                            ...e,
                            notifications: e.suppressed ? "Disabled" : "Enabled",
                            isIdempotent: e.isIdempotent ? "True" : "False",
                            feedCount: e.feedIds && e.feedIds.length > 0 ? e.feedIds.length : 0
                        }))
                }
                title={"Pipelines"}
                columns={generatePipelineColumns(history)}
                error={pipelineError || subscriptionError}
                entityState={pipelineState[StateActionType.LoadAll]}
                extraHeaderItems={
                    <>
                        <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                            <PreferredSelect
                                title="Subscription"
                                entries={subscriptionEntries}
                                selectedEntryId={subscriptionId}
                                setEntryId={setSubscriptionId}
                            />
                        </Stack>
                        <div
                            ref={addButtonRef}
                            onFocus={() => {
                                addButtonRef.current &&
                                    addButtonRef.current.scrollIntoView({
                                        behavior: "smooth",
                                        block: "nearest",
                                        inline: "start"
                                    });
                            }}
                        >
                            <Stack.Item grow align="center">
                                <PrimaryButton
                                    text="Add Pipeline"
                                    onClick={() => setShowAddPipeline(true)}
                                    allowDisabledFocus
                                />
                            </Stack.Item>
                        </div>
                    </>
                }
            />

            {showAddPipeline && (
                <AddPipeline {...{ showDialog: showAddPipeline, onClose: () => setShowAddPipeline(false) }} />
            )}
        </>
    );
}
export default PipelineTable;

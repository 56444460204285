import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ValidationResult } from "../../models/ValidationResult";
import { getAccessToken } from "../../service/authProvider";

const baseUrl = "/api/validation";

export const validationApi = createApi({
    reducerPath: "validationApi",
    tagTypes: ["AdlsValidationResult"],
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers) => {
            const token = await getAccessToken();
            if (token) {
                headers.set("authorization", `Bearer ${token.accessToken}`);
            }
            return;
        }
    }),
    endpoints: (builder) => ({
        getValidationResults: builder.query<ValidationResult[], void>({
            query: () => "/",
            providesTags: () => [{ type: "AdlsValidationResult", id: "LIST" }]
        }),
        getValidationResult: builder.query<ValidationResult, number>({
            query: (id) => `/${id}`,
            providesTags: (result) => [{ type: "AdlsValidationResult", id: result?.id }]
        }),
        dismiss: builder.mutation<ValidationResult, { id: number; comment: string }>({
            query: (props) => ({
                url: `dismiss/${props.id}?comment=${props.comment}`,
                method: "PUT"
            }),
            invalidatesTags: (result) => [
                { type: "AdlsValidationResult", id: result?.id },
                { type: "AdlsValidationResult", id: "LIST" }
            ]
        })
    })
});

// Export the generated hooks for easy usage
export const { useGetValidationResultQuery, useGetValidationResultsQuery, useDismissMutation } = validationApi;

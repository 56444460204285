import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import { getFeedDetail, getFeeds, editFeed, editFeeds } from "../api/FeedApi";
import { getDefaultCases } from "./DefaultCases";
import { IFeed, FeedDetail } from "../models";

const sliceName = "feeds";

const initialState = getInitialState<FeedDetail, IFeed>();

const getAllFeeds = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getFeeds();
    return response.data;
});

const getFeedById = createAsyncThunk(`${sliceName}/get_by_id`, async (id: number, _thunkAPI) => {
    const response = await getFeedDetail(id);
    return response.data;
});
const updateFeed = createAsyncThunk(`${sliceName}/edit`, async (feed: FeedDetail, _thunkAPI) => {
    const response = await editFeed(feed);
    return response.data;
});
const updateFeeds = createAsyncThunk(`${sliceName}/edits`, async (feeds: FeedDetail[], _thunkAPI) => {
    const response = await editFeeds(feeds);
    return response.data;
});

const feedsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IFeed>(),
    extraReducers: (builder) => {
        builder.addCase(getAllFeeds.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllFeeds.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getFeedById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(getFeedById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadSingle);
        });
        builder.addCase(updateFeed.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(updateFeed.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Update);
        });
        builder.addCase(updateFeeds.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(updateFeeds.fulfilled, (state, _action) => {
            onComplete(state, StateActionType.Update);
        });
    }
});

export const { reset, error, setCurrent } = feedsSlice.actions;
export { getAllFeeds, getFeedById, updateFeed, updateFeeds, sliceName };
export const { reducer } = feedsSlice;

//Dataset

import { Data } from "popper.js";

export interface IDataset {
    id: number;
    name: string;
    description: string;
    displayInCatalog: boolean;
    accessPackageReferenceId: number;
    accessPackageName: string;
    subscriptionId?: string;
    adlsDataset?: IAdlsDataset;
    kustoDataset?: IKustoDataset;
    sdwDataset?: ISDWDataset;
    datasetTags: IDatasetTag[];
    datasetAlerts: IDatasetAlert[];
    isDescriptionManuallyOverwritten?: boolean | null;
    availableIn?: string;
}

export const EmptyDataset: IDataset = {
    id: 0,
    name: "",
    description: "",
    displayInCatalog: true,
    accessPackageReferenceId: 0,
    accessPackageName: "",
    availableIn: "",
    datasetTags: [],
    datasetAlerts: []
};

export interface IDatasetTag {
    name: string;
    type: number;
}

export type AdlsAutoDiscoveryEntry = {
    path: string;
    fileExtension: string;
    isDeltaTable: boolean;
};

export type TagChanges = { add: string[]; delete: string[] };

export interface IDatasetAssociation {
    id: number;
    name: string;
    dsID: number;
    type: string;
    endpoint: string;
    tags: string;
    displayInCatalog: boolean;
    pipelineName?: string;
}

export interface IDatasetAlert {
    id: number;
    datasetId: number;
    name: string;
    datasetName: string;
    category:string
    type: string;
    configurations: string;
    isSupressed: boolean;
    suppressionStartTime: Date;
    suppressionEndTime: Date;
    createdBy: string;
    lastUpdated: Date;
    modifiedBy: string;
    lastTriggered: Date;
}

export const EmptyDatasetAssociation: IDatasetAssociation = {
    id: 0,
    dsID: 0,
    name: "",
    type: "",
    endpoint: "",
    tags: "",
    displayInCatalog: true
};

export type DatasetPipelineInfo = {
    pipelineName?: string;
    pipelineId?: number;
    activityName?: string;
    datafactoryAuthorLink?: string;
};
//ADLS Dataset

export type IAdlsDataset = {
    id: number;
    datasetId: number;
    datasetName: string;
    storageId: number;
    partitionSchema: string;
    fileExtension: string;
    storageAccountName: string;
    container: string;
    path: string;
    status: string;
    displayInCatalog: boolean;
    slaThresholdInHours: number;
    retentionInDays: number;
    updateFrequencyInHours: number;
    tagChanges: TagChanges;
    lastUpdated: Date;
    isDeltaTable?: boolean;
    isPurgingEnabled?: boolean;
    purgeBy?: string;
} & DatasetPipelineInfo;

export const EmptyAdlsDataset: IAdlsDataset = {
    id: 0,
    datasetId: 0,
    storageId: -1,
    storageAccountName: "",
    partitionSchema: "",
    fileExtension: "",
    container: "",
    datasetName: "",
    path: "",
    status: "",
    slaThresholdInHours: 24,
    retentionInDays: 181,
    updateFrequencyInHours: 24,
    displayInCatalog: true,
    tagChanges: { add: [], delete: [] },
    lastUpdated: new Date()
};

// SDW Dataset

export type ISDWDataset = {
    id: number;
    datasetId: number;
    datasetName: string;
    sqlServer: string;
    database: string;
    databaseId?: number;
    type?: number;
    name: string;
    status: string;
    slaThresholdInHours: number;
    retentionInDays: number;
    displayInCatalog: boolean;
    tagChanges: TagChanges;
    lastUpdated: Date;
    sProcSchemas?: SdwSchema[];
    getMetricsBy?: string;
} & DatasetPipelineInfo;

export const EmptySDWDataset: ISDWDataset = {
    id: 0,
    datasetId: 0,
    datasetName: "",
    sqlServer: "",
    database: "",
    name: "",
    status: "",
    slaThresholdInHours: 24,
    retentionInDays: 181,
    displayInCatalog: true,
    tagChanges: { add: [], delete: [] },
    lastUpdated: new Date()
};

// Kusto Dataset
export type IKustoDataset = {
    id: number;
    datasetId: number;
    datasetName: string;
    clusterId: number;
    cluster: string;
    region: string;
    databaseId: number;
    database: string;
    tableName: string;
    slaThresholdInHours: number;
    retentionInDays: number;
    status: string;
    displayInCatalog: boolean;
    lastUpdated: Date;
    tagChanges: TagChanges;
} & DatasetPipelineInfo;

export const EmptyKustoDataset: IKustoDataset = {
    id: 0,
    datasetId: 0,
    datasetName: "",
    clusterId: -1,
    cluster: "",
    region: "",
    databaseId: -1,
    database: "",
    tableName: "",
    status: "",
    slaThresholdInHours: 24,
    retentionInDays: 181,
    displayInCatalog: true,
    lastUpdated: new Date(),
    tagChanges: { add: [], delete: [] }
};

// Kusto Details
export interface IKustoCluster {
    id: number;
    name: string;
    region: string;
}

export interface IKustoDatabase {
    id: number;
    name: string;
}

// Storage Account

export type StorageAccount = {
    id: number;
    name: string;
    subscriptionId: string;
    storageAccountTypeId: string;
};

export enum DatasetType {
    Adls,
    Kusto,
    SDW
}

export type SDWSProcParamResponse = {
    sdwsProcParams: SProcParam[];
    errorMessage: string;
};

export type SProcParam = {
    name: string;
    value: string;
    type: string;
};

export type SDWSProcSchemaResponse = {
    sdwSchema: SdwSchema[];
    errorMessage: string;
};

export type SdwSchema = {
    autoDiscoverySDWDatasetId: number;
    name: string;
    type: string;
};

export type SProcSchemaRequest = {
    sProcName: string;
    paramList: SProcParam[];
};

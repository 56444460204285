import { CloneEntry } from "../../models/Elm/CloneEntry";

export function isCloneApproved(status: string) {
    return status === "Approved" || status === "Clone Request Conflict";
}

export function getCloneStatus(cloneEntry: CloneEntry) {
    const result = cloneEntry.status.reduce(
        (acc, x) => {
            if (isCloneApproved(x.status)) acc.approvedCount++;
            return acc;
        },
        { approvedCount: 0, totalCount: cloneEntry.status.length }
    );

    return result.approvedCount === result.totalCount ? "Complete" : `${result.approvedCount}/${result.totalCount}`;
}

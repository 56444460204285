import { PrimaryButton, Stack } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { generateDatasetColumns } from "../columns";
import StandardList from "../components/common/StandardList";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { IDataset } from "../models/Dataset";
import { DatasetComponents, getAllDatasets, resetDatasets } from "../reducers/Dataset";
import "../styles/App.css";
import { StateValues } from "../types";
import { dataSetSelector } from "./Selectors";
import useSubscriptionData from "../hooks/useSubscriptionData";
import PreferredSelect from "../components/common/PreferredSelect";

function DatasetList() {
    useEffect(() => {
        document.title = "List of Dataset - SIP Portal";
    }, []);

    const { datasets, dsLoading, dsErrors } = useAppSelector(dataSetSelector);
    const [dsStateValues, setDsStateValues] = useState<StateValues>("INIT");
    const { subscriptionId, setSubscriptionId, subscriptionEntries, subscriptionError } = useSubscriptionData();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getAllDatasets());
    }, [getAllDatasets]);

    useEffect(() => {
        if (datasets) {
            setDsStateValues("LOADED_ALL");
        }
    }, [datasets, dsLoading[DatasetComponents.Datasets]]);

    const addButtonRef = useRef<HTMLDivElement>(null);
    const history = useHistory();

    let finalDS: IDataset[] = [];
    if (datasets != undefined && datasets != null) {
        datasets.forEach((ds) => {
            const tempds = { ...ds };
            tempds.availableIn = getAvailability(ds);
            finalDS.push(tempds);
        });
    }

    return (
        <>
            <StandardList
                entities={finalDS.filter((ds) => subscriptionId === undefined || ds.subscriptionId === subscriptionId)}
                title={"Datasets"}
                columns={generateDatasetColumns(history)}
                error={dsErrors[DatasetComponents.Dataset] || subscriptionError}
                entityState={dsStateValues}
                extraHeaderItems={
                    <>
                        {" "}
                        <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                            <PreferredSelect
                                title="Subscription"
                                entries={subscriptionEntries}
                                selectedEntryId={subscriptionId}
                                setEntryId={setSubscriptionId}
                            />
                        </Stack>
                        <div
                            ref={addButtonRef}
                            onFocus={() => {
                                addButtonRef.current &&
                                    addButtonRef.current.scrollIntoView({
                                        behavior: "smooth",
                                        block: "nearest",
                                        inline: "start"
                                    });
                            }}
                        >
                            <Stack.Item grow align="center">
                                <PrimaryButton
                                    text="Add Dataset"
                                    onClick={() => {
                                        history.push(`/Datasets/New`);
                                        dispatch(resetDatasets());
                                    }}
                                    allowDisabledFocus
                                />
                            </Stack.Item>
                        </div>
                    </>
                }
            />
        </>
    );
}
function getAvailability(dsItem: IDataset) {
    let res: string = "";
    if (dsItem) {
        if (dsItem.adlsDataset != null) {
            res = "Adls; ";
        }
        if (dsItem.kustoDataset != null) {
            res += "Kusto; ";
        }
        if (dsItem.sdwDataset != null) {
            res += "SDW;";
        }
    }
    return res;
}
export default DatasetList;

import { IApplicationState } from "../store";

export const feedSelector = (state: IApplicationState) => state.feeds;

export const pipelineSelector = (state: IApplicationState) => state.pipelines;

export const feedCategorySelector = (state: IApplicationState) => state.feedCategory;

export const contactSelector = (state: IApplicationState) => state.contacts;

export const dataFactorySelector = (state: IApplicationState) => state.datafactories;

export const dataSourceSelector = (state: IApplicationState) => state.dataSources;

export const dataSourceContactSelector = (state: IApplicationState) => state.dataSourceContacts;

export const feedGroupSelector = (state: IApplicationState) => state.feedGroups;

export const activitiesSelector = (state: IApplicationState) => state.activites;

export const feedFreshnessSelector = (state: IApplicationState) => state.feedFreshness;
export const feedFreshnessHistorySelector = (state: IApplicationState) => ({
    timestamps: state.feedFreshness.history,
    error: state.feedFreshness.error
});

export const directoryAuditSelector = (state: IApplicationState) => state.directoryAudit;
export const AzureResourceSelector = (state: IApplicationState) => state.azureResources;
export const adlsCapacityTrendSelector = (state: IApplicationState) => state.adlsCapacityTrend;
export const accessPackageSelector = (state: IApplicationState) => state.accessPackages;
export const dataSetSelector = (state: IApplicationState) => state.dataSet;
export const dataSetAlertSelector = (state: IApplicationState) => state.dataSetAlerts;
export const subscriptionSelector = (state: IApplicationState) => state.subscription;
export const remoteServerConfigSelector = (state: IApplicationState) => state.remoteServerConfigs;
export const serverAdministrationSelector = (state: IApplicationState) => state.servAdministrations;
export const powerBIReportsSelector = (state: IApplicationState) => state.powerbiReports;
export const searchSelector = (state: IApplicationState) => state.search;
export const internalObjectTaggingSelector = (state: IApplicationState) => state.internalObjectTagging;

import { History } from "history";
import { AdminUser } from "../constants";

import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { addSortLabels, renderViewColumn } from "./utils";
import { QualityMetricsValidationResult } from "../models/QualityMetricsValidationResult";
import { IconButton } from "@fluentui/react";

export function generateQualityMetricsValidationColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Dataset Name",
            fieldName: "name",
            filterBy: true,
            minWidth: 75,
            maxWidth: 350,
            isResizable: true,
            isSorted: true
        },        
        {
            key: "type",
            name: "Type",
            fieldName: "type",
            filterBy: true,
            minWidth: 75,
            maxWidth: 200,
            isResizable: true,
            isSorted: true
        },
        {
            key: "dcURL",
            name: "View in Catalog",
            fieldName: "catalogURL",
            filterBy: true,
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            onRender: (item: QualityMetricsValidationResult) => {
                return item.catalogURL 
                    ? <IconButton
                        split
                        title={item.name + " - View in DataCatalog"}
                        onClick={() => {
                            window.open(item.catalogURL, "_blank");
                        }}
                        iconProps={{ iconName: "Link" }}
                        allowDisabledFocus
                    />
                    : "";
            }
        },
        {
            key: "dismissedBy",
            name: "Dismissed By",
            fieldName: "dismissedBy",
            filterBy: true,
            minWidth: 100,
            maxWidth: 350,
            isResizable: true,
            isSorted: true,
            onRender: (item: QualityMetricsValidationResult) => {
                return item.dismissedBy && item.dismissedOn
                    ? `${item.dismissedBy} (${new Date(item.dismissedOn).toLocaleString("en-US", {
                          timeZone: "America/Los_Angeles"
                      })})`
                    : "N/A";
            }
        }
    ];
    if (isInRole(AdminUser)) {
        cols.push({
            key: "id",
            name: "View/Edit",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: QualityMetricsValidationResult) => renderViewColumn(item, "qualityMetricsValidations", history)
        });
    }

    addSortLabels(cols);
    return cols;
}

import { IBasePickerSuggestionsProps, IPersonaProps, NormalPeoplePicker, Label } from "@fluentui/react";
import { getSqlDbUsers } from "../../api/AzureResourceApi";
import { listContainsTagList } from "../common/utils";
import { AzureResourceProps } from "./AzureResourceProps";

const SqlUserPicker = ({ editDisabled, azureResource, setState, setError }: AzureResourceProps) => {
    const title = "User";
    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: `Suggested ${title}`,
        noResultsFoundText: `No ${title} found`,
        suggestionsContainerAriaLabel: `Suggested ${title}`,
        suggestionsAvailableAlertText: "Suggestions Available",
        loadingText: "Suggestions loading"
    };
    function getBlackList() {
        if (azureResource && azureResource.config) {
            return azureResource.config["blackList"] as string[];
        } else {
            return [];
        }
    }

    const filterSuggestedTags = async (filterText: string, tagList?: IPersonaProps[]): Promise<IPersonaProps[]> => {
        if (filterText) {
            const freeForm = { text: filterText, secondaryText: "free form" };
            return [freeForm];
        }
        return [];
    };

    return (
        <>
            <Label className="label">Query Killer Black List</Label>
            <NormalPeoplePicker
                styles={{ root: { background: "#FFFFFF" } }}
                aria-label={title}
                enableSelectedSuggestionAlert={true}
                removeButtonAriaLabel={`Remove ${title}`}
                onChange={(item) => {
                    if (!item || item.length === 0) {
                        const newConfig = { ...azureResource.config };
                        newConfig["blackList"] = [];
                        setState({ ...azureResource, config: newConfig });
                    } else {
                        const newConfig = { ...azureResource.config };
                        // use email for indiviual users and spns and the group name for groups to match with how they are in the database
                        newConfig["blackList"] = item.map((x) => (x.typeof === "User" ? x.secondaryText : x.text));
                        setState({ ...azureResource, config: newConfig });
                    }
                }}
                selectedItems={
                    getBlackList()
                        ? getBlackList().map((s) => ({
                              text: s,
                              showSecondaryText: true,
                              id: s,
                              itemID: s
                          }))
                        : []
                }
                resolveDelay={700}
                onResolveSuggestions={(filter) => filterSuggestedTags(filter)}
                disabled={editDisabled}
                pickerSuggestionsProps={pickerSuggestionsProps}
                itemLimit={100}
                inputProps={{
                    id: "sqlUserPickerID",
                    "aria-label": title
                }}
            />
        </>
    );
};

export default SqlUserPicker;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPipelineActivity } from "../models/PipelineActivtiy";
import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import {
    getActivities,
    updateActivities as updateActivitiesApi,
    updateActivity as updateActivityApi
} from "../api/ActivityApi";

import { getDefaultCases } from "./DefaultCases";

const sliceName = "activities";

const initialState = getInitialState<IPipelineActivity, IPipelineActivity>();

const getAllActivites = createAsyncThunk(
    `${sliceName}/get_all`,

    async (props: { dfId: number; pipelineId: number }, _thunkAPI) => {
        const response = await getActivities(props.dfId, props.pipelineId);
        return response.data;
    }
);

const updateActivity = createAsyncThunk(`${sliceName}/update`, async (activity: IPipelineActivity, _thunkAPI) => {
    const response = await updateActivityApi(activity);
    return response.data;
});
const updateActivities = createAsyncThunk(
    `${sliceName}/updates`,
    async (activities: IPipelineActivity[], _thunkAPI) => {
        const response = await updateActivitiesApi(activities);
        return response.data;
    }
);

const activitiesSlice = createSlice({
    name: sliceName,
    initialState,

    reducers: getDefaultCases<IPipelineActivity>(),
    extraReducers: (builder) => {
        builder.addCase(getAllActivites.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllActivites.fulfilled, (state, action) => {
            state.entities = action.payload;

            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(updateActivity.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(updateActivity.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Update);
        });
        builder.addCase(updateActivities.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(updateActivities.fulfilled, (state, action) => {
            onComplete(state, StateActionType.Update);
        });
    }
});

export const { reset, error, setCurrent } = activitiesSlice.actions;
export { getAllActivites, updateActivity, updateActivities, sliceName };
export const { reducer } = activitiesSlice;

import React, { Dispatch, SetStateAction } from "react";
import { IFeedFreshness } from "../../../models/FeedFreshness";
import ExpectedTimeDonut from "./ExpectedTimeDonut";
import SlaDonut from "./SlaDonut";
import { ITolerance } from "../../../pages/FeedFreshnessList";

interface IDashboardProps {
    feedFreshnesses: IFeedFreshness[];
    tolerance: ITolerance;
    setTolerance: Dispatch<SetStateAction<ITolerance>>;
}

function Dashboard(props: IDashboardProps) {
    return (
        <table>
            <tbody>
                <tr>
                    <td>
                        <ExpectedTimeDonut
                            feedFreshnesses={props.feedFreshnesses}
                            tolerance={props.tolerance}
                            setTolerance={props.setTolerance}
                        ></ExpectedTimeDonut>
                    </td>
                    <td>
                        <SlaDonut
                            feedFreshnesses={props.feedFreshnesses}
                            tolerance={props.tolerance}
                            setTolerance={props.setTolerance}
                        ></SlaDonut>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default Dashboard;

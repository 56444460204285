import { IButtonStyles, IconButton, ISliderProps, Label, Slider, TooltipHost } from "@fluentui/react";
import React from "react";

type ToolTippedSliderProps = {
    toolTipsProps: {
        content: string;
        id: string;
    };
    label: string;
    sliderProps: ISliderProps;
};

const buttonStyles: IButtonStyles = {
    rootDisabled: { background: "transparent", color: "rgb(0, 120, 212)" },
    rootHovered: { background: "transparent", color: "rgb(0, 120, 212)", cursor: "default" }
};

export function ToolTippedSlider({ toolTipsProps, label, sliderProps }: ToolTippedSliderProps) {
    return (
        <div>
            <TooltipHost id={toolTipsProps.id} content={toolTipsProps.content}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <Label className="ml-2">{label}</Label>
                    <IconButton
                        onClick={(e) => e.preventDefault()}
                        styles={buttonStyles}
                        aria-labelledby={toolTipsProps.id}
                        iconProps={{ iconName: "info" }}
                    />
                </div>
            </TooltipHost>
            <Slider {...sliderProps} />
        </div>
    );
}

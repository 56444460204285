import { IToggleProps, Label, Shimmer, Toggle } from "@fluentui/react";
import { getShimmerStyles } from "../../../columns/utils";
type ShimmeredProps = {
    loading: boolean;
    toggleLabel: string;
    width?: string;
    isStackItem?: boolean;
} & IToggleProps;

function ShimmeredToggle(props: ShimmeredProps) {
    return (
        <div className={props.isStackItem ? "" : "col"}>
            <Label className="label"> {props.toggleLabel} </Label>
            <Shimmer
                isDataLoaded={!props.loading}
                ariaLabel={`Loading ${props.toggleLabel}`}
                styles={getShimmerStyles()}
            >
                <Toggle ariaLabel={props.toggleLabel} {...props} />
            </Shimmer>
        </div>
    );
}

export { ShimmeredToggle };

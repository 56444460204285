export interface IRemoteServerConfig {
    id: number;
    name: string;
    serverName: string;
    status: string;
    reboot: boolean;
    rebootStatus: string;
    restartService: boolean;
    serviceRestartStatus: string;
    actionCompletedTime: Date;
    isDeleted: boolean
    lastUpdated: Date;
}

export const EmptyRemoteServerConfig: IRemoteServerConfig = {
    id: 0,
    name:"",
    serverName: "",
    status: "",
    reboot: false,
    rebootStatus: "",
    restartService: false,
    serviceRestartStatus: "",
    actionCompletedTime: new Date(),
    isDeleted: false,
    lastUpdated: new Date()
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInitialState, onComplete, onLoading, StateActionType } from "../types";
import { getDataSourceContacts } from "../api/DataSourceApi";
import { IDataSource, IDataSourceContact } from "../models";
import { getDefaultCases } from "./DefaultCases";

const sliceName = "dataSourceContacts";

const getDataSourceContactsByID = createAsyncThunk(`${sliceName}/get_allDSContacts`, async (id: number, _thunkAPI) => {
    const response = await getDataSourceContacts(id);
    return response.data;
});

let initialState = getInitialState<IDataSourceContact, IDataSourceContact>();

// Then, handle actions in your reducers:
const dataSourceContactsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IDataSource>(),
    extraReducers: (builder) => {
        builder.addCase(getDataSourceContactsByID.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getDataSourceContactsByID.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
    }
});

export const { reset, error, setCurrent } = dataSourceContactsSlice.actions;
export {sliceName, getDataSourceContactsByID };
export const { reducer } = dataSourceContactsSlice;

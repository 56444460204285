import { Contact } from "@sipmon/reactcomponents/dist/components";
import {
    AccessPackageDto,
    AccessRequest,
    AccessRequestComment,
    ISipApiResponse,
    ProcessSipAccessRequest
} from "../models";
import {
    AccessPackageAddValidationResponse,
    AccessPackageReference,
    AccessPackageRole,
    OdataResponse
} from "../models/Elm/AccessPackageTypes";
import axios from "./SipMonClient";

export const createAccessPackage = async (
    vw: AccessPackageDto
): Promise<ISipApiResponse<OdataResponse<AccessPackageRole>>> => {
    const { data, status } = await axios.post<OdataResponse<AccessPackageRole>>(
        `api/EntitlementManagement/createAccessPackage/`,
        vw
    );
    return {
        data,
        message: "",
        status
    };
};

export const updateAccessPackage = async (
    vw: AccessPackageDto
): Promise<ISipApiResponse<OdataResponse<AccessPackageDto>>> => {
    const { data, status } = await axios.put<OdataResponse<AccessPackageDto>>(
        `api/EntitlementManagement/updateAccessPackage/`,
        vw
    );
    return {
        data,
        message: "",
        status
    };
};

export const migrateAccessPackage = async (
    vw: AccessPackageDto,
    feedId: string
): Promise<ISipApiResponse<OdataResponse<AccessPackageDto>>> => {
    const { data, status } = await axios.post<OdataResponse<AccessPackageDto>>(
        `api/EntitlementManagement/migrateAccessPackage/${feedId}`,
        vw
    );
    return {
        data,
        message: "",
        status
    };
};

export const processSipAccessRequest = async (request: ProcessSipAccessRequest): Promise<ISipApiResponse<string>> => {
    const { data, status } = await axios.post<string>(`api/EntitlementManagement/submitObo${request.type}`, request);

    return {
        data,
        message: "",
        status
    };
};

export const addAccessRequestComment = async (
    comment: Pick<AccessRequestComment, "createdBy" | "comment"> & { accessRequestId: number }
): Promise<ISipApiResponse<void>> => {
    const { data, status } = await axios.post<void>(`api/EntitlementManagement/addAccessRequestComment`, comment);

    return {
        data,
        message: "",
        status
    };
};

export const getAccessRequests = async (): Promise<ISipApiResponse<AccessRequest[]>> => {
    const { data, status } = await axios.get<AccessRequest[]>(`api/EntitlementManagement/accessRequests`);
    return {
        data,
        message: "",
        status
    };
};

export const getAccessPackageReferences = async (): Promise<ISipApiResponse<AccessPackageReference[]>> => {
    const { data, status } = await axios.get<AccessPackageReference[]>(`api/Dataset/accessPackageReferences`);
    return {
        data,
        message: "",
        status
    };
};

export const getAccessRequestById = async (id: number): Promise<ISipApiResponse<AccessRequest>> => {
    const { data, status } = await axios.get<AccessRequest>(`api/EntitlementManagement/accessRequests/${id}`);
    return {
        data,
        message: "",
        status
    };
};

export const validateOwnership = async (groupId: string, subscriptionId: string): Promise<ISipApiResponse<void>> => {
    const { data, status } = await axios.get<void>(
        `api/EntitlementManagement/validateOwnership?groupId=${groupId}&subscriptionId=${subscriptionId}`
    );
    return {
        data,
        message: "",
        status
    };
};
export const getPrePopulatedSecondaryApprovers = async (catalogId: string): Promise<ISipApiResponse<Contact[]>> => {
    const { data, status } = await axios.get<Contact[]>(`api/EntitlementManagement/getSecondaryApprovers/${catalogId}`);
    return {
        data,
        message: "",
        status
    };
};

export const validateAccessPackageAdd = async (
    securityGroupId: string,
    catalogId: string,
    displayName: string
): Promise<ISipApiResponse<AccessPackageAddValidationResponse>> => {
    const { data, status } = await axios.get<AccessPackageAddValidationResponse>(
        `api/EntitlementManagement/validateAdd/?securityGroupId=${securityGroupId}&displayName=${displayName}&catalogId=${catalogId}`
    );
    return {
        data,
        message: "",
        status
    };
};

import { IPipelineActivity } from "./PipelineActivtiy";

export interface IFeed {
    id: number;
    name: string;
    path: string;
    platformName: string;
    slaInHours: number;
    updateFrequencyInHours: number;
    feedCategoryId?: number | null;
    lastUpdated: Date;
    storageName: string;
    isPurgeEnabled: boolean;
    retentionDays?: number | null;
    feedAADGroupName: string;
    feedADGroupName: string;
    feedAADGroupEmail: string;
    feedADGroupEmail: string;
    commsAlias: string;
    activity?: IPipelineActivity;
    isDeprecated: boolean;
    accessPackageReferenceId?: number;
    accessPackageId: string;
    accessPackageName: string;
    accessPackageGroupId: string;
    accessPackageGroupName: string;
    accessPackageIsPlaceholder: boolean;
    accessPackageCatalogId: string;
    storageType: string;
    subscriptionId: string;
}

export const EmptyFeed: IFeed = {
    id: 0,
    name: "",
    path: "",
    platformName: "",
    slaInHours: 0,
    updateFrequencyInHours: 0,
    lastUpdated: new Date(),
    feedAADGroupName: "",
    feedADGroupName: "",
    feedAADGroupEmail: "",
    feedADGroupEmail: "",
    storageName: "",
    isPurgeEnabled: false,
    commsAlias: "",
    activity: undefined,
    isDeprecated: false,
    accessPackageId: "",
    accessPackageName: "",
    accessPackageGroupId: "",
    accessPackageGroupName: "",
    accessPackageIsPlaceholder: false,
    accessPackageCatalogId: "",
    storageType: "",
    subscriptionId: ""
};

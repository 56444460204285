import { useEffect, useRef, useState } from "react";
import "../styles/App.css";
import StandardList from "../components/common/StandardList";
import { useHistory } from "react-router-dom";
import { StateValues } from "../types";
import { generateAccessPackageColumns } from "../columns/AccessPackageColumns";
import { PrimaryButton, Stack } from "@fluentui/react";
import { AccessPackageView } from "../models";
import {
    useGetAccessPackageViewQuery,
    useGetMigrationSummaryQuery,
    useResetMigrationFailuresMutation
} from "../reducers/AccessPackageApi";
import { isFetchBaseQueryError, isErrorWithMessage } from "../reducers/utils";
import { useGetCatalogsQuery } from "../reducers/GraphElmApi";
import PreferredSelect from "../components/common/PreferredSelect";

function AccessPackageList() {
    const migrationPollingInterval = 300000; // 5 minutes
    const activePollingInterval = 30000; // 30 seconds
    const [resetMigration, result] = useResetMigrationFailuresMutation();
    const history = useHistory();

    const [stateValues, setStateValues] = useState<StateValues>("LOADED_ALL");
    const addButtonRef = useRef<HTMLDivElement>(null);
    const [views, setViews] = useState<AccessPackageView[]>();
    const [errorHandler, setErrorHandler] = useState<string>();
    const [selectedCatalogId, setSelectedCatalogId] = useState<string>();
    const [migPollingInterval, setMigPollingInterval] = useState<number>(migrationPollingInterval);

    const { data: catalogs } = useGetCatalogsQuery(undefined, {
        pollingInterval: 3600000 // 1 hour
    });
    const { data, isLoading, error } = useGetAccessPackageViewQuery(selectedCatalogId, {
        skip: !selectedCatalogId,
        pollingInterval: 300000 // 5 minutes
    });

    const { data: migrationSummary } = useGetMigrationSummaryQuery(selectedCatalogId, {
        skip: !selectedCatalogId,
        pollingInterval: migPollingInterval
    });

    useEffect(() => {
        document.title = "Summary of Access Packages - SIP Portal";

        if (selectedCatalogId) {
            setStateValues("LOADING_ALL");
            setViews(undefined);
        }
    }, [selectedCatalogId]);

    useEffect(() => {
        if (migrationSummary?.some((m) => m.isMigrationInPro)) {
            setMigPollingInterval(activePollingInterval);
        } else {
            setMigPollingInterval(migrationPollingInterval);
        }

        if (migrationSummary && data && data.some((view) => view.catalogId === selectedCatalogId)) {
            const updatedViews = data.map((view) => {
                const migration = migrationSummary.find((m) => m.accessPackageId === view.id);
                return migration ? { ...view, ...migration } : view;
            });
            setViews(updatedViews);
            setStateValues("LOADED_ALL");
        }
    }, [migrationSummary, data]);

    useEffect(() => {
        if (isFetchBaseQueryError(error)) {
            const errMsg =
                "error" in error
                    ? error.error
                    : error.data
                    ? JSON.stringify(error.data)
                    : `There was an error getting the Access Packages (error code: ${error.status})`;
            setErrorHandler(errMsg);
        } else if (isErrorWithMessage(error)) {
            setErrorHandler(error.message);
        }
    }, [error]);

    useEffect(() => {
        if (isLoading) {
            setStateValues("LOADING_ALL");
        }
    }, [isLoading]);

    return (
        <StandardList
            entities={views}
            title={"Access Packages"}
            columns={generateAccessPackageColumns(history, resetMigration)}
            error={errorHandler}
            entityState={stateValues}
            extraHeaderItems={
                <div
                    ref={addButtonRef}
                    onFocus={() => {
                        addButtonRef.current &&
                            addButtonRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                                inline: "start"
                            });
                    }}
                >
                    <Stack horizontal horizontalAlign="space-evenly" tokens={{ childrenGap: 1, padding: 10 }}>
                        <Stack.Item align="start">
                            <PreferredSelect
                                title="Catalog"
                                entries={catalogs}
                                urlParam="catalogId"
                                selectedEntryId={selectedCatalogId}
                                setEntryId={setSelectedCatalogId}
                            />
                        </Stack.Item>
                        <Stack.Item grow align="end">
                            <PrimaryButton
                                text="Add Access Package"
                                onClick={() => history.push(`/accessPackages/new`)}
                            />
                        </Stack.Item>
                    </Stack>
                </div>
            }
        />
    );
}
export default AccessPackageList;

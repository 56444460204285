import React, { Dispatch, SetStateAction, useState } from "react";
import {
    DefaultButton,
    IContextualMenuProps,
    IContextualMenuItem,
    ContextualMenuItemType,
    Announced
} from "@fluentui/react";
import { CoherenceTheme } from "@cseo/styles";

interface IFeedFreshnessFilterProps {
    filters: IFeedFreshnessFilters;
    setFilters: Dispatch<SetStateAction<IFeedFreshnessFilters>>;
}

export interface IFeedFreshnessFilters {
    expectedRefreshInterval_Met: boolean;
    expectedRefreshInterval_NotMet: boolean;
    mappedFeeds_Mapped: boolean;
    mappedFeeds_Unmapped: boolean;
    slaStatus_Met: boolean;
    slaStatus_NotMet: boolean;
    slaHighlight_On: boolean;
    dormantFeeds_True: boolean;
    dormantFeeds_False: boolean;
}

function FeedFreshnessFilters(props: IFeedFreshnessFilterProps) {
    const filters = props.filters;

    const [announcement, setAnnouncement] = useState<string>();
    const setFilters = props.setFilters;
    const keys = [
        "expectedRefreshInterval_Met",
        "expectedRefreshInterval_NotMet",
        "mappedFeeds_Mapped",
        "mappedFeeds_Unmapped",
        "slaStatus_Met",
        "slaStatus_NotMet",
        "slaHighlight_On",
        "dormantFeeds_True",
        "dormantFeeds_False"
    ];

    function toggle(
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item?: IContextualMenuItem
    ): void {
        ev && ev.preventDefault(); // prevent closing menu on click

        if (!item) {
            return;
        }
        // toggle chosen option and set all other options in the same submenu to false
        const filterUpdates = {};
        const keyFamily = item.key.substring(0, item.key.indexOf("_"));
        keys.forEach((k) => {
            if (k.indexOf(keyFamily) >= 0) {
                filterUpdates[k] = k === item.key ? !filters[item.key] : false;
            }
        });
        setAnnouncement(`${item.text} checked`);

        setFilters({ ...filters, ...filterUpdates });
    }

    const theme = CoherenceTheme;
    theme.semanticColors.bodyTextChecked = theme.palette.neutralDark; // text color of hovered over menu item

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: "expectedRefreshInterval",
                text: "Expected Refresh Interval",
                subMenuProps: {
                    items: [
                        {
                            key: keys[0],
                            text: "Only show feeds meeting Expected Refresh Interval",
                            canCheck: true,
                            checked: filters[keys[0]],
                            onClick: toggle
                        },
                        {
                            key: keys[1],
                            text: "Only show feeds not meeting Expected Refresh Interval",

                            canCheck: true,
                            checked: filters[keys[1]],
                            onClick: toggle
                        }
                    ]
                }
            },
            {
                key: "mappedFeeds",
                text: "Mapped/Unmapped Feeds",
                subMenuProps: {
                    items: [
                        {
                            key: keys[2],
                            text: "Only show mapped feeds",
                            canCheck: true,
                            checked: filters[keys[2]],
                            onClick: toggle
                        },
                        {
                            key: keys[3],
                            text: "Only show unmapped feeds",
                            canCheck: true,
                            checked: filters[keys[3]],
                            onClick: toggle
                        }
                    ]
                }
            },
            {
                key: "slaStatus",
                text: "SLA Status",
                subMenuProps: {
                    items: [
                        {
                            key: keys[4],
                            text: "Only show feeds meeting SLA",
                            canCheck: true,
                            checked: filters[keys[4]],
                            onClick: toggle
                        },
                        {
                            key: keys[5],
                            text: "Only show feeds not meeting SLA",
                            canCheck: true,
                            checked: filters[keys[5]],
                            onClick: toggle
                        }
                    ]
                }
            },
            {
                key: "dormantFeeds",
                text: "Dormant Feeds",
                subMenuProps: {
                    items: [
                        {
                            key: keys[7],
                            text: "Only show dormant feeds",
                            canCheck: true,
                            checked: filters[keys[7]],
                            onClick: toggle
                        },
                        {
                            key: keys[8],
                            text: "Only show active (not dormant) feeds",
                            canCheck: true,
                            checked: filters[keys[8]],
                            onClick: toggle
                        }
                    ]
                }
            }
        ]
    };

    return (
        <>
            {" "}
            <DefaultButton text="Filter Feeds By..." menuProps={menuProps} />
            <Announced id="searchAnnoucement" message={announcement} aria-live="assertive" />
        </>
    );
}

export default FeedFreshnessFilters;

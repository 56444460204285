export interface IDataFactory {
    id: number;
    name: string;
    isMonitoringEnabled: boolean;
    isDeleted: boolean;
    platformName: string;
    pipelineCount: number;
    lastUpdated: Date;
    deletedTime: Date;
    type: "Datafactory" | "Synapse";
    isGitEnabled: boolean;
    subscriptionId: string;
}

export const EmptyDataFactory: IDataFactory = {
    id: 0,
    name: "",
    isMonitoringEnabled: false,
    isDeleted: false,
    platformName: "",
    pipelineCount: 0,
    lastUpdated: new Date(),
    deletedTime: new Date(),
    type: "Datafactory",
    isGitEnabled: false,
    subscriptionId: ""
};

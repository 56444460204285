import {
    DetailsRow,
    FontIcon,
    FontSizes,
    GroupedList,
    IColumn,
    IGroup,
    IGroupHeaderProps,
    PrimaryButton,
    SelectionMode,
    Stack,
    Text,
    getTheme
} from "@fluentui/react";
import React from "react";
import { AccessPackageAssignment } from "../../models";

type GroupedAssignmentListProps = {
    catalogId: string;
    filterdAssignments: AccessPackageAssignment[];
    cloneAssignment: (e: React.MouseEvent<HTMLButtonElement>, key: string, catalogId: string) => void;
};

const columns: IColumn[] = [
    {
        key: "accessPackageName",
        name: "Access Package Name",
        fieldName: "accessPackageName",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
    }
];

const GroupedAssignmentList = ({ catalogId, filterdAssignments, cloneAssignment }: GroupedAssignmentListProps) => {
    const theme = getTheme();
    const [groups, setGroups] = React.useState<IGroup[]>([]);

    React.useEffect(() => {
        if (filterdAssignments) {
            const groups = groupAssignments(
                [...filterdAssignments].sort((a, b) => a.displayName.localeCompare(b.displayName))
            );
            setGroups(groups);
        }
    }, [filterdAssignments]);

    function groupAssignments(assignments: AccessPackageAssignment[]) {
        return assignments.reduce((acc: IGroup[], curr: AccessPackageAssignment, index: number) => {
            const { objectId, displayName } = curr;

            const group = {
                key: objectId,
                name: displayName,
                startIndex: 0,
                count: 1,
                isCollapsed: true,
                data: { userType: curr.userType, policy: curr.policyName }
            };

            if (acc.length === 0) {
                return [group];
            } else if (acc[acc.length - 1].key !== objectId) {
                const { count, startIndex } = acc[acc.length - 1];
                return [
                    ...acc,
                    {
                        ...group,
                        startIndex: count + startIndex
                    }
                ];
            }
            acc[acc.length - 1].count++;
            return acc;
        }, []);
    }

    const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element | null => {
        if (props) {
            const toggleCollapse = (): void => {
                props.onToggleCollapse!(props.group!);
            };
            return (
                <Stack horizontal style={{ width: "100%", marginBottom: "5px" }} horizontalAlign="space-between">
                    <Stack horizontal>
                        <FontIcon
                            style={{ marginRight: 8, marginTop: "15px", cursor: "pointer", fontSize: FontSizes.size14 }}
                            onClick={toggleCollapse}
                            iconName={props.group!.isCollapsed ? "ChevronRight" : "ChevronDown"}
                        />
                        <Stack>
                            <Text variant="large">
                                {props.group!.name} ({props.group?.count})
                            </Text>
                            <Text style={{ color: theme.palette.neutralSecondary }} variant="small">
                                {props.group!.data.userType === "servicePrincipal" ? "Service Principal" : "User"} |{" "}
                                {props.group?.data.policy}
                            </Text>
                        </Stack>
                    </Stack>
                    {props.group?.data.userType === "servicePrincipal" && (
                        <Stack.Item>
                            <PrimaryButton
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                    cloneAssignment(e, props.group?.key!, catalogId)
                                }
                            >
                                Clone Access
                            </PrimaryButton>
                        </Stack.Item>
                    )}
                </Stack>
            );
        }

        return null;
    };
    const onRenderCell = (
        nestingDepth?: number,
        item?: AccessPackageAssignment,
        itemIndex?: number,
        group?: IGroup
    ): React.ReactNode => {
        return item && typeof itemIndex === "number" && itemIndex > -1 ? (
            <DetailsRow
                styles={{ root: { width: "100%", userSelect: "text" } }}
                columns={columns}
                item={item}
                itemIndex={itemIndex}
                selectionMode={SelectionMode.multiple}
                group={group}
                groupNestingDepth={nestingDepth}
            />
        ) : null;
    };
    return (
        <GroupedList
            groupProps={{ onRenderHeader: onRenderHeader }}
            items={[...filterdAssignments].sort((a, b) => a.displayName.localeCompare(b.displayName))}
            onRenderCell={onRenderCell}
            selectionMode={SelectionMode.none}
            groups={groups}
        />
    );
};

export default GroupedAssignmentList;

import { Stack, PrimaryButton, CommandBarButton, IContextualMenuProps, IStackStyles } from "@fluentui/react";

import { useHistory } from "react-router-dom";

type PipelineCommandButtonProps = {
    name: string;
    datafactoryAuthorLink: string;
    pipelineId: number;
    editDisabled: boolean;
    popUpShow: () => void;
};

export const PipelineCommandButton = ({
    datafactoryAuthorLink,
    name,
    pipelineId,
    editDisabled,
    popUpShow
}: PipelineCommandButtonProps) => {
    const history = useHistory();

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: "goToPipelineDf",
                text: "View In Datafactory",
                iconProps: { iconName: "BuildDefinition" },
                href: datafactoryAuthorLink,
                target: "_blank"
            },
            {
                key: "goToPipelinePortal",
                text: "View in Portal",
                iconProps: { iconName: "View" },

                onClick: () => history.replace(`/pipelines/${pipelineId}`)
            },
            {
                key: "edit",
                text: "Edit",
                iconProps: { iconName: "Edit" },
                onClick: popUpShow,
                disabled: editDisabled
            }
        ]
    };
    const stackStyles: Partial<IStackStyles> = {
        root: { height: 32 }
    };

    if (!name) {
        return <PrimaryButton disabled={editDisabled} onClick={() => popUpShow()} text="Associate Pipeline" />;
    } else {
        return (
            <Stack horizontal styles={stackStyles}>
                <CommandBarButton
                    className="cmd-btn"
                    styles={{
                        root: { width: "100%" },
                        label: {
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        },
                        textContainer: {
                            overflow: "hidden",
                            textAlign: "left"
                        }
                    }}
                    title={name}
                    text={name}
                    menuProps={menuProps}
                />
            </Stack>
        );
    }
};

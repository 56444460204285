import {
    DefaultButton,
    DialogFooter,
    Stack,
    Sticky,
    StickyPositionType,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Toggle,
    TooltipHost,
    ITooltipHostStyles
} from "@fluentui/react";

import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { StackComboBox } from "../common/StackedComboBox";
import { DialgContainer } from "../common/DialogContainer";
import { StickyWrapper } from "../common/StickyHeader";
import { IDataFactory } from "../../models";
import { dataFactorySelector, pipelineSelector } from "../../pages/Selectors";
import DialogContainerProps from "../../models/dialogProps/DialogContainerProps";

import { StateActionType } from "../../types";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { getAllDatafactories } from "../../reducers/DataFactory";
import { addPipeline, getAllPipelines, reset } from "../../reducers/Pipelines";
import { getBranchNames, getNewPipelneNames } from "../../api/GitApi";
import CrudStatusDialog from "../common/CrudStatusDialog";
import { useHistory } from "react-router-dom";
import Errorbar from "../common/ErrorBar";
import { IPortalHttpError } from "../../models/PortalHttpError";
import { useId } from "@fluentui/react-hooks";

function AddPipeline(props: DialogContainerProps) {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { entities: dataFactories, state: dataFactoriesState } = useAppSelector(dataFactorySelector);
    const { state: pipelineAddState } = useAppSelector(pipelineSelector);
    const [dataFactoryId, setDataFactoryId] = React.useState<number>();
    const [branchName, setBranchName] = React.useState<string>("");
    const [isProducer, setIsProducer] = React.useState<boolean>(true);
    const [branchNames, setBranchNames] = React.useState<string[]>([]);
    const [branchNamesLoading, setBranchNamesLoading] = React.useState<boolean>(false);

    const [newPipelineNames, setNewPipelineNames] = React.useState<string[]>([]);
    const [error, setError] = React.useState<string>("");
    const [newPipelineNamesLoading, setNewPipelineNamesLoading] = React.useState<boolean>(false);

    const [newPipelineName, setNewPipelineName] = React.useState<string>("");
    const [gitEnabledDataFactories, setgitEnabledDataFactories] = useState<IDataFactory[]>([]);
    const [showAnnouncment, setShowAnnouncement] = React.useState(false);

    const getBranchesCallback = useCallback(async () => {
        if (dataFactoryId) {
            setError("");
            setBranchNamesLoading(true);

            try {
                const response = await getBranchNames(dataFactoryId);
                setBranchNames(response.data);
                setBranchNamesLoading(false);
            } catch (error: any) {
                setError(error.title);
                setBranchNamesLoading(false);
            }
        }
    }, [dataFactoryId, setBranchNamesLoading, getBranchNames, setBranchNamesLoading]);

    const getPipelineNamesCallback = useCallback(async () => {
        if (dataFactoryId && branchName) {
            setNewPipelineName("");
            setError("");
            setNewPipelineNamesLoading(true);

            try {
                const response = await getNewPipelneNames(dataFactoryId, branchName);
                setNewPipelineNames(response.data);
                setNewPipelineNamesLoading(false);
            } catch (error: any) {
                setError(error.title);
                setNewPipelineNamesLoading(false);
            }
        }
    }, [dataFactoryId, branchName, setNewPipelineName, setNewPipelineNamesLoading, getNewPipelneNames]);

    useEffect(() => {
        if (dataFactoriesState[StateActionType.LoadAll] === "INIT") {
            dispatch(getAllDatafactories());
        }
        // cleanup on unmount
        return () => {
            dispatch(reset(StateActionType.Add));
        };
    }, []);

    useEffect(() => {
        getBranchesCallback();
    }, [dataFactoryId]);

    useEffect(() => {
        if (pipelineAddState[StateActionType.Add] === "ADDED") {
            setShowAnnouncement(true);
        }
    }, [pipelineAddState]);

    useEffect(() => {
        getPipelineNamesCallback();
    }, [dataFactoryId, branchName]);

    useEffect(() => {
        if (dataFactories && dataFactories.length > 0) {
            const gitEnabledDataFactories = dataFactories.filter((df) => df.isGitEnabled);
            setgitEnabledDataFactories(gitEnabledDataFactories);
        }
    }, [dataFactories]);

    return (
        <DialgContainer {...{ ...props }}>
            <CrudStatusDialog
                showDialog={showAnnouncment}
                title={`Successfully added ${newPipelineName}!`}
                onClose={() => {
                    dispatch(getAllPipelines());
                    setShowAnnouncement(false);
                    props.onClose();
                }}
            />

            <Stack verticalFill verticalAlign={"space-evenly"} horizontalAlign="stretch" style={{ padding: "0 20px" }}>
                <StackComboBox
                    name="Data Factory"
                    onChange={(option) => {
                        setBranchName("");
                        setDataFactoryId(option as number);
                    }}
                    options={gitEnabledDataFactories.map((df) => ({ key: df.id, text: df.name }))}
                    selectedKey={dataFactoryId}
                    loading={!dataFactoryId && dataFactoriesState[StateActionType.LoadAll] === "LOADING_ALL"}
                />
                <StackComboBox
                    name="Git Branch"
                    onChange={setBranchName}
                    options={branchNames.map((b) => ({ key: b, text: b }))}
                    selectedKey={branchName}
                    disabled={!dataFactoryId && branchNames.length === 0}
                    loading={!branchName && branchNamesLoading}
                />
                <StackComboBox
                    name="New Pipeline"
                    onChange={setNewPipelineName}
                    selectedKey={newPipelineName}
                    disabled={!branchName || newPipelineNamesLoading || newPipelineNames.length === 0}
                    options={newPipelineNames.map((b) => ({ key: b, text: b }))}
                    loading={!branchName && newPipelineNamesLoading}
                />
                <TooltipHost
                    content="If true, this means the pipeline produces feeds/datasets, if false it's a maintence pipeline."
                    id="isProducerTooltip"
                >
                    <Toggle
                        label="Producer"
                        checked={isProducer}
                        onText="True"
                        offText="False"
                        onChange={(_e, checked) => setIsProducer(checked as boolean)}
                        aria-describedby="isProducerTooltip"
                    />
                </TooltipHost>
            </Stack>

            {!newPipelineNamesLoading && branchName && newPipelineNames.length === 0 && (
                <MessageBar
                    isMultiline={false}
                    overflowButtonAriaLabel="See more"
                    dismissButtonAriaLabel="Close"
                    truncated={true}
                    onDismiss={() => {}}
                    dismissIconProps={{ iconName: "noop" }}
                    messageBarType={MessageBarType.warning}
                >
                    No new pipelines found in branch [{branchName}] for{" "}
                    {dataFactoryId && dataFactories && dataFactories.find((x) => x.id === dataFactoryId)!.name}
                </MessageBar>
            )}

            <DialogFooter>
                <PrimaryButton
                    text="Add"
                    disabled={!(dataFactoryId && branchName && newPipelineName)}
                    onClick={() => {
                        if (dataFactoryId)
                            dispatch(
                                addPipeline({
                                    dfId: dataFactoryId,
                                    pipelineName: newPipelineName,
                                    isProducer,
                                    gitBranch: branchName
                                })
                            );
                    }}
                />
                <DefaultButton text="Cancel" onClick={props.onClose} />
            </DialogFooter>
            {error && <Errorbar msg={error} />}
        </DialgContainer>
    );
}

export default AddPipeline;

import * as React from "react";
import { useHistory } from "react-router-dom";
import { CoherenceNav, INavLinkGroup, INavLink, INavStyles } from "@cseo/controls/lib/nav";
import { isInRole } from "../../service/authProvider";
import { PMUser } from "../../constants";
interface NavProps {
    onNavCollapsed: (isCollapsed: boolean) => void;
}
function Nav(props: NavProps) {
    const history = useHistory();
    // Gets the url currently navigated to, lets us know which view is currently selected, if any.
    const viewUrl = history.location.pathname.split("/")[1];

    const elmLinks: INavLinkGroup[] = [
        {
            key: "elm",
            links: [
                {
                    name: "ELM",
                    key: "ELM",
                    icon: "SecurityGroup",
                    ariaLabel: "ELM",

                    onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                        if (ev) {
                        }
                    },
                    links: [
                        {
                            name: "Access Packages",
                            key: "Access Packages",
                            ariaLabel: "Access Packages",
                            isSelected: viewUrl ? "accessPackages" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/accessPackages");
                                }
                            }
                        },
                        {
                            name: "Access Requests",
                            key: "Access Requests",
                            ariaLabel: "Access Requests",
                            isSelected: viewUrl ? "accessRequests" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/accessRequests");
                                }
                            }
                        },
                        {
                            name: "Assignments",
                            key: "Assignments",
                            ariaLabel: "Assignments",
                            isSelected: viewUrl ? "assignments" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/assignments");
                                }
                            }
                        },
                        {
                            name: "Assignment Clones",
                            key: "Clones",
                            ariaLabel: "Assignment Clones",
                            isSelected: viewUrl ? "assignmentClones" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/assignmentClones");
                                }
                            }
                        }
                    ]
                }
            ]
        }
    ];
    let navLinkGroups: INavLinkGroup[] = [
        {
            key: "group1",
            links: [
                {
                    name: "Home",
                    key: "Home",
                    icon: "Home",
                    ariaLabel: "Home",
                    isSelected: viewUrl.length === 0,
                    onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                        if (ev) {
                            ev.nativeEvent.preventDefault();
                            history.push("/");
                        }
                    }
                },
                {
                    name: "Configuration",
                    key: "Config",
                    icon: "ConfigurationSolid",
                    ariaLabel: "Configurations",
                    onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                        if (ev) {
                        }
                    },
                    links: [
                        {
                            name: "Data Factories",
                            key: "DataFactory",
                            ariaLabel: "Data Factories",
                            isSelected: viewUrl ? "datafactories" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/datafactories");
                                }
                            }
                        },
                        {
                            name: "Pipelines",
                            key: "Pipeline",
                            ariaLabel: "Pipelines",
                            isSelected: viewUrl ? "pipelines" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();

                                    history.push("/pipelines");
                                }
                            }
                        },
                        {
                            name: "Datasets",
                            key: "Datasets",
                            ariaLabel: "Datasets",
                            isSelected: viewUrl ? "datasets" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/datasets");
                                }
                            }
                        },
                        {
                            name: "Dataset Alert Summary",
                            key: "DatasetAlerts",
                            ariaLabel: "Dataset Alerts",
                            isSelected: viewUrl ? "datasetalerts" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/datasetalerts");
                                }
                            }
                        },
                        {
                            name: "Feeds",
                            key: "Feed",
                            ariaLabel: "Feeds",
                            isSelected: viewUrl ? "feeds" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/feeds");
                                }
                            }
                        },
                        {
                            name: "Feed Categories",
                            key: "FeedCategory",
                            ariaLabel: "Feed Categories",
                            isSelected: viewUrl ? "feedcategories" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/feedcategories");
                                }
                            }
                        },
                        {
                            name: "Data Sources",
                            key: "DataSources",
                            ariaLabel: "Data Sources",
                            isSelected: viewUrl ? "datasources" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/datasources");
                                }
                            }
                        },
                        {
                            name: "Azure Resources",
                            key: "AzureResources",
                            ariaLabel: "Azure Resources",
                            isSelected: viewUrl ? "azureResources" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/azureResources");
                                }
                            }
                        },
                        {
                            name: "Key Vault Secrets",
                            key: "KeyVaultSecrets",
                            ariaLabel: "Key Vault Secrets",
                            isSelected: viewUrl ? "keyVaultSecrets" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/keyVaultSecrets");
                                }
                            }
                        },
                        {
                            name: "Tag Internal Objects",
                            key: "TagInternalObjects",
                            ariaLabel: "Tag Internal Objects",
                            isSelected: viewUrl ? "tagInternalObjects" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/tagInternalObjects");
                                }
                            }
                        }
                    ]
                },
                {
                    name: "Reports",
                    key: "Reports",
                    icon: "PieDouble",
                    ariaLabel: "Reports",
                    onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                        if (ev) {
                        }
                    },
                    links: [
                        {
                            name: "Feed Freshness",
                            key: "Feed Freshness",
                            ariaLabel: "Feed Freshness",
                            isSelected: viewUrl ? "feedfreshness" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/feedfreshness");
                                }
                            }
                        },
                        {
                            name: "Directory Audit",
                            key: "DirectoryAudit",
                            ariaLabel: "Directory Audit",
                            isSelected: viewUrl ? "directoryAudit" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/directoryAudit");
                                }
                            }
                        },
                        {
                            name: "Platform Metrics",
                            key: "SizingData",
                            ariaLabel: "Size Data",
                            isSelected: viewUrl ? "sizingData" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/sizingMetrics");
                                }
                            }
                        },
                        {
                            name: "Dashboards",
                            key: "PowerBIReports",
                            ariaLabel: "PowerBIReports",
                            isSelected: viewUrl ? "powerBIReports" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/powerBIReports");
                                }
                            }
                        },
                        {
                            name: "DataCatalog Validation",
                            key: "dataCatalogValidation",
                            ariaLabel: "DataCatalog Validation",
                            isSelected: viewUrl ? "dataCatalogValidation" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/dataCatalogValidations");
                                }
                            }
                        },
                        {
                            name: "Quality Metrics Validation",
                            key: "dataQMValidation",
                            ariaLabel: "QualityMetricsValidation",
                            isSelected: viewUrl ? "QualityMetricsValidation" === viewUrl : false,
                            onClick: (ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                                if (ev) {
                                    ev.nativeEvent.preventDefault();
                                    history.push("/qualityMetricsValidations");
                                }
                            }
                        }
                    ]
                }
            ]
        }
    ];

    if (isInRole(PMUser)) {
        navLinkGroups = [...navLinkGroups, ...elmLinks];
    }

    const styles: Partial<INavStyles> = {
        navContainer: {}
    };

    return (
        <CoherenceNav
            appName={"Security Intelligence Platform Portal"}
            groups={navLinkGroups}
            onNavCollapsed={props.onNavCollapsed}
            styles={styles}
        ></CoherenceNav>
    );
}

export default Nav;

import React, { useEffect, useState } from "react";
import { DialgContainer } from "../common/DialogContainer";
import AssociatePipelineDialogProps from "../../models/dialogProps/AssociatePipelineDialogProps";
import {
    DefaultButton,
    DialogFooter,
    Dropdown,
    IComboBoxOption,
    PrimaryButton,
    ProgressIndicator
} from "@fluentui/react";
import { IComboBoxStyles } from "@fluentui/react";
import { activitiesSelector, dataFactorySelector, feedSelector, pipelineSelector } from "../../pages/Selectors";
import { IPipelineActivity, EmptyPipelineActivity } from "../../models/PipelineActivtiy";
import Errorbar from "../common/ErrorBar";
import { ActivityActionTypes } from "../../types/ActivityType";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { StateActionType } from "../../types";
import { getAllActivites, reset, updateActivity } from "../../reducers/Activities";
import { getAllDatafactories } from "../../reducers/DataFactory";
import { getAllPipelines } from "../../reducers/Pipelines";

interface IAssociatePipelineDiaolagState {
    datafactoryName?: string;
    datafactoryId?: number;
    pipelineId?: number;
    feedId: number;
    activityName?: string;
    activities: IPipelineActivity[];
}

const AssociatePipelineDialog: React.FC<AssociatePipelineDialogProps> = (props) => {
    const { feed, externalError, showDialog } = props;
    const initState: IAssociatePipelineDiaolagState = {
        datafactoryName: "",
        datafactoryId: -1,
        pipelineId: -1,
        feedId: feed.id || -1,
        activityName: "",
        activities: []
    };

    const activitiesProperties = useAppSelector(activitiesSelector);

    const _getInitOptions = (name: string): IComboBoxOption[] => [{ key: -1, text: `Select a ${name}` }];

    const rsActivityState = activitiesProperties.state;

    const dispatch = useAppDispatch();
    const [errorState, setErrorState] = useState<string>("");
    const { entities: dataFactories, state: dfState } = useAppSelector(dataFactorySelector);
    const { entities: pipelines, state: pipelineState } = useAppSelector(pipelineSelector);

    const [dialogState, setDialogState] = useState<IAssociatePipelineDiaolagState>(initState);
    const [dfOptions, setDfOptionsState] = useState<IComboBoxOption[]>(_getInitOptions("Data Factory"));
    const [pipelineOptions, setPipelineOptionsState] = useState<IComboBoxOption[]>(_getInitOptions("Pipeline"));
    const [activityOptions, setActivityOptionsState] = useState<IComboBoxOption[]>(_getInitOptions("Activity"));

    useEffect(() => {
        setErrorState(activitiesProperties.error || externalError || "");
    }, [activitiesProperties.error, externalError]);

    useEffect(() => {
        if (feed && feed.activityId && showDialog) {
            setDialogState({
                ...dialogState,
                feedId: feed.id,
                datafactoryId: feed.pipelineDatafactoryId,
                datafactoryName: feed.pipelineDatafactoryName,
                pipelineId: feed.pipelineId,
                activityName: feed.activityName,
                activities: dialogState.activities
            });
        } else {
            setDialogState(initState);
        }
    }, [showDialog]);

    useEffect(() => {
        return () => {
            // cleanup on unmount
            dispatch(reset(StateActionType.LoadAll));
        };
    }, []);

    useEffect(() => {
        if (dfState[StateActionType.LoadAll] === "LOADED_ALL" && dataFactories) {
            const tempOptions: IComboBoxOption[] = dataFactories
                .filter((df) => !df.isDeleted && df.isMonitoringEnabled)
                .map((df) => ({
                    key: df.id,
                    text: `(${df.platformName}) - ${df.name}`
                }));

            setDfOptionsState((state) => [state[0], ...tempOptions]);
        } else {
            dispatch(getAllDatafactories());
        }
    }, [dfState[StateActionType.LoadAll]]);

    useEffect(() => {
        // make sure we don't overwrite dialogState by checkin datafactoryid
        if (rsActivityState[StateActionType.LoadAll] === "LOADED_ALL" && dialogState.datafactoryId !== -1) {
            setDialogState({ ...dialogState, activities: activitiesProperties.entities || [] });
        }
    }, [rsActivityState]);

    useEffect(() => {
        if (dialogState.activities && dialogState.activities.length > 0) {
            const tempOptions: IComboBoxOption[] = dialogState.activities.map((a) => ({
                key: a.name,
                text: `(${a.type}) - ${a.name}`
            }));

            setActivityOptionsState((state) => [state[0], ...tempOptions]);
        }
    }, [dialogState.activities]);

    useEffect(() => {
        const { datafactoryId, pipelineId } = dialogState;

        if (datafactoryId && datafactoryId !== -1 && pipelineId && pipelineId !== -1) {
            dispatch(getAllActivites({ dfId: datafactoryId, pipelineId }));
        }
    }, [dialogState.pipelineId]);

    useEffect(() => {
        if (dialogState.datafactoryId === -1) {
            setPipelineOptionsState((state) => [state[0]]);
            return;
        }

        if (pipelineState[StateActionType.LoadAll] === "LOADED_ALL" && pipelines) {
            const tempOptions: IComboBoxOption[] = pipelines
                .filter((p) => p.dataFactoryName === dialogState.datafactoryName)
                .map((df) => ({
                    key: df.id,
                    text: `${df.name}`
                }));

            setPipelineOptionsState((state) => [state[0], ...tempOptions]);
        } else {
            dispatch(getAllPipelines());
        }
    }, [dialogState.datafactoryId, pipelineState[StateActionType.LoadAll]]);

    const _getDfName = React.useCallback(
        (dfId: number): string => {
            if (dfId === -1 || !dataFactories) return "";
            return dataFactories.filter((d) => d.id === dfId)[0].name;
        },
        [dataFactories]
    );
    const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: "500px" } };

    const _onSubmit = React.useCallback(() => {
        // create a copy since it;s readonly
        const act =
            ({ ...dialogState.activities.find((x) => x.name === dialogState.activityName) } as IPipelineActivity) ||
            EmptyPipelineActivity;

        if (act.feedIds && dialogState.feedId) {
            act.feedIds = [...act.feedIds, dialogState.feedId];
        } else {
            act.feedIds = [dialogState.feedId];
        }

        dispatch(updateActivity(act));
    }, [dialogState, dispatch, updateActivity]);

    return (
        <DialgContainer {...props}>
            <Dropdown
                label="Data Factory"
                placeholder="Select a Data Factory"
                selectedKey={dialogState.datafactoryId}
                options={dfOptions}
                onChange={(e, i) =>
                    setDialogState({
                        ...dialogState,
                        datafactoryId: +i!.key,
                        datafactoryName: _getDfName(+i!.key),
                        pipelineId: -1,
                        activities: [],
                        activityName: ""
                    })
                }
                styles={comboBoxStyles}
            />
            <Dropdown
                label="Pipeline"
                placeholder="Select a Pipeline"
                selectedKey={dialogState.pipelineId}
                options={pipelineOptions}
                disabled={dialogState.datafactoryId === -1}
                onChange={(_e, i) =>
                    setDialogState({ ...dialogState, pipelineId: +i!.key, activities: [], activityName: "" })
                }
                styles={comboBoxStyles}
            />
            <Dropdown
                label="Activites"
                placeholder="Select an Activity"
                selectedKey={dialogState.activityName}
                options={activityOptions}
                disabled={
                    dialogState.pipelineId === -1 ||
                    activitiesProperties.state[StateActionType.LoadAll] !== "LOADED_ALL"
                }
                onChange={(_e, i) => setDialogState({ ...dialogState, activityName: i!.key as string })}
                styles={comboBoxStyles}
            />
            {rsActivityState[StateActionType.LoadAll] !== "LOADED_ALL" &&
                !errorState &&
                dialogState.pipelineId !== -1 && <ProgressIndicator />}
            <DialogFooter>
                <PrimaryButton onClick={_onSubmit} disabled={dialogState.activityName === ""} text="Update" />
                <DefaultButton onClick={props.onClose} text="Cancel" />
            </DialogFooter>
            {errorState && <Errorbar msg={errorState} />}
        </DialgContainer>
    );
};

export default AssociatePipelineDialog;

import React from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";

interface IErrorBarProps {
    msg?: string;
}
function Errorbar(props: IErrorBarProps) {
    return (
        <div className="row">
            <div className="col">
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    truncated={true}
                    dismissButtonAriaLabel="Close"
                >
                    {props.msg ||
                        "Error loading data causing parts of the site to not load as expected, press refresh button to reload data"}
                </MessageBar>
            </div>
        </div>
    );
}

export default Errorbar;

import { useState } from "react";
import { Stack, IStackTokens, IconButton, ProgressIndicator } from "@fluentui/react";
import Errorbar from "../common/ErrorBar";
import { useHistory } from "react-router-dom";
import { validateAccessPackageAdd } from "../../api/AccessPackageApi";
import AccessPackageDialog from "./AccessPackageDialog";
import { AccessPackageAddValidationResponse, AccessPackageDto } from "../../models/Elm";
import { CrudProps } from "./Types";
import AddValidationDialog from "./AddValidationDialog";
import AccessPackageForm from "./AccessPackageForm";
import { AccessPackageFormMode } from "../../models/Elm/AccessPackageFormMode";
import { useAddAccessPackageMutation } from "../../reducers/AccessPackageApi";
import { handleError } from "./utils";
import { useTempError } from "../../hooks/UseTempError";

type ValidationState = {
    validationResponse?: AccessPackageAddValidationResponse;

    showDialog: boolean;
};

export function AddAccessPackage() {
    const history = useHistory();
    const [createAccessPackage] = useAddAccessPackageMutation();
    const emptyCrudProps: CrudProps = { title: "", children: <></> };
    const [error, setError] = useState("");
    const [crudProps, setCrudProps] = useState<CrudProps>(emptyCrudProps);
    const [validationState, setValidationState] = useState<ValidationState>({ showDialog: false });

    let timeout: NodeJS.Timeout;
    useTempError(error, setError);

    async function validateAccessPackage(accessPackageDto: AccessPackageDto) {
        try {
            setCrudProps({ ...crudProps, title: "Validating Request" });

            const { data } = await validateAccessPackageAdd(
                accessPackageDto.securityGroupId,
                accessPackageDto.catalogId,
                accessPackageDto.name
            );

            if (data.isValid) {
                await processAccessPackage(accessPackageDto);
            } else {
                setValidationState({ ...validationState, validationResponse: data, showDialog: true });
            }
        } catch (error) {
            setError(`There was an error validating the access package ${error}`);
        }
    }

    function closeValidationDialog() {
        setValidationState({ ...validationState, showDialog: false });
    }
    async function processAccessPackage(accessPackageDto: AccessPackageDto) {
        try {
            if (accessPackageDto) {
                setError("");
                setCrudProps({ ...crudProps, title: "Creating Access Package" });

                const res = await createAccessPackage(accessPackageDto);

                if ("error" in res) {
                    setCrudProps({ ...crudProps, title: "" });
                    handleError(new Error("There was an error creating the access package"), setError, res.error);
                    return;
                }

                setCrudProps((p) => ({ ...p, title: "Created Access Package Successfully" }));

                timeout = setTimeout(() => {
                    setCrudProps((p) => ({ ...p, title: "" }));
                    history.push(`/accessPackages/?catalogId=${accessPackageDto ? accessPackageDto.catalogId : ""}`);
                }, 2000);
            }
        } catch (error) {
            setCrudProps({ ...crudProps, title: "" });
            handleError(new Error("There was an error creating the access package"), setError, error);
        }
    }

    const containerStackTokens: IStackTokens = { childrenGap: 10 };

    return (
        <>
            <Stack styles={{ root: { marginLeft: 10 } }} tokens={containerStackTokens}>
                {error && <Errorbar msg={error} />}
                {validationState.validationResponse !== undefined && (
                    <AddValidationDialog
                        showDialog={validationState.showDialog}
                        validationResponse={validationState.validationResponse}
                        handleClick={() => {
                            setCrudProps((prev) => ({ ...emptyCrudProps }));
                            closeValidationDialog();
                        }}
                        onDismiss={() => {
                            setCrudProps((prev) => ({ ...emptyCrudProps }));
                            closeValidationDialog();
                        }}
                        onClose={() => closeValidationDialog()}
                    />
                )}
                <AccessPackageDialog
                    showDialog={crudProps.title !== ""}
                    title={crudProps.title}
                    onClose={() =>
                        crudProps.title === "Created Access Package Successfully"
                            ? history.push("/accessPackages")
                            : setCrudProps(emptyCrudProps)
                    }
                >
                    {crudProps.children}
                    <ProgressIndicator
                        progressHidden={crudProps.title === "Created Access Package Successfully" || error !== ""}
                    />
                </AccessPackageDialog>

                <Stack>
                    <Stack.Item align="end">
                        <IconButton
                            title="Close"
                            className="routing"
                            onClick={() => history.goBack()}
                            iconProps={{ iconName: "Cancel" }}
                            allowDisabledFocus
                        />
                    </Stack.Item>
                </Stack>

                <AccessPackageForm
                    onSave={validateAccessPackage}
                    mode={AccessPackageFormMode.Add}
                    setError={setError}
                />
            </Stack>
        </>
    );
}

export default AddAccessPackage;

import axios, { getHeaders } from "./SipMonClient";
import { ISipApiResponse, ISubscription } from "../models";
import { sliceName } from "../reducers/Subscription";
import { StateActionType } from "../types";


export const getSubscription = async (): Promise<ISipApiResponse<ISubscription[]>> => {
    const { data, status } = await axios.get<ISubscription[]>(
        `api/subscription`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return {
        data,
        message: "",
        status
    };
};



import axios, { getHeaders } from "./SipMonClient";
import { IFeedCategory } from "../models/FeedCategory";
import { ISipApiResponse } from "../models/SipApiResponse";
import { IContact } from "../models";
import { sliceName } from "../reducers/FeedCategories";
import { StateActionType } from "../types";

export const getFeedCategories = async (): Promise<ISipApiResponse<IFeedCategory[]>> => {
    const { data, status } = await axios.get<IFeedCategory[]>(
        `api/feedCategories`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

export const getFeedCategory = async (id: number): Promise<ISipApiResponse<IFeedCategory>> => {
    const { data, status } = await axios.get<IFeedCategory>(
        `api/feedCategories/${id}`,
        getHeaders(sliceName, StateActionType.LoadSingle)
    );
    return { data, message: "", status };
};

export const editFeedCategory = async (values: IFeedCategory): Promise<ISipApiResponse<IFeedCategory>> => {
    const id = values.id;
    if (id === 0) {
        const { data, status } = await axios.post<IFeedCategory>(
            `api/feedCategories`,
            values,
            getHeaders(sliceName, StateActionType.Add)
        );

        return { data, message: "", status };
    } else {
        const { data, status } = await axios.put<IFeedCategory>(
            `api/feedCategories/${id}`,
            values,
            getHeaders(sliceName, StateActionType.Update)
        );

        return { data, message: "", status };
    }
};

export type EditFeedCategoryContactsRequest = {
    feedCategory: IFeedCategory;
    owners: Record<string, IContact>;
};

export const editFeedCategoryContacts = async (request: IFeedCategory): Promise<ISipApiResponse<IFeedCategory>> => {
    {
        const { data, status } = await axios.put<IFeedCategory>(
            `api/feedCategories/editContacts`,
            request,
            getHeaders(sliceName, StateActionType.Update)
        );

        return { data, message: "", status };
    }
};

export const deleteFeedCategory = async (values: IFeedCategory): Promise<ISipApiResponse<IFeedCategory>> => {
    const id = values.id;
    const { data, status } = await axios.delete(
        `api/feedCategories/${id}`,
        getHeaders(sliceName, StateActionType.Delete)
    );

    return { data, message: "", status };
};

import axios, { getHeaders } from "./SipMonClient";
import { EmptyFeedGroup, IFeedGroup, ISipApiResponse } from "../models";
import { sliceName } from "../reducers/FeedGroups";
import { StateActionType } from "../types";

export const getFeedGroups = async (): Promise<ISipApiResponse<IFeedGroup[]>> => {
    const { data, status } = await axios.get(`api/feedGroups`, getHeaders(sliceName, StateActionType.LoadAll));
    return { data, message: "", status };
};

export const getFeedGroup = async (id: number): Promise<ISipApiResponse<IFeedGroup>> => {
    const { data, status } = await axios.get(`api/feedGroups/${id}`, getHeaders(sliceName, StateActionType.LoadSingle));
    return { data, message: "", status };
};

export const getFeedGroupUser = async (mail: string): Promise<ISipApiResponse<IFeedGroup[]>> => {
    const { data, status } = await axios.post<IFeedGroup[]>(
        `api/feedGroups/${mail}`,
        mail,
        getHeaders(sliceName, StateActionType.LoadAll));
    return { data, message: "", status };
};
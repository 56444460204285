import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInitialState, onComplete, onLoading, StateActionType } from "../types";
import { getAllRemoteServerConfigs, postAdministrationCommand } from "../api/RemoteServerConfigApi";

import { IRemoteServerAdministration, IRemoteServerConfig } from "../models";
import { getDefaultCases } from "./DefaultCases";

const sliceName = "remoteServerConfigs";

const getAllRemoteServerConfigDetails = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getAllRemoteServerConfigs();
    return response.data;
});

export const postAdministrationCmd = createAsyncThunk(`${sliceName}/get_postAdministrationCommand`, async (props: IRemoteServerAdministration[], _thunkAPI) => {
    const response = await postAdministrationCommand(props);
    return response.data;
});

const initialState = getInitialState<IRemoteServerConfig, IRemoteServerConfig>();

// Then, handle actions in your reducers:
const remoteServerConfigsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IRemoteServerConfig>(),
    extraReducers: (builder) => {
        builder.addCase(getAllRemoteServerConfigDetails.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllRemoteServerConfigDetails.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(postAdministrationCmd.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(postAdministrationCmd.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
    }
});

export const { reset, error, setCurrent } = remoteServerConfigsSlice.actions;
export { getAllRemoteServerConfigDetails, sliceName };
export const { reducer } = remoteServerConfigsSlice;

import { History } from "history";
import { AdminUser } from "../constants";

import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { _renderShimmer, addSortLabels } from "./utils";
import { IDatasetAlert } from "../models";
import { Icon, IconButton } from "@fluentui/react";

export function generateDatasetAlertColumns(history: History, invokeDeleteCallback: Function): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "type",
            name: "Type",
            fieldName: "type",
            filterBy: true,
            minWidth: 25,
            maxWidth: 50,
            isResizable: true
        },
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            filterBy: true,
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            isSorted: true
        },
        {
            key: "category",
            name: "Category",
            fieldName: "category",
            filterBy: true,
            minWidth: 75,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: "alertType",
            name: "Alert Type",
            fieldName: "alertType",
            filterBy: true,
            minWidth: 75,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: "createdBy",
            name: "Created By",
            fieldName: "createdBy",
            filterBy: true,
            minWidth: 150,
            maxWidth: 200,
            isResizable: true
        },
        {
            key: "lastUpdatedOn",
            name: "Last Updated",
            fieldName: "lastUpdated",
            filterBy: true,
            minWidth: 100,
            maxWidth: 350,
            isResizable: true,
            onRender: (item: IDatasetAlert) => {
                return item.lastUpdated ? item.lastUpdated.toString().split("T")[0] : "N/A";
            }
        },
        {
            key: "id",
            name: "Is Active",
            fieldName: "isSupressed",
            minWidth: 95,
            maxWidth: 100,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDatasetAlert) => renderIsActiveColumn(item, history)
        }
    ];

    if (isInRole(AdminUser)) {
        cols.push({
            key: "edit",
            name: "Edit",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDatasetAlert) => renderEditColumn(item, history)
        });
    }
    if (isInRole(AdminUser)) {
        cols.push({
            key: "delete",
            name: "Delete",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IDatasetAlert) => renderDeleteColumn(item, invokeDeleteCallback)
        });
    }

    addSortLabels(cols);
    return cols;
}

export function renderIsActiveColumn(item: IDatasetAlert, history: History) {
    return (
        <Icon
            title={!item.isSupressed ? "Active" : "In active"}
            iconName={!item.isSupressed ? "Ringer" : "RingerOff"}
            styles={{
                root: { alignContent: "center", color: item.isSupressed ? "gray" : "rgb(0, 100, 191)", fontSize: 18 }
            }}
        />
    );
}

export function renderEditColumn(item: IDatasetAlert, history: History) {
    if (item.name === "shimmer") {
        return _renderShimmer();
    }
    return (
        <IconButton
            title={item.name + " Edit Button"}
            className="routing"
            onClick={() => {
                history.replace(`/Alert/Edit${item.id ? "/" + item.id : ""}`);
            }}
            iconProps={{ iconName: "Edit" }}
            allowDisabledFocus
        />
    );
}

// Delete Function Callback
export function renderDeleteColumn(item: IDatasetAlert, invokeDeleteCallback: Function) {
    if (item.name === "shimmer") {
        return _renderShimmer();
    }
    return (
        <IconButton
            title={item.name + " Delete Button"}
            className="routing"
            onClick={() => {
                invokeDeleteCallback(item.id, item.datasetId);
            }}
            iconProps={{ iconName: "Delete" }}
            allowDisabledFocus
        />
    );
}

import axios, { getHeaders } from "./SipMonClient";
import { ISipApiResponse, IInternalObjectTagging } from "../models";
import { sliceName } from "../reducers/AddInternalObject";
import { StateActionType } from "../types";

export const getInternalObj = async (): Promise<ISipApiResponse<IInternalObjectTagging[]>> => {
    const { data, status } = await axios.get<IInternalObjectTagging[]>(
        `api/internalObjectTagging`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return {
        data,
        message: "",
        status
    };
};
export const addInternalObj = async (
    appId: string,
    objId: string,
    desc: string
): Promise<ISipApiResponse<IInternalObjectTagging>> => {
    const { data, status } = await axios.post<IInternalObjectTagging>(
        `api/internalObjectTagging`,
        { appId: appId, objectId: objId, description: desc },
        getHeaders(sliceName, StateActionType.Add)
    );
    return { data, message: "", status };
};

export const deleteInternalObj = async (values: IInternalObjectTagging): Promise<ISipApiResponse<IInternalObjectTagging>> => {
    const id = values.id;
    const { data, status } = await axios.delete(
        `api/internalObjectTagging/${id}`,
        getHeaders(sliceName, StateActionType.Delete)
    );

    return { data, message: "", status };
};
import { configureStore } from "@reduxjs/toolkit";

import {
    activitesReducer,
    contactsReducer,
    datafactoriesReducer,
    directoryAuditReducer,
    feedCategoryReducer,
    feedFreshnessReducer,
    feedGroupsReducer,
    feedsReducer,
    pipelinesReducer,
    azureResourceReducer,
    dataSourcesReducer,
    dataSourceContactsReducer,
    adlsCapacityTrendReducer,
    subscriptionReducer,
    remoteServerConfigsReducer,
    serverAdministrationsReducer,
    powerBIReportsReducer,
    datasetReducer,
    datasetAlertReducer,
    searchReducer,
    internalObjectTaggingReducer
} from "./reducers";

import { reducer as accessPackageReducer } from "./reducers/AccessPackages";

import { StateValues } from "./types";
import { feedbacksAPI, pageViewsAPI } from "./api/SIPFeatureApi";
import { accessPackagesApi } from "./reducers/AccessPackageApi";
import { graphElmApi } from "./reducers/GraphElmApi";
import { notificationsSlice } from "./reducers/NotificationsReducer";
import { validationApi } from "./components/DataValidation/api";
import { dQMValidationApi } from "./components/QualityMetricsValidation/api";
import { keyVaultApi } from "./reducers/KeyVaultApi";
import { assignmentApi } from "./reducers/AssignmentsApi";

const store = configureStore({
    reducer: {
        datafactories: datafactoriesReducer,
        activites: activitesReducer,
        pipelines: pipelinesReducer,
        feeds: feedsReducer,
        feedGroups: feedGroupsReducer,
        feedCategory: feedCategoryReducer,
        feedFreshness: feedFreshnessReducer,
        contacts: contactsReducer,
        directoryAudit: directoryAuditReducer,
        dataSources: dataSourcesReducer,
        dataSourceContacts: dataSourceContactsReducer,
        azureResources: azureResourceReducer,
        adlsCapacityTrend: adlsCapacityTrendReducer,
        subscription: subscriptionReducer,
        remoteServerConfigs: remoteServerConfigsReducer,
        servAdministrations: serverAdministrationsReducer,
        powerbiReports: powerBIReportsReducer,
        [feedbacksAPI.reducerPath]: feedbacksAPI.reducer,
        [pageViewsAPI.reducerPath]: pageViewsAPI.reducer,
        dataSet: datasetReducer,
        dataSetAlerts: datasetAlertReducer,
        accessPackages: accessPackageReducer,
        [accessPackagesApi.reducerPath]: accessPackagesApi.reducer,
        [graphElmApi.reducerPath]: graphElmApi.reducer,
        notifications: notificationsSlice.reducer,
        [validationApi.reducerPath]: validationApi.reducer,
        [dQMValidationApi.reducerPath]: dQMValidationApi.reducer,
        [keyVaultApi.reducerPath]: keyVaultApi.reducer,
        [assignmentApi.reducerPath]: assignmentApi.reducer,
        search: searchReducer,
        internalObjectTagging: internalObjectTaggingReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
            .concat(feedbacksAPI.middleware)
            .concat(pageViewsAPI.middleware)
            .concat(accessPackagesApi.middleware)
            .concat(graphElmApi.middleware)
            .concat(validationApi.middleware)
            .concat(dQMValidationApi.middleware)
            .concat(keyVaultApi.middleware)
            .concat(assignmentApi.middleware)
});

export type IApplicationState = ReturnType<typeof store.getState>;

export const isSuccessStates = (value: StateValues) => {
    switch (value) {
        case "LOADED_ALL":
        case "LOADED_SINGLE":
        case "UPDATED":
        case "ADDED":
        case "DELETED":
            return true;
        default:
            return false;
    }
};

export type AppDispatch = typeof store.dispatch;

export default store;

import axios from "./SipMonClient";
import { ISipApiResponse } from "../models";

export const getBranchNames = async (dataFactoryId: number): Promise<ISipApiResponse<string[]>> => {
    const { data, status } = await axios.get<string[]>(`api/git/branch/${dataFactoryId}`);
    return { data, message: "", status };
};
export const getNewPipelneNames = async (
    dataFactoryId: number,
    branchName: string
): Promise<ISipApiResponse<string[]>> => {
    const { data, status } = await axios.get<string[]>(`api/git/pipeline/${dataFactoryId}?branchName=${branchName}`);
    return { data, message: "", status };
};

import {
    Checkbox,
    createTheme,
    FontIcon,
    FontSizes,
    FontWeights,
    HoverCard,
    HoverCardType,
    IconButton,
    IPlainCardProps,
    ITheme,
    mergeStyleSets,
    ProgressIndicator,
    Separator,
    Stack,
    Text
} from "@fluentui/react";
import { History } from "history";
import { AccessPackageAssignmentPolicy, AccessPackageView } from "../models";
import { IStandardColumn } from "../models/StandardColumn";
import { addSortLabels, renderViewColumn } from "./utils";
import ToolTippedIconLink from "../components/common/ToolTippedIconLink";
import {
    MutationDefinition,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta
} from "@reduxjs/toolkit/dist/query";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";

type UseResetMigrationFailuresType = MutationTrigger<
    MutationDefinition<
        string,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
        "AccessPackages" | "AccessPackagesDto" | "PolicyDetails" | "MigrationStatus" | "AccessPackageInfo",
        void,
        "accessPackagesApi"
    >
>;
export function generateAccessPackageColumns(
    history: History,
    resetMigrationFailures: UseResetMigrationFailuresType
): IStandardColumn[] {
    const classNames = mergeStyleSets({
        compactCard: {
            padding: "16px 24px",
            minWidth: "300px"
        },
        item: {
            selectors: {
                "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer"
                }
            }
        }
    });

    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            filterBy: true,
            minWidth: 75,
            maxWidth: 250,
            isResizable: true,
            isSorted: true,
            onRender: (item: AccessPackageView) => (
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <Stack.Item>
                        <ToolTippedIconLink tooltipContent="View in Azure Portal" iconName="ELM" link={item.link} />
                    </Stack.Item>
                    <Stack.Item align="center">{item.name}</Stack.Item>
                </Stack>
            )
        },
        {
            key: "createdDateTime",
            name: "Created On",
            fieldName: "createdDateTime",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true,
            isDate: true,
            onRender: (item: AccessPackageView) => {
                const pstDateTime = new Date(item.createdDateTime).toLocaleString("en-US", {
                    timeZone: "America/Los_Angeles"
                });
                return pstDateTime;
            }
        },
        {
            key: "isMigrationInPro",
            name: "Migration Status",
            fieldName: "isMigrationInPro",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true,
            onRender: (item: AccessPackageView) => renderMigrationStatus(classNames, item, resetMigrationFailures)
        },
        {
            key: "isRoleGroup",
            name: "Role Group",
            fieldName: "isRoleGroup",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true,
            onRender: (item: AccessPackageView) => (
                <Stack horizontal horizontalAlign="center">
                    <Checkbox checked={item.isRoleGroup} />
                </Stack>
            )
        },
        {
            key: "description",
            name: "Description",
            fieldName: "description",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "resourceName",
            name: "Resource Name",
            fieldName: "resourceName",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "onBoarded",
            name: "Onboarded",
            fieldName: "onBoarded",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            onRender: (item: AccessPackageView) => (item.onboarded ? "Yes" : "No"),
            filterBy: true
        },
        {
            key: "approvers",
            name: "Approvers",
            fieldName: "approvers",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            filterBy: false,
            onRender: (accessPkg: AccessPackageView) => {
                const cardProps: IPlainCardProps = getCardProps(classNames, accessPkg);
                return (
                    <HoverCard plainCardProps={cardProps} instantOpenOnClick={true} type={HoverCardType.plain}>
                        <div className={classNames.item}>View</div>
                    </HoverCard>
                );
            }
        },
        {
            key: "id",
            name: "View",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: AccessPackageView) => renderViewColumn(item, "accessPackage", history)
        }
    ];

    addSortLabels(cols);
    return cols;
}
export function renderMigrationStatus(
    classNames,
    item: AccessPackageView,
    resetMigrationFailures: UseResetMigrationFailuresType
): JSX.Element | string {
    if (item.isMigrationInPro) {
        if (item.failureMessages && item.failureMessages.length > 0) {
            return (
                <Stack horizontalAlign="center">
                    <Stack.Item>
                        <HoverCard
                            plainCardProps={getFailureMessageCardProps(classNames, item, resetMigrationFailures)}
                            instantOpenOnClick={true}
                            type={HoverCardType.plain}
                        >
                            <IconButton iconProps={{ iconName: "Error" }} style={{ color: "red" }} />
                        </HoverCard>
                    </Stack.Item>
                    <Stack.Item>
                        {item.migrationCompletedCount}/{item.migrationTotalCount}
                    </Stack.Item>
                </Stack>
            );
        }
        return (
            <Stack horizontalAlign="center">
                <Stack.Item>
                    {item.migrationCompletedCount}/{item.migrationTotalCount}
                    <ProgressIndicator />
                </Stack.Item>
            </Stack>
        );
    } else if (!item.isMigration) {
        return "N/A";
    } else {
        return <FontIcon title="Migration Complete" style={{ fontSize: FontSizes.mediumPlus }} iconName="Completed" />;
    }
}
function getFailureMessageCardProps(
    classNames,
    accessPkg: AccessPackageView,
    resetMigrationFailures: UseResetMigrationFailuresType
): IPlainCardProps {
    return {
        onRenderPlainCard: (accessPkg: AccessPackageView) => (
            <div className={classNames.compactCard}>
                <IconButton
                    iconProps={{ iconName: "Refresh", title: "Retry Failed Migrations" }}
                    onClick={() => resetMigrationFailures(accessPkg.id)}
                />
                <Stack tokens={{ childrenGap: 12 }} key={accessPkg.id}>
                    {accessPkg.failureMessages?.map((message) => (
                        <Stack.Item key={message}>
                            <Text variant="medium">{message}</Text>
                        </Stack.Item>
                    ))}
                </Stack>
            </div>
        ),
        renderData: accessPkg
    };
}

function getCardProps(classNames, accessPkg: AccessPackageView): IPlainCardProps {
    return {
        onRenderPlainCard: (accessPkg: AccessPackageView) => (
            <div className={classNames.compactCard}>
                <Stack tokens={{ childrenGap: 12 }} key={accessPkg.id}>
                    {accessPkg.assignmentPolicies &&
                        [...accessPkg.assignmentPolicies]
                            .sort((a, b) => a.displayName.localeCompare(b.displayName))
                            .map((policy) => (
                                <ApproversCard key={policy.displayName} accessPkg={accessPkg} policy={policy} />
                            ))}
                </Stack>
            </div>
        ),
        renderData: accessPkg
    };
}

type ApproversCardProps = {
    accessPkg: AccessPackageView;
    policy: AccessPackageAssignmentPolicy;
};

function ApproversCard({ accessPkg, policy }: ApproversCardProps): JSX.Element {
    const theme: ITheme = createTheme({
        fonts: {
            medium: {
                fontSize: FontSizes.mediumPlus,
                fontWeight: FontWeights.semibold
            }
        }
    });
    return (
        <>
            <Stack.Item key={accessPkg.id + policy.displayName}>
                <Separator theme={theme}> {policy.displayName}</Separator>
            </Stack.Item>

            {policy.requestApprovalSettings.stages?.map((stage, ix) => (
                <Stack tokens={{ childrenGap: 5 }} key={`${accessPkg.id}_${ix}_stage`}>
                    <Stack.Item style={{ marginLeft: "10px" }} key={accessPkg.id}>
                        <Text style={{ fontWeight: "500" }} variant="medium">
                            Stage {ix + 1} Approver(s):
                        </Text>
                    </Stack.Item>
                    {stage.primaryApprovers.map((approver) => (
                        <Stack.Item style={{ marginLeft: "20px" }} key={approver.description + ix}>
                            <Text variant="medium">{approver.description}</Text>
                        </Stack.Item>
                    ))}
                </Stack>
            ))}
        </>
    );
}

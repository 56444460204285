import { BaseAction, StateValues } from ".";
import { IFeedCategory } from "../models/FeedCategory";

export enum FeedCategoryActionTypes {
    GETALL = "FEEDCATEGORY/GETALL",
    GETSINGLE = "FEEDCATEGORY/GETSINGLE",
    INIIALIZE = "FEEDCATEGORY/INIT",
    ADDFEEDCATEGORY = "FEEDCATEGORY/ADD",
    EDITFEEDCATEGORY = "FEEDCATEGORY/EDIT",
    DELETEFEEDCATEGORY = "FEEDCATEGORY/DELETE",
    SEARCHFEEDCATEGORY = "FEEDCATEGORY/SEARCH",
    LOADINGFEEDCATEGORY = "FEEDCATEGORY/LOADING",
    LOADINGERROR = "FEEDCATEGORY/ERROR",
    TESTFEEDCATEGORY = "FEEDCATEGORY/TEST"
}

interface IFeedCategoryTest extends BaseAction {
    type: typeof FeedCategoryActionTypes.TESTFEEDCATEGORY;
}

export interface IFeedCategorySearchAction extends BaseAction<string> {
    type: FeedCategoryActionTypes.SEARCHFEEDCATEGORY;
}

export interface IFeedCategoryGetAllAction extends BaseAction<IFeedCategory[]> {
    type: FeedCategoryActionTypes.GETALL;
}

export interface IFeedCategoryLoading extends BaseAction {
    type: FeedCategoryActionTypes.LOADINGFEEDCATEGORY;
}
export interface IFeedCategoryInit extends BaseAction {
    type: FeedCategoryActionTypes.INIIALIZE;
}

export interface IFeedCategoryGetSingleAction extends BaseAction<IFeedCategory> {
    type: FeedCategoryActionTypes.GETSINGLE;
}

export interface IFeedCategoryAddAction extends BaseAction<IFeedCategory> {
    type: FeedCategoryActionTypes.ADDFEEDCATEGORY;
}

export interface IFeedCategoryEditAction extends BaseAction<IFeedCategory> {
    type: FeedCategoryActionTypes.EDITFEEDCATEGORY;
}

export interface IFeedCategoryDeleteAction extends BaseAction<IFeedCategory> {
    type: FeedCategoryActionTypes.DELETEFEEDCATEGORY;
}

export interface IFeedCategoryError extends BaseAction<string> {
    type: FeedCategoryActionTypes.LOADINGERROR;
}

export type FeedCategoryActions =
    | IFeedCategoryGetAllAction
    | IFeedCategoryGetSingleAction
    | IFeedCategoryAddAction
    | IFeedCategoryEditAction
    | IFeedCategoryDeleteAction
    | IFeedCategoryInit
    | IFeedCategorySearchAction
    | IFeedCategoryLoading
    | IFeedCategoryError
    | IFeedCategoryTest;

export interface IFeedCategoryState {
    readonly currentFeedCategory: IFeedCategory | null;
    readonly feedCategories: IFeedCategory[];
    readonly feedCategoryLoading: boolean;
    readonly feedCategoryState: StateValues;
    readonly feedCategorySearch: string;
    readonly feedCategoryError: string;
}

import React, { useEffect } from "react";
import "../styles/App.css";
import { dataFactorySelector } from "./Selectors";
import StandardList from "../components/common/StandardList";
import { generateDatafactoryColumns } from "../columns";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { StateActionType } from "../types";
import { getAllDatafactories } from "../reducers/DataFactory";
import useSubscriptionData from "../hooks/useSubscriptionData";
import { Stack } from "@fluentui/react";
import PreferredSelect from "../components/common/PreferredSelect";

function DataFactoryList() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {
        entities: dataFactories,
        error: dataFactoriesError,
        state: dataFactoriesState
    } = useAppSelector(dataFactorySelector);
    const { subscriptionId, setSubscriptionId, subscriptionEntries, subscriptionError } = useSubscriptionData();
    useEffect(() => {
        document.title = "Summary of Data Factories - SIP Portal";
        if (dataFactoriesState[StateActionType.LoadAll] !== "LOADED_ALL") {
            dispatch(getAllDatafactories());
        }
    }, []);

    return (
        <StandardList
            entities={
                dataFactories &&
                dataFactories
                    .filter((e) => subscriptionId === undefined || e.subscriptionId === subscriptionId)
                    .map((e) => ({
                        ...e,
                        resourceStatus: e.isDeleted ? "Deleted" : "Active",
                        monitoringStatus: e.isMonitoringEnabled ? "Active" : "Disabled"
                    }))
            }
            title={"Data Factories"}
            columns={generateDatafactoryColumns(history)}
            error={dataFactoriesError || subscriptionError}
            entityState={dataFactoriesState[StateActionType.LoadAll]}
            extraHeaderItems={
                <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                    <PreferredSelect
                        title="Subscription"
                        entries={subscriptionEntries}
                        selectedEntryId={subscriptionId}
                        setEntryId={setSubscriptionId}
                    />
                </Stack>
            }
        />
    );
}
export default DataFactoryList;

import React, { useEffect, useState } from "react";
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, TextField } from "@fluentui/react";
import SaveButtonContainer from "../Dataset/SaveButtonContainer";

interface DismissModalProps {
    dismiss: (props: { id: number; comment: string }) => void;
    id: number;
}

const DismissModal: React.FC<DismissModalProps> = ({ dismiss, id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [comment, setComment] = useState("");

    useEffect(() => {
        if (!isModalOpen) {
            setComment("");
        }
    }, [isModalOpen]);

    const handleDismissClick = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <PrimaryButton text="Dismiss" onClick={handleDismissClick} />
            <Dialog
                hidden={!isModalOpen}
                onDismiss={() => setIsModalOpen(false)}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Enter a comment"
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } }
                }}
            >
                <TextField
                    multiline
                    rows={3}
                    value={comment}
                    onChange={(event, newValue) => setComment(newValue || "")}
                />
                <DialogFooter>
                    <SaveButtonContainer topPadding={0}>
                        <PrimaryButton
                            onClick={() => {
                                dismiss({ id, comment });
                                setIsModalOpen(false);
                            }}
                            text="Submit"
                        />
                        <DefaultButton onClick={() => setIsModalOpen(false)} text="Cancel" />
                    </SaveButtonContainer>
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default DismissModal;

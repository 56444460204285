import { History } from "history";
import { IStandardColumn } from "../models/StandardColumn";
import { addSortLabels } from "./utils";

export function generateDataSourceContactsColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "contactMethod",
            name: "Contact Method",
            fieldName: "Contactmethod",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: "value",
            name: "Value",
            fieldName: "Value",
            filterBy: true,
            minWidth: 275,
            maxWidth: 500,
            isResizable: true,
            isSorted: true
        }
    ];

    addSortLabels(cols);
    return cols;
}

import { History } from "history";
import { AdminUser, PMUser } from "../constants";
import { IFeed } from "../models";
import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { FlexIcon } from "../components/common/FlexIcon";
import { addSortLabels, renderViewColumn } from "./utils";
import { _renderShimmer } from "./getShimmerStyles";

function _renderStatus(feed: IFeed) {
    if (!feed.isDeprecated) {
        return <FlexIcon iconName="FabricSyncFolder" title="Feed Status Active" color="green" />;
    } else {
        return <FlexIcon iconName="FabricUnsyncFolder" title="Feed Status Deprecated" color="red" />;
    }
}

export function generateFeedColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 100,
            maxWidth: 250,
            isResizable: true,
            filterBy: true,
            isSorted: true
        },
        {
            key: "pipelineName",
            name: "Pipeline",
            fieldName: "pipelineName",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            filterBy: true,
            onRender: (feed: IFeed) => feed.activity?.pipelineName
        },
        {
            key: "platformName",
            name: "Platform",
            fieldName: "platformName",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            filterBy: true
        },
        {
            key: "path",
            name: "Path",
            fieldName: "path",
            minWidth: 100,
            maxWidth: 300,
            isResizable: true,
            filterBy: true
        },
        {
            key: "storageName",
            name: "Storage",
            fieldName: "storageName",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "feedCategoryName",
            name: "Category",
            fieldName: "feedCategoryName",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "slaInHours",
            name: "SLA",
            fieldName: "slaInHours",
            minWidth: 30,
            maxWidth: 30,
            isResizable: true
        },
        {
            key: "retentionDays",
            name: "Retention",
            fieldName: "retentionDays",
            minWidth: 65,
            maxWidth: 65,
            isResizable: true
        },
        {
            key: "isDeprecated",
            name: "Status",
            fieldName: "isDeprecated",
            minWidth: 65,
            maxWidth: 65,
            isResizable: true,
            onRender: (feed: IFeed) => _renderStatus(feed)
        }
    ];
    if (isInRole(PMUser)) {
        cols.push({
            key: "migrationStatus",
            name: "Migration Status",
            fieldName: "migrationStatus",
            minWidth: 65,
            maxWidth: 65,
            isResizable: true,
            onRender: (feed: IFeed) =>
                feed.storageType !== "ADLSGen2" ? (
                    "NA"
                ) : feed.accessPackageReferenceId !== null && !feed.accessPackageIsPlaceholder ? (
                    <FlexIcon iconName="ReminderGroup" title="Feed Migrated" color="green" />
                ) : (
                    <FlexIcon iconName="SecurityGroup" title="Feed Migrated" color="red" />
                ),
            sortFunction: (a: IFeed, b: IFeed, desc?: boolean) => {
                if (a.storageType !== "ADLSGen2") {
                    return desc ? 1 : -1;
                }
                if (b.storageType !== "ADLSGen2") {
                    return desc ? -1 : 1;
                }
                if (a.accessPackageReferenceId !== null && !a.accessPackageIsPlaceholder) {
                    return desc ? 1 : -1;
                }
                if (b.accessPackageReferenceId !== null && !b.accessPackageIsPlaceholder) {
                    return desc ? -1 : 1;
                }
                if (a.accessPackageReferenceId === null && b.accessPackageReferenceId === null) {
                    return 0;
                }
                if (a.accessPackageReferenceId === null) {
                    return desc ? 1 : -1;
                }
                if (b.accessPackageReferenceId === null) {
                    return desc ? -1 : 1;
                }
                return 0;
            }
        });
    }
    if (isInRole(AdminUser)) {
        cols.push({
            key: "id",
            name: "View",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IFeed) => renderViewColumn(item, "feeds", history)
        });
    }

    addSortLabels(cols);
    return cols;
}

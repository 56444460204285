import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import "../../styles/App.css";
import {
    ConstrainMode,
    DetailsList,
    IColumn,
    IDetailsColumnRenderTooltipProps,
    IDetailsHeaderProps,
    IDetailsList,
    SelectionMode,
    SearchBox,
    Stack,
    IRenderFunction,
    TooltipHost,
    Announced,
    IDetailsListStyleProps,
    IDetailsListStyles,
    IStyleFunctionOrObject,
    Shimmer,
    Label,
    Text
} from "@fluentui/react";

import { DetailsListLayoutMode, Selection } from "@fluentui/react/lib/DetailsList";
import { IStandardColumn, SortFunction } from "../../models/StandardColumn";
import Errorbar from "../../components/common/ErrorBar";
import { useWindowSize } from "../../hooks";
import { _renderShimmer, getShimmerStyles } from "../../columns/utils";
import { StateValues } from "../../types";
import { debounce, set } from "lodash";
import { searchSelector } from "../../pages/Selectors";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { setFilteredEntities, setSearchString } from "../../reducers/Search";

export type StandardEntity = {
    id: number | string;
    name: string;
};

export type StandardListProps<T extends StandardEntity> = {
    entities?: T[];
    title: string;
    columns: IStandardColumn[];
    error?: string;
    extraHeaderItems?: React.ReactNode;
    entityState: StateValues;
    selection?: (items: any) => any;
    canSelectItem?: (items: any) => boolean;
    selectionMode?: SelectionMode;
    customFilterFunctions?: {
        [key: string]: {
            function: (items: T[], filter: string) => T[] | Promise<T[]>;
            isAsync: boolean;
        };
    };
    getKey?: ((item: any, index?: number | undefined) => string) | undefined;
};

function StandardList<T extends StandardEntity = StandardEntity>({
    columns,
    entities,
    error,
    title,
    extraHeaderItems,
    getKey = (item) => item.id,
    entityState,
    selectionMode,
    selection,
    canSelectItem,
    customFilterFunctions
}: StandardListProps<T>) {
    const ref = useRef<HTMLDivElement>(null);
    const tableRef = useRef<IDetailsList>(null);
    const windowSize = useWindowSize();

    const [navBarHeight, setNavBarHeight] = useState<number>(0);
    const searchState = useAppSelector(searchSelector);
    const dispatch = useAppDispatch();
    const placeholders = Array(20)
        .fill(0)
        .map((_, i) => ({ id: i, name: "shimmer" } as T));

    const shimmerColumns = columns.map(
        (column) =>
            ({
                key: column.key,
                name: column.name,
                fieldName: column.fieldName,
                minWidth: 100,
                maxWidth: 200,
                isResizable: false,
                filterBy: false,
                onRender: () => _renderShimmer()
            } as IStandardColumn)
    );

    const [shimmering, setShimmering] = useState<boolean>(true);
    const [announcement, setAnnouncement] = useState<string>(`${title} loading`);
    const [searchAnnouncement, setSearchAnnouncement] = useState<string>();
    const [columnState, setColumnState] = useState<IStandardColumn[]>(shimmerColumns);

    function _copyAndSort<T>(
        items: T[],
        columnKey: string,
        isSortedDescending?: boolean,
        sortFunction?: SortFunction
    ): T[] {
        const key = columnKey as keyof T;

        const col = columnState.find((c) => c.key === columnKey);

        if (col && col.isDate) {
            return items.slice(0).sort((a: T, b: T) => {
                const dateA = new Date(a[key] as unknown as string);
                const dateB = new Date(b[key] as unknown as string);
                return isSortedDescending ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
            });
        }

        if (sortFunction) {
            return items.slice(0).sort((a: T, b: T) => sortFunction(a, b, isSortedDescending));
        }

        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    useEffect(() => {
        const nav = document.querySelector(`[role="banner"]`);

        const listHeader = document.querySelector(".ms-DetailsList-headerWrapper");

        if (ref.current && nav && listHeader) {
            setNavBarHeight(nav.clientHeight + listHeader.clientHeight + ref.current.clientHeight - 40);
        }
    }, [ref, windowSize]);

    useEffect(() => {
        if (entities && entityState === "LOADED_ALL") {
            if (entities.length === 0) {
                setEntities([]);
            }
            setAnnouncement(`Loaded ${entities.length} ${title}`);
        } else {
            setEntities(placeholders, true, true);
        }
    }, [entities, entityState]);

    useEffect(() => {
        if (error) {
            // you can stop shimmering now the data isn't coming
            setEntities([]);
        }
    }, [error]);

    function setEntities(entities: StandardEntity[], setColumns = true, isPlaceholder = false) {
        if (isPlaceholder) {
            setShimmering(true);
            setColumnState(shimmerColumns);
            dispatch(setFilteredEntities({ page: title, filteredEntities: entities }));
        } else {
            if (setColumns) {
                setColumnState(columns);
            }
            dispatch(setFilteredEntities({ page: title, filteredEntities: entities }));
            setShimmering(false);
        }
    }

    const debouncedSearch = useMemo(
        () =>
            debounce((searchString: string, entities: T[]) => {
                filterSearch(searchString, entities);
            }, 300),
        []
    );

    const debouncedOnSearch = useCallback(
        (searchString: string, entities: T[]) => {
            debouncedSearch(searchString, entities);
        },
        [debouncedSearch]
    );

    useEffect(() => {
        if (entities && entities.length > 0) debouncedOnSearch(searchState[title]?.searchString, entities);
    }, [searchState[title]?.searchString, entities]);

    const handleAsyncCustomFilterFunction = useCallback(
        debounce(async (customFilterFunction: (items: T[], filter: string) => T[] | Promise<T[]>, query: string) => {
            const filteredEntities = await customFilterFunction(entities!, query);
            setEntities(filteredEntities);
        }, 700),
        [entities]
    );
    function filterSearch(searchString: string, entities: T[]) {
        if (entities && entities.length > 0) {
            if (searchString && searchString.startsWith(":")) {
                const functionName = searchString.slice(1).split(" ")[0];
                const query = searchString.slice(1).split(" ")[1];

                if (query && query.length > 0) {
                    if (customFilterFunctions && customFilterFunctions[functionName]) {
                        if (customFilterFunctions[functionName].isAsync) {
                            handleAsyncCustomFilterFunction(customFilterFunctions[functionName].function, query);
                        } else {
                            const filteredEntities = customFilterFunctions[functionName].function(
                                entities,
                                query
                            ) as T[];
                            setEntities(filteredEntities);
                        }
                    }
                } else {
                    setEntities(entities);
                }
                return;
            }

            const filteredEntities = _applyFilters(searchString, entities);
            setEntities(filteredEntities);

            if (searchString) {
                setSearchAnnouncement(`Found ${filteredEntities.length} ${title} matching ${searchString}`);
            }
        }
    }
    function onSearchChange(_ev?: any, text?: string) {
        const value = !text ? "" : text;
        dispatch(setSearchString({ page: title, searchString: value }));
    }

    function _applyFilters(str: string, entities: T[]): T[] {
        if (!str) {
            return entities || [];
        }

        const filteredEntities = columns.reduce((acc: T[], column) => {
            if (column.filterBy && entities) {
                const filtered = entities.filter(
                    (e) =>
                        typeof e[column.key] === "string" &&
                        e[column.key] &&
                        e[column.key].toLowerCase().includes(str.toLowerCase()) &&
                        !acc.some((f) => f.id === e.id)
                );
                return [...acc, ...filtered];
            }
            return acc;
        }, []);

        return filteredEntities;
    }
    function _onColumnClick(column?: IStandardColumn): void {
        if (!column || column.name === "View") return;
        const newColumns: IStandardColumn[] = columns.slice();
        const currColumn: IStandardColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IStandardColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const key = column.sortKey || column.key;

        const newItems = _copyAndSort(
            searchState[title]?.filteredEntities,
            key,
            currColumn.isSortedDescending,
            currColumn.sortFunction
        );
        setEntities(newItems, false);
        setColumnState(newColumns);
    }

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
            <TooltipHost {...tooltipHostProps} />
        );

        return defaultRender!({
            ...props,
            onRenderColumnHeaderTooltip
        });
    };

    const gridStyles: IStyleFunctionOrObject<IDetailsListStyleProps, IDetailsListStyles> = {
        root: {
            overflowX: "auto",
            selectors: {
                "& [role=grid]": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%",
                    height: `calc(100vh - ${navBarHeight + 50 + "px"})`
                }
            }
        },
        headerWrapper: {
            flex: "0 0 auto"
        },
        contentWrapper: {
            flex: "1 1 auto",
            overflowY: "overlay",
            overflowX: "hidden"
        }
    };

    // this handles announcing blank information (accessibility)
    function _onRenderItemColumn(item?: T, index?: number, column?: IColumn): React.ReactNode {
        if (!item || !column) return null;

        if (item[column.key] || item[column.key] === 0) {
            return <div data-is-focusable="true">{item[column.key]}</div>;
        } else {
            return (
                <div data-is-focusable="true" className="sr-only">
                    Blank
                </div>
            );
        }
    }
    // Current Selection
    const dlSelection = new Selection({
        onSelectionChanged: () => {
            // sending selected items details to parent component
            selection && selection(dlSelection.getSelection());
        },
        canSelectItem: canSelectItem && canSelectItem
    });

    return (
        <>
            <div
                ref={ref}
                aria-label={`${title} List Header`}
                style={{
                    background: "white"
                }}
            >
                <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 5, padding: "0 10px" }}>
                    <Stack tokens={{ childrenGap: 1, padding: 10 }}>
                        <Stack.Item align="start">
                            <h2 style={{ margin: "2px" }}>{title}</h2>
                        </Stack.Item>
                        <Stack.Item align="start">
                            <SearchBox
                                ariaLabel="search box"
                                placeholder="Search"
                                onChange={onSearchChange}
                                value={searchState[title]?.searchString}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 5, padding: 10 }}>
                        <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                            <Label role="status">{title} Count:</Label>

                            <Shimmer
                                style={{ minWidth: 30, alignSelf: "center" }}
                                isDataLoaded={!shimmering}
                                styles={getShimmerStyles("")}
                            >
                                <Text>{searchState[title]?.filteredEntities?.length || 0}</Text>
                            </Shimmer>
                        </Stack>

                        {extraHeaderItems}
                    </Stack>
                </Stack>

                {error && <Errorbar msg={error} />}
            </div>
            <Announced id="LoadingAnnoucement" message={announcement} aria-live="assertive" />
            <Announced id="searchAnnoucement" message={searchAnnouncement} aria-live="assertive" />
            <div style={{ width: "100%" }}>
                <DetailsList
                    data-is-scrollable="true"
                    componentRef={tableRef}
                    ariaLabel={`${title} List`}
                    columns={columnState}
                    setKey={"StandardList"}
                    getKey={getKey}
                    items={searchState[title]?.filteredEntities || []}
                    styles={gridStyles}
                    layoutMode={DetailsListLayoutMode.justified}
                    constrainMode={ConstrainMode.unconstrained}
                    selection={dlSelection}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={selectionMode ? selectionMode : SelectionMode.none}
                    onRenderItemColumn={_onRenderItemColumn}
                    className="standardlist"
                    onColumnHeaderClick={(_, column) => _onColumnClick(column as IStandardColumn)}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                />
            </div>
        </>
    );
}

export default StandardList;

import type { Draft } from "@reduxjs/toolkit";
//@ts-ignore
import type { WritableDraft } from "immer/dist/types/types-external";
export * from "./ActivityType";
export * from "./ContactType";
export * from "./DataFactoryType";
export * from "./FeedCategoryType";
export * from "./FeedFreshnessType";
export * from "./FeedGroupType";
export * from "./FeedType";
export * from "./PipelineType";
export * from "./DirectoryAuditType";
export * from "./DataSourceType";
export * from "./AdlsCapacityTrend";
export * from "./Subscription";
export * from "./RemoteServerAdministrationType";

export type StateValues =
    | "INIT"
    | "LOADED_ALL"
    | "LOADING_ALL"
    | "LOADING_SINGLE"
    | "LOADED_SINGLE"
    | "ERROR"
    | "UPDATED"
    | "UPDATING"
    | "ADDING"
    | "ADDED"
    | "DELETING"
    | "DELETED";

export enum StateActionType {
    Add,
    Delete,
    Update,
    LoadAll,
    LoadSingle
}

export const intialStateMap: Record<StateActionType, StateValues> = {
    0: "INIT",
    1: "INIT",
    2: "INIT",
    3: "INIT",
    4: "INIT"
};

export type EntityState<TDetail, TSummery> = {
    readonly currentEntity: TDetail | null;
    readonly entities?: TSummery[];
    readonly state: Record<StateActionType, StateValues>;
    readonly error: string;
};

export function isLoading(stateValue: StateValues): boolean {
    const loadingValues = ["LOADING_ALL", "LOADING_SINGLE", "UPDATING", "ADDING", "DELETING"];

    return loadingValues.includes(stateValue);
}

export function getInitialState<TDetail, TSummery>(): EntityState<TDetail, TSummery> {
    return {
        currentEntity: null,
        entities: undefined,
        state: intialStateMap,
        error: ""
    };
}

export function onLoading<TDetail, TSummery>(
    state: WritableDraft<EntityState<TDetail, TSummery>>,
    type: StateActionType
): void {
    state.error = "";
    // create switch statement for each type
    switch (type) {
        case StateActionType.LoadAll:
            state.state[type] = "LOADING_ALL";
            break;
        case StateActionType.LoadSingle:
            state.state[type] = "LOADING_SINGLE";
            break;
        case StateActionType.Add:
            state.state[type] = "ADDING";
            break;
        case StateActionType.Delete:
            state.state[type] = "DELETING";
            break;
        case StateActionType.Update:
            state.state[type] = "UPDATING";
            break;
        default:
            break;
    }
}

export function onComplete<TDetail, TSummery>(
    state: WritableDraft<EntityState<TDetail, TSummery>>,
    type: StateActionType
): void {
    state.error = "";
    switch (type) {
        case StateActionType.LoadAll:
            state.state[type] = "LOADED_ALL";
            break;
        case StateActionType.LoadSingle:
            state.state[type] = "LOADED_SINGLE";
            break;
        case StateActionType.Add:
            state.state[type] = "ADDED";
            break;
        case StateActionType.Delete:
            state.state[type] = "DELETED";
            break;
        case StateActionType.Update:
            state.state[type] = "UPDATED";
            break;
        default:
            break;
    }
}

export type BaseAction<T = undefined> = {
    type: string;
} & (T extends undefined
    ? {}
    : {
          payload: T;
      });

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISubscription } from "../models";
import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import { getSubscription } from "../api/SubscriptionApi";

import { getDefaultCases } from "./DefaultCases";

const sliceName = "subscription";

const initialState = getInitialState<ISubscription, ISubscription>();

const getAllSubscriptions = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getSubscription();
    return response.data;
});

const subscriptionSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<ISubscription>(),
    extraReducers: (builder) => {
        builder.addCase(getAllSubscriptions.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllSubscriptions.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
    }
});

export const { reset, error, setCurrent } = subscriptionSlice.actions;
export { getAllSubscriptions, sliceName };
export const { reducer } = subscriptionSlice;

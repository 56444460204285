import React, { useState } from "react";
import { IFeedFreshness } from "../../../models/FeedFreshness";
import { SharedColors } from "@uifabric/fluent-theme";
import { ISliderProps } from "@fluentui/react";
import { DonutChart } from "@fluentui/react-charting";
import { ToolTippedSlider } from "../../common/ToolTippedSlider";
import DashboardItem from "../dashboard/DashboardItem";

export interface ITrendFilter {
    tolerance: number;
}

interface IExpectedRefreshIntervalDonutProps {
    feedFreshness: IFeedFreshness;
    refreshTimeStamps: Date[];
    href?: string;
}

function valueFormat(value: number, unit: string, expectedHours: number) {
    if (unit != "Minute") {
        return `${Math.floor(value * expectedHours!)}h${Math.floor(
            (value * expectedHours - Math.floor(value * expectedHours)) * 60
        )}m`;
    } else {
        return `${Math.floor(value * expectedHours! * 60)}m${Math.floor(
            (value * expectedHours * 60 - Math.floor(value * expectedHours * 60)) * 60
        )}s`;
    }
}

function ExpectedRefreshIntervalDonut({ feedFreshness, refreshTimeStamps, href }: IExpectedRefreshIntervalDonutProps) {
    const [tolerance, setTolerance] = useState({ tolerance: 0.01 });
    const toolTip = `This chart shows the proportion of ${feedFreshness.feedName}'s past refreshes that took less time than its Expected Refresh Interval (ERI).`;
    if (feedFreshness.expectedRefreshInterval) {
        let expectedHours: number = 0;

        switch (feedFreshness.expectedRefreshIntervalUnit) {
            case "Month":
                expectedHours = feedFreshness.expectedRefreshInterval! * 30 * 24;
                break;
            case "Week":
                expectedHours = feedFreshness.expectedRefreshInterval! * 7 * 24;
                break;
            case "Day":
                expectedHours = feedFreshness.expectedRefreshInterval! * 24;
                break;
            case "Hour":
                expectedHours = feedFreshness.expectedRefreshInterval!;
                break;
            case "Minute":
                expectedHours = feedFreshness.expectedRefreshInterval! / 60;
                break;
            default:
                expectedHours = 0;
        }

        let meetsERI: number = 0;
        let doesNotMeetERI: number = 0;

        let firstPointer: number = 0;
        let secondPointer: number = 1;

        while (secondPointer < refreshTimeStamps.length) {
            const moreRecentTime: Date = new Date(refreshTimeStamps[firstPointer]);
            const lessRecentTime: Date = new Date(refreshTimeStamps[secondPointer]);
            const intervalInHours = (moreRecentTime.getTime() - lessRecentTime.getTime()) / (1000 * 60 * 60);

            if (intervalInHours < (1 + tolerance.tolerance) * expectedHours) meetsERI++;
            else doesNotMeetERI++;

            firstPointer++;
            secondPointer++;
        }

        const eriLegend = "Times Refresh Met Expected Refresh Interval";
        const notEriLegend = "Times Refresh Did Not Meet Expected Refresh Interval";
        const points = [
            { legend: eriLegend, data: meetsERI, color: SharedColors.green20 },
            {
                legend: notEriLegend,
                data: doesNotMeetERI,
                color: SharedColors.red20
            }
        ];

        const data = { chartTitle: "History of Refreshes Over/Under ERI", chartData: points };

        const chart = <DonutChart data={data} innerRadius={50} href={href ? href : ""} />;

        const sliderToolTipsProps = {
            id: "eriToleranceSlider",
            content:
                "Feeds will be marked as meeting their Expected Refresh Interval if their Most Recent Refresh Interval is less than this percentage over their Expected Refresh Interval."
        };
        const sliderProps: ISliderProps = {
            min: 0,
            max: 0.25,
            step: 0.0025,
            ariaLabel: "Tolerance",
            value: tolerance.tolerance,

            onChanged: (event, value: number) => setTolerance({ ...tolerance, tolerance: value }),
            valueFormat: (value: number) =>
                valueFormat(value, feedFreshness.expectedRefreshIntervalUnit!, expectedHours),
            ariaValueText: (value: number) =>
                valueFormat(value, feedFreshness.expectedRefreshIntervalUnit!, expectedHours)
        };

        const child = (
            <div>
                {chart}
                <ToolTippedSlider label="Tolerance" toolTipsProps={sliderToolTipsProps} sliderProps={sliderProps} />
            </div>
        );

        return (
            <DashboardItem
                width="750px"
                label="History of Refreshes and Expected Refresh Interval"
                tooltip={toolTip}
                child={child}
            />
        );
    } else {
        let label = "";
        if (!feedFreshness.expectedRefreshInterval) {
            label = "Feed Not Mapped, Cannot Show ERI History";
        } else {
            label = "Error Showing ERI History";
        }
        return <DashboardItem width="700px" label={label} tooltip={toolTip} />;
    }
}

export default ExpectedRefreshIntervalDonut;

import axios, { getHeaders } from "./SipMonClient";
import { ISipApiResponse } from "../models/SipApiResponse";
import { StateActionType } from "../types";
import { IServerAdministrationHistory } from "../models";
import { sliceName } from "../reducers/ServerAdministration";

export const getServerAdministrationHistoriesById = async (id: number): Promise<ISipApiResponse<IServerAdministrationHistory[]>> => {
    const { data, status } = await axios.get(
        `api/remoteserveradministration/${id}`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};




import { History } from "history";
import { IconButton, Link } from "@fluentui/react";
import React from "react";
import store from "../store";

import { AdminUser } from "../constants";
import { IFeedCategory } from "../models";
import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { addSortLabels, renderViewColumn } from "./utils";
import { deleteFeedCategory, getAllFeedCategories } from "../reducers/FeedCategories";

export function generateFeedCategoryColumns(
    history: History,
    setAnnouncement: React.Dispatch<
        React.SetStateAction<{
            show: boolean;
            msg: string;
            onClose: () => void;
        }>
    >
): IStandardColumn[] {
    function _onRenderDeleteColumn(item: IFeedCategory) {
        return (
            <IconButton
                iconProps={{ iconName: "Delete" }}
                title="Delete"
                ariaLabel="Delete"
                onClick={() => _onClickDeleteFeedCategory(item)}
            />
        );
    }
    function _onClickDeleteFeedCategory(feedCategory: IFeedCategory): void {
        if (feedCategory.feedIds && feedCategory.feedIds.length > 0) {
            alert("Category is associated with one or more feeds");
        } else {
            setAnnouncement({
                show: true,
                msg: `Are you sure you want to delete ${feedCategory.name}?`,
                onClose: () => {
                    store.dispatch(deleteFeedCategory(feedCategory));
                    setAnnouncement({ show: false, msg: "", onClose: () => {} });
                }
            });
        }
    }
    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 50,
            maxWidth: 100,
            isSorted: true,
            isSortedDescending: false,
            isResizable: true,
            filterBy: true
        },
        {
            key: "description",
            name: "Description",
            fieldName: "description",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            filterBy: true
        },
        {
            key: "dataSourceOwner",
            name: "Data Source Owner",
            fieldName: "dataSourceOwner",
            minWidth: 75,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "dataBusinessOwner",
            name: "Data Business Owner",
            fieldName: "dataBusinessOwner",
            minWidth: 75,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "secondaryUseAccessApprover",
            name: "Secondary Use Access Approver",
            fieldName: "secondaryUseAccessApprover",
            minWidth: 75,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "securityUseAccessApprover",
            name: "Security Use Access Approver",
            fieldName: "securityUseAccessApprover",
            minWidth: 75,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "feedCount",
            name: "Feed Count",
            fieldName: "feedCount",
            minWidth: 75,
            maxWidth: 75,
            isResizable: true
        },
        {
            key: "sourceCardLink",
            name: "Source Card",
            fieldName: "sourceCardLink",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (feedCategory: IFeedCategory) => (
                <Link
                    style={{ color: feedCategory.sourceCardLink ? "inherit" : "#CA0000" }}
                    target="_blank"
                    href={feedCategory.sourceCardLink}
                    disabled={!feedCategory.sourceCardLink}
                >
                    {feedCategory.sourceCardLink ? "Link" : "Missing"}
                </Link>
            )
        }
    ];
    if (isInRole(AdminUser)) {
        cols.push({
            key: "View",
            name: "View",
            fieldName: "view",
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            isIconOnly: false,
            onRender: (item: IFeedCategory) => renderViewColumn(item, "feedcategories", history)
        });
        cols.push({
            key: "Delete",
            name: "Delete",
            fieldName: "delete",
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            isIconOnly: false,
            onRender: (item: IFeedCategory) => _onRenderDeleteColumn(item)
        });
    }
    addSortLabels(cols);
    return cols;
}

export interface IChangeHistory {
    commitId: string;
    committerName: string;
    committerEmail: string;
    committerDate: Date;
    committerImage: string;
    authorName: string;
    authorEmail: string;
    authorImage: string;
    authorDate: Date;
    comment: string;
    path: string;
    isFolder: boolean;
    url: string;
}

export const EmptyChangeHistory: IChangeHistory = {
    commitId: "",
    committerName: "",
    committerEmail: "",
    committerDate: new Date(),
    committerImage: "",
    authorName: "",
    authorEmail: "",
    authorDate: new Date(),
    comment: "",
    authorImage: "",
    path: "",
    isFolder: false,
    url: ""
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInitialState, onComplete, onLoading, StateActionType } from "../types";
import { getAzureResource, getAzureResources, editAzureResource } from "../api/AzureResourceApi";
import { getDefaultCases } from "./DefaultCases";
import { AzureResource } from "../models";

const sliceName = "azureResources";

const getAllAzureResources = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getAzureResources();
    return response.data;
});

const getAzureResourceById = createAsyncThunk(`${sliceName}/get_by_id`, async (id: number, _thunkAPI) => {
    const response = await getAzureResource(id);
    return response.data;
});

const updateAzureResource = createAsyncThunk(`${sliceName}/update`, async (azureResource: AzureResource, _thunkAPI) => {
    const response = await editAzureResource(azureResource);
    return response.data;
});

const initialState = getInitialState<AzureResource, AzureResource>();

// Then, handle actions in your reducers:
const azureResourcesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<AzureResource>(),
    extraReducers: (builder) => {
        builder.addCase(getAllAzureResources.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllAzureResources.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getAzureResourceById.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadSingle);
        });
        builder.addCase(getAzureResourceById.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.LoadSingle);
        });
        builder.addCase(updateAzureResource.pending, (state, _action) => {
            onLoading(state, StateActionType.Update);
        });
        builder.addCase(updateAzureResource.fulfilled, (state, action) => {
            state.currentEntity = action.payload;
            onComplete(state, StateActionType.Update);
        });
    }
});

export const { reset, error, setCurrent } = azureResourcesSlice.actions;
export { getAllAzureResources, getAzureResourceById, updateAzureResource, sliceName };
export const { reducer } = azureResourcesSlice;

import { IFeed } from "../models/Feeds";
import { BaseAction, StateValues } from ".";
export enum FeedActionTypes {
    GETALL = "FEED/GETALL",
    GETSINGLE = "FEED/GETSINGLE",
    INIIALIZE = "FEED/INIT",
    ADDFEED = "FEED/ADD",
    EDITFEED = "FEED/EDIT",
    EDITFEEDS = "FEED/EDITS",
    SEARCHFEED = "FEED/SEARCH",
    LOADINGFEED = "FEED/LOADING",
    LOADINGERROR = "FEED/ERROR",
    TESTFEED = "TEST/FEED"
}

// adding a test default action that calls the reducer default.
interface IFeedTest extends BaseAction {
    type: typeof FeedActionTypes.TESTFEED;
}

export interface IFeedError extends BaseAction<string> {
    type: FeedActionTypes.LOADINGERROR;
}

export interface IFeedSearchAction extends BaseAction<string> {
    type: FeedActionTypes.SEARCHFEED;
}
export interface IFeedGetAllAction extends BaseAction<IFeed[]> {
    type: FeedActionTypes.GETALL;
}

export interface IFeedLoading extends BaseAction {
    type: FeedActionTypes.LOADINGFEED;
}

export interface IFeedInit extends BaseAction {
    type: FeedActionTypes.INIIALIZE;
}

export interface IFeedGetSingleAction extends BaseAction<IFeed> {
    type: FeedActionTypes.GETSINGLE;
}

export interface IFeedAddAction extends BaseAction<IFeed> {
    type: FeedActionTypes.ADDFEED;
}

export interface IFeedEditAction extends BaseAction<IFeed> {
    type: FeedActionTypes.EDITFEED;
}

export interface IFeedsEditAction extends BaseAction<IFeed[]> {
    type: FeedActionTypes.EDITFEEDS;
}

export type FeedActions =
    | IFeedTest
    | IFeedGetAllAction
    | IFeedGetSingleAction
    | IFeedAddAction
    | IFeedEditAction
    | IFeedInit
    | IFeedSearchAction
    | IFeedLoading
    | IFeedError;

export interface IFeedState {
    readonly currentFeed: IFeed | null;
    readonly feeds: IFeed[];
    readonly feedLoading: boolean;
    readonly feedState: StateValues;
    readonly feedSearch: string;
    readonly feedError: string;
}

import {    
    Stack,
    PrimaryButton,
    IconButton,    
    Separator,
    TextFieldBase,
    Label
} from "@fluentui/react";
import Errorbar from "../../src/components/common/ErrorBar";
import React from "react";
import { EmptyInternalObjectTagging, IInternalObjectTagging } from "../../src/models";
import DialogContainerProps from "../../src/models/dialogProps/DialogContainerProps";
import { useAppDispatch, useAppSelector } from "../../src/hooks/ReduxHooks";
import CrudStatusDialog from "../../src/components/common/CrudStatusDialog";
import { useHistory } from "react-router-dom";
import { createInternalObject, reset } from "../../src/reducers/AddInternalObject";
import { internalObjectTaggingSelector } from "./Selectors";
import { useEffect, useState, useRef } from "react";
import StandardList from "../components/common/StandardList";
import { getInternalObjs } from "../reducers/AddInternalObject";
import { generateInternalObjectsColumns } from "../columns/InternalObjIdAppIdColumns";
import { StateActionType } from "../types";
import StandardForm from "../../src/components/common/StandardForm";
import { StackRow } from "../../src/components/common/StackComponents";
import SaveModal from "../../src/components/Dataset/SaveModal";
import { ShimmeredTextField } from "../../src/components/common/Shimmered";
interface IFormState extends IInternalObjectTagging {
    message: string;
    error: boolean;
}

function InternalObjectTagging(props: DialogContainerProps) {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const nameRef = useRef<TextFieldBase>(null);
    const [internalObjectTagging, setInternalObjectTagging] = useState<(IInternalObjectTagging)>(EmptyInternalObjectTagging);
    const [appId, setAppId] = React.useState<string>("");
    const [objId, setObjId] = React.useState<string>("");
    const [desc, setDesc] = React.useState<string>("");
    const { state, error } = useAppSelector(internalObjectTaggingSelector);
    const [annoucement, setAnnouncement] = useState<{ show: boolean; msg: string; onClose: () => void }>({
        show: false,
        msg: "",
        onClose: () => setshowAnnouncmentforDelete({ show: false, msg: "", onClose: () => { } })
    });

    const textfieldControlStyles = {
        root: {
            maxWidth: "300px"
        }
    };


    const {
        entities: internalObjEntity,
        error: internalObjEntityError,
        state: internalObjEntityState
    } = useAppSelector(internalObjectTaggingSelector);
    const [FormState, setFormState] = useState<IFormState>({
        id: 0,
        appId: internalObjectTagging.appId,
        objId: internalObjectTagging.objId,
        description: internalObjectTagging.description,
        message: "",
        sysLastModifiedTime: new Date(),
        name: "",
        error: false
    });
    const [showAnnouncmentforDelete, setshowAnnouncmentforDelete] = useState<{ show: boolean; msg: string; onClose: () => void }>({
        show: false,
        msg: "",
        onClose: () => setshowAnnouncmentforDelete({ show: false, msg: "", onClose: () => { } })
    });


    //capture value entered for appid
    function getAppId(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setAppId(newText);
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            } else setAppId("");
        }
    }
    function getObjId(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setObjId(newText);
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            } else setObjId("");
        }
    }
    function getDesc(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setDesc(newText);
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            } else setDesc("");
        }
    }

    useEffect(() => {       
        document.title = "Internal Objects - SIP Portal";
        if (internalObjEntityState[StateActionType.LoadAll] === "INIT") {
            dispatch(getInternalObjs());
        }
    }, []);

    useEffect(() => {
        if (internalObjEntityState[StateActionType.Delete] === "DELETED") {
            dispatch(getInternalObjs());
            setshowAnnouncmentforDelete({
                show: true,
                msg: "Internal Object successfully deleted",
                onClose: () => setshowAnnouncmentforDelete({ show: false, msg: "", onClose: () => { } })
            });
            dispatch(reset(StateActionType.Delete));
        }
    }, [internalObjEntityState[StateActionType.Delete]]);

    return (
        <StandardForm widthPercent={90} mediumWidthPercent={90} smallWidthPercent={90}>
            <SaveModal
                hidden={!annoucement.show}
                onClose={annoucement.onClose}
                successMsg={annoucement.msg}
                error={error}
                loading={state[StateActionType.Update] === "UPDATING"}
            />
            <Stack.Item align="end" styles={{ root: { marginBottom: "-50px" } }}>
                <IconButton
                    title="Close"
                    onClick={() => window.location.reload()}
                    iconProps={{ iconName: "Cancel" }}
                    allowDisabledFocus
                />
            </Stack.Item>
            <StackRow horizontalAlign="space-between" columnWidth={100}>
                <Label className="label">Tag Internal Object</Label>                      
                
            </StackRow>
            <Separator />
            {error && <Errorbar msg={error} />}
            <StackRow>
                <ShimmeredTextField
                        txtLabel="Application Id (Guid only)"
                        loading={state[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                        id="component"
                        componentRef={nameRef}
                        ariaLabel="Application Id (Guid only)"
                        styles={textfieldControlStyles}
                        required
                        onChange={getAppId}
                        value={appId}
                        className="name"
                        isStackItem
                    />
            </StackRow>
            <StackRow>
                <ShimmeredTextField
                    txtLabel="Object Id (Guid only)"
                    loading={state[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                    id="component"
                    componentRef={nameRef}
                    ariaLabel="Object Id (Guid only)"
                    styles={textfieldControlStyles}
                    required
                    onChange={getObjId}
                    value={objId}
                    className="name"
                    isStackItem
                />
            </StackRow>
            <StackRow>
                <ShimmeredTextField
                    txtLabel="Description"
                    loading={state[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                    id="component"
                    componentRef={nameRef}
                    ariaLabel="Description"
                    styles={textfieldControlStyles}
                    required
                    onChange={getDesc}
                    value={desc}
                    className="name"
                    isStackItem
                />
            </StackRow>
            <StackRow>
                <PrimaryButton
                    text="Save"
                    onClick={() => {
                        if (appId && objId && desc)      
                        {
                            dispatch(
                                createInternalObject({
                                    appId: appId,
                                    objectId: objId,
                                    description: desc
                                })
                            )
                            window.location.reload()
                        }
                        else
                            setAnnouncement({
                                show: true,
                                msg: "All fields are mandatory",
                                onClose: () => setAnnouncement({ show: false, msg: "", onClose: () =>{} })
                            });                     
                    }} ></PrimaryButton>         
                <PrimaryButton
                    text="Cancel"
                    onClick={() => window.location.reload()}
                ></PrimaryButton>    
            </StackRow>
            <Separator />
            <StackRow columnWidth={100}>
                <StandardList
                    entities={
                        internalObjEntity &&
                        internalObjEntity.map((e) => ({
                            ...e,
                            ids: e.ids ? e.ids.length : 0
                        }))
                    }
                    title={"Internal Object Ids"}
                    columns={generateInternalObjectsColumns(history, setshowAnnouncmentforDelete)}
                    error={internalObjEntityError}
                    entityState={internalObjEntityState[StateActionType.LoadAll]}
                    extraHeaderItems={
                        <>

                            <CrudStatusDialog
                                showDialog={showAnnouncmentforDelete.show}
                                title={showAnnouncmentforDelete.msg}
                                onClose={showAnnouncmentforDelete.onClose}
                                onDismiss={() => setshowAnnouncmentforDelete({ show: false, msg: "", onClose: () => { } })}
                            />
                        </>
                    }
                />

            </StackRow>
        </StandardForm>
       );
};
export default InternalObjectTagging;

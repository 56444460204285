import React, { useEffect } from "react";
import { useGetAssignmentClonesQuery } from "../../reducers/AssignmentsApi";
import { useGetCatalogsQuery } from "../../reducers/GraphElmApi";
import StandardForm from "../common/StandardForm";
import PreferredSelect from "../common/PreferredSelect";
import {
    Depths,
    DocumentCard,
    DocumentCardActivity,
    DocumentCardDetails,
    DocumentCardLogo,
    DocumentCardTitle,
    FontSizes,
    IDocumentCardActivityPerson,
    MessageBar,
    MessageBarType,
    Separator,
    Stack,
    getTheme
} from "@fluentui/react";
import { useHistory } from "react-router-dom";
import { getCloneStatus } from "./utils";
import { StackRow } from "../common/StackComponents";
import { LoadingOverLay } from "../common/LoadingOverLay";
import { getErrorMessage } from "../AccessPackages/utils";

const AssignmentClones = () => {
    const { data: catalogs, error: catalogError } = useGetCatalogsQuery(undefined, {
        pollingInterval: 3600000 // 1 hour
    });
    const [catalogId, setCatalogId] = React.useState<string>();
    const [error, setError] = React.useState<string>("");
    const {
        data: clones,
        isLoading,
        error: clonesError
    } = useGetAssignmentClonesQuery(catalogId, { skip: !catalogId });
    const history = useHistory();
    const theme = getTheme();

    useEffect(() => {
        if (catalogError) {
            const errorMessage = getErrorMessage(catalogError);
            setError(errorMessage);
        } else if (clonesError) {
            const errorMessage = getErrorMessage(clonesError);
            setError(errorMessage);
        } else {
            setError("");
        }
    }, [catalogError, clonesError]);

    return (
        <StandardForm widthPercent={90} mediumWidthPercent={90} smallWidthPercent={90}>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            <StackRow>
                <PreferredSelect
                    setEntryId={setCatalogId}
                    title="Catalog"
                    selectedEntryId={catalogId}
                    entries={catalogs}
                    disabled={false}
                />
            </StackRow>
            <Stack>
                <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
                    <LoadingOverLay isOverlayVisible={isLoading} />
                    {clones &&
                        clones.map((clone) => (
                            <DocumentCard
                                styles={{ root: { width: 350, height: 320, boxShadow: Depths.depth4 } }}
                                aria-label={`Clone ${clone.original} to ${clone.target}`}
                                onClick={() => {
                                    history.push(`/cloneStatusView/${clone.target}/${clone.original}/${catalogId}`);
                                }}
                            >
                                <DocumentCardLogo
                                    styles={{
                                        root: {
                                            backgroundColor: theme.palette.themeLight,
                                            textAlign: "center",
                                            margin: "auto 0",
                                            verticalAlign: "center"
                                        }
                                    }}
                                    logoIcon="Switch"
                                />
                                <DocumentCardDetails>
                                    <DocumentCardTitle
                                        styles={{ root: { fontSize: FontSizes.mediumPlus, height: "120px" } }}
                                        title={`Clone ${clone.original} to ${clone.target}`}
                                    />

                                    <DocumentCardTitle
                                        styles={{ root: { fontSize: FontSizes.small } }}
                                        title={`${clone.originalType} to ${clone.targetType}`}
                                        showAsSecondaryTitle
                                    />
                                    <Separator />
                                    <DocumentCardActivity
                                        activity={"Status: " + getCloneStatus(clone)}
                                        people={clone.status.map(
                                            (x): IDocumentCardActivityPerson => ({
                                                name: x.accessPackageName,
                                                profileImageSrc: ""
                                            })
                                        )}
                                    />
                                </DocumentCardDetails>
                            </DocumentCard>
                        ))}
                </Stack>
            </Stack>
        </StandardForm>
    );
};

export default AssignmentClones;

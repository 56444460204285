import { IPipelineActivity } from "../models/PipelineActivtiy";
import { BaseAction, StateValues } from ".";
export enum ActivityActionTypes {
    GETALL = "ACTIVITIES/GETALL",
    GETSINGLE = "ACTIVITIES/GETSINGLE",
    INIIALIZE = "ACTIVITIES/INIT",
    LOADING = "ACTIVITIES/LOADING",
    LOADINGERROR = "ACTIVITIES/ERROR",
    TESTACTIVITIES = "TEST/ACTIVITIES",
    UPDATING = "ACTIVITIES/UPDATING",
    EDIT = "ACTIVITIES/EDIT",
    EDITS = "ACTIVITIES/EDITS"
}

interface IActivitesTest extends BaseAction {
    type: ActivityActionTypes.TESTACTIVITIES;
}

export interface IActivitesError extends BaseAction<string> {
    type: ActivityActionTypes.LOADINGERROR;
}

export interface IActivitesGetAllAction extends BaseAction<IPipelineActivity[]> {
    type: ActivityActionTypes.GETALL;
    payload: IPipelineActivity[];
}

export interface IActivitesGetSingleAction extends BaseAction<IPipelineActivity> {
    type: ActivityActionTypes.GETSINGLE;
}

export interface IActivitesEditAction extends BaseAction<IPipelineActivity> {
    type: ActivityActionTypes.EDIT;
}

export interface IActivitesEditsAction extends BaseAction<IPipelineActivity[]> {
    type: ActivityActionTypes.EDITS;
}

export interface IActivitesUpdatingAction extends BaseAction {
    type: ActivityActionTypes.UPDATING;
}

export interface IActivitesLoading extends BaseAction {
    type: ActivityActionTypes.LOADING;
}

export interface IActivitesInit extends BaseAction {
    type: ActivityActionTypes.INIIALIZE;
}

export type ActivitesActions =
    | IActivitesTest
    | IActivitesGetAllAction
    | IActivitesGetSingleAction
    | IActivitesInit
    | IActivitesLoading
    | IActivitesError
    | IActivitesEditAction
    | IActivitesEditsAction
    | IActivitesUpdatingAction;

export interface IActivitesState {
    readonly currentActivity: IPipelineActivity | null;
    readonly activities: IPipelineActivity[];
    readonly activitiesLoading: boolean;
    readonly activitiesUpdate: "UPDATED" | "UPDATING" | "INIT" | "ERROR";
    readonly activitiesState: StateValues;
    readonly activitiesError: string;
}

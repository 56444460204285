import { History } from "history";
import { IconButton } from "@fluentui/react";
import { AdminUser } from "../constants";
import { IPipeline } from "../models";
import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { addSortLabels, renderViewColumn } from "./utils";

export function generatePipelineColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 100,
            maxWidth: 250,
            isSorted: true,
            isSortedDescending: false,
            isResizable: true,
            filterBy: true
        },
        {
            key: "dataFactoryName",
            name: "Datafactory",
            fieldName: "dataFactoryName",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            filterBy: true
        },
        {
            key: "platformName",
            name: "Platform",
            fieldName: "platformName",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            filterBy: true
        },
        {
            key: "notifications",
            name: "Notification Status",
            fieldName: "notifications",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: "sliceRelevanceType",
            name: "Slice Relevancy",
            fieldName: "sliceRelevanceType",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: "isIdempotent",
            name: "Idempotence",
            fieldName: "isIdempotent",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: "feedCount",
            name: "Feed Count",
            fieldName: "feedCount",
            minWidth: 75,
            maxWidth: 75,
            isResizable: true
        },
        {
            key: "tsgLink",
            name: "TSG",
            fieldName: "tsgLink",
            minWidth: 75,
            maxWidth: 75,
            isResizable: true,
            onRender: (item: IPipeline) => (
                <IconButton
                    title="TSG"
                    className="routing"
                    disabled={!item.tsgLink}
                    href={item.tsgLink}
                    iconProps={{ iconName: "ClassNotebookLogo16" }}
                    allowDisabledFocus
                    target="_blank"
                />
            )
        },
        {
            key: "datafactoryAuthorLink",
            name: "View in Datafactory",
            fieldName: "datafactoryAuthorLink",
            minWidth: 75,
            maxWidth: 75,
            isResizable: true,
            onRender: (item: IPipeline) => (
                <IconButton
                    title="View in Datafactory"
                    className="routing"
                    href={item.datafactoryAuthorLink}
                    iconProps={{
                        iconName:
                            item.datafactoryAuthorLink &&
                            item.datafactoryAuthorLink.startsWith("https://ms-adf.azure.com")
                                ? "ADF"
                                : "Synapse"
                    }}
                    allowDisabledFocus
                    target="_blank"
                />
            )
        }
    ];
    if (isInRole(AdminUser)) {
        cols.push({
            key: "Id",
            name: "View",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: IPipeline) => renderViewColumn(item, "pipelines", history)
        });
    }

    addSortLabels(cols);
    return cols;
}

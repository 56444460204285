import React, { useEffect } from "react";
import "../styles/App.css";
import { feedSelector, subscriptionSelector } from "./Selectors";
import StandardList, { StandardEntity } from "../components/common/StandardList";
import { generateFeedColumns } from "../columns";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { StateActionType } from "../types";
import { getAllFeeds } from "../reducers/Feeds";
import { filterFeedsByAcl } from "../api/FeedApi";
import { IFeed } from "../models";
import { isInRole } from "../service/authProvider";
import { PMUser } from "../constants";
import { Stack, Toggle } from "@fluentui/react";
import PreferredSelect from "../components/common/PreferredSelect";
import { getAllSubscriptions } from "../reducers/Subscription";
import useSubscriptionData from "../hooks/useSubscriptionData";

function FeedList() {
    const dispatch = useAppDispatch();
    const { subscriptionId, setSubscriptionId, subscriptionEntries, subscriptionError } = useSubscriptionData();

    const history = useHistory();
    const { entities: feeds, state: feedState, error: feedError } = useAppSelector(feedSelector);
    const [filter, setFilter] = React.useState<(feed: IFeed) => boolean>();
    useEffect(() => {
        document.title = "List of Feeds - SIP Portal";
        if (feedState[StateActionType.LoadAll] === "INIT") {
            dispatch(getAllFeeds());
        }
    }, []);

    function filterMigration(feed: IFeed) {
        if (!feed) {
            return false;
        }

        return feed.storageType === "ADLSGen2";
    }

    return (
        <StandardList
            entities={
                feeds &&
                feeds
                    .filter((f) => f.subscriptionId === subscriptionId || subscriptionId === undefined)
                    .filter((f) => !filter || filter(f))
                    .map((f) => ({
                        ...f,
                        retentionDays: f.isPurgeEnabled ? (f.retentionDays ? f.retentionDays + "d" : "") : "Inf",
                        slaInHours: f.slaInHours ? f.slaInHours + "h" : ""
                    }))
            }
            customFilterFunctions={{
                acl: {
                    function: async (items: StandardEntity[], filter: string) => {
                        const { data: feedIds } = await filterFeedsByAcl(filter);
                        return items.filter((f) => feedIds.includes(f.id as number));
                    },
                    isAsync: true
                }
            }}
            title={"Feeds"}
            entityState={feedState[StateActionType.LoadAll]}
            columns={generateFeedColumns(history)}
            extraHeaderItems={
                <>
                    {isInRole(PMUser) ? (
                        <Toggle
                            label="Migration"
                            onChange={(_e, checked) =>
                                checked ? setFilter(() => filterMigration) : setFilter(undefined)
                            }
                        />
                    ) : undefined}
                    <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                        <PreferredSelect
                            title="Subscription"
                            entries={subscriptionEntries}
                            selectedEntryId={subscriptionId}
                            setEntryId={setSubscriptionId}
                        />
                    </Stack>
                </>
            }
            error={feedError || subscriptionError}
        />
    );
}
export default FeedList;

export interface FeedDetail {
    id: number;
    name: string;
    isDeprecated: boolean;
    path: string;
    storageName: string;
    storageId: number;
    storageType: string;
    platformName: string;
    pipelineId?: number;
    pipelineName?: string;
    activityName?: string;
    activityId?: number;
    activityType?: string;
    pipelineDatafactoryId: number;
    pipelineDatafactoryName: string;
    slaInHours: number;
    feedCategoryId: number;
    feedCategoryName: string;
    feedCategories: { [key: string]: number };
    feedPartitions: { [key: string]: number };
    feedPartitionId: number;
    isDeltaLake: boolean;
    purgeId: number;
    purgeIsEnabled: boolean;
    purgeRetentionInDays: number;
    feedGroupAADObjectId: string | null;
    feedGroupADObjectId: string | null;
    feedGroupAADEmail: string | null;
    feedGroupADEmail: string | null;
    feedGroupAADName: string | null;
    feedGroupADName: string | null;
    datafactoryAuthorLink: string;
    updateFrequencyInHours: number | null;
    lastUpdated: Date;
    accessPackageIsPlaceholder: boolean;
}

// create empty feeddetail
export const EmptyFeedDetail: FeedDetail = {
    id: 0,
    name: "",
    isDeprecated: false,
    path: "",
    storageName: "",
    storageId: 0,
    platformName: "",
    pipelineId: 0,
    pipelineName: "",
    pipelineDatafactoryId: 0,
    pipelineDatafactoryName: "",
    slaInHours: 0,
    feedCategoryId: 0,
    feedCategoryName: "",
    feedCategories: {},
    feedPartitions: {},
    feedPartitionId: -1,
    isDeltaLake: false,
    purgeId: 0,
    purgeIsEnabled: false,
    purgeRetentionInDays: 0,
    feedGroupAADEmail: null,
    feedGroupADEmail: null,
    feedGroupAADName: null,
    feedGroupADName: null,
    feedGroupAADObjectId: null,
    feedGroupADObjectId: null,
    updateFrequencyInHours: null,
    lastUpdated: new Date(),
    datafactoryAuthorLink: "",
    storageType: "",
    accessPackageIsPlaceholder: false
};

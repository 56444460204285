import { History } from "history";
import { AdminUser } from "../constants";

import { IDataset } from "../models/Dataset";
import { IStandardColumn } from "../models/StandardColumn";
import { isInRole } from "../service/authProvider";
import { addSortLabels, renderViewColumn } from "./utils";
import { ValidationResult } from "../models/ValidationResult";

export function generateDataValidationColumns(history: History): IStandardColumn[] {
    const cols: IStandardColumn[] = [
        {
            key: "name",
            name: "Dataset Name",
            fieldName: "Name",
            filterBy: true,
            minWidth: 75,
            maxWidth: 350,
            isResizable: true,
            isSorted: true
        },
        {
            key: "endpoint",
            name: "Endpoint",
            fieldName: "endpoint",
            filterBy: true,
            minWidth: 275,
            maxWidth: 400,
            isResizable: true,
            isSorted: true
        },
        {
            key: "message",
            name: "Message",
            fieldName: "message",
            filterBy: true,
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
            isSorted: true
        },
        {
            key: "type",
            name: "Type",
            fieldName: "type",
            filterBy: true,
            minWidth: 75,
            maxWidth: 200,
            isResizable: true,
            isSorted: true
        },
        {
            key: "dismissedBy",
            name: "Dismissed By",
            fieldName: "dismissedBy",
            filterBy: true,
            minWidth: 75,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            onRender: (item: ValidationResult) => {
                return item.dismissedBy && item.dismissedOn
                    ? `${item.dismissedBy} (${new Date(item.dismissedOn).toLocaleString("en-US", {
                          timeZone: "America/Los_Angeles"
                      })})`
                    : "N/A";
            }
        }
    ];
    if (isInRole(AdminUser)) {
        cols.push({
            key: "id",
            name: "View/Edit",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isIconOnly: false,
            onRender: (item: ValidationResult) => renderViewColumn(item, "dataCatalogValidations", history)
        });
    }

    addSortLabels(cols);
    return cols;
}

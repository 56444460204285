import React, { useCallback } from "react";
import { Stack, Label, Shimmer, DefaultButton, TextField } from "@fluentui/react";
import { getShimmerStyles } from "../../../columns/utils";

interface LinkProps {
    label: string;
    loading: boolean;
    link: string;
    disabled?: boolean;
    onChange: (value: string) => void;
}

const ShimmeredLink: React.FC<LinkProps> = ({ link, label, loading, onChange, disabled }) => {
    const handleChange = useCallback(
        (_, newValue) => {
            onChange(newValue || "");
        },
        [onChange]
    );

    return (
        <Stack>
            <Label className="label">{label}</Label>
            <Shimmer isDataLoaded={!loading} ariaLabel={`Loading ${label}`} width="30%" styles={getShimmerStyles()}>
                <span className="input-group">
                    <div className="input-group-prepend">
                        <DefaultButton
                            style={{ borderRadius: "2px 0 0 2px" }}
                            href={link}
                            iconProps={{ iconName: "Send" }}
                            target="_blank"
                            title="Go"
                        />
                    </div>
                    <TextField
                        ariaLabel={label}
                        title={link}
                        styles={{ fieldGroup: { borderRadius: "0 2px 2px 0", borderLeft: "0" } }}
                        value={link}
                        disabled={disabled}
                        onChange={handleChange}
                    />
                </span>
            </Shimmer>
        </Stack>
    );
};

export default ShimmeredLink;

import axios, { getHeaders } from "./SipMonClient";
import { IDataSource } from "../models/DataSource";
import { ISipApiResponse } from "../models/SipApiResponse";
import { sliceName } from "../reducers/DataSource";
import { StateActionType } from "../types";
import { IDataSourceContact } from "../models";

export const getDataSources = async (): Promise<ISipApiResponse<IDataSource[]>> => {
    const { data, status } = await axios.get<IDataSource[]>(
        `api/datasources`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

export const getDataSourceContacts = async (id: number): Promise<ISipApiResponse<IDataSourceContact[]>> => {
    const { data, status } = await axios.get<IDataSourceContact[]>(
        `api/datasources/getcontactinfo/${id}`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return { data, message: "", status };
};

export const getDataSource = async (id: number): Promise<ISipApiResponse<IDataSource>> => {
    const { data, status } = await axios.get(
        `api/datasources/${id}`,
        getHeaders(sliceName, StateActionType.LoadSingle)
    );
    return { data, message: "", status };
};

export const editDataSource = async (values: IDataSource): Promise<ISipApiResponse<IDataSource>> => {
    const id = values.id;
    const { data, status } = await axios.put(
        `api/datasources/${id}`,
        values,
        getHeaders(sliceName, StateActionType.Update)
    );

    return { data, message: "", status };
};

export const createDataSource = async (values: IDataSource): Promise<ISipApiResponse<IDataSource>> => {
    const { data, status } = await axios.post(
        `api/datasources`,
        values,
        getHeaders(sliceName, StateActionType.Add)
    );

    return { data, message: "", status };
};

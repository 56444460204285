import { Text, Stack, FontIcon, Separator, DefaultEffects, mergeStyles, SearchBox } from "@fluentui/react";
import { FeedAcl, FeedPermission } from "../../models/FeedAcl";
import { useEffect, useRef, useState } from "react";

function AclList({ acls }: { acls: FeedAcl[] }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredAcls, setFilteredAcls] = useState(acls.sort(sortAcls));
    const [listHieight, setListHeight] = useState(0);
    const listRef = useRef<HTMLDivElement>(null);
    function getIconFromType(type: string) {
        switch (type) {
            case "#microsoft.graph.user":
                return <FontIcon iconName="azureUser" />;

            case "#microsoft.graph.servicePrincipal":
                return <FontIcon iconName="azureSpn" />;

            default:
                return <FontIcon iconName="azureSg" />;
        }
    }

    function sortAcls(a: FeedAcl, b: FeedAcl) {
        if (a.objectName === "other" || a.objectName === "mask") {
            return -1;
        } else if (b.objectName === "other" || b.objectName === "mask") {
            return 1;
        } else {
            return a.objectName.localeCompare(b.objectName);
        }
    }
    //  avoid the page jumping when the list gets filtered
    useEffect(() => {
        if (listRef.current) {
            setListHeight(listRef.current.offsetHeight);
        }
    }, []);
    useEffect(() => {
        if (searchTerm) {
            const filteredAcls = acls
                .filter(
                    (acl) =>
                        acl.objectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        acl.objectId.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .sort(sortAcls);
            setFilteredAcls(filteredAcls);
        } else {
            setFilteredAcls(acls);
        }
    }, [searchTerm]);

    const containerStyles = mergeStyles({
        width: "60%",
        background: "#fff",
        borderRadius: DefaultEffects.roundedCorner2,
        padding: "35px",
        boxSizing: "content-box",
        boxShadow: DefaultEffects.elevation4,
        "@media (max-width: 1000px)": {
            width: "95%"
        }
    });

    function getAclGroups(acl: FeedAcl) {
        const groupedPermissions: Record<string, FeedPermission[]> = acl.permissions.reduce((groups, permission) => {
            const scope = permission.scopes;
            if (!groups[scope]) {
                groups[scope] = [];
            }
            groups[scope].push(permission);
            return groups;
        }, {});

        return Object.entries(groupedPermissions);
    }
    return (
        <div style={{ minHeight: `${listHieight}px`, marginBottom: "50px" }} ref={listRef}>
            <Stack className={containerStyles} styles={{ root: { margin: "0 auto" } }} tokens={{ childrenGap: "10" }}>
                <Stack.Item align="center" style={{ width: "50%", marginBottom: "30px" }}>
                    <SearchBox
                        value={searchTerm}
                        onChange={(e) =>
                            e && e.target && e.target.value ? setSearchTerm(e.target.value) : setSearchTerm("")
                        }
                    />
                </Stack.Item>
                <Separator />
                {filteredAcls.map((acl) => (
                    <Stack key={acl.objectId}>
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: "10" }}>
                                    {getIconFromType(acl.objectType)} <Text>{acl.objectName}</Text>
                                </Stack>
                                {acl.objectName !== "mask" && acl.objectName !== "other" && (
                                    <Stack.Item style={{ marginLeft: "28px" }}>
                                        <Text variant="small">{acl.objectId}</Text>
                                    </Stack.Item>
                                )}
                            </Stack>
                            <Stack style={{ width: "25%" }} horizontalAlign="start">
                                {getAclGroups(acl).map(([scope, permissions]) => (
                                    <Stack
                                        key={acl.objectId + scope}
                                        horizontal
                                        horizontalAlign="start"
                                        tokens={{ childrenGap: "5" }}
                                    >
                                        <Text>{scope}:</Text>
                                        <Text>{permissions[0].action} </Text>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                        <Separator />
                    </Stack>
                ))}
            </Stack>
        </div>
    );
}

export default AclList;

import { render } from "react-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import { mergeStyles, loadTheme, registerIcons } from "@fluentui/react";
import "bootstrap/dist/css/bootstrap-grid.css";
import { CoherenceTheme } from "@cseo/styles";
import { authProvider } from "./service/authProvider";
import { ReactComponent as UserIcon } from "./images/User.svg";
import { ReactComponent as SecurityGroupIcon } from "./images/SecurityGroup.svg";
import { ReactComponent as SpnIcon } from "./images/Spn.svg";
import { ReactComponent as Synapse } from "./images/Synapse.svg";
import { ReactComponent as ADF } from "./images/ADF.svg";
import { ReactComponent as ELM } from "./images/ELM.svg";
import { ReactComponent as AAD } from "./images/AAD.svg";
import { ReactComponent as KeyVaults } from "./images/KeyVaults.svg";
mergeStyles({
    selectors: {
        ":global(body), :global(html), :global(#app)": {
            margin: 0,
            padding: 0,
            height: "100vh",
            backgroundColor: "#f8f8f8",
            color: "white"
        }
    }
});

loadTheme(CoherenceTheme);
registerIcons({
    icons: {
        azureUser: <UserIcon title="User" />,
        azureSg: <SecurityGroupIcon title="Security Group" />,
        azureSpn: <SpnIcon title="Spn" />,
        Synapse: <Synapse title="Synapse" />,
        ADF: <ADF title="Azure Data Factory" />,
        ELM: <ELM title="Entitlement management" />,
        AAD: <AAD title="Azure Active Directory" />,
        KeyVaults: <KeyVaults title="Azure Key Vault" />
    }
});

render(<App msalInstance={authProvider} />, document.getElementById("root"));

unregister();

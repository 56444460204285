import React from "react";
import { Dialog, DialogFooter, DialogType, PrimaryButton, Separator, Text, useTheme } from "@fluentui/react";
import SaveButtonContainer from "../Dataset/SaveButtonContainer";

type DialogProps = {
    errorMsg?: string;
    success?: boolean;
    show: boolean;
    title?: string
    message: string;
    onClose: () => void;    
};

const CrudStatusDialogV2: React.FC<DialogProps> = ({ errorMsg, success, message, show, onClose, title = "Update Status" }) => {
    const theme = useTheme();
    return (
        <Dialog
            hidden={!show}
            dialogContentProps={{
                styles: {
                    content: {
                        borderTop: `4px solid ${success ? theme.palette.green : theme.palette.red}`
                    },
                    subText: {
                        textAlign: "center"
                    },
                    title: {
                        textAlign: "center"
                    }
                },
                type: DialogType.largeHeader,
                title: title,
                subText: errorMsg || message,
                onDismiss: onClose
            }}
        >
            <Separator />

            <SaveButtonContainer topPadding={0}>
                <PrimaryButton text="Ok" onClick={onClose} />
            </SaveButtonContainer>
        </Dialog>
    );
};

export default CrudStatusDialogV2;

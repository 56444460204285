import React, { useCallback, useEffect } from "react";
import { AccessPackagePolicy, AccessPackagePolicyDetails } from "../../models";
import {
    ComboBox,
    Depths,
    FontSizes,
    ITheme,
    Label,
    Position,
    SpinButton,
    Spinner,
    Stack,
    getTheme
} from "@fluentui/react";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from "@fluentui/react-accordion";

const PolicyDetails = ({
    policyDetails,
    setPolicyDetails,
    loading
}: {
    policyDetails?: AccessPackagePolicyDetails;
    setPolicyDetails: React.Dispatch<React.SetStateAction<AccessPackagePolicyDetails | undefined>>;
    loading: boolean;
}) => {
    const theme: ITheme = getTheme();

    const handlePolicyChange = useCallback(
        (policy: AccessPackagePolicy, expirationType: string) => {
            if (policyDetails) {
                const accessPackagePolicies = policyDetails.accessPackagePolicies.map((p) =>
                    p.name === policy.name
                        ? {
                              ...p,
                              expirationType: expirationType,
                              expirationDuration: isExpirable(expirationType) ? 0 : p.expirationDuration
                          }
                        : p
                );
                setPolicyDetails({ ...policyDetails, accessPackagePolicies });
            }
        },
        [policyDetails, setPolicyDetails]
    );

    const handleDurationChange = useCallback(
        (policy: AccessPackagePolicy, duration: string) => {
            if (policyDetails) {
                const accessPackagePolicies = policyDetails.accessPackagePolicies.map((p) =>
                    p.name === policy.name
                        ? {
                              ...p,
                              expirationDuration: +duration
                          }
                        : p
                );
                setPolicyDetails({ ...policyDetails, accessPackagePolicies });
            }
        },
        [policyDetails, setPolicyDetails]
    );

    const getExpirationDisplayName = useCallback((expirationType: string) => {
        let formattedString = expirationType.charAt(0).toUpperCase();
        for (let i = 1; i < expirationType.length; i++) {
            if (expirationType.charAt(i) === expirationType.charAt(i).toUpperCase()) {
                formattedString += " ";
            }
            formattedString += expirationType.charAt(i);
        }
        return formattedString;
    }, []);

    return (
        <Accordion
            style={{
                background: !policyDetails ? theme.palette.neutralLight : theme.palette.white,
                boxShadow: Depths.depth4,
                padding: 10,
                marginBottom: 50
            }}
            multiple
            collapsible
        >
            <AccordionItem disabled={!policyDetails} value={"Policies"}>
                <AccordionHeader>
                    <Stack horizontal horizontalAlign="space-between" style={{ marginLeft: "10px", width: "100%" }}>
                        <Stack.Item>
                            <Label>Policies</Label>
                        </Stack.Item>

                        {loading && (
                            <Stack.Item align="center">
                                <Spinner />
                            </Stack.Item>
                        )}
                    </Stack>
                </AccordionHeader>
                <AccordionPanel>
                    {policyDetails &&
                        policyDetails.accessPackagePolicies &&
                        policyDetails.accessPackagePolicies.map((policy) => (
                            <Stack key={policy.name} horizontal tokens={{ childrenGap: 10 }}>
                                <Stack.Item style={{ width: "70%" }}>
                                    <ComboBox
                                        label={policy.name}
                                        styles={{ label: { fontSize: FontSizes.size12 } }}
                                        selectedKey={policyDetails.expirationTypeOptions.find(
                                            (x) => x === policy.expirationType
                                        )}
                                        onChange={(e, o) => o && handlePolicyChange(policy, o.key as string)}
                                        options={policyDetails.expirationTypeOptions.map((t) => ({
                                            key: t,
                                            text: getExpirationDisplayName(t)
                                        }))}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <SpinButton
                                        itemType="number"
                                        styles={{
                                            labelWrapper: { margin: "0" },
                                            label: { fontSize: FontSizes.size12, lineHeight: "unset" }
                                        }}
                                        labelPosition={Position.top}
                                        min={0}
                                        disabled={isExpirable(policy.expirationType)}
                                        label="Expiration"
                                        onChange={(e, v) => v && handleDurationChange(policy, v.replace(/\D/g, ""))}
                                        value={policy.expirationDuration.toString()}
                                    />
                                </Stack.Item>
                            </Stack>
                        ))}
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export { PolicyDetails };
function isExpirable(expirationType: string) {
    return expirationType === "noExpiration" || expirationType === "notSpecified";
}

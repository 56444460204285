import { IFeedGroup } from "../models/FeedGroup";
import { BaseAction, StateValues } from ".";
export enum FeedGroupActionTypes {
    LOADINGFEEDGROUP = "FEEDGROUP/LOADING",
    LOADINGERROR = "FEEDGROUP/ERROR",
    GETALL = "FEEDGROUP/GETALL",
    GETSINGLE = "FEEDGROUP/GETSINGLE",
    INIIALIZE = "FEEDGROUP/INIT"
}

export interface IFeedGroupGetAllAction extends BaseAction<IFeedGroup[]> {
    type: FeedGroupActionTypes.GETALL;
}

export interface IFeedGroupLoading extends BaseAction {
    type: FeedGroupActionTypes.LOADINGFEEDGROUP;
}

export interface IFeedGroupInit extends BaseAction {
    type: FeedGroupActionTypes.INIIALIZE;
}

export interface IFeedGroupGetSingleAction extends BaseAction<IFeedGroup> {
    type: FeedGroupActionTypes.GETSINGLE;
}

export interface IFeedGroupError extends BaseAction<string> {
    type: FeedGroupActionTypes.LOADINGERROR;
}

export type FeedGroupActions =
    | IFeedGroupGetAllAction
    | IFeedGroupLoading
    | IFeedGroupInit
    | IFeedGroupGetSingleAction
    | IFeedGroupError;

export interface IFeedGroupState {
    readonly currentFeedGroup: IFeedGroup | null;
    readonly feedGroups: IFeedGroup[];
    readonly feedGroupLoading: boolean;
    readonly feedGroupState: StateValues;
    readonly feedGroupSearch: string;
    readonly feedGroupError: string;
}

import axios, { getHeaders } from "./SipMonClient";
import { IContact } from "../models/Contact";
import { ISipApiResponse } from "../models/SipApiResponse";
import { sliceName } from "../reducers/Contacts";
import { StateActionType } from "../types";

export const getContacts = async (): Promise<ISipApiResponse<IContact[]>> => {
    const { data, status } = await axios.get<IContact[]>(
        `api/Contacts`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return {
        data,
        message: "",
        status
    };
};

export const getContact = async (id: number): Promise<ISipApiResponse<IContact>> => {
    const { data, status } = await axios.get<IContact>(
        `api/Contacts/${id}`,
        getHeaders(sliceName, StateActionType.LoadSingle)
    );
    return { data, message: "", status };
};

export const getContactByEmail = async (email: number): Promise<ISipApiResponse<IContact>> => {
    const { data, status } = await axios.get<IContact>(
        `api/Contacts/0?email=${email}`,
        getHeaders(sliceName, StateActionType.LoadSingle)
    );
    return { data, message: "", status };
};

export const editContact = async (values: IContact): Promise<ISipApiResponse<IContact>> => {
    const id = values.id;
    const { data, status } = await axios.put<IContact>(
        `api/Contacts/${id}`,
        values,
        getHeaders(sliceName, StateActionType.Update)
    );
    return { data, message: "", status };
};

export const addContact = async (contact: IContact): Promise<ISipApiResponse<IContact>> => {
    const { data, status } = await axios.post<IContact>(
        `api/Contacts`,
        contact,
        getHeaders(sliceName, StateActionType.Add)
    );
    return { data, message: "", status };
};

export const deleteContact = async (values: IContact): Promise<ISipApiResponse<IContact>> => {
    const id = values.id;
    const { data, status } = await axios.delete<IContact>(
        `api/Contacts/${id}`,
        getHeaders(sliceName, StateActionType.Delete)
    );
    return { data, message: "", status };
};

export type SearchType =
    | "All"
    | "AllGroups"
    | "AADGroups"
    | "ADGroups"
    | "Users"
    | "AllMailEnabledGroups"
    | "ServicePrincipals";

export const searchAAD = async (name: String, type: SearchType = "All"): Promise<ISipApiResponse<IContact[]>> => {
    const { data, status } = await axios.get<IContact[]>(`api/SearchAAD/${name}/?type=${type}`);
    return {
        data,
        message: "",
        status
    };
};

export const getGroupById = async (id: string): Promise<ISipApiResponse<IContact>> => {
    const { data, status } = await axios.get<IContact>(`api/SearchAAD/getGroupById/${id}`);
    return {
        data,
        message: "",
        status
    };
};

import React from "react";
import { StackRow } from "../common/StackComponents";
import { Stack } from "@fluentui/react";

const SaveButtonContainer = ({ children, topPadding }: { children: React.ReactNode; topPadding?: string | number }) => {
    return (
        <StackRow columnWidth={100}>
            <Stack
                style={{ paddingTop: topPadding ?? "40px" }}
                horizontal
                horizontalAlign="center"
                tokens={{ childrenGap: "10" }}
            >
                {children}
            </Stack>
        </StackRow>
    );
};

export default SaveButtonContainer;

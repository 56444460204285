import React from "react";
import { Stack, Text, mergeStyles } from "@fluentui/react";
import Background from "../images/Hero.png";
import { useLocation } from "react-router-dom";

const heroStyle = mergeStyles({
    backgroundImage: `url(${Background})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "white",
    height: "300px",
    marginBottom: "20px",
    selectors: {
        "@media(min-width: 576px)": {
            padding: "4rem 2rem"
        },
        "@media(max-width:576px)": {
            background: "none",
            backgroundColor: "#002060",
            padding: "2rem 2rem"
        }
    }
});

const Unauthorized = (props) => {
    type b = { roles: string[]; protectedPath: string };
    const { state } = useLocation();

    const { roles, protectedPath } = state as b;

    return (
        <Stack className={`${heroStyle} jumbotron`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <Stack.Item>
                            <Text style={{ color: "white" }} variant={"xxLarge"} className="protectedPath">
                                You are not authorized to view {protectedPath}
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text style={{ color: "white" }}>The following roles are required to view this page:</Text>
                        </Stack.Item>
                        <Stack.Item>
                            {roles &&
                                roles.map((role) => (
                                    <Stack.Item key={role}>
                                        <Text style={{ color: "white" }} className="role">
                                            {role}
                                        </Text>
                                    </Stack.Item>
                                ))}
                        </Stack.Item>
                    </div>
                </div>
            </div>
        </Stack>
    );
};

export default Unauthorized;

import { getAxiosErrorMessage } from "../../api/SipMonClient";
import { NotificationTypes } from "../../notifications/types";
import { getNotificationItem } from "../../notifications/utils";
import { addNotification } from "../../reducers/NotificationsReducer";
import { isErrorWithMessage, isFetchBaseQueryError } from "../../reducers/utils";
import store from "../../store";

type SetError = (error: string) => void;

export function getErrorMessage(error: any): string {
    if (isErrorWithMessage(error)) {
        return error.message;
    } else if (isFetchBaseQueryError(error)) {
        if ("error" in error) {
            return error.error;
        } else if (error.data) {
            if (typeof error.data === "string") {
                return error.data;
            } else if (typeof error.data === "object" && error.data != null && "message" in error.data) {
                return error.data.message as string;
            } else {
                return JSON.stringify(error.data);
            }
        } else {
            return `There was an error (error code: ${error.status})`;
        }
    } else {
        return "";
    }
}

export function handleError(customError: Error, setError: SetError, unknownError?: any) {
    console.log(unknownError);

    const axiosError = getAxiosErrorMessage(unknownError);
    const errorMessage = getErrorMessage(unknownError);

    if (axiosError) {
        store.dispatch(
            addNotification(
                getNotificationItem(NotificationTypes.Error, `${customError.message} : ${axiosError}`, "Error")
            )
        );
        setError(`${customError.message} : ${axiosError}`);
    } else if (errorMessage) {
        setError(errorMessage);
        store.dispatch(addNotification(getNotificationItem(NotificationTypes.Error, errorMessage, "Error")));
    } else {
        setError(customError.message);
        store.dispatch(addNotification(getNotificationItem(NotificationTypes.Error, customError.message, "Error")));
    }
}

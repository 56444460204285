import React, { useState, useEffect, useRef } from "react";
import { Depths } from "@uifabric/fluent-theme";
import "../../styles/App.css";

import {
    DetailsRow,
    IColumn,
    IDetailsRowProps,
    IDetailsRowStyles,
    SelectionMode,
    SearchBox,
    Stack,
    IconButton,
    IButton,
    TooltipHost,
    Icon,
    ProgressIndicator,
    Spinner,
    SpinnerSize,
    ShimmeredDetailsList,
    Announced
} from "@fluentui/react";

import { PipelineFeedDialog } from "../Pipeline/PipelineFeedDialog";
import dialogTableState from "../../models/dialogProps/PipelineFeedDialogProps";
import { useHistory } from "react-router-dom";
import { IFeedFreshness } from "../../models/FeedFreshness";
import { isInRole } from "../../service/authProvider";
import { AdminUser } from "../../constants";
import Errorbar from "../../components/common/ErrorBar";
import { feedFreshnessSelector } from "../../pages/Selectors";
import { ITolerance } from "../../pages/FeedFreshnessList";
import FeedFreshnessFilters, { IFeedFreshnessFilters } from "./FeedFreshnessFilters";
import { MessagingColors } from "@cseo/styles";
import { StateActionType } from "../../types";
import { useAppSelector } from "../../hooks/ReduxHooks";
import { addSortLabels } from "../../columns/utils";
import { IStandardColumn } from "../../models/StandardColumn";

interface RequestTableProps {
    feedFreshnesses: IFeedFreshness[];
    tableTitle: string;
    enableRouting: boolean;
    tolerance: ITolerance;
}

interface RequestTableState {
    SearchbyPath: string;
    canEditPipeline: boolean;
}

function FeedFreshnessTable(props: RequestTableProps) {
    const history = useHistory();
    const [dialogState, setShowDialog] = useState<dialogTableState>({
        feedId: 0,
        showDialog: false,

        onClose: () => _popUpClose()
    });
    const [tableState, setTableState] = useState<RequestTableState>({
        SearchbyPath: "",
        canEditPipeline: false
    });

    const [columns, setColumns] = useState<IColumn[]>([]);
    const [lastUpdated, setLastUpdated] = useState<string>("...Loading");
    const [filters, setFilters] = useState<IFeedFreshnessFilters>({
        expectedRefreshInterval_Met: false,
        expectedRefreshInterval_NotMet: false,
        mappedFeeds_Mapped: false,
        mappedFeeds_Unmapped: false,
        slaStatus_Met: false,
        slaStatus_NotMet: false,
        slaHighlight_On: false,
        dormantFeeds_True: false,
        dormantFeeds_False: false
    });
    const feedFreshnessProperties = useAppSelector(feedFreshnessSelector);

    const rsFeedFreshnessError = feedFreshnessProperties.error;
    const rsFeedFreshnessState = feedFreshnessProperties.state;
    const error = rsFeedFreshnessError !== "";

    const [feedFreshnessesVM, setFeedFreshnessesVM] = useState<IFeedFreshness[]>([]);
    const [searchAnnouncement, setSearchAnnouncement] = useState<string>();

    const currentRef = useRef<IButton>(null);

    useEffect(() => {
        setColumns(_generateColumns);
    }, []);

    useEffect(() => {
        if (props.feedFreshnesses && props.feedFreshnesses.length > 0) {
            const lastUpdate = new Date(
                props.feedFreshnesses
                    .map((f) => f.lastRefresh)
                    .sort()
                    .slice(-1)[0]
            ).toLocaleString("en-US", {
                timeZoneName: "short"
            });
            setLastUpdated(lastUpdate);
        }
    }, [props.feedFreshnesses]);

    useEffect(() => {
        const sortedCol = columns.filter((col) => col.isSorted)[0];
        if (sortedCol) {
            sortedCol.isSortedDescending = !sortedCol.isSortedDescending; // it gets flipped back to what it originally was in _onColumnClick
            _onColumnClick(undefined, sortedCol);
        } else {
            const filteredfeeds = _applyFilters();

            setFeedFreshnessesVM(filteredfeeds);
        }
    }, [props.feedFreshnesses, tableState.SearchbyPath, props.tolerance, filters]);

    useEffect(() => {
        if (currentRef.current) {
            currentRef.current.focus();
        }
    });

    function _popUpShow(id: number, feedFreshnesses: IFeedFreshness[]) {
        const updatedTableState = {
            ...dialogState,
            feedId: id,
            showDialog: true
        };
        setShowDialog(updatedTableState);
    }

    function _popUpClose() {
        const updatedTableState = {
            ...dialogState,
            showDialog: false
        };
        setShowDialog(updatedTableState);
    }
    function _generateColumns(): IStandardColumn[] {
        const cols: IStandardColumn[] = [
            {
                key: "feedName",
                name: "Feed Name",
                fieldName: "feedName",
                minWidth: 130,
                maxWidth: 130,
                isResizable: true,
                isSorted: true
            },
            {
                key: "storageAccountName",
                name: "Storage",
                fieldName: "storageAccountName",
                minWidth: 130,
                maxWidth: 130,
                isResizable: true
            },
            {
                key: "lastRefresh",
                name: "Last Refresh Timestamp",
                fieldName: "lastRefresh",
                minWidth: 175,
                maxWidth: 175,
                isResizable: true,
                onRenderHeader: (_item) => (
                    <TooltipHost content="A feed's Last Refresh Timestamp describes the most recent time and date the feed was refreshed.">
                        {"Last Refresh Timestamp "}
                        <Icon iconName="Info" />
                    </TooltipHost>
                )
            },
            {
                key: "expectedRefreshInterval",
                name: "Expected Refresh Interval",
                fieldName: "expectedRefreshInterval",
                minWidth: 185,
                maxWidth: 185,
                isResizable: true,
                onRenderHeader: (_item) => (
                    <TooltipHost
                        content="A feed's Expected Refresh Interval is determined by looking at the triggers of the pipeline mapped to it. 
                It is not necessarily the same as SLA. For unmapped feeds, this field will show 'Unknown'."
                    >
                        {"Expected Refresh Interval "}
                        <Icon iconName="Info" />
                    </TooltipHost>
                )
            },
            {
                key: "lastRefreshFrequency",
                name: "Most Recent Refresh Interval",
                fieldName: "lastRefreshFrequency",
                minWidth: 210,
                maxWidth: 210,
                isResizable: true,
                onRenderHeader: (_item) => (
                    <TooltipHost content="A feed's Most Recent Refresh Interval is the difference between its two most recent refresh timestamps. For feeds that have only been refreshed once, this field will show 'Not Enough Data'.">
                        {"Most Recent Refresh Interval "}
                        <Icon iconName="Info" />
                    </TooltipHost>
                )
            },
            {
                key: "averageRefreshFrequency",
                name: "Average Refresh Interval",
                fieldName: "averageRefreshFrequency",
                minWidth: 210,
                maxWidth: 210,
                isResizable: true,
                onRenderHeader: (_item) => (
                    <TooltipHost
                        content={
                            <>
                                A feed's Average Refresh Interval is the average difference between recent refresh
                                timestamps. For feeds that have only been refreshed once{" "}
                                <strong>or for feeds that are dormant</strong>, this field will show 'Not Enough Data'
                            </>
                        }
                    >
                        {"Average Refresh Interval "}
                        <Icon iconName="Info" />
                    </TooltipHost>
                )
            },
            {
                key: "slaThresholdInHours",
                name: "SLA",
                fieldName: "slaThresholdInHours",
                minWidth: 50,
                maxWidth: 50,
                isResizable: true,
                onRenderHeader: (_item) => (
                    <TooltipHost content="This field lists the Service Level Agreement that was decided with the customer. This does not represent how often the feeds are actually refreshed or should be refreshed.">
                        {"SLA "}
                        <Icon iconName="Info" />
                    </TooltipHost>
                )
            },
            {
                key: "timeOverSlaInHours",
                name: "Time Over SLA",
                fieldName: "timeOverSlaInHours",
                minWidth: 125,
                maxWidth: 125,
                isResizable: true,
                onRenderHeader: (_item) => (
                    <TooltipHost content="This field shows by how much time this feed is over its SLA (based on its latest refresh).">
                        {"Time Over SLA "}
                        <Icon iconName="Info" />
                    </TooltipHost>
                )
            }
        ];
        if (isInRole(AdminUser)) {
            cols.push({
                key: "Id",
                name: "View",
                fieldName: "id",
                minWidth: 50,
                maxWidth: 50,
                isResizable: true,
                isIconOnly: false,
                onRenderHeader: (_item) => (
                    <TooltipHost content="Click on the icon to see more information about the feed.">
                        {"View "}
                        <Icon iconName="Info" />
                    </TooltipHost>
                )
            });
        }
        cols.push({
            key: "trends",
            name: "Trends",
            fieldName: "trends",
            minWidth: 60,
            maxWidth: 60,
            isResizable: true,
            isIconOnly: false,
            onRenderHeader: (_item) => (
                <TooltipHost content="Click on the icon to see Feed Freshness trends specific to this feed.">
                    {"Trends "}
                    <Icon iconName="Info" />
                </TooltipHost>
            )
        });

        addSortLabels(cols);

        return cols;
    }

    function _onChangeSearchField(ev?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string): void {
        const value = !text ? "" : text;
        const updatedTableState = {
            ...tableState,
            SearchbyPath: value
        };

        setTableState(updatedTableState);
    }

    function _applyFilters() {
        let _feedFreshnesses = props.feedFreshnesses;
        _feedFreshnesses = _searchByName(_feedFreshnesses, tableState.SearchbyPath);
        _feedFreshnesses = _filterByMapped(_feedFreshnesses, filters);
        _feedFreshnesses = _filterByERI(_feedFreshnesses, filters, props.tolerance.eri_tolerance);
        _feedFreshnesses = _filterBySLA(_feedFreshnesses, filters);
        _feedFreshnesses = _filterByDormant(_feedFreshnesses, filters);
        return _feedFreshnesses;
    }

    function _searchByName(feedFreshnesses: IFeedFreshness[], text: string): IFeedFreshness[] {
        if (!text) {
            return feedFreshnesses;
        }

        const searchString = text.toLowerCase();

        return feedFreshnesses.filter((feedFreshness) => {
            return feedFreshness.feedName.toLowerCase().indexOf(searchString) > -1;
        });
    }

    function _filterByMapped(feedFreshnesses: IFeedFreshness[], filters: IFeedFreshnessFilters): IFeedFreshness[] {
        if (!filters.mappedFeeds_Mapped && !filters.mappedFeeds_Unmapped) return feedFreshnesses;

        return feedFreshnesses.filter((feedFreshness) => {
            if (filters.mappedFeeds_Mapped) {
                return feedFreshness.expectedRefreshInterval != -1;
            } else if (filters.mappedFeeds_Unmapped) {
                return feedFreshness.expectedRefreshInterval == -1;
            }
        });
    }

    function _filterByERI(
        feedFreshnesses: IFeedFreshness[],
        filters: IFeedFreshnessFilters,
        tolerance: number
    ): IFeedFreshness[] {
        if (!filters.expectedRefreshInterval_Met && !filters.expectedRefreshInterval_NotMet) return feedFreshnesses;

        return feedFreshnesses.filter((feedFreshness) => {
            let hours = 0;
            if (feedFreshness.expectedRefreshInterval && feedFreshness.expectedRefreshIntervalUnit) {
                hours =
                    feedFreshness.expectedRefreshInterval *
                    (feedFreshness.expectedRefreshIntervalUnit.includes("Minute")
                        ? 1 / 60
                        : feedFreshness.expectedRefreshIntervalUnit.includes("Hour")
                        ? 1
                        : feedFreshness.expectedRefreshIntervalUnit.includes("Day")
                        ? 24
                        : feedFreshness.expectedRefreshIntervalUnit.includes("Week")
                        ? 24 * 7
                        : feedFreshness.expectedRefreshIntervalUnit.includes("Month")
                        ? 24 * 30
                        : 1);
            }

            if (filters.expectedRefreshInterval_Met) {
                return (
                    feedFreshness.lastRefreshFrequency != -1 && // Not Enough Data
                    hours > 0 &&
                    hours * (1 + tolerance) >= feedFreshness.lastRefreshFrequency
                );
            } else if (filters.expectedRefreshInterval_NotMet) {
                return (
                    feedFreshness.lastRefreshFrequency != -1 && // Not Enough Data
                    hours > 0 &&
                    hours * (1 + tolerance) < feedFreshness.lastRefreshFrequency
                );
            }
        });
    }

    function _filterBySLA(feedFreshnesses: IFeedFreshness[], filters: IFeedFreshnessFilters): IFeedFreshness[] {
        if (!filters.slaStatus_Met && !filters.slaStatus_NotMet) return feedFreshnesses;

        return feedFreshnesses.filter((feedFreshness) => {
            const timeOverSlaInHours = feedFreshness.timeOverSlaInHours;
            if (filters.slaStatus_Met) {
                return (
                    timeOverSlaInHours <= props.tolerance.sla_tolerance * feedFreshness.slaThresholdInHours &&
                    !feedFreshness.isDormant
                );
            } else if (filters.slaStatus_NotMet) {
                return (
                    timeOverSlaInHours > props.tolerance.sla_tolerance * feedFreshness.slaThresholdInHours &&
                    !feedFreshness.isDormant
                );
            }
        });
    }

    function _filterByDormant(feedFreshnesses: IFeedFreshness[], filters: IFeedFreshnessFilters): IFeedFreshness[] {
        if (!filters.dormantFeeds_True && !filters.dormantFeeds_False) return feedFreshnesses;

        return feedFreshnesses.filter((feedFreshness) => {
            if (filters.dormantFeeds_True) {
                return feedFreshness.isDormant;
            } else if (filters.dormantFeeds_False) {
                return !feedFreshness.isDormant;
            }
        });
    }

    function _onColumnClick(ev?: React.MouseEvent<HTMLElement>, column?: IColumn): void {
        if (!column || column.fieldName === "id" || column.fieldName === "trends") return;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(_applyFilters(), currColumn.fieldName!, currColumn.isSortedDescending);

        if (tableState.SearchbyPath) {
            setSearchAnnouncement(`Found ${newItems.length} feeds matching ${tableState.SearchbyPath}`);
        }

        setFeedFreshnessesVM(newItems);
        setColumns(newColumns);
    }

    function _copyAndSort(items: IFeedFreshness[], columnKey: string, isSortedDescending?: boolean): IFeedFreshness[] {
        if (columnKey == "expectedRefreshInterval") {
            return items.slice(0).sort((a, b) => {
                const unitA = a.expectedRefreshIntervalUnit || "";
                const eriA = a.expectedRefreshInterval || 0;
                const minutesA =
                    eriA *
                    (unitA.includes("Minute")
                        ? 1
                        : unitA.includes("Hour")
                        ? 60
                        : unitA.includes("Day")
                        ? 60 * 24
                        : unitA.includes("Week")
                        ? 60 * 24 * 7
                        : unitA.includes("Month")
                        ? 60 * 24 * 30
                        : 1);
                const unitB = b.expectedRefreshIntervalUnit || "";
                const eriB = b.expectedRefreshInterval || 0;
                const minutesB =
                    eriB *
                    (unitB.includes("Minute")
                        ? 1
                        : unitB.includes("Hour")
                        ? 60
                        : unitB.includes("Day")
                        ? 60 * 24
                        : unitB.includes("Week")
                        ? 60 * 24 * 7
                        : unitB.includes("Month")
                        ? 60 * 24 * 30
                        : 1);

                return (isSortedDescending ? minutesA < minutesB : minutesA > minutesB) ? 1 : -1;
            });
        } else if (columnKey == "lastRefresh") {
            return items.slice(0).sort((a, b) => {
                return (
                    isSortedDescending
                        ? new Date(a.lastRefresh) < new Date(b.lastRefresh)
                        : new Date(a.lastRefresh) > new Date(b.lastRefresh)
                )
                    ? 1
                    : -1;
            });
        } else
            return items
                .slice(0)
                .sort((a, b) =>
                    (isSortedDescending ? a[columnKey] < b[columnKey] : a[columnKey] > b[columnKey]) ? 1 : -1
                );
    }

    function _handleTrendsClick(id: number): void {
        history.push(`trends/${id}`);
    }

    function _onRenderItemColumn(item: IFeedFreshness, _: number | undefined, column?: IColumn) {
        if (!column) {
            return <div data-is-focusable="true"></div>;
        }

        if (column.fieldName === "id") {
            const id = item.feedId;
            if (isInRole(AdminUser))
                if (props.enableRouting) {
                    if (feedFreshnessProperties.currentEntity && feedFreshnessProperties.currentEntity.feedId == id) {
                        return (
                            <IconButton
                                componentRef={currentRef}
                                title={item.feedName + " View Button"}
                                className="routing"
                                onClick={() => history.push(`feeds/${id}`)}
                                iconProps={{ iconName: "View" }}
                                allowDisabledFocus
                            />
                        );
                    }
                    return (
                        <IconButton
                            title={item.feedName + " View Button"}
                            className="routing"
                            onClick={() => history.push(`feeds/${id}`)}
                            iconProps={{ iconName: "View" }}
                            allowDisabledFocus
                        />
                    );
                } else {
                    return (
                        <IconButton
                            title={item.feedName + " View Button"}
                            onClick={() => _popUpShow(id, props.feedFreshnesses)}
                            iconProps={{ iconName: "View" }}
                            className="popup"
                        />
                    );
                }
        } else if (column.fieldName === "trends") {
            const id = item.feedId;
            if (isInRole(AdminUser))
                if (props.enableRouting) {
                    if (feedFreshnessProperties.currentEntity && feedFreshnessProperties.currentEntity.feedId == id) {
                        return history.location.pathname.indexOf(id.toString()) < 0 ? (
                            <IconButton
                                componentRef={currentRef}
                                title={item.feedName + " Trends Button"}
                                className="routing"
                                onClick={() => history.push(`trends/${id}`)}
                                iconProps={{ iconName: "Diagnostic" }}
                                allowDisabledFocus
                            />
                        ) : (
                            <Spinner size={SpinnerSize.small} />
                        );
                    }
                    return history.location.pathname.indexOf(id.toString()) < 0 ? (
                        <IconButton
                            title={item.feedName + " Trends Button"}
                            className="routing"
                            onClick={() => _handleTrendsClick(id)}
                            iconProps={{ iconName: "Diagnostic" }}
                            allowDisabledFocus
                        />
                    ) : (
                        <Spinner size={SpinnerSize.small} />
                    );
                } else {
                    return (
                        <IconButton
                            title={item.feedName + " Trends Button"}
                            onClick={() => _popUpShow(id, props.feedFreshnesses)}
                            iconProps={{ iconName: "Diagnostic" }}
                            className="popup"
                        />
                    );
                }
        } else if (column.fieldName === "expectedRefreshInterval") {
            const unit = item.expectedRefreshIntervalUnit || "";
            const eri = item.expectedRefreshInterval || 0;
            if (
                eri === -1 ||
                (unit !== "Month" && unit !== "Week" && unit !== "Day" && unit !== "Hour" && unit !== "Minute")
            ) {
                const fieldContent = (
                    <TooltipHost content="This feed is unmapped to a particular pipeline, so its Expected Refresh Interval currently cannot be determined.">
                        {"Unknown"}
                        <Icon iconName="Info" />
                    </TooltipHost>
                );
                return <div data-is-focusable="true">{fieldContent}</div>;
            }
            if (unit === "Minute") {
                const fieldContent = "0h " + eri + "m";
                return <div data-is-focusable="true">{fieldContent}</div>;
            }
            if (unit === "Hour") {
                const fieldContent = eri + "h " + " 0m";
                return <div data-is-focusable="true">{fieldContent}</div>;
            }
            if (unit === "Day") {
                const fieldContent = 24 * eri + "h 0m";
                return <div data-is-focusable="true">{fieldContent}</div>;
            }
            if (unit === "Week") {
                const fieldContent = 168 * eri + "h 0m";
                return <div data-is-focusable="true">{fieldContent}</div>;
            }
            if (unit === "Month") {
                const fieldContent = 730 * eri + "h 0m";
                return <div data-is-focusable="true">{fieldContent}</div>;
            }
        } else if (column.fieldName === "lastRefreshFrequency" || column.fieldName === "averageRefreshFrequency") {
            const interval = item[column.fieldName];
            const hrs = Math.floor(interval);
            const mins = Math.floor((interval - hrs) * 60);
            const fieldContent = hrs + "h " + mins + "m";

            if (interval === -1) {
                return <div data-is-focusable="true">{"Not Enough Data"}</div>;
            }
            return <div data-is-focusable="true">{fieldContent}</div>;
        } else if (column.fieldName === "slaThresholdInHours") {
            const fieldContent = item.slaThresholdInHours + "h";
            return <div data-is-focusable="true">{fieldContent}</div>;
        } else if (column.fieldName === "timeOverSlaInHours") {
            if (item.isDormant) {
                const fieldContent = (
                    <TooltipHost content="This feed has surpassed SLA by 10 days (by default) and is presumed to be inactive or no longer used.">
                        {"Dormant "}
                        <Icon iconName="Info" />
                    </TooltipHost>
                );
                return <div data-is-focusable="true">{fieldContent}</div>;
            } else if (item.timeOverSlaInHours === 0) {
                const fieldContent = "Not Yet Over SLA";
                return <div data-is-focusable="true">{fieldContent}</div>;
            } else {
                const time = item.timeOverSlaInHours;
                const hrs = Math.floor(time);
                const mins = Math.floor((time - hrs) * 60);
                const fieldText = hrs + "h " + mins + "m ";
                const fieldContent = filters.slaHighlight_On ? (
                    <div>
                        {fieldText}
                        <Icon iconName="Warning" />
                    </div>
                ) : (
                    fieldText
                );
                return <div data-is-focusable="true">{fieldContent}</div>;
            }
        } else if (column.fieldName == "lastRefresh") {
            return (
                <div data-is-focusable="true">
                    {new Date(item.lastRefresh).toLocaleString("en-US", {
                        timeZoneName: "short"
                    })}
                </div>
            );
        } else if (column.fieldName) {
            const fieldContent = item[column.fieldName] as string;
            return <div data-is-focusable="true">{fieldContent}</div>;
        }
    }

    function _onRenderRow(rowProps?: IDetailsRowProps) {
        const customStyles: Partial<IDetailsRowStyles> = {};

        if (rowProps) {
            const item = rowProps.item as IFeedFreshness;
            if (
                filters.slaHighlight_On &&
                item.timeOverSlaInHours > props.tolerance.sla_tolerance * item.slaThresholdInHours &&
                !item.isDormant
            ) {
                customStyles.root = { backgroundColor: MessagingColors.severeWarning, color: "black" };
            }
            return <DetailsRow {...rowProps} styles={customStyles} />;
        }
        return null;
    }

    return (
        <Stack
            style={{
                boxShadow: Depths.depth4,
                padding: "5px 5px 5px 20px",
                marginBottom: "5px",
                backgroundColor: "white"
            }}
        >
            <h2>{props.tableTitle}</h2>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-12">
                        <SearchBox
                            name="Search"
                            placeholder="Search"
                            onChange={_onChangeSearchField}
                            value={tableState.SearchbyPath}
                        />
                    </div>
                    <div className="col-lg-4 col-md-3">
                        <FeedFreshnessFilters filters={filters} setFilters={setFilters} />
                    </div>
                    <div className="col-lg-4 col-md-3">
                        <label>Table Last Updated: {lastUpdated} </label>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12">
                        <label className="feedCount" role="status">
                            Feed count: {feedFreshnessesVM.length}
                        </label>
                    </div>
                </div>
            </div>
            {error && <Errorbar />}
            {rsFeedFreshnessState[StateActionType.LoadAll] === "LOADING_ALL" && (
                <ProgressIndicator label="Loading Feed Freshness Data..." />
            )}
            <Announced id="searchAnnoucement" message={searchAnnouncement} aria-live="assertive" />

            <ShimmeredDetailsList
                ariaLabel="Feed Freshness List"
                columns={columns}
                items={feedFreshnessesVM}
                onRenderItemColumn={_onRenderItemColumn}
                onRenderRow={_onRenderRow}
                selectionMode={SelectionMode.none}
                className="feedfreshnesslist"
                onColumnHeaderClick={_onColumnClick}
                enableShimmer={rsFeedFreshnessState[StateActionType.LoadAll] === "LOADING_ALL"}
            />
            <PipelineFeedDialog {...tableState} {...dialogState} />
        </Stack>
    );
}

export default FeedFreshnessTable;

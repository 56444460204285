import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Stack, Label, Toggle, PrimaryButton, TextFieldBase, IconButton, Separator } from "@fluentui/react";
import "../../styles/App.css";
import { IDataFactory } from "../../models/DataFactory";
import { dataFactorySelector } from "../../pages/Selectors";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { getAllDatafactories, getDatafactoryById, reset, updateDatafactory } from "../../reducers/DataFactory";
import { StateActionType } from "../../types";
import { useHistory, useParams } from "react-router-dom";
import StandardForm from "../common/StandardForm";
import SaveModal from "../Dataset/SaveModal";
import { StackRow } from "../common/StackComponents";
import { ShimmeredText, ShimmeredTextField, ShimmeredToggle } from "../common/Shimmered";

const textfieldControlStyles = {
    root: {
        maxWidth: "300px"
    }
};

interface IFormState extends IDataFactory {
    message: string;
    editDisabled: boolean;
}

export function EditDataFactory() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const nameRef = useRef<TextFieldBase>(null);
    // only gets set upon update completion just in case someone hits the back button or closes the window
    // to prevent refetching the data if no update occurred
    const [isDismissed, setIsDismissed] = useState(true);

    const [annoucement, setAnnouncement] = useState<{ show: boolean; msg: string; onClose: () => void }>({
        show: false,
        msg: "",
        onClose: () => {}
    });
    const [FormState, setFormState] = useState<IFormState>({
        id: 0,
        name: "",
        platformName: "",
        isMonitoringEnabled: false,
        message: "",
        lastUpdated: new Date(),
        isGitEnabled: false,
        editDisabled: false,
        deletedTime: new Date(),
        isDeleted: false,
        pipelineCount: 0,
        type: "Datafactory",
        subscriptionId: ""
    });

    const { state, currentEntity, error } = useAppSelector(dataFactorySelector);

    const { id: dataFactoryId } = useParams<{ id: string }>();

    useEffect(() => {
        const updatedFormState = { ...FormState };

        if (!currentEntity || currentEntity.id !== +dataFactoryId) {
            dispatch(getDatafactoryById(+dataFactoryId));
        }

        if (currentEntity && currentEntity.id === +dataFactoryId) {
            updatedFormState.id = +dataFactoryId;
            updatedFormState.platformName = currentEntity.platformName;
            updatedFormState.name = currentEntity.name;
            updatedFormState.isMonitoringEnabled = currentEntity.isMonitoringEnabled;
            updatedFormState.editDisabled = true;
            updatedFormState.subscriptionId = currentEntity.subscriptionId;

            setFormState(updatedFormState);
        }
    }, [currentEntity, dataFactoryId]);

    // exit when the update is complete
    useEffect(() => {
        if (state[StateActionType.Update] === "UPDATED") {
            setIsDismissed(false);
            setAnnouncement((p) => ({
                ...p,
                msg: `${FormState.name} updated successfully`
            }));
        }

        if (state[StateActionType.Update] === "UPDATING") {
            setAnnouncement({
                show: true,
                msg: `Updating`,
                onClose: history.goBack
            });
        }
    }, [state[StateActionType.Update]]);

    useEffect(() => {
        // clean up on unmount
        return () => {
            // refetch to update state with new values
            if (!isDismissed) {
                dispatch(getAllDatafactories());
                // timeout to allow the form to close before resetting the state
                setTimeout(() => {
                    dispatch(reset(StateActionType.Update));
                }, 700);
            }
        };
    }, [isDismissed]);

    function _on_ChangeComponent(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target)
            if (newText && newText.length <= 200) {
                const updatedFormState = {
                    ...FormState,
                    name: newText
                };
                setFormState(updatedFormState);
            }
    }

    function _onChangeEditToggle(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        const updatedFormState = {
            ...FormState,
            editDisabled: !checked
        };
        setFormState(updatedFormState);
    }

    function _on_ComponentKeyPress(ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const keycode = ev.keyCode;
        if (keycode === 8 && ev.currentTarget.value.length === 1) {
            const updatedFormState = {
                ...FormState,
                name: ""
            };
            setFormState(updatedFormState);
        }
    }

    function _on_Change_MonitoringStatus(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        if (checked == null || checked == undefined) {
            checked = false;
        }
        const updatedFormState = {
            ...FormState,
            isMonitoringEnabled: checked
        };
        setFormState(updatedFormState);
    }

    function processFormSubmission(): void {
        const currentDataFactory: IDataFactory = {
            ...FormState
        };

        dispatch(updateDatafactory(currentDataFactory));
    }

    return (
        <StandardForm widthPercent={90} mediumWidthPercent={90} smallWidthPercent={90}>
            <SaveModal
                hidden={!annoucement.show}
                onClose={annoucement.onClose}
                successMsg={annoucement.msg}
                error={error}
                loading={state[StateActionType.Update] === "UPDATING"}
            />
            <Stack.Item align="end" styles={{ root: { marginBottom: "-50px" } }}>
                <IconButton
                    title="Close"
                    onClick={() => history.replace("/datafactories")}
                    iconProps={{ iconName: "Cancel" }}
                    allowDisabledFocus
                />
            </Stack.Item>
            <StackRow horizontalAlign="space-between" columnWidth={100}>
                <Toggle
                    label={<Label className="label">Edit</Label>}
                    defaultChecked={false}
                    onText="On"
                    offText="Off"
                    onChange={_onChangeEditToggle}
                />
            </StackRow>
            <Separator />

            <StackRow>
                <ShimmeredTextField
                    txtLabel="Data Factory Name"
                    loading={state[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                    id="component"
                    componentRef={nameRef}
                    ariaLabel="Data Factory Name"
                    styles={textfieldControlStyles}
                    required
                    onChange={_on_ChangeComponent}
                    onKeyDown={_on_ComponentKeyPress}
                    value={FormState.name.toString()}
                    className="name"
                    disabled={FormState.editDisabled}
                    isStackItem
                />

                <ShimmeredText
                    txtLabel="Platform Name"
                    loading={state[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                    isStackItem
                >
                    {FormState.platformName}{" "}
                </ShimmeredText>

                <ShimmeredToggle
                    toggleLabel="Monitoring Status"
                    loading={state[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                    onText="Enabled"
                    offText="Disabled"
                    ariaLabel="Monitoring Status Toggle Button"
                    checked={FormState.isMonitoringEnabled}
                    onChange={_on_Change_MonitoringStatus}
                    disabled={FormState.editDisabled}
                />
            </StackRow>
            <Separator />
            <StackRow columnWidth={100}>
                <PrimaryButton
                    text="Save"
                    onClick={processFormSubmission}
                    disabled={FormState.editDisabled}
                ></PrimaryButton>
            </StackRow>
        </StandardForm>
    );
}

export default EditDataFactory;

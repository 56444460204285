import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../service/authProvider";
import { AccessPackageAssignment } from "../models";
import { CloneRequest } from "../models/Elm/CloneRequest";
import { CloneEntry } from "../models/Elm/CloneEntry";

export const assignmentApi = createApi({
    reducerPath: "assignmentApi",
    tagTypes: ["Assignments", "CloneAssignment", "Clones", "Policies"],
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, api) => {
            const token = await getAccessToken();
            if (token) {
                headers.set("authorization", `Bearer ${token.accessToken}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAssignments: builder.query<AccessPackageAssignment[], { catalogId: string; policy: string }>({
            query: (req) => ({
                url: `api/EntitlementManagement/assignments/?catalogId=${req.catalogId}&policy=${req.policy}`,
                method: "GET"
            }),
            providesTags: () => [{ type: "Assignments" as const, id: "LIST" }],
            keepUnusedDataFor: 120
        }),
        getAssignmentsByUser: builder.query<AccessPackageAssignment[], string | undefined>({
            query: (userId: string) => ({
                url: `api/EntitlementManagement/assignmentsByUser/${userId}`,
                method: "GET"
            }),
            providesTags: (result) => [{ type: "CloneAssignment" as const, id: result?.[0]?.objectId }],
            keepUnusedDataFor: 120
        }),
        clone: builder.mutation<undefined, CloneRequest>({
            query: (request) => ({
                url: `api/EntitlementManagement/cloneAssignment`,
                method: "POST",
                body: request
            }),
            invalidatesTags: ["Assignments", "Clones"]
        }),
        getAssignmentClones: builder.query<CloneEntry[], string | undefined>({
            query: (catalogId: string) => ({
                url: `api/EntitlementManagement/clones/${catalogId}`,
                method: "GET"
            }),
            providesTags: () => [{ type: "Clones" as const, id: "List" }],
            keepUnusedDataFor: 120
        }),
        getPolicies: builder.query<string[], string | undefined>({
            query: (catalogId: string) => ({
                url: `api/EntitlementManagement/policies/${catalogId}`,
                method: "GET"
            }),
            providesTags: () => [{ type: "Policies" as const, id: "List" }],
            keepUnusedDataFor: 120
        })
    })
});

export const {
    useGetAssignmentsQuery,
    useGetAssignmentsByUserQuery,
    useCloneMutation,
    useGetAssignmentClonesQuery,
    useGetPoliciesQuery
} = assignmentApi;

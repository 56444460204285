import { Shimmer, Stack, Text } from "@fluentui/react";
import { toNumber } from "lodash";
import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useGetPageViewsDetailsQuery } from "../../api/SIPFeatureApi";
import { getShimmerStyles } from "../../columns/utils";
import Feedback from "./Feedback";
import { useWindowSize } from "../../hooks";
import PageVisitsOverflow from "./PageVisitsOverflow";

const PageVisits = () => {
    const loc = useLocation();

    const { width } = useWindowSize();

    const { data, isLoading, isSuccess } = useGetPageViewsDetailsQuery("pageViewsAPI");
    let count = 0;
    // Method to retrive page visit details
    const getPageVisitInfo = useCallback(async () => {
        resetStateValues();
        // Api cal
        let response;
        if (isSuccess && data) {
            // Getall all the data for the current page
            response = data.filter((d) => d.pageURL == window.location.href.toLowerCase());
            if (response) {
                // Total visit till now
                count = response.length;
                setTotalVisit(count == null ? 0 : toNumber(count));

                // Total visit in 180 days
                let curDate = new Date(new Date());
                curDate.setDate(curDate.getDate() - 180);
                let res = response.filter((d) => new Date(d.timestamp) >= curDate);
                setTotalVisitIn180days(count == null ? 0 : toNumber(res.length));

                // Total visit in 30 days
                curDate = new Date(new Date());
                curDate.setDate(curDate.getDate() - 30);
                res = response.filter((d) => new Date(d.timestamp) >= curDate);
                setTotalVisitIn30days(count == null ? 0 : toNumber(res.length));
            }
        }
    }, [isSuccess, loc.pathname]);

    React.useEffect(() => {
        getPageVisitInfo();
    }, [loc.pathname, isSuccess]);

    function resetStateValues() {
        setTotalVisit(0);
        setTotalVisitIn180days(0);
        setTotalVisitIn30days(0);
    }

    const hitStyles = {
        root: {
            backgroundColor: "lightgray",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            borderRadius: "7px",
            padding: "0px 10px 0px 10px",
            fontWeight: "bold"
        }
    };
    const [totalVisit, setTotalVisit] = React.useState<number>(0);
    const [totalVisitIn180days, setTotalVisitIn180days] = React.useState<number>(0);
    const [totalVisitIn30days, setTotalVisitIn30days] = React.useState<number>(0);

    function PageHit({ number, lbl }: { number: number; lbl: string }) {
        return (
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                <Text aria-label={lbl}>{lbl}:</Text>
                <Text styles={hitStyles} aria-label={lbl}>
                    {number}
                </Text>
            </Stack>
        );
    }

    function PageVisitCountInfo() {
        return (
            <Shimmer
                isDataLoaded={!isLoading}
                ariaLabel={`Loading Page visit details details`}
                width={"50%"}
                styles={getShimmerStyles()}
            >
                {(width < 560 && (
                    <PageVisitsOverflow
                        totalVisit={totalVisit}
                        totalVisitIn180days={totalVisitIn180days}
                        totalVisitIn30days={totalVisitIn30days}
                    />
                )) || (
                    <Stack
                        tokens={{ childrenGap: 5 }}
                        horizontalAlign="stretch"
                        styles={{ root: { padding: 5 } }}
                        horizontal
                    >
                        <PageHit number={totalVisit} lbl="Total Visits" />
                        <PageHit number={totalVisitIn180days} lbl="Last 180 days" />
                        <PageHit number={totalVisitIn30days} lbl="Last 30 days" />
                    </Stack>
                )}
            </Shimmer>
        );
    }
    return (
        <Stack styles={{ root: { backgroundColor: "white" } }} horizontal horizontalAlign="space-between">
            <Stack.Item aria-label="Page Visit Details" align="center" role="contentinfo">
                <Feedback />
            </Stack.Item>
            <Stack horizontalAlign="end">
                <Stack.Item>
                    <PageVisitCountInfo />
                </Stack.Item>
            </Stack>
        </Stack>
    );
};

export default PageVisits;

import axios, { AxiosError } from "axios";
import { getAccessToken } from "../service/authProvider";
import { TelemetryService } from "../service/telemetry";
import { IPortalHttpError } from "../models/PortalHttpError";
import store from "../store";
import { StateActionType } from "../types";

export function getHeaders(sliceName: string, actionType: StateActionType) {
    const opts = { headers: { "x-router-path": `${sliceName}/error`, "x-statetype": actionType } };
    return opts;
}

export function getAxiosErrorMessage(error: any) {
    if (axios.isAxiosError(error)) {
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
            return error.response.data.error.message as string;
        }
    }

    if (error.message) {
        return error.message;
    }
    return "";
}
axios.interceptors.request.use((config) => {
    return new Promise((resolve, reject) => {
        // only add tokens that don't already have auth set
        if (config.headers?.Authorization) {
            return resolve(config);
        }

        getAccessToken().then((token) => {
            if (token.accessToken && config.headers) {
                config.headers.Authorization = `Bearer ${token.accessToken}`;
                return resolve(config);
            } else {
                return reject(config);
            }
        });

        axios.interceptors.response.use(
            (res) => Promise.resolve(res),
            (error: AxiosError) => {
                if (
                    error &&
                    error.response &&
                    error.response.config.url &&
                    error.response.config.url.indexOf("api/") !== -1 &&
                    error.config.headers
                ) {
                    const { status, data, config } = error.response;

                    const errorObj = data as IPortalHttpError;
                    const errorMessage = errorObj.detail || errorObj.title || (errorObj as any) || error.message;

                    const routerPath = error.config.headers["x-router-path"];
                    const actionType = error.config.headers["x-statetype"];
                    const err = new Error(errorMessage);
                    error.message = errorMessage;

                    // if it doesn't have a router path just kick it back and let the calling function handle the error
                    // this flow is only for reducers
                    if (!routerPath) {
                        return Promise.reject(error);
                    }

                    TelemetryService.trackException(err, {
                        method: config.method,
                        status,
                        url: config.url
                    });

                    if (routerPath && actionType) {
                        store.dispatch({
                            type: routerPath,
                            payload: { msg: errorMessage, type: actionType }
                        });
                    }
                    return Promise.reject(err);
                }

                return Promise.reject(error);
            }
        );
    });
});

export default axios;

export interface IInternalObjectTagging {
    id: number,
    appId: string,
    objId: string,
    description: string,
    sysLastModifiedTime: Date,
    name: string,
    ids?: number[];
}

export const EmptyInternalObjectTagging: IInternalObjectTagging = {
    id: 0,
    appId: "",
    objId: "",
    description: "",
    sysLastModifiedTime: new Date(),
    name: ""
};


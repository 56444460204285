import React, { useState } from "react";
import { IFeedFreshness } from "../../../models/FeedFreshness";
import { SharedColors } from "@uifabric/fluent-theme";
import { ISliderProps } from "@fluentui/react";
import { DonutChart } from "@fluentui/react-charting";
import DashboardItem from "../dashboard/DashboardItem";
import { ToolTippedSlider } from "../../common/ToolTippedSlider";

export interface ISlaTrendFilter {
    tolerance: number;
}

interface ISlaHistoryDonutProps {
    feedFreshness: IFeedFreshness;
    historicalTimestamps: Date[];
    href?: string;
}

function valueFormat(value: number, unit: string, sla: number) {
    if (unit != "Minute") {
        return `${Math.floor(value * sla)}h${Math.floor((value * sla - Math.floor(value * sla)) * 60)}m`;
    } else {
        return `${Math.floor(value * sla * 60)}m${Math.floor((value * sla * 60 - Math.floor(value * sla * 60)) * 60)}s`;
    }
}

function SlaHistoryDonut({ feedFreshness, href, historicalTimestamps }: ISlaHistoryDonutProps) {
    const [tolerance, setTolerance] = useState({ tolerance: 0 });

    const { slaThresholdInHours, expectedRefreshIntervalUnit, expectedRefreshInterval, feedName } = feedFreshness;
    const toolTip = `This chart shows the proportion of ${feedName}'s past refreshes that took less time than its SLA.`;
    if (slaThresholdInHours != -1 || historicalTimestamps.length < 2) {
        let meetsSLA: number = 0;
        let doesNotMeetSLA: number = 0;

        let firstPointer: number = 0;
        let secondPointer: number = 1;

        while (secondPointer < historicalTimestamps.length) {
            const moreRecentTime: Date = new Date(historicalTimestamps[firstPointer]);
            const lessRecentTime: Date = new Date(historicalTimestamps[secondPointer]);
            const intervalInHours = (moreRecentTime.getTime() - lessRecentTime.getTime()) / (1000 * 60 * 60);

            if (intervalInHours < (1 + tolerance.tolerance) * slaThresholdInHours) meetsSLA++;
            else doesNotMeetSLA++;

            firstPointer++;
            secondPointer++;
        }

        const meetsLegend = "Times Refresh Met SLA";
        const doesntmeetLegend = "Times Refresh Did Not Meet SLA";

        const points = [
            { legend: meetsLegend, data: meetsSLA, color: SharedColors.green20 },
            { legend: doesntmeetLegend, data: doesNotMeetSLA, color: SharedColors.red20 }
        ];

        const data = { chartTitle: "History of Refreshes Over/Under SLA", chartData: points };

        const chart = <DonutChart data={data} innerRadius={50} href={href ? href : ""} />;
        const sliderToolTipsProps = {
            id: "trendsSlaSlider",
            content:
                "Feeds will be marked as meeting their SLA if their Most Recent Refresh Interval is less than this percentage over their SLA."
        };
        const sliderProps: ISliderProps = {
            min: 0,
            max: 0.15,
            step: 0.0025,
            ariaLabel: "Tolerance",
            value: tolerance.tolerance,
            onChanged: (event, value: number) => setTolerance({ ...tolerance, tolerance: value }),
            valueFormat: (value: number) => valueFormat(value, expectedRefreshIntervalUnit!, slaThresholdInHours),
            ariaValueText: (value: number) => valueFormat(value, expectedRefreshIntervalUnit!, slaThresholdInHours)
        };

        const child = (
            <div>
                {chart}
                <ToolTippedSlider label="Tolerance" toolTipsProps={sliderToolTipsProps} sliderProps={sliderProps} />
            </div>
        );

        return <DashboardItem width="750px" label="History of Refreshes and SLA" tooltip={toolTip} child={child} />;
    } else {
        let label = "";
        if (expectedRefreshInterval === -1) {
            label = "Feed Not Mapped, Cannot Show SLA History";
        } else {
            label = "Error Showing SLA History";
        }
        return <DashboardItem width="750px" label={label} tooltip={toolTip} />;
    }
}

export default SlaHistoryDonut;

import { IStackItemProps, IStackProps, Stack } from "@fluentui/react";
import React from "react";
import { useIsSmallScreen } from "../../hooks";

export function StackRow({
    children,
    columnWidth = 30,
    columnProps,
    horizontalAlign = "space-between"
}: {
    horizontalAlign?: IStackProps["horizontalAlign"];
    children: React.ReactNode;
    singleColumn?: boolean;
    columnWidth?: number;
    columnProps?: IStackItemProps;
} & IStackProps) {
    const smallScreen = useIsSmallScreen(1500);

    return (
        <Stack horizontalAlign={smallScreen ? "center" : horizontalAlign} horizontal={!smallScreen}>
            {React.Children.map(children, (child) => {
                return (
                    <Stack.Item
                        style={{
                            width: smallScreen
                                ? columnWidth < 50
                                    ? `${columnWidth * 2}%`
                                    : `${columnWidth}%`
                                : `${columnWidth}%`
                        }}
                        {...columnProps}
                    >
                        {child}
                    </Stack.Item>
                );
            })}
        </Stack>
    );
}

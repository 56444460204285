import { mergeStyleSets, IStyle } from "@fluentui/react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import PageVisits from "./PageVisits";
import { InteractionStatus } from "@azure/msal-browser";

const rootDivStyles = {
    position: "fixed",
    top: 48,
    bottom: 0,
    right: 0
};

const _Styles = mergeStyleSets({
    rootDivCollapsed: {
        ...rootDivStyles,
        left: 48
    } as IStyle,
    rootDivExpand: {
        ...rootDivStyles,
        left: 228
    } as IStyle,
    contentRight: {
        width: "100%",
        height: "100%",
        overflow: "auto"
    } as IStyle,
    toggle: {
        marginBottom: "30px"
    } as IStyle
});

interface ContainerProps {
    isNavCollapsed: boolean;
    children: any;
}

function Container(props: ContainerProps) {
    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();
    return (
        <div className={props.isNavCollapsed ? _Styles.rootDivCollapsed : _Styles.rootDivExpand}>
            {isAuthenticated && inProgress === InteractionStatus.None && <PageVisits />}
            <div className={_Styles.contentRight}>{props.children}</div>
        </div>
    );
}

export default Container;

import dayjs from "dayjs";
import { NotificationType } from "./types";
import { NotificationListItem } from "@cseo/controls";

export function getNotificationItem(type: NotificationType, body: string, header = "", icon = "", iconColor = "") {
    const _header = header
        ? header
        : type === "error"
        ? "An error has occured"
        : type === "success"
        ? "Operation was successful"
        : "Attention";

    const _icon = icon ? icon : type === "error" ? "Error" : type === "success" ? "CheckMark" : "Info";

    const notification: NotificationListItem = {
        itemKey: crypto.randomUUID(),
        timeDelivered: dayjs(),
        status: "unread",
        subjectHeader: _header,
        subjectIcon: _icon,
        summaryBodyText: body
    };
    return notification;
}

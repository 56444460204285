export interface IDataSource {
    id: number;
    name: string;
    endpoint: string;
    dataFactoryID: number;
    dataFactoryName: string;
    type: string;
    provisionedBy: string;
    lastUpdated: Date;
    contactInfo: string;
}

export const EmptyDataSource: IDataSource = {
    id: 0,
    name: "",
    endpoint: "",
    dataFactoryID:0,
    dataFactoryName:"",
    type: "",
    provisionedBy: "",
    contactInfo: "",
    lastUpdated: new Date()
};

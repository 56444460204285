import { ILabelStyles, ITextProps, Label, Shimmer, Text } from "@fluentui/react";
import { getShimmerStyles } from "../../../columns/utils";
type ShimmeredProps = {
    loading: boolean;
    txtLabel?: string;
    width?: string;
    isStackItem?: boolean;
    lblStyle?: ILabelStyles;
} & ITextProps;

function ShimmeredText(props: ShimmeredProps) {
    return (
        <div className={props.isStackItem ? "" : "col"}>
            {props.txtLabel && <Label id={props.txtLabel} className="label"> {props.txtLabel} </Label>}
            <Shimmer isDataLoaded={!props.loading} ariaLabel={`Loading ${props.txtLabel}`} styles={getShimmerStyles()}>
                <Text aria-labelledby={props.txtLabel} {...props} />
            </Shimmer>
        </div>
    );
}

export { ShimmeredText };

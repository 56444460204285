import axios, { getHeaders } from "./SipMonClient";
import { EmptyPipelineActivity, IPipelineActivity } from "../models/PipelineActivtiy";
import { ISipApiResponse } from "../models/SipApiResponse";
import { sliceName } from "../reducers/Activities";
import { StateActionType } from "../types";

export const getActivities = async (
    dfId: number,
    pipelineId: number
): Promise<ISipApiResponse<IPipelineActivity[]>> => {
    const { data, status } = await axios.get<IPipelineActivity[]>(
        `api/Activities/df/${dfId}/pipeline/${pipelineId}`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return {
        data,
        message: "",
        status
    };
};

export const updateActivity = async (activity: IPipelineActivity): Promise<ISipApiResponse<IPipelineActivity>> => {
    const { data, status } = await axios.put<IPipelineActivity>(
        `api/Activities/${activity.id}`,
        activity,
        getHeaders(sliceName, StateActionType.Update)
    );
    return {
        data,
        message: "",
        status
    };
};

export const updateActivities = async (
    activities: IPipelineActivity[]
): Promise<ISipApiResponse<IPipelineActivity[]>> => {
    const { data, status } = await axios.put<IPipelineActivity[]>(
        `api/Activities/`,
        activities,
        getHeaders(sliceName, StateActionType.Update)
    );
    return {
        data,
        message: "",
        status
    };
};

import * as React from "react";
import { useState, useEffect } from "react";
import { Stack, Label, Toggle, PrimaryButton, IconButton, Separator } from "@fluentui/react";
import { AzureResourceSelector } from "../../pages/Selectors";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { getAllAzureResources, getAzureResourceById, reset, updateAzureResource } from "../../reducers/AzureResources";
import { StateActionType } from "../../types";
import { useHistory, useParams } from "react-router-dom";
import { AzureResource } from "../../models";
import EditAzureSqlDb from "./EditAzureSqlDb";
import { nameof } from "../common/utils";
import { StackRow } from "../common/StackComponents";
import StandardForm from "../common/StandardForm";
import SaveModal from "../Dataset/SaveModal";
import { ShimmeredText, ShimmeredTextField, ShimmeredToggle } from "../common/Shimmered";

const textfieldControlStyles = {
    root: {
        maxWidth: "300px"
    }
};

export function EditAzureResource() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    // only gets set upon update completion just in case someone hits the back button or closes the window
    // to prevent refetching the data if no update occurred
    const [isDismissed, setIsDismissed] = useState(true);
    const [childError, setChildError] = useState("");
    const [editDisabled, setEditDisabled] = useState(true);
    const [FormState, setFormState] = useState<AzureResource>({
        id: 0,
        name: "",
        platformName: "",
        isMonitoringEnabled: false,
        lastUpdated: "",
        config: {},
        isDeleted: false,
        resourceGroup: "",
        type: "",
        subscriptionId: ""
    });
    const [annoucement, setAnnouncement] = useState<{ show: boolean; msg: string; onClose: () => void }>({
        show: false,
        msg: "",
        onClose: () => {}
    });

    const { state, currentEntity, error } = useAppSelector(AzureResourceSelector);

    const { id: azureResourceId } = useParams<{ id: string }>();

    useEffect(() => {
        if (!currentEntity || currentEntity.id !== +azureResourceId) {
            dispatch(getAzureResourceById(+azureResourceId));
        }

        if (currentEntity && currentEntity.id === +azureResourceId) {
            setFormState(currentEntity);
        }
    }, [currentEntity, azureResourceId]);

    // exit when the update is complete
    useEffect(() => {
        if (state[StateActionType.Update] === "UPDATED") {
            setIsDismissed(false);
            setAnnouncement((p) => ({
                ...p,
                msg: `${FormState.name} was successfully updated!`
            }));
        }

        if (state[StateActionType.Update] === "UPDATING") {
            setAnnouncement({
                show: true,
                msg: "",
                onClose: history.goBack
            });
        }
    }, [state[StateActionType.Update]]);

    useEffect(() => {
        // clean up on unmount
        return () => {
            // refetch to update state with new values
            if (!isDismissed) {
                dispatch(getAllAzureResources());
                // timeout to allow the form to close before resetting the state
                setTimeout(() => {
                    dispatch(reset(StateActionType.Update));
                }, 700);
            }
        };
    }, [isDismissed]);

    function onChange(value: any, stateKey: string): void {
        const updatedFormState = {
            ...FormState,
            [stateKey]: value
        };

        setFormState(updatedFormState);
    }

    function _onChangeEditToggle(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        setEditDisabled(!editDisabled);
    }

    function processFormSubmission(): void {
        const currentAzureResource: AzureResource = {
            ...FormState
        };

        dispatch(updateAzureResource(currentAzureResource));
    }

    return (
        <>
            <StandardForm widthPercent={90} mediumWidthPercent={90} smallWidthPercent={90}>
                <SaveModal
                    hidden={!annoucement.show}
                    onClose={annoucement.onClose}
                    successMsg={annoucement.msg}
                    error={error}
                    loading={state[StateActionType.Update] === "UPDATING"}
                />
                <Stack.Item align="end" styles={{ root: { marginBottom: "-50px" } }}>
                    <IconButton
                        title="Close"
                        onClick={() => history.replace("/azureResources")}
                        iconProps={{ iconName: "Cancel" }}
                        allowDisabledFocus
                    />
                </Stack.Item>
                <StackRow horizontalAlign="space-between" columnWidth={100}>
                    <Toggle
                        label={<Label className="label">Edit</Label>}
                        defaultChecked={false}
                        onText="On"
                        offText="Off"
                        onChange={_onChangeEditToggle}
                    />
                </StackRow>
                <Separator />
                <StackRow>
                    <ShimmeredTextField
                        loading={state[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                        txtLabel="Resource Name"
                        id="name"
                        ariaLabel="Resource Name"
                        styles={textfieldControlStyles}
                        required
                        isStackItem
                        onChange={(_e, value) => onChange(value, nameof<AzureResource>("name"))}
                        value={FormState.name}
                        disabled={editDisabled}
                    />

                    <ShimmeredText
                        txtLabel="Platform Name"
                        loading={state[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                        isStackItem
                    >
                        {" "}
                        {FormState.platformName}{" "}
                    </ShimmeredText>

                    <ShimmeredToggle
                        onText="Enabled"
                        toggleLabel="Monitoring Status"
                        isStackItem
                        loading={state[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                        offText="Disabled"
                        ariaLabel="Monitoring Status Toggle Button"
                        checked={FormState.isMonitoringEnabled}
                        onChange={(_e, checked) => onChange(checked, nameof<AzureResource>("isMonitoringEnabled"))}
                        disabled={editDisabled}
                    />
                </StackRow>

                {FormState && FormState.id !== 0 && FormState.isMonitoringEnabled && (
                    <StackRow>
                        {/* this is where we can handle more types as this grows */}
                        {FormState.type.split(" ")[0] === "Microsoft.Sql/servers/databases" && (
                            <EditAzureSqlDb
                                setState={setFormState}
                                setError={setChildError}
                                azureResource={FormState}
                                editDisabled={editDisabled}
                            />
                        )}
                    </StackRow>
                )}

                <Separator />
                <StackRow columnWidth={100}>
                    <PrimaryButton text="Save" onClick={processFormSubmission} disabled={editDisabled}></PrimaryButton>
                </StackRow>
            </StandardForm>
        </>
    );
}

export default EditAzureResource;

import { Stack, Label, Shimmer, ITag } from "@fluentui/react";
import { getShimmerStyles } from "../../../columns/utils";
import { AutoCompleteTextField, AutoCompleteProps, DataTag } from "../AutoCompleteTextField";

type ShimmeredAutoCompleteProps<T extends DataTag | DataTag[]> = {
    loading: boolean;
    label: string;
} & AutoCompleteProps<T>;

const ShimmeredAutoComplete = <T extends DataTag | DataTag[]>({
    label,
    loading,
    disabled,
    sendSelectedValue,
    data,
    val,
    itemName,
    allowFreeform,
    multiSelect,
    placeHolderText,
    errorMessage
}: ShimmeredAutoCompleteProps<T>) => {
    return (
        <Stack>
            <Label className="label">{label}</Label>
            <Shimmer isDataLoaded={!loading} ariaLabel={`Loading ${itemName}`} styles={getShimmerStyles()}>
                <AutoCompleteTextField<T>
                    itemName={itemName}
                    disabled={disabled}
                    sendSelectedValue={sendSelectedValue}
                    allowFreeform={allowFreeform}
                    multiSelect={multiSelect}
                    data={data}
                    val={val}
                    placeHolderText={placeHolderText}
                    errorMessage={errorMessage}
                />
            </Shimmer>
        </Stack>
    );
};

export { ShimmeredAutoComplete };

import { Stack, Label, FontWeights } from "@fluentui/react";
import { ReactElement } from "react";

export function FormTextField({ label, txt }: { label: string; txt?: string | ReactElement }) {
    return (
        <Stack tokens={{ childrenGap: 10 }} horizontal>
            <Stack.Item>
                <Label>{label}: </Label>
            </Stack.Item>
            <Stack.Item>
                <Label styles={{ root: { fontWeight: FontWeights.regular } }}>{txt}</Label>
            </Stack.Item>
        </Stack>
    );
}

import { IconButton, MessageBar, MessageBarType, PrimaryButton, Stack, ITag, Label, Shimmer } from "@fluentui/react";
import { toNumber } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { DatasetType, IKustoDataset } from "../../models/Dataset";
import { dataSetSelector } from "../../pages/Selectors";
import { getAccessPackageReferenceAsync } from "../../reducers/AccessPackages";
import {
    addKustoDataset,
    clearResponse,
    DatasetComponents,
    getAllDatasets,
    getAllDatasetTags,
    getAllKustoClusters,
    getAllKustoDatabases,
    getAllKustoTables,
    getDatasetById,
    getKustoDatasetByDSId,
    setCurrentDataset,
    setCurrentKustoDataset
} from "../../reducers/Dataset";

import { ShimmeredAutoComplete, ShimmeredComboBox, ShimmeredTextField } from "../common/Shimmered";
import { FieldState, anyFieldErrors, getITag, navigateToDataset } from "./utils";
import { DisplayInCatalogToggle } from "./DisplayInCatalogToggle";
import StandardForm from "../common/StandardForm";
import { StackRow } from "../common/StackComponents";
import SaveButtonContainer from "./SaveButtonContainer";
import { useAssociatePipeline } from "../../hooks";
import { PipelineCommandButton } from "../Feed/PipelineCommandButton";
import { getShimmerStyles } from "../../columns/utils";
import AssociatePipeline from "./AssociatePipeline";
import { useGetActionAndId } from "./useGetActionAndId";
import { parseNumberField } from "../common/utils";
import DatasetTagSelection from "./DatasetTagSelection";
import { DataTag } from "../common/AutoCompleteTextField";
import { checkKustoDuplicate } from "../../api/DatasetApi";
import { LoadingOverLay } from "../common/LoadingOverLay";
import SaveModal from "./SaveModal";

export function KustoDatasetForm() {
    const history = useHistory();
    const { dsId, isAdd, isDatasetAdd } = useGetActionAndId();

    const dispatch = useAppDispatch();
    const {
        datasets,
        addKustoDatasetResponse,
        currentDataset,
        currentKustoDataset,
        kustoClusters,
        kustoDatabases,
        kustoTables,
        dsErrors,
        dsLoading
    } = useAppSelector(dataSetSelector);
    const [associatePipelineProps, setShowDialog] = useAssociatePipeline();
    const [FormState, setFormState] = useState<FieldState>({
        message: "",
        errors: {}
    });

    const [showAnnouncment, setShowAnnouncement] = useState(false);
    const [successMsg, setSuccessMsg] = useState<string>("");
    const [fieldState, setFieldState] = useState<FieldState>({
        message: "",
        errors: {}
    });

    async function checkDuplicate() {
        if (!currentKustoDataset) return;
        try {
            const isDup = await checkKustoDuplicate(
                currentKustoDataset.id,
                currentKustoDataset.cluster,
                currentKustoDataset.database,
                currentKustoDataset.tableName
            );
            if (isDup) {
                setFieldState({
                    ...fieldState,
                    errors: { cluster: "Cluster, Database, and Name combination already exists" }
                });
            } else {
                setFieldState({ ...fieldState, errors: { cluster: "" } });
            }
        } catch (error) {
            setFieldState({ ...fieldState, errors: { cluster: "" } });
            console.error("An error occurred checking duplicates");
            console.error(JSON.stringify(error));
        }
    }

    useEffect(() => {
        dispatch(getAllKustoClusters());
        dispatch(getAccessPackageReferenceAsync());
        dispatch(getAllDatasets());
    }, [getAllKustoClusters, getAccessPackageReferenceAsync, getAllDatasets]);

    useEffect(() => {
        if (currentKustoDataset && currentKustoDataset.clusterId) {
            dispatch(getAllKustoDatabases(currentKustoDataset.clusterId));
        }
    }, [currentKustoDataset?.clusterId]);

    useEffect(() => {
        if (currentKustoDataset?.database && currentKustoDataset?.cluster && currentKustoDataset?.tableName) {
            checkDuplicate();
        }
    }, [currentKustoDataset?.database, currentKustoDataset?.cluster, currentKustoDataset?.tableName]);
    useEffect(() => {
        if (currentKustoDataset && currentKustoDataset.databaseId) {
            dispatch(getAllKustoTables(currentKustoDataset.databaseId));
        }
    }, [currentKustoDataset?.databaseId]);

    useEffect(() => {
        if (dsId) {
            dispatch(getDatasetById(dsId));
            if (!isAdd) {
                dispatch(getKustoDatasetByDSId(toNumber(dsId)));
            }
        }
    }, [dsId]);

    useEffect(() => {
        dispatch(getAllDatasetTags());
    }, []);

    // exit when the creation is complete
    useEffect(() => {
        if (addKustoDatasetResponse === "Success") {
            setSuccessMsg("Update completed successfully.");
            setShowAnnouncement(true);
        }
    }, [addKustoDatasetResponse]);

    function onChange<K extends keyof IKustoDataset>(value: any, stateKey: K): void {
        if (currentKustoDataset) {
            const updatedDataset = {
                ...currentKustoDataset,
                [stateKey]: value
            };

            if (stateKey === "tableName") {
                setFieldState({ ...fieldState, errors: { cluster: "" } });
            }

            dispatch(setCurrentKustoDataset(updatedDataset));
        }
    }

    function isFormValid() {
        if (!currentKustoDataset) return false;
        if (anyFieldErrors(fieldState)) return false;

        if (currentKustoDataset.cluster?.trim().length === 0) return false;
        if (currentKustoDataset.tableName?.trim().length === 0) return false;
        if (!currentKustoDataset.pipelineId) return false;
        return true;
    }

    async function submitKustoDatasetDetails() {
        if (!currentKustoDataset || !currentDataset) return;
        setShowAnnouncement(true);
        dispatch(setCurrentDataset({ ...currentDataset, kustoDataset: currentKustoDataset }));
        dispatch(addKustoDataset(currentKustoDataset));
    }

    function resetForm() {
        dispatch(clearResponse("addKustoDatasetResponse"));
        setShowAnnouncement(false);
        navigateToDataset(history, isAdd, dsId);
    }

    function goBackToDataset() {
        if (currentDataset) dispatch(setCurrentDataset({ ...currentDataset, kustoDataset: currentKustoDataset }));
        setSuccessMsg("The Kusto dataset has been configured. You must save the dataset or changes will not be saved.");
        setShowAnnouncement(true);
    }
    function cancelAndGoBackToDataset() {
        dispatch(setCurrentKustoDataset(undefined));
        navigateToDataset(history, isAdd, dsId);
    }

    function onChangeDisplayInCatalog(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        if (!currentKustoDataset) return;

        const update = { ...currentKustoDataset, displayInCatalog: checked ? checked : false };

        dispatch(setCurrentKustoDataset(update));
    }

    function onChangeCluster(tag?: DataTag) {
        if (!currentKustoDataset || !kustoClusters) return;

        setFieldState({ ...fieldState, errors: { cluster: "" } });
        const update = { ...currentKustoDataset, cluster: tag?.name || "", clusterId: tag?.key as number };
        dispatch(setCurrentKustoDataset(update));
    }

    function onChangeDatabase(tag?: DataTag) {
        if (!currentKustoDataset || !kustoDatabases) return;
        setFieldState({ ...fieldState, errors: { cluster: "" } });
        const update = {
            ...currentKustoDataset,
            database: tag?.name || "",
            databaseId: tag?.key as number,
            tableName: ""
        };
        dispatch(setCurrentKustoDataset(update));
    }

    return (
        <StandardForm widthPercent={70} mediumWidthPercent={90} smallWidthPercent={90}>
            <LoadingOverLay isOverlayVisible={dsLoading[DatasetComponents.currentKustoDataset]} />
            {dsErrors[DatasetComponents.KustoDataset] && (
                <MessageBar messageBarType={MessageBarType.error} title="Kusto Dataset Creation Error">
                    {dsErrors[DatasetComponents.KustoDataset]}
                </MessageBar>
            )}
            <SaveModal
                hidden={!showAnnouncment}
                onClose={resetForm}
                successMsg={successMsg}
                error={dsErrors[DatasetComponents.KustoDataset]}
                loading={dsLoading[DatasetComponents.KustoDataset]}
            />
            <Stack.Item align="end" style={{ textAlign: "right" }}>
                <IconButton
                    title="Close"
                    className="routing"
                    onClick={cancelAndGoBackToDataset}
                    iconProps={{ iconName: "Cancel" }}
                    allowDisabledFocus
                />
            </Stack.Item>
            <DisplayInCatalogToggle
                enabled={isAdd || !!currentKustoDataset}
                checked={currentKustoDataset?.displayInCatalog ?? false}
                onChange={onChangeDisplayInCatalog}
            />
            <StackRow columnWidth={40}>
                {!isAdd && (
                    <ShimmeredComboBox
                        loading={!datasets}
                        cbxLabel="Dataset"
                        selectedKey={currentDataset?.id || 0}
                        placeholder="Select Dataset"
                        autoComplete="on"
                        options={datasets?.map((b) => ({ key: b.id!, text: b.name })) || []}
                        onChange={(_ev, option) => onChange(parseInt(option!.key as string), "datasetId")}
                        isStackItem
                    />
                )}
                <Stack>
                    <Label className="label">Pipeline Name</Label>
                    <Shimmer
                        isDataLoaded={!!currentKustoDataset || isAdd}
                        ariaLabel="Loading Feed Pipeline Name"
                        styles={getShimmerStyles()}
                    >
                        <PipelineCommandButton
                            popUpShow={() => setShowDialog({ ...associatePipelineProps, showDialog: true })}
                            pipelineId={currentKustoDataset?.pipelineId!}
                            name={currentKustoDataset?.pipelineName!}
                            editDisabled={false}
                            datafactoryAuthorLink={currentKustoDataset?.datafactoryAuthorLink!}
                        />
                    </Shimmer>
                </Stack>
            </StackRow>
            <StackRow columnWidth={40}>
                <ShimmeredAutoComplete<ITag>
                    label="Cluster"
                    loading={!isAdd && !currentKustoDataset}
                    itemName="clusters"
                    disabled={!kustoClusters || kustoClusters.length === 0}
                    sendSelectedValue={(t) => onChangeCluster(t)}
                    data={kustoClusters?.map((entry) => ({ key: entry.id, name: entry.name })) || []}
                    val={getITag(currentKustoDataset?.cluster, currentKustoDataset?.clusterId)}
                    placeHolderText={"Start typing for suggestions"}
                    errorMessage={fieldState.errors.cluster}
                />
                <ShimmeredAutoComplete<ITag>
                    label="Database"
                    loading={!isAdd && !currentKustoDataset}
                    itemName="databases"
                    disabled={!kustoDatabases || kustoDatabases.length === 0}
                    sendSelectedValue={(t) => onChangeDatabase(t)}
                    data={kustoDatabases?.map((entry) => ({ key: entry.id, name: entry.name })) || []}
                    val={getITag(currentKustoDataset?.database, currentKustoDataset?.databaseId)}
                    placeHolderText={"Start typing for suggestions"}
                    errorMessage={fieldState.errors.cluster}
                />
            </StackRow>
            <StackRow columnWidth={40}>
                <ShimmeredAutoComplete<ITag>
                    label="Table Name"
                    loading={!isAdd && !currentKustoDataset}
                    itemName="tables"
                    disabled={!kustoTables || kustoTables.length === 0}
                    sendSelectedValue={(t) => onChange(t?.name, "tableName")}
                    data={kustoTables?.map((entry) => ({ key: entry, name: entry })) || []}
                    val={getITag(currentKustoDataset?.tableName)}
                    placeHolderText={"Start typing for suggestions"}
                    errorMessage={fieldState.errors.cluster}
                />

                <ShimmeredTextField
                    loading={!isAdd && !currentKustoDataset}
                    id="SLAinhours"
                    txtLabel="SLA in hours"
                    required
                    suffix="hrs"
                    isStackItem
                    numeric
                    onChange={(_e, newValue) => {
                        onChange(parseNumberField(newValue), "slaThresholdInHours");
                    }}
                    value={currentKustoDataset?.slaThresholdInHours?.toString() || "24"}
                    errorMessage={FormState.errors["slaThresholdInHours"]}
                />
            </StackRow>
            <StackRow columnWidth={40}>
                <ShimmeredTextField
                    isStackItem
                    loading={!isAdd && !currentKustoDataset}
                    id="retentionDays"
                    txtLabel="Purge Retention Days"
                    numeric
                    onChange={(_e, newValue) => {
                        onChange(parseNumberField(newValue), "retentionInDays");
                    }}
                    value={currentKustoDataset?.retentionInDays?.toString() || "181"}
                    suffix="days"
                />
                <DatasetTagSelection
                    type={DatasetType.Kusto}
                    isLoading={!isAdd && !currentKustoDataset}
                    disabled={!currentDataset}
                />
            </StackRow>

            <SaveButtonContainer>
                <PrimaryButton
                    text={isDatasetAdd ? "Next" : "Save"}
                    type="button"
                    disabled={!isFormValid()}
                    onClick={() => {
                        isDatasetAdd ? goBackToDataset() : submitKustoDatasetDetails();
                    }}
                ></PrimaryButton>
                <PrimaryButton text="Close" type="button" onClick={cancelAndGoBackToDataset}></PrimaryButton>
            </SaveButtonContainer>

            {associatePipelineProps.showDialog && (
                <AssociatePipeline datasetName="currentKustoDataset" {...associatePipelineProps} />
            )}
        </StandardForm>
    );
}
export default KustoDatasetForm;

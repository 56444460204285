export interface IFeedFreshness {
    platformName: string;
    subscriptionName: string;
    feedId: number;
    feedName: string;
    storageAccountName: string;
    expectedRefreshInterval?: number | null;
    expectedRefreshIntervalUnit?: string | null;
    lastRefresh: Date;
    slaThresholdInHours: number;
    isDormant: boolean;
    averageRefreshFrequency: number;
    lastRefreshFrequency: number;
    timeOverSlaInHours: number;
    historicalTimestamps?: Date[];
}

export const EmptyFeedFreshness: IFeedFreshness = {
    platformName: "",
    subscriptionName: "",
    feedId: 0,
    feedName: "",
    storageAccountName: "",
    expectedRefreshInterval: null,
    expectedRefreshIntervalUnit: null,
    lastRefresh: new Date(),
    slaThresholdInHours: 0,
    isDormant: false,
    averageRefreshFrequency: 0,
    lastRefreshFrequency: 0,
    timeOverSlaInHours: 0
};

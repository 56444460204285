import { IconButton, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

type ToolTippedIconLinkProps = {
    tooltipContent: string;
    iconName: string;
    link: string;
    size?: number;
};

const ToolTippedIconLink = ({ link, iconName, tooltipContent, size = 25 }: ToolTippedIconLinkProps) => {
    const tooltipId = useId("tooltip");
    const handleLinkClick = () => {
        window.open(link, "_blank");
    };

    return (
        <TooltipHost content={tooltipContent}>
            <IconButton
                role="link"
                styles={{
                    icon: {
                        "& svg": {
                            height: `${size}px`,
                            width: `${size}px`
                        }
                    }
                }}
                aria-describedby={tooltipId}
                iconProps={{ iconName: iconName }}
                onClick={handleLinkClick}
            />
        </TooltipHost>
    );
};

export default ToolTippedIconLink;

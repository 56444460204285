export enum DataSourceType {
    "Unknown",
    "AzSql",
    "Sql",
    "Csv",
    "Json",
    "Parquet",
    "Kusto",
    "Adls"
}

export enum ProvisionType {
    "Automation",
    "Manual"
}
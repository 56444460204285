import { IPersonaProps } from "@fluentui/react";

export const listContainsTagList = (tag: IPersonaProps, tagList?: IPersonaProps[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
        return false;
    }
    return tagList.some((compareTag) => compareTag.secondaryText === tag.secondaryText && compareTag.text === tag.text);
};

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export function parseNumberField(value: string | undefined): number {
    if (!value) {
        return 0;
    }
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
}

export function toPacificTimeString(dateStr: string): string {
    const pstDateTime = new Date(dateStr).toLocaleString("en-US", {
        timeZone: "America/Los_Angeles"
    });
    return pstDateTime;
}

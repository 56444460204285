import { IButtonStyles, FontSizes, Stack, IconButton, OverflowSet, Label } from "@fluentui/react";

interface PageVisitsOverflowProps {
    totalVisit: number;
    totalVisitIn30days: number;
    totalVisitIn180days: number;
}

const PageVisitsOverflow = ({ totalVisit, totalVisitIn30days, totalVisitIn180days }: PageVisitsOverflowProps) => {
    const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
        const buttonStyles: Partial<IButtonStyles> = {
            root: {
                minWidth: 0,
                boxSizing: "content-box",
                padding: "10px",
                alignSelf: "stretch",
                height: "auto"
            },
            menuIcon: { fontSize: FontSizes.size16 }
        };
        return (
            <Stack horizontal>
                <Label>Page Visits</Label>
                <IconButton
                    title="Page Visits"
                    styles={buttonStyles}
                    menuIconProps={{ iconName: "More" }}
                    menuProps={{ items: overflowItems! }}
                />
            </Stack>
        );
    };

    return (
        <OverflowSet
            aria-label="Page Visit Details"
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={(_item) => <></>}
            overflowItems={[
                { key: "totalVisit", name: `Total Visits: ${totalVisit}` },
                { key: "totalVisitIn180days", name: `Last 180 days: ${totalVisitIn180days}` },
                { key: "totalVisitIn30days", name: `Last in 30 days: ${totalVisitIn30days}` }
            ]}
        />
    );
};

export default PageVisitsOverflow;

import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { isInRole } from "../../service/authProvider";

export interface RbacRouteProps extends RouteProps {
    role: string;
}

export const RbacRoute: React.FC<RbacRouteProps> = (props) => {
    const { role } = props;
    let path: string | string[] | undefined = "";
    let redirectPath = "";
    if (!isInRole(role)) {
        path = props.component ? props.component.name : "the requested page";
        redirectPath = "/unauthorized";
    }

    if (redirectPath) {
        const renderComponent = () => (
            <Redirect
                to={{
                    pathname: redirectPath,
                    state: {
                        protectedPath: path,
                        roles: role.split(",")
                    }
                }}
            />
        );
        return <Route {...props} component={renderComponent} render={undefined} />;
    } else {
        return <Route {...props} />;
    }
};

export default RbacRoute;

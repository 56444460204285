export interface ISourceOwner {
    id: number;
    name: string;
    lastUpdated: Date;
}

export const EmptySourceOwner: ISourceOwner = {
    id: 0,
    name: "",
    lastUpdated: new Date()
};

export interface IPipelineActivity {
    id: number;
    name: string;
    dataFactoryId: number;
    dataFactoryName: string;
    pipelineId: number;
    pipelineName: string;
    platformName: string;
    feedIds: number[];
    lastUpdated: Date;
    type: string;
}

export const EmptyPipelineActivity: IPipelineActivity = {
    id: -1,
    name: "",
    dataFactoryId: -1,
    dataFactoryName: "",
    pipelineId: -1,
    pipelineName: "",
    platformName: "",
    feedIds: [],
    lastUpdated: new Date(),
    type: ""
};

import { BaseAction, StateValues } from ".";
import { IPipeline } from "../models/Pipeline";

export enum PipelineActionTypes {
    GETALL = "PIPELINE/GETALL",
    GETSINGLE = "PIPELINE/GETSINGLE",
    INIIALIZE = "PIPELINE/INIT",
    ADDPIPELINE = "PIPELINE/ADD",
    EDITPIPELINE = "PIPELINE/EDIT",
    SEARCHPIPELINE = "PIPELINE/SEARCH",
    LOADINGPIPELINE = "PIPELINE/LOADING",
    LOADINGERROR = "PIPELINE/ERROR",
    SETCURRENT = "PIPELINE/CURRENT",
    TESTPIPELINE = "PIPELINE/TEST"
}

// adding a test default action that calls the reducer default.
interface IPipelineTest extends BaseAction {
    type: typeof PipelineActionTypes.TESTPIPELINE;
}

export interface IPipelineSearchAction extends BaseAction<string> {
    type: PipelineActionTypes.SEARCHPIPELINE;
}

export interface IPipelineGetAllAction extends BaseAction<IPipeline[]> {
    type: PipelineActionTypes.GETALL;
}

export interface IPipelineLoading extends BaseAction {
    type: PipelineActionTypes.LOADINGPIPELINE;
}
export interface IPipelineInit extends BaseAction {
    type: PipelineActionTypes.INIIALIZE;
}

export interface IPipelineGetSingleAction extends BaseAction<IPipeline> {
    type: PipelineActionTypes.GETSINGLE;
}

export interface IPipelineSetCurrent extends BaseAction<IPipeline> {
    type: PipelineActionTypes.SETCURRENT;
}

export interface IPipelineAddAction extends BaseAction<IPipeline> {
    type: PipelineActionTypes.ADDPIPELINE;
}

export interface IPipelineEditAction extends BaseAction<IPipeline> {
    type: PipelineActionTypes.EDITPIPELINE;
}

export interface IPipelineError extends BaseAction<string> {
    type: PipelineActionTypes.LOADINGERROR;
}

export type PipelineActions =
    | IPipelineGetAllAction
    | IPipelineGetSingleAction
    | IPipelineAddAction
    | IPipelineEditAction
    | IPipelineInit
    | IPipelineSearchAction
    | IPipelineLoading
    | IPipelineSetCurrent
    | IPipelineError
    | IPipelineTest;

export interface IPipelineState {
    readonly currentPipeline: IPipeline | null;
    readonly pipelines: IPipeline[];
    readonly pipelineLoading: boolean;
    readonly pipelineState: StateValues;
    readonly pipelineSearch: string;
    readonly pipelineError: string;
}

import React, { useState, useEffect } from "react";
import {
    Text,
    FontWeights,
    ITextStyles,
    PrimaryButton,
    IComboBoxOption,
    IComboBox,
    IComboBoxStyles,
    IconButton,
    Toggle,
    Link,
    Stack,
    Separator,
    Label
} from "@fluentui/react";
import "../../styles/App.css";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import { EmptyFeedCategory, IFeedCategory } from "../../models/FeedCategory";
import { IFeed } from "../../models/Feeds";
import { IContact } from "../../models/Contact";
import { feedSelector, feedCategorySelector, contactSelector } from "../../pages/Selectors";

import StandardList from "../common/StandardList";
import { generateFeedColumns } from "../../columns";
import { StateActionType, StateValues } from "../../types";
import { getAllFeeds } from "../../reducers/Feeds";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import {
    getAllFeedCategories,
    getFeedCategoryById,
    reset,
    updateFeedCategoryContacts
} from "../../reducers/FeedCategories";
import { getAllContacts } from "../../reducers/Contacts";
import Errorbar from "../common/ErrorBar";
import { ShimmeredComboBox, ShimmeredGraphLookup, ShimmeredTextField } from "../common/Shimmered";
import CrudStatusDialog from "../common/CrudStatusDialog";
import StandardForm from "../common/StandardForm";
import SaveModal from "../Dataset/SaveModal";
import { StackRow } from "../common/StackComponents";
import ShimmeredLink from "../common/Shimmered/ShimmeredLink";

const siteTextStyles: ITextStyles = {
    root: {
        fontWeight: FontWeights.semibold
    }
};

interface IFormState extends IFeedCategory {
    errors: { [index: string]: string };
    dataSourceOwnerDropdownValues: IComboBoxOption[];
    dataBusinessOwnerDropdownValues: IComboBoxOption[];
    secondaryUseAccessApproverDropdownValues: IComboBoxOption[];
    securityUseAccessApproverDropdownValues: IComboBoxOption[];
    editDisabled: boolean;
}

interface IParentprops {
    feedCategoryId: string;
}

interface IProps extends RouteComponentProps<IParentprops> {}

export function EditFeedCategory(props: IProps) {
    const dispatch = useAppDispatch();
    const history = useHistory();

    // only gets set upon update completion just in case someone hits the back button or closes the window
    // to prevent refetching the data if no update occurred
    const [isDismissed, setIsDismissed] = useState(true);
    const [selectedFeeds, setSelectedFeeds] = useState<IFeed[]>();
    const [feedComboBoxOptions, setFeedComboBoxOptions] = useState<IComboBoxOption[]>([]);

    const feedProperties = useAppSelector(feedSelector);
    const feedCategoryProperties = useAppSelector(feedCategorySelector);

    const currentFeedCategory = feedCategoryProperties.currentEntity;
    const feedCategoriesError = feedCategoryProperties.error;
    const rsFeedCategoryState = feedCategoryProperties.state;
    const rsFeeds = feedProperties.entities;

    let { id } = useParams<{ id: string }>();
    if (id == "new") id = "0";
    const feedCategoryId = parseInt(id, 10);

    const comboBoxStyles: Partial<IComboBoxStyles> = {
        root: {
            minWidth: "300px",
            margin: "5px"
        }
    };

    const [annoucement, setAnnouncement] = useState<{ show: boolean; msg: string; onClose: () => void }>({
        show: false,
        msg: "",
        onClose: () => {}
    });

    const [FormState, setFormState] = useState<IFormState>({
        ...EmptyFeedCategory,
        dataSourceOwnerDropdownValues: [],
        dataBusinessOwnerDropdownValues: [],
        secondaryUseAccessApproverDropdownValues: [],
        securityUseAccessApproverDropdownValues: [],
        dataSourceOwnerEmail: "",
        dataBusinessOwnerEmail: "",
        secondaryUseAccessApproverEmail: "",
        securityUseAccessApproverEmail: "",
        dataSourceOwnerName: "",
        dataBusinessOwnerName: "",
        secondaryUseAccessApproverName: "",
        securityUseAccessApproverName: "",
        editDisabled: true,
        errors: {}
    });

    useEffect(() => {
        // if it's not new (new will have id eq 0 then hydrate form from existing)
        if (feedCategoryId > 0) {
            if (currentFeedCategory && currentFeedCategory.id == feedCategoryId) {
                const updatedFormState = { ...FormState, ...currentFeedCategory };

                setFormState(updatedFormState);
            } else {
                dispatch(getFeedCategoryById(feedCategoryId));
            }
        } else if (!currentFeedCategory) {
            // if this is new the first go around currentFeedCategory will be null,
            // this just prevent a flicker of the form being reintialized
            setFormState({
                ...FormState,
                ...EmptyFeedCategory
            });
        }
    }, [currentFeedCategory]);

    useEffect(() => {
        if (feedProperties.state[StateActionType.LoadAll] !== "LOADED_ALL") {
            dispatch(getAllFeeds());
        }
    }, [feedProperties.state[StateActionType.LoadAll]]);

    useEffect(() => {
        if (rsFeedCategoryState[StateActionType.Update] === "UPDATED") {
            setIsDismissed(false);
            setAnnouncement((p) => ({
                ...p,
                msg: `${FormState.name} was successfully updated!`
            }));
        }

        if (rsFeedCategoryState[StateActionType.Update] === "UPDATING") {
            setAnnouncement({
                show: true,
                msg: "",
                onClose: history.goBack
            });
        }
    }, [rsFeedCategoryState]);

    useEffect(() => {
        // clean up on unmount
        return () => {
            if (!isDismissed) {
                // refetch feed categories and feeds to update state with new values
                dispatch(getAllFeeds());
                dispatch(getAllFeedCategories());
                // timeout to allow the form to close before resetting the state
                setTimeout(() => {
                    dispatch(reset(StateActionType.Update));
                }, 500);
            }
        };
    }, [isDismissed]);

    useEffect(() => {
        // we don't want to mess with the form state if when its updating
        if (
            currentFeedCategory &&
            currentFeedCategory.id === feedCategoryId &&
            rsFeedCategoryState[StateActionType.Update] !== "UPDATING"
        ) {
            setFormState({ ...FormState, ...currentFeedCategory });
        }
    }, [currentFeedCategory, rsFeedCategoryState]);

    useEffect(() => {
        if (rsFeeds && currentFeedCategory && currentFeedCategory.id === feedCategoryId) {
            const { feedIds } = { ...currentFeedCategory };
            if (feedIds && feedIds.length > 0) {
                const feedCategoryfeedIds = feedIds;
                const selectedFeeds = rsFeeds.filter(function (feed) {
                    return feedCategoryfeedIds.indexOf(feed.id) > -1;
                });

                setSelectedFeeds(selectedFeeds);
            }
        }
        if (rsFeeds) setFeedComboBoxOptions(getFeedsFromStore(rsFeeds));
    }, [rsFeeds, currentFeedCategory]);

    function onChangeName(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        const updatedFormState = {
            ...FormState,
            name: newValue || ""
        };

        setFormState(updatedFormState);
    }

    function onChangeDescription(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        const updatedFormState = {
            ...FormState,
            description: newValue || ""
        };

        setFormState(updatedFormState);
    }

    function onChangeComments(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        const updatedFormState = {
            ...FormState,
            comments: newValue || ""
        };

        setFormState(updatedFormState);
    }

    function onChangesourceCardLink(
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        const updatedFormState = {
            ...FormState,
            sourceCardLink: newValue || ""
        };

        setFormState(updatedFormState);
    }

    function getFeedsFromStore(feed: IFeed[]): IComboBoxOption[] {
        let feeds: IComboBoxOption[] = [];
        if (feed.length > 0) {
            feeds = feed.map((item) => {
                const value: IComboBoxOption = {
                    key: item.id.toString(),
                    text: item.name
                };
                return value;
            });
        }
        return feeds;
    }

    function _onChangeSelectedFeeds(
        _event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        _index?: number,
        _value?: string
    ): void {
        if (option) {
            const selectedFeedIds = (selectedFeeds && selectedFeeds.map((feed) => feed.id.toString())) || [];
            const key = option.key as string;
            const idx = selectedFeedIds.indexOf(key);
            if (option.selected && idx === -1) {
                selectedFeedIds.push(option.key as string);
            } else if (idx !== -1) {
                selectedFeedIds.splice(idx, 1);
            }

            if (selectedFeedIds && selectedFeedIds.length > 0) {
                const newselectedFeeds =
                    rsFeeds &&
                    rsFeeds.filter(function (feed) {
                        return selectedFeedIds.indexOf(String(feed.id)) > -1;
                    });

                setSelectedFeeds(newselectedFeeds);
            }
        } else return;
    }
    function _onChangeEditToggle(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        const updatedFormState = {
            ...FormState,
            editDisabled: !checked
        };

        setFormState(updatedFormState);
    }
    function formIsValid(): boolean {
        const { name, description, comments, sourceCardLink } = FormState;
        const formErrors: { [index: string]: string } = {};
        if (!name || name.trim() === "") formErrors.name = "Name is required";
        if (description && description.length > 500)
            formErrors.description = "Description cannot be more than 500 characters";
        if (comments && comments.length > 500) formErrors.comments = "Comments cannot be more than 1000 characters";
        if (sourceCardLink && sourceCardLink.length > 500)
            formErrors.sourceCardLink = "Source Card Link cannot be more than 250 characters";
        if (sourceCardLink && !validURL(sourceCardLink)) formErrors.sourceCardLink = "Invalid Url";

        if (Object.keys(formErrors).length !== 0) {
            const updatedFormState = {
                ...FormState,
                errors: formErrors,
                message: ""
            };

            setFormState(updatedFormState);
        }
        return Object.keys(formErrors).length === 0;
    }

    function validURL(str: string) {
        const pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return !!pattern.test(str);
    }
    function _getStateForShimmer(): boolean {
        return id === "0" ? false : rsFeedCategoryState[StateActionType.LoadSingle] == "LOADING_SINGLE";
    }

    function processFormSubmission(): void {
        if (!formIsValid()) return;
        const {
            id,
            name,
            description,
            dataSourceOwnerId,
            dataBusinessOwnerId,
            secondaryUseAccessApproverId,
            securityUseAccessApproverId,
            dataSourceOwnerEmail,
            dataBusinessOwnerEmail,
            secondaryUseAccessApproverEmail,
            securityUseAccessApproverEmail,
            dataSourceOwnerName,
            dataBusinessOwnerName,
            secondaryUseAccessApproverName,
            securityUseAccessApproverName,
            secondaryUseAccessApproverObjectId,
            dataSourceOwnerObjectId,
            dataBusinessOwnerObjectId,
            securityUseAccessApproverObjectId,
            sourceCardLink,
            comments
        } = { ...FormState };

        const currentFeedCategory: IFeedCategory = {
            id,
            name,
            description,
            dataSourceOwnerId,
            dataSourceOwnerEmail,
            dataSourceOwnerName,
            dataSourceOwnerObjectId,
            dataBusinessOwnerId,
            dataBusinessOwnerEmail,
            dataBusinessOwnerName,
            dataBusinessOwnerObjectId,
            secondaryUseAccessApproverId,
            secondaryUseAccessApproverEmail,
            secondaryUseAccessApproverName,
            secondaryUseAccessApproverObjectId,
            securityUseAccessApproverId,
            securityUseAccessApproverEmail,
            securityUseAccessApproverName,
            securityUseAccessApproverObjectId,
            sourceCardLink,
            comments,
            feedIds: selectedFeeds && selectedFeeds.map((feed) => feed.id)
        };

        dispatch(updateFeedCategoryContacts(currentFeedCategory));
    }

    return (
        <>
            <StandardForm widthPercent={90} mediumWidthPercent={90} smallWidthPercent={90}>
                <SaveModal
                    hidden={!annoucement.show}
                    onClose={annoucement.onClose}
                    successMsg={annoucement.msg}
                    error={feedCategoriesError}
                    loading={rsFeedCategoryState[StateActionType.Update] === "UPDATING"}
                />
                <Stack.Item align="end" styles={{ root: { marginBottom: "-50px" } }}>
                    <IconButton
                        title="Close"
                        onClick={() => history.replace("/feedcategories")}
                        iconProps={{ iconName: "Cancel" }}
                        allowDisabledFocus
                    />
                </Stack.Item>
                <StackRow horizontalAlign="space-between" columnWidth={100}>
                    <Toggle
                        label={<Label className="label">Edit</Label>}
                        defaultChecked={false}
                        onText="On"
                        offText="Off"
                        onChange={_onChangeEditToggle}
                    />
                </StackRow>
                <Separator />
                <StackRow>
                    <ShimmeredTextField
                        loading={_getStateForShimmer()}
                        txtLabel={"Name"}
                        value={FormState.name}
                        onChange={onChangeName}
                        styles={{ fieldGroup: { width: 300 } }}
                        errorMessage={FormState.errors["name"]}
                        isStackItem
                        disabled={FormState.editDisabled}
                    />
                    <ShimmeredGraphLookup
                        txtLabel="Data Source Owner"
                        loading={_getStateForShimmer()}
                        formState={FormState}
                        setState={setFormState}
                        stateKey="dataSourceOwner"
                        title="Data Source Owner"
                        editDisabled={FormState.editDisabled}
                    ></ShimmeredGraphLookup>
                    <ShimmeredGraphLookup
                        txtLabel="Secondary Use Access Approver"
                        loading={_getStateForShimmer()}
                        formState={FormState}
                        setState={setFormState}
                        stateKey="secondaryUseAccessApprover"
                        title="Secondary Use Access Approver"
                        editDisabled={FormState.editDisabled}
                    ></ShimmeredGraphLookup>
                </StackRow>
                <StackRow>
                    <ShimmeredLink
                        label="Source Card Link"
                        loading={rsFeedCategoryState[StateActionType.LoadSingle] === "LOADING_SINGLE"}
                        onChange={(value) => setFormState({ ...FormState, sourceCardLink: value })}
                        link={FormState.sourceCardLink || ""}
                        disabled={FormState.editDisabled}
                    />

                    <ShimmeredGraphLookup
                        txtLabel="Data Business Owner"
                        loading={_getStateForShimmer()}
                        formState={FormState}
                        setState={setFormState}
                        stateKey="dataBusinessOwner"
                        title="Data Business Owner"
                        editDisabled={FormState.editDisabled}
                    ></ShimmeredGraphLookup>
                    <ShimmeredGraphLookup
                        txtLabel="Security Use Access Approver"
                        loading={_getStateForShimmer()}
                        formState={FormState}
                        setState={setFormState}
                        stateKey="securityUseAccessApprover"
                        title="Security Use Access Approver"
                        editDisabled={FormState.editDisabled}
                    ></ShimmeredGraphLookup>
                </StackRow>
                <StackRow>
                    <ShimmeredTextField
                        loading={_getStateForShimmer()}
                        txtLabel={"Description"}
                        value={FormState.description}
                        onChange={onChangeDescription}
                        placeholder="Max length: 500 characters"
                        errorMessage={FormState.errors["description"]}
                        isStackItem
                        multiline
                        autoAdjustHeight
                        disabled={FormState.editDisabled}
                    />
                    <ShimmeredTextField
                        loading={_getStateForShimmer()}
                        txtLabel={"Comments"}
                        value={FormState.comments}
                        onChange={onChangeComments}
                        placeholder="Max length: 1000 characters"
                        errorMessage={FormState.errors["comments"]}
                        isStackItem
                        multiline
                        autoAdjustHeight
                        disabled={FormState.editDisabled}
                    />
                    <ShimmeredComboBox
                        loading={_getStateForShimmer()}
                        cbxLabel="Associate New Feeds"
                        styles={comboBoxStyles}
                        selectedKey={selectedFeeds && selectedFeeds.map((feed) => feed.id.toString())}
                        allowFreeform={true}
                        autoComplete="on"
                        options={feedComboBoxOptions}
                        dropdownMaxWidth={400}
                        isStackItem
                        useComboBoxAsMenuWidth={true}
                        onChange={_onChangeSelectedFeeds}
                        multiSelect
                        disabled={FormState.editDisabled}
                    />
                </StackRow>
                <Separator />
                <StackRow columnWidth={100}>
                    <PrimaryButton
                        text="Save"
                        onClick={processFormSubmission}
                        disabled={FormState.editDisabled}
                    ></PrimaryButton>
                </StackRow>
            </StandardForm>

            {selectedFeeds && selectedFeeds.length > 0 && (
                <div className="top-margin25" style={{ position: "relative", height: "100%" }}>
                    <StandardList
                        entityState={feedProperties.state[StateActionType.LoadAll]}
                        entities={selectedFeeds}
                        title="Feeds"
                        columns={generateFeedColumns(history)}
                    />
                </div>
            )}
        </>
    );
}

export default EditFeedCategory;

import { useEffect, useState } from "react";
import { useGetKeyVaultSecretEntriesQuery } from "../reducers/KeyVaultApi";
import StandardList from "../components/common/StandardList";
import { generateKeyVaultSecretColumns } from "../columns/KeyVaultSecretColumns";
import { StateValues } from "../types";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { subscriptionSelector } from "./Selectors";
import { getAllSubscriptions } from "../reducers/Subscription";
import { Stack } from "@fluentui/react";
import { KeyVaultSecretEntry } from "../models/KeyVaultSecretEntry";
import PreferredSelect from "../components/common/PreferredSelect";
import { handleError } from "../components/AccessPackages/utils";

const KeyVaultSecretList = () => {
    const [stateValues, setStateValues] = useState<StateValues>("LOADED_ALL");

    const { entities: subscriptionEntity, error: subscriptionError } = useAppSelector(subscriptionSelector);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [secrets, setSecrets] = useState<KeyVaultSecretEntry[]>();
    const [subscriptionId, setSubscriptionId] = useState<string>();
    const [subscriptionEntries, setSubscriptionEntries] = useState<{ id: string; displayName: string }[]>([]);
    const { data, isLoading, error } = useGetKeyVaultSecretEntriesQuery(subscriptionId, { skip: !subscriptionId });

    const dispatch = useAppDispatch();

    useEffect(() => {
        document.title = "Key Vault Secrets - SIP Portal";
    }, []);

    // reset the state when the subscription changes
    useEffect(() => {
        setStateValues("LOADING_ALL");
        setSecrets(undefined);
    }, [subscriptionId]);

    useEffect(() => {
        if (error) {
            setStateValues("ERROR");
            handleError(new Error("There was an error getting key vault secrets"), setErrorMsg, error);
        }
    }, [error]);
    useEffect(() => {
        if (data) {
            setStateValues("LOADED_ALL");
            setErrorMsg(undefined);
        }
        setSecrets(data);
    }, [data]);

    useEffect(() => {
        if (!subscriptionEntity && !subscriptionError) {
            dispatch(getAllSubscriptions());
        } else if (subscriptionEntity) {
            setSubscriptionEntries(subscriptionEntity.map((sub) => ({ id: sub.id, displayName: sub.name })) || []);
        }
    }, [subscriptionEntity, subscriptionError]);

    return (
        <StandardList
            entities={secrets}
            title={"Key Vault Secrets"}
            error={errorMsg}
            columns={generateKeyVaultSecretColumns()}
            entityState={stateValues}
            extraHeaderItems={
                <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                    <PreferredSelect
                        title="Subscription"
                        entries={subscriptionEntries}
                        selectedEntryId={subscriptionId}
                        setEntryId={setSubscriptionId}
                    />
                </Stack>
            }
        />
    );
};

export default KeyVaultSecretList;

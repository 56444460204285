import {
    DocumentCard,
    DocumentCardActivity,
    DocumentCardTitle,
    DocumentCardLogo,
    DocumentCardStatus,
    IDocumentCardLogoProps,
    IDocumentCardActivityPerson,
    IDocumentCardStyles,
    DocumentCardDetails,
    DocumentCardLocation,
    DocumentCardActions
} from "@fluentui/react/lib/DocumentCard";
import { FontWeights, mergeStyles } from "@fluentui/react/lib/Styling";
import Background from "../images/Hero.png";
import React, { useEffect, useRef, useState } from "react";
import "../styles/App.css";
import { useAppDispatch, useAppSelector } from "../hooks/ReduxHooks";
import { StateActionType } from "../types";
import { powerBIReportsSelector } from "./Selectors";
import { useHistory } from "react-router-dom";
import { Stack, PrimaryButton, Label, IComboBox, IComboBoxOption, Shimmer, ComboBox } from "@fluentui/react";
import { getAllReports, deleteDashboardTile } from "../reducers/PowerBIReports";
import { IPowerBIReports } from "../models";
import { ShimmeredComboBox } from "../components/common/Shimmered";
import { getShimmerStyles } from "../columns/utils";
import { keys } from "lodash";
import store from "../store";

const conversationTileClass = mergeStyles({ height: 182 });
const dropdownControlStyles = {
    root: {
        minWidth: "150px",
        margin: "0 30px 20px 0",
        maxWidth: "450px"
    }
};

function PowerBIReports() {
    const [selectedKeysForCategory, setSelectedKeysForCategory] = React.useState<string[]>([]);
    function DocCard() {
        if (powerBIReportEntity) {
            let doccards: any = [];
            let filterentities =
                selectedKeysForCategory.length > 0
                    ? powerBIReportEntity.filter((p) => selectedKeysForCategory.includes(p.category))
                    : powerBIReportEntity;

            filterentities.forEach((newCol: IPowerBIReports) => {
                let people: IDocumentCardActivityPerson[] = [];

                if (newCol.contacts) {
                    const contact = newCol.contacts.split(";");
                    contact.forEach((c: string) => {
                        people.push({ name: c, profileImageSrc: c.substring(0, 3) });
                    });
                }

                ////function to delete the tile
                function _onClickDeleteButton(powerBIReport: IPowerBIReports): void {
                    if (powerBIReport.id) {
                        let confirmAction = confirm(
                            `Are you sure you want to delete this ${powerBIReport.name} Dashboard?`
                        );
                        if (confirmAction) {
                            store.dispatch(deleteDashboardTile(powerBIReport.id));
                            window.location.reload();
                        }
                    }
                }

                const documentCardActions = [
                    {
                        iconProps: { iconName: "delete" },
                        onClick: () => {
                            _onClickDeleteButton(newCol);
                        }
                    }
                ];

                const temp = (
                    <DocumentCard aria-label={newCol.tags} styles={cardStyles}>
                        <DocumentCardLogo {...logoProps} />
                        <DocumentCardLocation
                            location={newCol.name}
                            locationHref={newCol.link}
                            ariaLabel={newCol.name}
                            styles={{ root: { fontSize: 20 } }}
                        />

                        <div className={conversationTileClass}>
                            <DocumentCardTitle title={newCol.description} shouldTruncate showAsSecondaryTitle />
                            <DocumentCardStatus
                                statusIcon="Certificate"
                                status={newCol.category}
                                styles={{ root: { background: Background } }}
                            />
                        </div>

                        <DocumentCardActivity activity={newCol.sysLastModifiedTime.toString()} people={people} />
                        <DocumentCardActions actions={documentCardActions} />
                    </DocumentCard>
                );

                doccards.push(temp);
            });
            return doccards;
        }
    }

    const logoProps: IDocumentCardLogoProps = {
        logoIcon: "PowerBILogo"
    };

    const dispatch = useAppDispatch();
    const history = useHistory();
    const {
        entities: powerBIReportEntity,
        error: powerBIReportError,
        state: powerBIReportState
    } = useAppSelector(powerBIReportsSelector);
    const addButtonRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (powerBIReportState[StateActionType.LoadAll] === "INIT") {
            dispatch(getAllReports());
        }
    }, []);

    const cardStyles: IDocumentCardStyles = {
        root: { display: "inline-block", marginRight: 20, width: 320 }
    };

    //handle on change for Category
    const onChangeOfCategory = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
        if (option) {
            setSelectedKeysForCategory(
                option.selected
                    ? [...selectedKeysForCategory, option.key as string]
                    : selectedKeysForCategory.filter((key) => key !== option.key)
            );
        }
    };

    const uniqueCategory: any = powerBIReportEntity ? [new Set(powerBIReportEntity.map((item) => item.category))] : [];

    let arr = uniqueCategory && uniqueCategory.length != 0 ? [...uniqueCategory[0]] : [];
    return (
        <div>
            <Shimmer
                isDataLoaded={powerBIReportEntity && powerBIReportEntity.length > 0}
                ariaLabel={"Loading Categories"}
                styles={getShimmerStyles()}
            >
                <ComboBox
                    styles={dropdownControlStyles}
                    selectedKey={selectedKeysForCategory}
                    placeholder="Select All"
                    options={arr ? arr.map((b) => ({ key: b, text: b })) : []}
                    allowFreeform
                    autoComplete="on"
                    onChange={onChangeOfCategory}
                    disabled={false}
                    multiSelect
                />
            </Shimmer>
            <div
                ref={addButtonRef}
                onFocus={() => {
                    addButtonRef.current &&
                        addButtonRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start"
                        });
                }}
            >
                <Stack.Item grow align="auto">
                    <PrimaryButton
                        text="Add New Report"
                        onClick={() => {
                            history.push("/powerBIReports/new");
                            window.location.reload();
                        }}
                        allowDisabledFocus
                    />
                </Stack.Item>
            </div>
            <br />
            <br />
            {DocCard()}
        </div>
    );
}
export default PowerBIReports;

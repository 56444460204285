import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AccessPackageDto, AccessPackagePolicyDetails, AccessPackageView, IContact } from "../models";
import { authProvider, authenticationParameters, getAccessToken } from "../service/authProvider";
import { MigrationSummary } from "../models/Elm/MigrationSummary";
import { AccessPackageInfo } from "../components/AccessPackages/Types";

export const accessPackagesApi = createApi({
    reducerPath: "accessPackagesApi",
    tagTypes: ["AccessPackages", "AccessPackagesDto", "PolicyDetails", "MigrationStatus", "AccessPackageInfo"],
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, api) => {
            if (api.endpoint === "getGroupById") {
                const token = await getAccessToken();
                if (token) {
                    headers.set("authorization", `Bearer ${token.accessToken}`);
                }
                return;
            }

            const token = await authProvider.acquireTokenSilent({
                ...authenticationParameters
            });

            if (token) {
                headers.set("authorization", `Bearer ${token.accessToken}`);
            }
        }
    }),
    endpoints: (builder) => ({
        getAccessPackageView: builder.query<AccessPackageView[], string | undefined>({
            query: (catalogId: string) => ({
                url: `api/EntitlementManagement/getAccessPackageViews/${catalogId}`,
                method: "GET"
            }),
            providesTags: () => [{ type: "AccessPackages" as const, id: "LIST" }],
            keepUnusedDataFor: 120
        }),
        getMigrationSummary: builder.query<MigrationSummary[], string | undefined>({
            query: (catalogId: string) => ({
                url: `api/EntitlementManagement/getMigrationSummary/${catalogId}`,
                method: "GET"
            }),
            providesTags: () => [{ type: "MigrationStatus" as const, id: "LIST" }],
            keepUnusedDataFor: 120
        }),
        getAccessPackage: builder.query<AccessPackageDto, string>({
            query: (accessPackageId: string) => ({
                url: `api/EntitlementManagement/getAccessPackage/${accessPackageId}`,
                method: "GET"
            }),
            providesTags: (result) => [{ type: "AccessPackagesDto" as const, id: result?.id }],
            keepUnusedDataFor: 120
        }),
        getMigrationAccessPackage: builder.query<AccessPackageDto, string>({
            query: (feedId: string) => ({
                url: `api/EntitlementManagement/getMigrationDetails/${feedId}`,
                method: "GET"
            }),
            providesTags: (result) => [{ type: "AccessPackagesDto" as const, id: result?.id }],
            keepUnusedDataFor: 120
        }),
        getAccessPackagePolicyDetails: builder.query<
            AccessPackagePolicyDetails,
            { catalogId: string; accessPackageId: string; isMigration: boolean }
        >({
            query: (params) => ({
                url: `api/EntitlementManagement/getPolicyDetails/${params.catalogId}?accessPackageId=${params.accessPackageId}&isMigration=${params.isMigration}`,
                method: "GET"
            }),
            providesTags: (_r, _e, args) => [
                { type: "PolicyDetails" as const, id: args.catalogId + args.accessPackageId }
            ],
            keepUnusedDataFor: 120
        }),
        addAccessPackage: builder.mutation<AccessPackageDto, AccessPackageDto>({
            query: (accessPackageView) => ({
                url: `api/EntitlementManagement/createAccessPackage`,
                method: "POST",
                body: accessPackageView
            }),
            invalidatesTags: ["AccessPackages"]
        }),
        updateAccessPackage: builder.mutation<AccessPackageDto, AccessPackageDto>({
            query: (accessPackageDto) => ({
                url: `api/EntitlementManagement/updateAccessPackage`,
                method: "PUT",
                body: accessPackageDto
            }),
            invalidatesTags: ["AccessPackages", "AccessPackagesDto", "PolicyDetails"]
        }),
        migrateAccessPackage: builder.mutation<
            AccessPackageDto,
            { accessPackageDto: AccessPackageDto; feedId: string }
        >({
            query: ({ accessPackageDto, feedId }) => ({
                url: `api/EntitlementManagement/migrateAccessPackage/${feedId}`,
                method: "POST",
                body: accessPackageDto
            }),
            invalidatesTags: ["AccessPackages", "MigrationStatus"]
        }),
        getAccessPackageInfo: builder.query<AccessPackageInfo[], string | undefined>({
            query: (catalogId: string) => ({
                url: `api/EntitlementManagement/accessPackageInfo/${catalogId}`,
                method: "GET"
            }),
            providesTags: () => [{ type: "AccessPackageInfo" as const, id: "LIST" }],
            keepUnusedDataFor: 120
        }),
        getGroupById: builder.query<IContact, string | undefined>({
            query: (id: string) => ({
                url: `api/SearchAAD/getGroupById/${id}`,
                method: "GET"
            }),
            keepUnusedDataFor: 120
        }),
        resetMigrationFailures: builder.mutation<void, string>({
            query: (accessPackageId) => ({
                url: `api/EntitlementManagement/resetMigration/${accessPackageId}`,
                method: "GET"
            }),
            invalidatesTags: ["MigrationStatus"]
        }),
        getAllowedAccountTypes: builder.query<string[], void>({
            query: () => ({
                url: `api/EntitlementManagement/getAllowedAccountTypes/`,
                method: "GET"
            })
        })
    })
});

export const {
    useGetAccessPackageViewQuery,
    useAddAccessPackageMutation,
    useUpdateAccessPackageMutation,
    useMigrateAccessPackageMutation,
    useGetMigrationAccessPackageQuery,
    useGetAccessPackagePolicyDetailsQuery,
    useGetAccessPackageQuery,
    usePrefetch,
    useGetMigrationSummaryQuery,
    useGetAccessPackageInfoQuery,
    useGetGroupByIdQuery,
    useResetMigrationFailuresMutation,
    useGetAllowedAccountTypesQuery
} = accessPackagesApi;

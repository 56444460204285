import { History } from "history";
import {
    IButtonStyles,
    IContextualMenuProps,
    IconButton,
    ShimmerElementType,
    ShimmerElementsGroup
} from "@fluentui/react";
import React from "react";
import { StandardEntity } from "../components/common/StandardList";
import { Shimmer } from "@fluentui/react";
import { IStandardColumn } from "../models/StandardColumn";

export const getShimmerStyles = (padding = "8px 0") => {
    return {
        shimmerWrapper: [{ padding, backgroundColor: "#deecf9", zIndex: 9998 }],
        shimmerGradient: [
            {
                backgroundColor: "#deecf9",
                backgroundImage:
                    "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #c7e0f4 50%, rgba(255, 255, 255, 0) 100%)"
            }
        ]
    };
};
export function _renderShimmer() {
    return <Shimmer width={"200px"} styles={getShimmerStyles()} />;
}

export function ShimmeredPersona() {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <ShimmerElementsGroup
                shimmerElements={[
                    { type: ShimmerElementType.circle, height: 40 },
                    { type: ShimmerElementType.gap, width: 10, height: 40 }
                ]}
            />
            <ShimmerElementsGroup
                flexWrap
                width={"calc(100% - 50px)"}
                shimmerElements={[
                    { type: ShimmerElementType.line, width: "90%", height: 10 },
                    { type: ShimmerElementType.gap, width: "10%", height: 20 },
                    { type: ShimmerElementType.line, width: "100%", height: 10 }
                ]}
            />
        </div>
    );
}

export function renderViewColumn(item: StandardEntity, path: string, history: History) {
    if (item.name === "shimmer") {
        return _renderShimmer();
    }
    const url = window.location.origin + `/${path}/${item.id}`;
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: "newTab",
                text: "Open in New Tab",
                iconProps: {
                    iconName: "OpenInNewWindow"
                },
                onClick: () => {
                    window.open(url, "_blank");
                }
            }
        ]
    };
    const customSplitButtonStyles: IButtonStyles = {
        splitButtonMenuButton: { backgroundColor: "inherit", width: 28, border: "none" },
        splitButtonMenuIcon: { fontSize: "7px" },
        splitButtonDivider: { backgroundColor: "#c8c8c8", width: 1, right: 26, position: "absolute", top: 4, bottom: 4 }
    };

    return (
        <IconButton
            split
            styles={customSplitButtonStyles}
            title={item.name + " View Button"}
            className="routing"
            onClick={() => {
                history.push(`/${path}/${item.id}`);
            }}
            menuProps={menuProps}
            iconProps={{ iconName: "View" }}
            allowDisabledFocus
        />
    );
}
export function customRender(item: StandardEntity, customRenderCallback: () => any) {
    if (item.name === "shimmer") {
        return _renderShimmer();
    }
    return customRenderCallback();
}
export function addSortLabels(cols: IStandardColumn[]) {
    for (const col of cols) {
        col.sortAscendingAriaLabel = `Sorted by ${col.name} ascending`;
        col.sortDescendingAriaLabel = `Sorted by ${col.name} descending`;
    }
}

import { IDataFactory } from "../models/DataFactory";
import { BaseAction, StateValues } from ".";
export enum DataFactoryActionTypes {
    GETALL = "DATAFACTORIES/GETALL",
    GETSINGLE = "DATAFACTORIES/GETSINGLE",
    INIIALIZE = "DATAFACTORIES/INIT",
    EDITDATAFACTORY = "DATAFACTORIES/EDIT",
    SEARCHDATAFACTORY = "DATAFACTORIES/SEARCH",
    LOADING = "DATAFACTORIES/LOADING",
    LOADINGERROR = "DATAFACTORIES/ERROR",
    TESTDATAFACTORY = "TEST/DATAFACTORY"
}

// adding a test default action that calls the reducer default.
interface IDataFactoryTest extends BaseAction {
    type: DataFactoryActionTypes.TESTDATAFACTORY;
}

export interface IDataFactoryError extends BaseAction<string> {
    type: DataFactoryActionTypes.LOADINGERROR;
}

export interface IDataFactoryGetAllAction extends BaseAction<IDataFactory[]> {
    type: DataFactoryActionTypes.GETALL;
}

export interface IDataFactoryGetSingleAction extends BaseAction<IDataFactory> {
    type: DataFactoryActionTypes.GETSINGLE;
}

export interface IDataFactoryLoading extends BaseAction {
    type: DataFactoryActionTypes.LOADING;
}

export interface IDataFactoryInit extends BaseAction {
    type: DataFactoryActionTypes.INIIALIZE;
}

export interface IDataFactoryEditAction extends BaseAction<IDataFactory> {
    type: DataFactoryActionTypes.EDITDATAFACTORY;
}

export interface IDataFactorySearchAction extends BaseAction<string> {
    type: DataFactoryActionTypes.SEARCHDATAFACTORY;
}

export type DataFactoryActions =
    | IDataFactoryTest
    | IDataFactoryGetAllAction
    | IDataFactoryGetSingleAction
    | IDataFactoryEditAction
    | IDataFactoryInit
    | IDataFactorySearchAction
    | IDataFactoryLoading
    | IDataFactoryError;

export interface IDataFactoryState {
    readonly currentDataFactory: IDataFactory | null;
    readonly dataFactories: IDataFactory[];
    readonly dataFactoriesLoading: boolean;
    readonly dataFactoriesState: StateValues;
    readonly dataFactoriesSearch: string;
    readonly dataFactoriesError: string;
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAdlsCapacityTrend } from "../models";
import { onComplete, getInitialState, StateActionType, onLoading } from "../types";
import { getCapacityMetrics, getSizingMetricsByPlatform } from "../api/AdlsCapacityTrendApi";
import { getDefaultCases } from "./DefaultCases";

const sliceName = "adlsCapacityTrend";

const initialState = getInitialState<IAdlsCapacityTrend, IAdlsCapacityTrend>();

const getAllCapacityMetrics = createAsyncThunk(`${sliceName}/get_all`, async (_thunkAPI) => {
    const response = await getCapacityMetrics();
    return response.data;
});

const getAllSizingMetricsByPlatform = createAsyncThunk(
    `${sliceName}/get_allSizingMetricsByPlatform`,
    async (platform: string[], _thunkAPI) => {
        const response = await getSizingMetricsByPlatform(platform);
        return response.data;
    }
);

const adlsCapacityTrendSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: getDefaultCases<IAdlsCapacityTrend>(),
    extraReducers: (builder) => {
        builder.addCase(getAllCapacityMetrics.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllCapacityMetrics.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllSizingMetricsByPlatform.pending, (state, _action) => {
            onLoading(state, StateActionType.LoadAll);
        });
        builder.addCase(getAllSizingMetricsByPlatform.fulfilled, (state, action) => {
            state.entities = action.payload;
            onComplete(state, StateActionType.LoadAll);
        });
    }
});

export const { reset, error, setCurrent } = adlsCapacityTrendSlice.actions;
export { getAllCapacityMetrics, getAllSizingMetricsByPlatform, sliceName };
export const { reducer } = adlsCapacityTrendSlice;

import React from "react";
import { DialgContainer } from "./DialogContainer";
import { DialogFooter, PrimaryButton } from "@fluentui/react";
import DialogContainerProps from "../../models/dialogProps/DialogContainerProps";

export type ActionAnnouncementProps = {
    title: string;
    showFooter?: boolean;
} & DialogContainerProps;

function ActionAnnouncement(props: ActionAnnouncementProps) {
    return (
        <DialgContainer
            {...{
                ...props,
                minHeight: "0"
            }}
        >
            {props.showFooter !== false && (
                <DialogFooter>
                    <PrimaryButton text="Ok" onClick={props.onClose} />
                </DialogFooter>
            )}
        </DialgContainer>
    );
}

export default ActionAnnouncement;

import axios, { getHeaders } from "./SipMonClient";
import { ISipApiResponse, IPowerBIReports } from "../models";
import { sliceName } from "../reducers/PowerBIReports";
import { StateActionType } from "../types";

export const getReports = async (): Promise<ISipApiResponse<IPowerBIReports[]>> => {
    const { data, status } = await axios.get<IPowerBIReports[]>(
        `api/powerBIReports`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return {
        data,
        message: "",
        status
    };
};

export const createReport = async (
    name: string,
    description: string,
    link: string,
    tags: string,
    repoLink: string,
    contacts: string,
    category: string
): Promise<ISipApiResponse<IPowerBIReports>> => {
    const { data, status } = await axios.post<IPowerBIReports>(
        `api/powerBIReports`,
        { name: name, description, link, tags, repoLink, contacts, category },
        getHeaders(sliceName, StateActionType.Add)
    );
    return { data, message: "", status };
};

export const deleteDashboardTileApi = async (id: number): Promise<ISipApiResponse<IPowerBIReports>> => {

    const { data, status } = await axios.delete(
        `api/powerBIReports/${id}`,
        getHeaders(sliceName, StateActionType.Delete)
    );

    return { data, message: "", status };
};
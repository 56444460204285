import { useEffect, useState } from "react";
import useParamsInt from "../../hooks/UseParamsInt";
import { ShimmeredText } from "../common/Shimmered";
import { StackRow } from "../common/StackComponents";
import StandardForm from "../common/StandardForm";
import { LoadingOverLay } from "../common/LoadingOverLay";
import { Stack, IconButton, Separator, DefaultButton } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import SaveButtonContainer from "../Dataset/SaveButtonContainer";
import CrudStatusDialogV2 from "../common/CrudStatusDialogV2";
import { getErrorMessage } from "../AccessPackages/utils";
import DismissModal from "../DataValidation/DismissModal";
import { useDismissMutation, useGetDQMValidationResultQuery } from "./api";

export function EditQMValidation() {
    const id = useParamsInt("id") as number;

    const { data, isLoading, error, isError } = useGetDQMValidationResultQuery(id, { skip: !id });
    const [dismiss, dismissResult] = useDismissMutation();
    const [errorMsg, setErrorMsg] = useState<string>();

    const history = useHistory();

    const [showDialog, setShowDialog] = useState(false);
    useEffect(() => {
        if (dismissResult.isSuccess || dismissResult.isError) {
            setShowDialog(true);
        }
    }, [dismissResult.isError, dismissResult.isSuccess]);

    useEffect(() => {
        if (error) {
            const strError = getErrorMessage(error);
            setErrorMsg(strError);
        } else {
            setErrorMsg(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (dismissResult.error) {
            const strError = getErrorMessage(dismissResult.error);
            setErrorMsg(strError);
        } else {
            setErrorMsg(undefined);
        }
    }, [dismissResult.error]);

    const loading = isLoading && !isError;

    return (
        <>
            <CrudStatusDialogV2
                errorMsg={errorMsg}
                success={dismissResult.isSuccess}
                show={showDialog}
                message={"Updated Successfully"}
                onClose={() => setShowDialog(false)}
            />
            <LoadingOverLay isOverlayVisible={!!loading || dismissResult.isLoading} />
            <StandardForm widthPercent={50} mediumWidthPercent={90} smallWidthPercent={90}>
                <Stack.Item align="end" style={{ textAlign: "right" }}>
                    <IconButton
                        title="Close"
                        className="routing"
                        onClick={() => {
                            history.push("/qualityMetricsValidations");
                        }}
                        iconProps={{ iconName: "Cancel" }}
                        allowDisabledFocus
                    />
                </Stack.Item>
                <StackRow columnWidth={40}>
                    <ShimmeredText loading={loading} txtLabel="Name">
                        <a href={data?.catalogURL} target="_blank" title="View in Catalog">{data?.name}</a>
                    </ShimmeredText>
                    <ShimmeredText loading={loading} txtLabel="Type">
                        {data?.type}
                    </ShimmeredText>
                </StackRow>
                <StackRow columnWidth={40}>
                    <ShimmeredText loading={loading} txtLabel="Dismissed By">
                        <Stack>
                            <Stack.Item>{data?.dismissedBy}</Stack.Item>

                            <Stack.Item>
                                {data?.dismissedOn ? new Date(data?.dismissedOn).toLocaleString() : ""}
                            </Stack.Item>
                        </Stack>
                    </ShimmeredText>

                    <ShimmeredText loading={loading} txtLabel="Comment">
                        {data?.comment}
                    </ShimmeredText>
                </StackRow>
                <Separator />
                <SaveButtonContainer topPadding={0}>
                    <DismissModal dismiss={dismiss} id={id} />
                    <DefaultButton onClick={() => history.push("/qualityMetricsValidations")} text="Cancel" />
                </SaveButtonContainer>
            </StandardForm>
        </>
    );
}

import { IListProps, Label, List, Shimmer } from "@fluentui/react";
import { getShimmerStyles } from "../../../columns/utils";
type ShimmeredProps = {
    loading: boolean;
    lsLabel: string;
    width?: string;
    isStackItem?: boolean;
} & IListProps;

function ShimmeredList(props: ShimmeredProps) {
    return (
        <div className={props.isStackItem ? "" : "col"}>
            <Label className="label"> {props.lsLabel} </Label>
            <Shimmer isDataLoaded={!props.loading} ariaLabel={`Loading ${props.lsLabel}`} styles={getShimmerStyles()}>
                <List aria-label={props.lsLabel} {...props} />
            </Shimmer>
        </div>
    );
}

export { ShimmeredList };

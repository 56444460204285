import axios from "axios";
import { ISipApiResponse } from "../models";
import { IDirectoryAudit } from "../models/DirectoryAudit";
import { sliceName } from "../reducers/DirectoryAudit";
import { StateActionType } from "../types";
import { getHeaders } from "./SipMonClient";

export const getDirAudit = async (): Promise<ISipApiResponse<IDirectoryAudit[]>> => {
    const { data, status } = await axios.get<IDirectoryAudit[]>(
        `api/SearchAAD/directoryOwnership`,
        getHeaders(sliceName, StateActionType.LoadAll)
    );
    return {
        data,
        message: "",
        status
    };
};
export const getDirAuditById = async (id: string): Promise<ISipApiResponse<IDirectoryAudit>> => {
    const { data, status } = await axios.get<IDirectoryAudit>(
        `api/SearchAAD/directoryOwnershipById/${id}`,
        getHeaders(sliceName, StateActionType.LoadSingle)
    );
    return {
        data,
        message: "",
        status
    };
};
export const updateDirAudit = async (directoryAudit: IDirectoryAudit): Promise<ISipApiResponse<IDirectoryAudit>> => {
    const { data, status } = await axios.put<IDirectoryAudit>(
        `api/SearchAAD/directoryOwnership/${directoryAudit.id}`,
        directoryAudit,
        getHeaders(sliceName, StateActionType.Update)
    );
    return {
        data,
        message: "",
        status
    };
};
